import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';

import { Button } from './../Button';
import { BaseModal } from './BaseModal';

interface IProps {
  onClose?: () => void;
  onSubmit?: () => void;
  open: boolean;
}

export function ExitConfirmationModal({ onClose, onSubmit, open }: IProps) {
  const { t } = useTranslation();
  return (
    <BaseModal
      hideCloseButton={true}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: 558,
          borderRadius: 4,
          padding: 1,
        },
      }}
      title={t('modals.exitConfirmation.title', 'Your progress will be lost')}
      actionBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          pb={1}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            sx={{
              fontWeight: FontWeight.medium,
              color: 'secondary.main',
              fontSize: '1.3125rem',
              width: 130,
            }}
          >
            {t('common.actions.cancel', 'Cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            sx={{
              padding: (theme) => theme.spacing(1, 4),
              fontWeight: FontWeight.medium,
              fontSize: '1.3125rem',
            }}
          >
            {t('common.actions.exit', 'Exit')}
          </Button>
        </Stack>
      }
    >
      <Typography
        fontWeight={FontWeight.regular}
        lineHeight="145%"
        sx={{ color: 'grey.80' }}
      >
        {t(
          'modals.exitConfirmation.detail',
          'Are you sure you want to continue?',
        )}
      </Typography>
    </BaseModal>
  );
}
