import { FormControl, InputBaseProps, FormHelperText } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { StyledInput } from '../StyledInput';
import { StyledLabel } from '../StyledLabel';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label?: string;
}

export function BasicInputWithLabel<T extends FieldValues>({
  control,
  label,
  name,
  ...inputProps
}: IProps<T> & InputBaseProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth>
          <StyledLabel htmlFor={`${name}-basic-input`} shrink>
            {label}
          </StyledLabel>
          <StyledInput
            {...field}
            id={`${name}-basic-input`}
            onChange={(event) =>
              field.onChange(
                inputProps.type === 'number'
                  ? parseFloat(event.target.value)
                  : event.target.value,
              )
            }
            {...inputProps}
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
