import { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FullscreenLoader } from '../../components/Loaders';
import { ShareQuoteModal } from '../../components/Modals';
import { User } from '../../graphql/generated';
import { useMockDataMutation } from '../../hooks';
import { IQuoteOption } from '../../store/quoter';

// TODO: get successfully shared quotes response
const mockSharedQuotesResponse = {
  shareQuotes: {
    message: 'Quotes shared successfully',
  },
};

interface IProps {
  client: User | undefined;
  open: boolean;
  setShareQuotesOpen: (open: boolean) => void;
  quotes: IQuoteOption[];
}

export function ShareQuoteModalContainer({
  client,
  open = false,
  setShareQuotesOpen,
  quotes,
}: IProps) {
  const navigate = useNavigate();
  // TODO: replace with real mutation state once we know the shape for shared emails
  const [shareEmails, setShareEmails] = useState('');

  // hooks
  const [
    shareQuotesMutation,
    { data: shareQuotesData, isLoading: shareQuotesLoading },
  ] = useMockDataMutation({
    mockResponse: mockSharedQuotesResponse,
    mockDelay: 2000,
  });

  // handlers
  const closeShareModal = useCallback(() => {
    setShareQuotesOpen(false);
  }, [setShareQuotesOpen]);

  const onAddEmailHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const emails = event.target.value;

      setShareEmails(emails);
    },
    [],
  );

  const onClientSendHandler = useCallback(async () => {
    const payload = {
      shareQuoteIds: quotes
        .filter((quote) => quote.selected)
        .map((quote) => quote.id),
      clientEmail: client?.email.toLowerCase().replace(/ /g, '').split(','),
    };

    // alert('payload:\n' + JSON.stringify(payload, null, 2));

    const sendQuotes = await shareQuotesMutation({
      input: payload,
    });

    // alert('sendQuotes:\n' + JSON.stringify(sendQuotes, null, 2));
  }, [client, quotes, shareQuotesMutation]);

  const onSubmitHandler = useCallback(async () => {
    const payload = {
      shareQuoteIds: quotes
        .filter((quote) => quote.selected)
        .map((quote) => quote.id),
      shareEmails: shareEmails.toLowerCase().replace(/ /g, '').split(','),
    };

    // alert('payload:\n' + JSON.stringify(payload, null, 2));

    const sharedQuotes = await shareQuotesMutation({
      input: payload,
    });

    // alert('sharedQuotes:\n' + JSON.stringify(sharedQuotes, null, 2));

    navigate('/quoter/success');
  }, [quotes, navigate, shareEmails, shareQuotesMutation]);

  return (
    <Fragment>
      {shareQuotesLoading && (
        <FullscreenLoader>Sharing Quotes...</FullscreenLoader>
      )}
      <ShareQuoteModal
        client={client}
        clientEmailSent={
          shareQuotesData?.shareQuotes?.message === 'Quotes shared successfully'
        }
        isLoading={shareQuotesLoading}
        onClose={closeShareModal}
        open={open}
        onClientSend={onClientSendHandler}
        onSubmit={onSubmitHandler}
        onAddEmail={onAddEmailHandler}
        shareEmails={shareEmails}
      />
    </Fragment>
  );
}
