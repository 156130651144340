import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Paper } from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CoverageOptionsInfoDrawer,
  DrawerContent,
} from '../CoverageOptionsInfoDrawer';
import { HorizontalRuler } from '../HorizontalRuler';
import { ContainerLoader } from '../Loaders';

import {
  getCustomizeCoverageSchema,
  ICustomizeCoverageSchema,
  ICustomizeCoverageSchemaDeepParial,
} from './CustomizeCoverageOption.schema';
import { OptionFooter } from './OptionFooter';
import { OptionHeader } from './OptionHeader';
import {
  CoverageSection,
  DeductibleSection,
  EndorsementSection,
  MainCoverageSection,
  ParametricExpenseSection,
  WaterDamageSection,
} from './Sections';
import {
  COVERAGE_B_PERCENTAGES,
  COVERAGE_C_PERCENTAGES,
  HURRICANE_DEDUCTIBLE_OPTIONS,
  HURRICANE_DEDUCTIBLE_PERCENTAGES,
  ORDINANCE_OR_LAW_PERCENTAGES,
} from './constants';
import {
  formatCurrencyOptions,
  formatPercentageOptions,
  getContainerWidthBySize,
} from './helpers';

export const BORDER_RADIUS = 4;

interface IProps {
  coverageAmount?: number;
  initialValues?: ICustomizeCoverageSchemaDeepParial;
  isLoading?: boolean;
  monthlyPaymentAmount?: number;
  onChange?: (values: ICustomizeCoverageSchemaDeepParial) => void;
  onRemoveQuote?: (requestedIndex: number) => void;
  optionNumber: number;
  size: ISize;
}

export function CustomCoverageOption({
  coverageAmount,
  initialValues,
  isLoading,
  monthlyPaymentAmount,
  onChange,
  onRemoveQuote,
  optionNumber,
  size,
}: IProps) {
  // hooks
  const { t } = useTranslation();
  const isMountedRef = useRef(false);

  const { control, handleSubmit, watch, getValues } =
    useForm<ICustomizeCoverageSchema>({
      defaultValues: {
        ...initialValues,
      },
      resolver: zodResolver(getCustomizeCoverageSchema()),
    });

  // state
  const [infoDrawerIndex, setInfoDrawerIndex] = useState<number>(-1);

  const debouncedOnChange = useMemo(
    () =>
      debounce((values: ICustomizeCoverageSchemaDeepParial) => {
        onChange?.(values);
      }, 2000),
    [onChange],
  );

  // side-effects
  // --/ on first mount send initial values to parent
  useEffect(() => {
    if (!isMountedRef.current) {
      onChange?.(getValues());

      isMountedRef.current = true;
    }
  }, [getValues, onChange]);

  useEffect(() => {
    const subscription = watch(debouncedOnChange);
    return () => subscription.unsubscribe();
  }, [debouncedOnChange, handleSubmit, watch]);

  // computed values
  const coverageA = watch('mainHomeCoverage');

  // -- percentage of Coverage I/Coverage A Options
  const coverageBOptions = useMemo(() => {
    return formatPercentageOptions(
      parseFloat(coverageA || '0'),
      COVERAGE_B_PERCENTAGES,
    );
  }, [coverageA]);

  const coverageCOptions = useMemo(() => {
    return formatPercentageOptions(
      parseFloat(coverageA || '0'),
      COVERAGE_C_PERCENTAGES,
    );
  }, [coverageA]);

  const ordinanceOrlawPercentages = useMemo(() => {
    return formatPercentageOptions(
      parseFloat(coverageA || '0'),
      ORDINANCE_OR_LAW_PERCENTAGES,
    );
  }, [coverageA]);

  const hurricaneDeductibleOptions = useMemo(() => {
    return [
      ...formatCurrencyOptions(HURRICANE_DEDUCTIBLE_OPTIONS),
      ...formatPercentageOptions(
        parseFloat(coverageA || '0'),
        HURRICANE_DEDUCTIBLE_PERCENTAGES,
      ),
    ];
  }, [coverageA]);

  // handlers
  const handleInfoDrawerClose = () => setInfoDrawerIndex(DrawerContent.Closed);
  const handleInfoDrawerCoverage = () =>
    setInfoDrawerIndex(DrawerContent.Coverage);
  const handleInfoDrawerDeductibles = () =>
    setInfoDrawerIndex(DrawerContent.Deductibles);
  const handleInfoDrawerParametric = () =>
    setInfoDrawerIndex(DrawerContent.ParametricExpense);

  return (
    <Paper
      component="form"
      sx={{
        borderRadius: BORDER_RADIUS,
        maxWidth: getContainerWidthBySize(size),
      }}
    >
      <CoverageOptionsInfoDrawer
        open={infoDrawerIndex}
        onClose={handleInfoDrawerClose}
      />

      <OptionHeader
        coverageAmount={coverageAmount}
        isLoading={isLoading}
        monthlyPaymentAmount={monthlyPaymentAmount}
        onRemoveQuote={onRemoveQuote}
        optionNumber={optionNumber}
        quoteId={initialValues?.id || ''}
        size={size}
      />
      <Box
        sx={{
          bgcolor: 'grey.10',
          paddingX: size === 'sm' ? 4 : 5,
          paddingY: 3,
          position: 'relative',
        }}
      >
        <ContainerLoader sx={{ borderRadius: 4 }} sticky visible={isLoading}>
          {t('common.loaders.quoteGenerating', 'Generating quote ...')}
        </ContainerLoader>
        <MainCoverageSection
          control={control}
          size={size}
          onInfoClick={handleInfoDrawerCoverage}
        />
        <HorizontalRuler />
        <CoverageSection
          control={control}
          coverageBOptions={coverageBOptions}
          coverageCOptions={coverageCOptions}
          size={size}
          onInfoClick={handleInfoDrawerCoverage}
        />
        <HorizontalRuler />
        <WaterDamageSection control={control} size={size} />
        <HorizontalRuler />
        <ParametricExpenseSection
          control={control}
          size={size}
          onInfoClick={handleInfoDrawerParametric}
        />
        <HorizontalRuler />
        <DeductibleSection
          control={control}
          onInfoClick={handleInfoDrawerDeductibles}
          hurricaneDeductibleOptions={hurricaneDeductibleOptions}
          size={size}
        />
        <HorizontalRuler />
        <EndorsementSection
          control={control}
          percentageOfAOptions={coverageBOptions}
          ordinanceOrlawPercentages={ordinanceOrlawPercentages}
          size={size}
        />
      </Box>
      <OptionFooter />
    </Paper>
  );
}
