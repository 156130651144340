import { styled } from '@mui/material';
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';

export const StyledDataTable = styled(MUIDataGrid)(({ theme }) => ({
  color: theme.palette.grey[80],
  border: 'none',
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: `solid 1px ${theme.palette.grey[50]}`,
    },
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      display: 'none',
    },
  '& .MuiCheckbox-root.Mui-checked': {
    color: '#5D5FEF',
  },
  '& .MuiDataGrid-checkboxInput': {
    color: theme.palette.grey[30],
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-iconSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.grey[70],
    fontSize: '0.8125rem',
  },
  '& .MuiDataGrid-columnHeaders': {
    paddingBottom: '0px',
  },
  '& .MuiTablePagination-displayedRows': {
    color: theme.palette.grey[80],
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-selectedRowCount': {
    visibility: 'hidden',
  },
  '& .custom-header-class': {
    paddingTop: theme.spacing(2),
  },
  '& .status-cell': {
    fontStyle: 'italic',
  },
}));
