import { useCallback, useState } from 'react';

import { delay } from '../helpers';

interface IArgs<Res, Err, Var = any> {
  mockResponse?: Res;
  mockError?: Err;
  mockDelay?: number;
  mockVariables?: Var;
}

export function useMockDataMutation<Res, Err, Var = any>({
  mockDelay = 500,
  mockError,
  mockResponse,
}: IArgs<Res, Err, Var>): [
  (variables?: Var) => Promise<Res | Err>,
  {
    data: Res | null;
    error: Err | null;
    isLoading: boolean;
    reset: () => void;
  },
] {
  const [data, setData] = useState<Res | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Err | null>(null);

  const mutate = useCallback(
    async (variables?: Var): Promise<Res | Err> => {
      setLoading(true);

      await delay(mockDelay);

      if (mockError) {
        setData(null);
        setError(mockError);
      } else {
        setData(mockResponse || null);
        setError(null);
      }

      setLoading(false);

      // at least one needs to be returned
      if (!mockResponse && !mockError) {
        throw new Error('No mock response or error provided');
      }

      // at least one of these are defined and not null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return mockError || mockResponse!;
    },
    [mockDelay, mockError, mockResponse],
  );

  const reset = useCallback(() => {
    setData(null);
    setError(null);
    setLoading(false);
  }, []);

  return [
    mutate,
    {
      data,
      error,
      isLoading,
      reset,
    },
  ];
}
