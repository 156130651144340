import { Stack, styled, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { shadowBoxes } from '../../config/theme/base';

export interface IQuoteProps {
  id: string;
  coverage: string;
  parametric: string;
  startDate: string;
  status: string;
  createdAt: string;
}

const QuoteRowContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey['00'],
  color: theme.palette.grey[80],
  borderRadius: theme.spacing(2),
  boxShadow: shadowBoxes.type1,
  height: '54px',
  width: '100%',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
}));

const RowText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[80],
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: '15px',
  lineHeight: '21.75px',
  textAlign: 'left',
  minWidth: '100px',
  flex: 1,
  whiteSpace: 'nowrap',
  overflowX: 'clip',
  textOverflow: 'ellipsis',
}));

export function QuoteRow({
  id,
  coverage,
  parametric,
  startDate,
  status,
  createdAt,
}: IQuoteProps) {
  return (
    <QuoteRowContainer direction="row" gap={4}>
      <RowText>{id}</RowText>
      <RowText>
        <NumericFormat
          displayType="text"
          prefix="$"
          value={coverage}
          thousandSeparator
        />
      </RowText>
      <RowText>
        <NumericFormat
          displayType="text"
          prefix="$"
          value={parametric}
          thousandSeparator
        />
      </RowText>
      <RowText sx={{ minWidth: '60px' }}>Quote</RowText>
      <RowText>{startDate}</RowText>
      <RowText
        sx={{
          flex: 2,
          color: status === 'Expired' ? 'error.main' : 'grey.80',
        }}
      >
        {status}
      </RowText>
      <RowText sx={{ textAlign: 'right' }}>{createdAt}</RowText>
    </QuoteRowContainer>
  );
}
