import { Button, Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FontWeight } from '../../config/theme/typography';
import { useBindingStore } from '../../hooks/useBindingStore';

export function QuoterApplicationSuccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { input } = useBindingStore();

  return (
    <Container
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 11,
        width: '782px',
      }}
    >
      <Stack gap={2}>
        <Typography variant="h1" fontSize="40px" lineHeight="56px">
          {t('pages.quoterApplicationSuccessPage.title', 'Success!')}
        </Typography>
        <Typography variant="subtitle2" lineHeight="24.65px">
          {t(
            'pages.quoterApplicationSuccessPage.description',
            'The application has been successfully sent to your client. Please ensure they received it. If your client informs you they have not received the email, instruct them to check spam folders and reach out to support for more help.',
          )}
        </Typography>
      </Stack>
      <Divider
        sx={{
          border: '0.5px solid',
          borderColor: 'grey.40',
          marginTop: 6,
          marginBottom: 5,
        }}
      />
      <Stack gap={2}>
        <Typography variant="h2" fontSize="28px" lineHeight="40.6px">
          {t('pages.quoterApplicationSuccessPage.nextSteps', 'Next Steps')}
        </Typography>
        <Typography variant="subtitle2" lineHeight="24.65px">
          {t(
            'pages.quoterApplicationSuccessPage.nextStepsDetails',
            'After the client signs the application, they can sign up for payment. You can always track this progress of this application on the quote detaisl page.',
          )}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        color="secondary"
        sx={{ width: '243px', marginTop: 7, alignSelf: 'center' }}
        onClick={() => navigate('/quotes/' + input?.quoteId)}
      >
        <Typography
          variant="h2"
          fontWeight={FontWeight.medium}
          lineHeight="30.45px"
          color="grey.00"
        >
          {t(
            'pages.quoterApplicationSuccessPage.viewQuoteDetails',
            'View Quote Details',
          )}
        </Typography>
      </Button>
    </Container>
  );
}
