import {
  Box,
  BoxProps,
  Step,
  StepLabelProps,
  Stepper,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import {
  FirstIcon,
  LastIcon,
  PolicyCustomStepIcon,
} from './PolicyCustomStepIcon';
import {
  StyledConnector,
  StyledStepContent,
  StyledStepLabel,
} from './PolicyStyledStepperComps';

export interface IStep extends StepLabelProps {
  label: string;
  description?: string;
  eventDate?: string;
  icon?: JSX.Element;
}

interface IProps {
  steps: IStep[];
  setActiveStep?: Dispatch<SetStateAction<number>>;
  title: string;
}

export function PolicyVerticalProgressStepper({
  steps,
  title,
  ...props
}: IProps & BoxProps) {
  return (
    <Box {...props}>
      <Typography
        sx={{ fontWeight: 600, fontSize: '1.75rem', lineHeight: 1.45, pb: 2 }}
      >
        {title}
      </Typography>
      <Stepper orientation="vertical" connector={<StyledConnector />}>
        {steps.map((step, index) => (
          <Step key={index} expanded>
            {index === 0 ? (
              <StyledStepLabel StepIconComponent={FirstIcon}>
                {step.label}
              </StyledStepLabel>
            ) : step.label === 'Claim approved' ? (
              <StyledStepLabel StepIconComponent={LastIcon}>
                {step.label}
              </StyledStepLabel>
            ) : (
              <StyledStepLabel
                StepIconComponent={PolicyCustomStepIcon}
                StepIconProps={{
                  icon: step.icon,
                }}
              >
                {step.label}
              </StyledStepLabel>
            )}
            <StyledStepContent>
              <Typography
                sx={{
                  color: 'grey.70',
                  fontSize: '0.8125rem',
                  fontWeight: 400,
                  paddingLeft: 1,
                  marginTop: -1.2,
                }}
              >
                {step.description}
              </Typography>
              <Typography
                sx={{
                  color: 'grey.70',
                  fontSize: '0.8125rem',
                  fontWeight: 400,
                  paddingLeft: 1,
                  paddingTop: '4px',
                }}
              >
                {step.eventDate}
              </Typography>
            </StyledStepContent>
          </Step>
        ))}
        {steps.length === 1 && <StyledConnector gradient />}
      </Stepper>
    </Box>
  );
}
