import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Paper(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M7.5 10.15a.75.75 0 0 0 0 1.5h5.4a.75.75 0 0 0 0-1.5H7.5ZM7.5 13.75a.75.75 0 0 0 0 1.5h2.7a.75.75 0 0 0 0-1.5H7.5Z"
        fill="currentColor"
      />
      <path
        d="M4.8.25A2.55 2.55 0 0 0 2.25 2.8v14.4a2.55 2.55 0 0 0 2.55 2.55h10.8a2.55 2.55 0 0 0 2.55-2.55V6.708a2.55 2.55 0 0 0-.767-1.824L13.386.977A2.55 2.55 0 0 0 11.604.25H4.8Zm-.742 1.808A1.05 1.05 0 0 1 4.8 1.75h6.45V4.6a2.55 2.55 0 0 0 2.55 2.55h2.85V17.2a1.05 1.05 0 0 1-1.05 1.05H4.8a1.05 1.05 0 0 1-1.05-1.05V2.8c0-.278.11-.546.308-.742ZM16.02 5.65l-3.27-3.198V4.6a1.05 1.05 0 0 0 1.05 1.05h2.22Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
