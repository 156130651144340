import { MobileStepper } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

interface IProps {
  steps: number;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export function DotStepper({ activeStep, setActiveStep, steps }: IProps) {
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isLastStep = activeStep === steps - 1;

  return (
    <MobileStepper
      activeStep={activeStep}
      position="static"
      steps={steps}
      sx={{
        flexGrow: 1,
        padding: 0,
        '& .MuiMobileStepper-dot': {
          backgroundColor: 'grey.30',
          mx: 0.5,
        },
        '& .MuiMobileStepper-dot.MuiMobileStepper-dotActive': {
          backgroundColor: 'primary.main',
        },
      }}
      variant="dots"
      nextButton={
        <Button
          sx={{ color: 'primary.dark', fontSize: '1rem' }}
          size="small"
          onClick={handleNext}
          disabled={isLastStep}
        >
          <strong>{t('common.actions.next', 'Next')}</strong>
        </Button>
      }
      backButton={
        <Button
          sx={{ color: 'primary.dark', fontSize: '1rem' }}
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          <strong>{t('common.actions.previous', 'Previous')}</strong>
        </Button>
      }
    />
  );
}
