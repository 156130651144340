import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';
import { HorizontalRuler } from '@/components/HorizontalRuler';
import { AlternatingRadioRow } from '@/components/Inputs';
import { IRadioOptions } from '@/components/Inputs/interfaces';

interface IProps {
  open?: boolean;
  onClose?: () => void;
  ineligibleChecks?: IRadioOptions<boolean>[];
  onProceed?: () => void;
}

export function UnderwriteCheckConfirmationModal({
  ineligibleChecks = [],
  onClose,
  onProceed,
  open = false,
}: IProps) {
  const { t } = useTranslation();

  const radioOptions = useMemo(() => {
    return [
      {
        label: t('common.labels.yes'),
        value: true,
      },
      {
        label: t('common.labels.no'),
        value: false,
      },
    ];
  }, [t]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      scroll="body"
      PaperProps={{
        sx: {
          maxHeight: 'none',
          m: 0,
          px: 5,
          py: 4,
          maxWidth: '738px',
        },
      }}
    >
      <DialogTitle sx={{ fontSize: '2.5rem', fontWeight: 600 }}>
        {t('modals.underwritingChecksModalConfirmation.title', 'Hold up...')}
      </DialogTitle>
      <DialogContent>
        <Typography color="grey.80">
          {t(
            'modals.underwritingChecksModalConfirmation.subtitle',
            'You answered yes to a question that will prevent you from being approved.',
          )}
        </Typography>
        <Box sx={{ my: 4 }}>
          {ineligibleChecks.map((check, index) => (
            <AlternatingRadioRow
              key={`${check?.name}-${index}`}
              alternateBackground="odd"
              radioOptions={radioOptions}
              label={check.label}
              size="large"
              value={true}
            />
          ))}
        </Box>
        <Typography color="grey.80">
          {t(
            'modals.underwritingChecksModalConfirmation.description',
            "Arbol doesn't provide coverage to properties that are deemed un-inhabital. This falls outside of Arbol's risk profile and therefore we cannot allow you to proceed with the selected location.",
          )}
        </Typography>
        <HorizontalRuler sx={{ my: 4 }} />
        <Typography fontSize="1.3125rem" fontWeight={600} sx={{ mb: 2 }}>
          {t(
            'modals.underwritingChecksModalConfirmation.verifyQuestion',
            'Is this answer correct?',
          )}
        </Typography>
        <Typography color="grey.80">
          <Trans i18nKey="modals.underwritingChecksModalConfirmation.verifyDescription">
            If the answer is{' '}
            <Typography component="span" color="grey.100" fontWeight={600}>
              yes
            </Typography>
            , then you will be redirected back to the Property Selection screen
            to enter a new property. If no, then the answer will be updated and
            you will return to Underwriting Checks.
          </Trans>
        </Typography>
        <Box mt={6.5} gap={2.5} display="flex" justifyContent="center">
          <Button onClick={onClose} color="primary" variant="contained">
            <strong>
              {t('common.labels.noUpdateAnswer', 'No, update answer')}
            </strong>
          </Button>
          <Button
            onClick={() => {
              onClose?.();
              onProceed?.();
            }}
            sx={{ backgroundColor: 'grey.20', color: 'secondary.main' }}
            variant="contained"
          >
            <strong>{t('common.labels.yes')}</strong>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
