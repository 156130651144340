import { ButtonBase, ButtonBaseProps, styled } from '@mui/material';
import { PickersArrowSwitcherSlotsComponentsProps } from '@mui/x-date-pickers/internals/components/PickersArrowSwitcher';

import { SVG } from '../SVGs/SVG';

const ArrowWrapper = styled(ButtonBase)`
  align-items: center;
  border-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.spacing(1)};
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)};

  &:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px;

    svg {
      stroke-width: 2px;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }

  &.Mui-disabled {
    border-color: ${({ theme }) => theme.palette.grey[40]};
    color: ${({ theme }) => theme.palette.grey[50]};
  }
`;

export function StyledLeftArrowButton(
  props: ButtonBaseProps & PickersArrowSwitcherSlotsComponentsProps,
) {
  return (
    <ArrowWrapper disableRipple disableTouchRipple {...props}>
      <SVG name="LeftArrow" height={12} width={12} />
    </ArrowWrapper>
  );
}

export function StyledRightArrowButton(
  props: ButtonBaseProps & PickersArrowSwitcherSlotsComponentsProps,
) {
  return (
    <ArrowWrapper disableRipple disableTouchRipple {...props}>
      <SVG name="RightArrow" height={12} width={12} />
    </ArrowWrapper>
  );
}
