import { Box, Stack, styled, Typography } from '@mui/material';

import { EditButton } from '../../components/EditButton';
import { FontWeight } from '../../config/theme/typography';
import { IAddress } from '../../store/quoter';

import { PropertyCard } from './PropertyCard';

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '175px',
  backgroundColor: theme.palette.grey[10],
  borderRadius: '0px 16px 0px 0px',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`,
}));

interface IProps {
  fullName: string;
  phone: string;
  email: string;
  properties?: IAddress[];
}

export function ClientContactHeader({
  fullName,
  phone,
  email,
  properties,
}: IProps) {
  return (
    <Wrapper>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h1" marginTop={2} lineHeight="40.6px">
          {fullName}
        </Typography>
        <EditButton
          sx={{
            fontWeight: FontWeight.semibold,
            alignSelf: 'baseline',
          }}
          onClick={() => console.log('edit button click on client page')}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography fontSize="17px" lineHeight="24.65px" color="grey.80">
            {phone}
          </Typography>
          <Typography fontSize="17px" lineHeight="24.65px" color="grey.80">
            {email}
          </Typography>
        </Stack>
        {properties &&
          properties.map((address, index) => (
            <PropertyCard key={index} address={address} />
          ))}
      </Stack>
    </Wrapper>
  );
}
