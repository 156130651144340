import { Grid, Paper, PaperProps } from '@mui/material';

const BORDER_RADIUS = '16px';
const PANELS_PADDING = 4;

interface IProps extends PaperProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  panelsPadding?: number;
  scrollableRight?: boolean;
}

export function TwoPanelPaper({
  leftChildren,
  rightChildren,
  panelsPadding = PANELS_PADDING,
  scrollableRight = false,
  ...paperProps
}: IProps) {
  return (
    <Paper
      {...paperProps}
      sx={{
        ...paperProps.sx,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <Grid container direction="row">
        <Grid item sx={{ flex: '1 1 auto', padding: panelsPadding }}>
          {leftChildren}
        </Grid>
        <Grid
          item
          sx={{
            height: scrollableRight ? '540px' : 'initial',
            overflowY: scrollableRight ? 'auto' : 'initial',
            bgcolor: 'grey.10',
            borderLeftColor: 'grey.30',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1,
            borderTopRightRadius: BORDER_RADIUS,
            borderBottomRightRadius: BORDER_RADIUS,
            padding: panelsPadding,
            flex: {
              xs: '1 1 auto',
              sm: '0 1 380px',
            },
          }}
        >
          {rightChildren}
        </Grid>
      </Grid>
    </Paper>
  );
}
