import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function TrashCan(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88 6.26a.6.6 0 0 1 .6.6v3.84a.6.6 0 0 1-1.2 0V6.86a.6.6 0 0 1 .6-.6ZM8.44 6.26a.6.6 0 0 1 .6.6v3.84a.6.6 0 0 1-1.2 0V6.86a.6.6 0 0 1 .6-.6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.38v.68H1.4a.6.6 0 1 0 0 1.2h.68v8.36a1.88 1.88 0 0 0 1.88 1.88h6.4a1.88 1.88 0 0 0 1.88-1.88V4.26h.68a.6.6 0 1 0 0-1.2h-2.6v-.68A1.88 1.88 0 0 0 8.44.5H5.88A1.88 1.88 0 0 0 4 2.38Zm1.88-.68a.68.68 0 0 0-.68.68v.68h3.92v-.68a.68.68 0 0 0-.68-.68H5.88Zm-2.6 2.56v8.36a.68.68 0 0 0 .68.68h6.4a.68.68 0 0 0 .68-.68V4.26H3.28Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
