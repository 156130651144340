import { Container, Paper, Stack } from '@mui/material';
import { GridCellParams, GridSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { QuoteDetailsActionBarContainer } from '../../components/ActionBar/QuoteDetailsActionBar.container';
import { Button } from '../../components/Button';
import { CompareQuotesDataTable } from '../../components/DataTable';
import { SVG } from '../../components/SVGs/SVG';
import { IStep, VerticalProgressStepper } from '../../components/Stepper';
import { SummaryBody } from '../../components/SummaryCard';
import { shadowBoxes } from '../../config/theme/base';
import { compareQuoteColumns } from '../../pages/QuoteDetailPage/helpers';
import { QuoteTableRowFormat } from '../QuotesPage/helpers';

import { QuoteDetailSpecific } from './QuoteDetailSpecific';

interface IProps {
  activeStep: number;
  initialValues: QuoteTableRowFormat;
  isLoading?: boolean;
  onBack?: () => void;
  onCheck?: (model: GridSelectionModel, event: any) => void;
  onClick?: (value: GridCellParams) => void;
  onCompare?: () => void;
  quoteId?: string;
  selectedRows: string[];
  showActionId?: string;
  status?: string;
  steps: IStep[];
  summaryRowData: any[];
  title: string;
}

export function QuotesDetailPage({
  activeStep,
  initialValues,
  isLoading,
  onBack,
  onCheck,
  onClick,
  onCompare,
  quoteId,
  selectedRows,
  status,
  steps,
  summaryRowData,
  title,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Container disableGutters sx={{ paddingY: 3, minWidth: '1244px' }}>
      <Button
        color="secondary"
        size="small"
        sx={{ fontSize: '1rem', marginBottom: 1 }}
        variant="text"
        onClick={onBack}
        startIcon={<SVG name="LeftArrow" height={12} width={11} />}
      >
        {t('pages.quoteDetails.backButtonLabel', 'Back to Quotes')}
      </Button>
      <Paper
        sx={{
          backgroundColor: 'grey.20',
          boxShadow: shadowBoxes.type1,
          borderRadius: 2,
          marginBottom: 3,
        }}
        elevation={0}
      >
        <QuoteDetailsActionBarContainer quoteId={quoteId} status={status} />
        <Stack
          direction="row"
          spacing={1}
          alignItems="stretch"
          sx={{ pl: 3, py: 3, position: 'relative' }}
        >
          <QuoteDetailSpecific
            sx={{
              backgroundColor: 'grey.00',
              borderRadius: '12px',
              minWidth: '377px',
              padding: 4,
            }}
          />
          <SummaryBody
            summaryRowData={summaryRowData}
            summaryTitle={t('common.labels.summary', 'Summary')}
            sx={{
              backgroundColor: 'grey.00',
              borderRadius: '12px',
              height: '100%',
              minWidth: '457px',
              padding: 5,
            }}
          />
          <VerticalProgressStepper
            activeStep={activeStep}
            title={title}
            steps={steps}
            sx={{
              backgroundColor: 'grey.00',
              borderRadius: 2,
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              height: '100%',
              minWidth: '354px',
              position: 'absolute',
              px: 5,
              py: 4,
              right: 0,
              top: 0,
              zIndex: 1,
            }}
          />
        </Stack>
      </Paper>
      <CompareQuotesDataTable
        columns={compareQuoteColumns}
        name="Andrew"
        onCellClick={onClick}
        onCheck={onCheck}
        onCompare={onCompare}
        isLoading={isLoading}
        rowCount={initialValues.length}
        rows={initialValues}
        selectedRows={selectedRows}
      />
    </Container>
  );
}
