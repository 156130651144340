import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { InlineDatePicker } from '../../components/DatePicker/InlineDatePicker';
import { QuoteStepperContainer } from '../../components/QuoteStepper';

import {
  getQuoterSelectStartDateSchema,
  IQuoterSelectStartDateSchema,
} from './QuoterSelectStartDatePage.schema';

interface IProps {
  confirmation?: boolean;
  initialValue?: string;
  onSubmit: (values: IQuoterSelectStartDateSchema) => void;
}

export function QuoterSelectStartDatePage({
  confirmation = false,
  initialValue = '',
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, watch } =
    useForm<IQuoterSelectStartDateSchema>({
      defaultValues: {
        startDate: initialValue ? new Date(initialValue) : null,
      },
      resolver: zodResolver(getQuoterSelectStartDateSchema()),
    });

  const startDate = watch('startDate');
  const hasStartDate = Boolean(startDate);
  const prevPageRoute = confirmation
    ? '/quoter/customize-coverage'
    : '/quoter/select-property';

  const prevPageText = confirmation
    ? t('pages.customizeCoveragePage.title')
    : t('pages.quoterSelectPropertyPage.title');

  const pageTitle = confirmation
    ? t('pages.quoterConfirmStartDatePage.title', 'Confirm inception date')
    : t('pages.quoterSelectStartDatePage.title', 'Select a start date');

  const pageDescription = confirmation
    ? t(
        'pages.quoterConfirmStartDatePage.description',
        'Please confirm the inception date of your policy.',
      )
    : t(
        'pages.quoterSelectStartDatePage.description',
        'Please select the inception date of your policy.',
      );

  return (
    <Box>
      <QuoteStepperContainer />
      <PageTitle title={pageTitle}>
        <Typography
          mt={2}
          sx={{ lineHeight: 1.5, maxWidth: '644px', color: 'grey.80' }}
        >
          {pageDescription}
        </Typography>
      </PageTitle>
      <Box component="form" display="flex" justifyContent="center" mt={5}>
        <InlineDatePicker control={control} name="startDate" />
      </Box>
      <QuoterActionBarContainer
        backText={prevPageText}
        hideSaveExit={confirmation}
        onBack={prevPageRoute}
      >
        <Button
          color="secondary"
          disabled={!hasStartDate}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
        >
          {t('common.actions.continue')}
        </Button>
      </QuoterActionBarContainer>
    </Box>
  );
}
