import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function OutlinedRightArrow(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.034 16.044a1.2 1.2 0 0 1 1.697.033l6.825 7.09a1.2 1.2 0 0 1 0 1.665l-6.825 7.091A1.2 1.2 0 0 1 24 30.259l4.87-5.06H16.09a1.2 1.2 0 0 1 0-2.4h12.78L24 17.742a1.2 1.2 0 0 1 .033-1.697Z"
        fill="#0D1329"
      />
      <rect x=".5" y=".5" width="47" height="47" rx="23.5" stroke="#0D1329" />
    </StyledSVG>
  );
}
