import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoIconButtonToolTip } from '@/components/Button';
import { ControllerDropdown } from '@/components/Inputs';

import { ICustomizeCoverageSchema } from '../CustomizeCoverageOption.schema';
import { WATER_DAMAGE_OPTIONS } from '../constants';
import { formatCurrencyOptions } from '../helpers';

interface IProps {
  control: Control<ICustomizeCoverageSchema>;
  size: ISize;
}

export function WaterDamageSection({ size, control }: IProps) {
  const { t } = useTranslation();

  const waterDamageOptions = useMemo(
    () => formatCurrencyOptions(WATER_DAMAGE_OPTIONS),
    [],
  );

  return (
    <Box mt={size === 'lg' ? 4 : 5} pb={5}>
      <Typography
        variant="h2"
        sx={{
          alignItems: 'center',
          display: 'flex',
          pb: { xs: 2, sm: 1.5 },
        }}
      >
        {t('pages.customizeCoveragePage.waterDamageTitle', 'Water Damage')}
        <InfoIconButtonToolTip
          tooltipText={t(
            'common.tooltips.waterDamage',
            'Use the dropdown to select a water sublimit. If 0 is selected, the policy will be endorsed to exclude water damage',
          )}
        />
      </Typography>
      <Box maxWidth={size === 'lg' ? '448px' : '100%'}>
        <ControllerDropdown
          fullWidth
          control={control}
          name="waterDamageLimit"
          label={t('common.labels.limit', 'Limit')}
          options={waterDamageOptions}
          sx={{ flex: 1 }}
        />
      </Box>
    </Box>
  );
}
