import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Paper,
  styled,
} from '@mui/material';
import { DatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { SVG } from '../SVGs/SVG';

import { DatePickerStylePaperProps } from './ControllerDatePicker';
import {
  StyledLeftArrowButton,
  StyledRightArrowButton,
} from './StyledArrowButton';
import { renderStyledPickersDay } from './StyledPickersDay';

const Wrapper = styled(Box)`
  min-width: 300px;
  max-width: 355px;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  background-color: ${({ theme }) => theme.palette.common.white};

  fontweight: 400;

  input {
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

const CalendarDateRangeIcon = () => (
  <SVG name="CalendarDateRange" sx={{ color: 'secondary.main', width: 20 }} />
);

interface IProps<T extends FieldValues> {
  disablePast?: boolean;
  control: Control<T>;
  name: Path<T>;
}

export function InlineDatePicker<T extends FieldValues>({
  control,
  disablePast = false,
  name,
}: IProps<T>) {
  return (
    <Wrapper>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...field}
            components={{
              OpenPickerIcon: CalendarDateRangeIcon,
            }}
            disablePast={disablePast}
            label="Date"
            PaperProps={DatePickerStylePaperProps}
            OpenPickerButtonProps={{
              disabled: true,
            }}
            renderInput={({ InputProps, ...params }) => (
              <Box>
                <FormControl fullWidth>
                  <StyledOutlinedInput
                    fullWidth
                    error={Boolean(error)}
                    {...InputProps}
                    {...(params as any)}
                  />
                  <FormHelperText error>{error?.message}</FormHelperText>
                </FormControl>
                <Paper sx={{ mt: 2, p: 1, ...DatePickerStylePaperProps.sx }}>
                  <StaticDatePicker
                    {...field}
                    components={{
                      LeftArrowButton: StyledLeftArrowButton,
                      RightArrowButton: StyledRightArrowButton,
                    }}
                    disableOpenPicker
                    disablePast
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    renderDay={renderStyledPickersDay}
                    renderInput={() => <div />}
                  />
                </Paper>
              </Box>
            )}
          />
        )}
      />
    </Wrapper>
  );
}
