import { Container, Paper, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { SVG } from '../SVGs/SVG';

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.grey[70],
  backgroundColor: theme.palette.grey[30],
  marginRight: theme.spacing(0.5),
  borderRadius: '8px 8px 0px 0px',
  '&.Mui-selected': {
    color: theme.palette.grey[90],
    backgroundColor: theme.palette.grey['00'],
  },
  fontWeight: '600',
}));

export function TabbedContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container
      fixed
      sx={{
        marginTop: 5,
        minWidth: '1300px',
      }}
    >
      <Tabs
        sx={{
          '.MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        }}
        value={location.pathname}
        onChange={(e, value) => navigate(value)}
      >
        <StyledTab disableRipple label="Quotes" value="/quotes" />
        <StyledTab disableRipple label="Policies" value="/policies" />
        <StyledTab
          disableRipple
          label="Expiring Soon"
          value="/policies/expiring-soon"
        />
        <StyledTab disableRipple label="Archive" value="/archive" />
        <StyledTab
          disableRipple
          icon={<SVG name="Search" width="20px" />}
          value="/search"
        />
      </Tabs>
      <Paper
        sx={{
          borderRadius: '0px 8px 8px 8px',
          textAlign: 'center',
        }}
      >
        <Outlet />
      </Paper>
    </Container>
  );
}
