import { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddEndorsementsConfirmationModal } from '../Modals/AddEndorsementsConfirmationModal';

import { PolicyDetailsActionBar } from './PolicyDetailsActionBar';

interface IProps {
  policyId: string;
  status: string;
}
export function PolicyDetailsActionBarContainer({ policyId, status }: IProps) {
  const navigate = useNavigate();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleSubmit = useCallback(() => {
    setConfirmationModalOpen(false);
    navigate(`/policies/${policyId}/endorsements`);
  }, [navigate, policyId]);

  return (
    <Fragment>
      <PolicyDetailsActionBar
        policyId={policyId}
        status={status}
        onViewPolicyDocsClick={() => console.log('View Policy Docs')}
        onAddEndorsementsClick={() => setConfirmationModalOpen(true)}
        onEditPolicyDetailsClick={() => console.log('Edit Policy Details')}
        onGetHelpClick={() => console.log('Get Help')}
      />
      <AddEndorsementsConfirmationModal
        open={confirmationModalOpen}
        onSubmit={handleSubmit}
        onClose={() => setConfirmationModalOpen(false)}
      />
    </Fragment>
  );
}
