import { Button, ButtonProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SVG } from '../SVGs/SVG';

interface IProps extends ButtonProps {
  onClick: () => void;
}

export function EditButton({ onClick, ...buttonProps }: IProps) {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} {...buttonProps}>
      <SVG name="Pencil" sx={{ color: 'primary.dark' }} width="13px" />
      <Typography color="primary.dark" paddingX={1} fontWeight="inherit">
        {t('common.labels.edit', 'Edit')}
      </Typography>
    </Button>
  );
}
