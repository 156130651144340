import { IQuoterInput } from '../store/quoter';

export enum LocalStorageKeys {
  QUOTER = 'quoter',
  USER_TOKEN = 'userToken',
}

export function getLoginToken() {
  return localStorage.getItem(LocalStorageKeys.USER_TOKEN) || '';
}

export function setLoginToken(token: string) {
  localStorage.setItem(LocalStorageKeys.USER_TOKEN, token);
}

function getQuoterKey(clientId: string) {
  return `${LocalStorageKeys.QUOTER}_${clientId}`;
}

export function saveQuoterInput(quoter: IQuoterInput, clientId: string) {
  const key = getQuoterKey(clientId);
  const shallowClone = { ...quoter };

  if (!shallowClone.createdAt) {
    shallowClone.createdAt = new Date().toISOString();
  }

  localStorage.setItem(key, JSON.stringify(shallowClone));
}

export function getQuoterInput(clientId: string): IQuoterInput {
  const key = getQuoterKey(clientId);
  const quoter = localStorage.getItem(key);

  return quoter ? JSON.parse(quoter) : null;
}
