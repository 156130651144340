import { Box, Stack, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';

import { SummaryCardRow } from './SummaryCardRow';

interface IProps {
  actionButton?: React.ReactNode;
  summaryRowData: any[];
  summaryTitle?: string;
}

export function SummaryBody({
  actionButton,
  summaryRowData,
  summaryTitle,
  ...props
}: IProps & BoxProps) {
  return (
    <Box {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{ fontSize: '1.0625rem', fontWeight: 600, paddingBottom: 2 }}
        >
          {summaryTitle}
        </Typography>
        {actionButton}
      </Stack>
      {summaryRowData.map((obj, index) => (
        <SummaryCardRow
          key={index}
          letter={obj.letter}
          name={obj.name}
          value={obj.value}
        />
      ))}
    </Box>
  );
}
