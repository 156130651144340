import { RouteObject } from 'react-router-dom';

import { AgreementsNoticesPageContainer } from '@/pages/AgreementsNoticesPage';
import { ClientsPageContainer } from '@/pages/ClientsPage';
import { ClientDetailsContainer } from '@/pages/ClientsPage/ClientDetails.container';
import { DashboardPageContainer } from '@/pages/DashboardPage';
import { ForgotPasswordPageContainer } from '@/pages/ForgotPassword';
import { HelpPage } from '@/pages/HelpPage';
import { LoginPageContainer } from '@/pages/LoginPage';
import { MidtermEndorsementSuccessPage } from '@/pages/MidtermEndorsementSuccessPage/MidtermEndorsementSuccessPage';
import { MidtermEndorsementsPageContainer } from '@/pages/MidtermEndorsementsPage/MidtermEndorsementsPage.container';
import { MidtermEndorsementsReviewPageContainer } from '@/pages/MidtermEndorsementsReviewPage/MidtermEndorsementsReviewPage.container';
import { MyInformationPage } from '@/pages/MyInformationPage';
import { MyTeamPageContainer } from '@/pages/MyTeamPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { PoliciesPageContainer } from '@/pages/PoliciesPage';
import { PolicyDetailsPageContainer } from '@/pages/PolicyDetailsPage';
import { QuoteDetailPageContainer } from '@/pages/QuoteDetailPage';
import { QuoterAddSignaturePageContainer } from '@/pages/QuoterAddSignaturePage';
import { QuoterApplicationSuccessPage } from '@/pages/QuoterApplicationSuccessPage';
import { QuoterCustomizeCoveragePageContainer } from '@/pages/QuoterCustomizeCoveragePage';
import { QuoterDiligentSearchPageContainer } from '@/pages/QuoterDiligentSearchPage';
import { QuoterReviewCoveragePageContainer } from '@/pages/QuoterReviewCoveragePage';
import { QuoterReviewPropertyDataPageContainer } from '@/pages/QuoterReviewPropertyDataPage';
import { QuoterSelectAndBindPageContainer } from '@/pages/QuoterSelectAndBindPage';
import { QuoterSelectClientPageContainer } from '@/pages/QuoterSelectClientPage';
import { QuoterSelectPropertyPageContainer } from '@/pages/QuoterSelectPropertyPage';
import { QuoterSelectStartDatePageContainer } from '@/pages/QuoterSelectStartDatePage';
import { QuoterShareApplicationPageContainer } from '@/pages/QuoterShareApplicationPage';
import { QuoterSharePageContainer } from '@/pages/QuoterSharePage';
import { QuoterSuccessPageContainer } from '@/pages/QuoterSuccessPage';
import { QuoterUnderwritingChecksPageContainer } from '@/pages/QuoterUnderwritingChecksPage';
import { QuoterUploadDiligentSearchPageContainer } from '@/pages/QuoterUploadDiligentSearchPage';
import { QuotesComparePageContainer } from '@/pages/QuotesComparePage';
import { QuotesPageContainer } from '@/pages/QuotesPage';
import { ResetPasswordPageContainer } from '@/pages/ResetPasswordPage';
import { TestPage } from '@/pages/TestPage';

import { AccountSideBarContainer } from '../AccountSideBar';
import { Layout } from '../Layout';
import { MidtermEndorsementOptions } from '../MidtermEndorsementOptions/MidtermEndorsementOptions';
import { QuoterInitializer } from '../Quoter';
import { SimpleEndorsementContainer } from '../SimpleEndorsement/SimpleEndorsement.container';
import { TabbedContainer } from '../TabbedContainer/TabbedContainer';

import { ProtectedRouteCheck } from './ProtectedRouteCheck';
import { UnprotectedRouteCheck } from './UnprotectedRouteCheck';

export const routes: RouteObject[] = [
  {
    // - Unprotected Routes
    element: <UnprotectedRouteCheck />,
    children: [
      // -- Unprotected: Standard Routes Layout
      {
        element: <Layout type="standard" />,
        children: [
          {
            index: true,
            path: '/',
            element: <LoginPageContainer />,
          },
          {
            path: '/login',
            element: <LoginPageContainer />,
          },
          {
            path: '/forgot-password',
            element: <ForgotPasswordPageContainer />,
          },
          {
            path: '/reset-password',
            element: <ResetPasswordPageContainer />,
          },
        ],
      },
    ],
  },
  {
    //- Protected Routes
    element: <ProtectedRouteCheck />,
    children: [
      {
        // -- Protected: Standard Routes Layout
        element: <Layout type="standard" />,
        children: [
          // --- Protected: Dashboard
          {
            path: '/dashboard',
            element: <DashboardPageContainer />,
          },
          // --- Protected: Clients routes ⌄
          {
            path: '/clients/',
            element: <ClientsPageContainer />,
            children: [
              {
                path: ':id',
                element: <ClientDetailsContainer />,
              },
            ],
          },
          // --- Protected: Quotes & Policies routes ⌄
          {
            element: <TabbedContainer />,
            children: [
              {
                path: '/quotes',
                element: <QuotesPageContainer />,
              },
              {
                path: '/policies',
                element: <PoliciesPageContainer />,
              },
              {
                path: '/policies/expiring-soon',
                element: <div>Policies Expiring Soon</div>,
              },
              {
                path: '/archive',
                element: <div>Archive</div>,
              },
              {
                path: '/search',
                element: <div>Search</div>,
              },
            ],
          },
          {
            path: '/quotes/:id',
            element: <QuoteDetailPageContainer />,
          },
          {
            path: '/quotes/share',
            element: <QuotesComparePageContainer />,
          },
          // --- Protected: Policy Details & Mid-term Endorsement Routes
          {
            path: '/policies/:id',
            element: <PolicyDetailsPageContainer />,
          },
          {
            element: <MidtermEndorsementsPageContainer />,
            children: [
              {
                path: '/policies/:id/endorsements',
                element: <MidtermEndorsementOptions />,
              },
              {
                path: '/policies/:id/endorsements/dwelling',
                element: <div>Dwelling Endorsement</div>,
              },
              {
                path: '/policies/:id/endorsements/other-structures',
                element: <div>Other Structures</div>,
              },
              {
                path: '/policies/:id/endorsements/ordinance-or-law',
                element: <div>Ordinance or Law</div>,
              },
              {
                path: '/policies/:id/endorsements/personal-property',
                element: <div>Personal Property</div>,
              },
              {
                path: '/policies/:id/endorsements/carport',
                element: <div>Carport Coverage</div>,
              },
              {
                path: '/policies/:id/endorsements/animal-liability',
                element: <SimpleEndorsementContainer />,
              },
              {
                path: '/policies/:id/endorsements/review',
                element: <MidtermEndorsementsReviewPageContainer />,
              },
            ],
          },
          // --- Protected: Account routes
          {
            element: <AccountSideBarContainer />,
            children: [
              {
                path: '/account/my-information',
                element: <MyInformationPage />,
              },
              // {
              //   path: '/account/payment-information',
              //   element: <SettingsPageContainer />,
              // },
              {
                path: '/account/my-team',
                element: <MyTeamPageContainer />,
              },
              {
                path: '/account/agreements-notices',
                element: <AgreementsNoticesPageContainer />,
              },
            ],
          },
        ],
      },
      {
        // -- Protected: Quoter Routes Layout
        element: <Layout type="homes" />,
        children: [
          {
            element: <QuoterInitializer />,
            children: [
              {
                path: '/quoter/select-client',
                element: <QuoterSelectClientPageContainer />,
              },
              {
                path: '/quoter/select-property',
                element: <QuoterSelectPropertyPageContainer />,
              },
              {
                path: '/quoter/select-start-date',
                element: <QuoterSelectStartDatePageContainer />,
              },
              {
                path: '/quoter/diligent-search',
                element: <QuoterDiligentSearchPageContainer />,
              },
              {
                path: '/quoter/underwriting-checks',
                element: <QuoterUnderwritingChecksPageContainer />,
              },
              {
                path: '/quoter/property-details',
                element: <QuoterReviewPropertyDataPageContainer />,
              },
              {
                path: '/quoter/customize-coverage',
                element: <QuoterCustomizeCoveragePageContainer />,
              },
              {
                path: '/quoter/share',
                element: <QuoterSharePageContainer />,
              },
              {
                path: '/quoter/success',
                element: <QuoterSuccessPageContainer />,
              },
              {
                path: '/quoter/confirm-start-date',
                element: <QuoterSelectStartDatePageContainer confirmation />,
              },
              {
                path: '/quoter/upload-diligent-search',
                element: <QuoterUploadDiligentSearchPageContainer />,
              },
              {
                path: '/quoter/review-coverage',
                element: <QuoterReviewCoveragePageContainer />,
              },
              {
                path: 'quoter/add-signature',
                element: <QuoterAddSignaturePageContainer />,
              },
              {
                path: '/quoter/select-and-bind',
                element: <QuoterSelectAndBindPageContainer />,
              },
              {
                path: '/quoter/share-application',
                element: <QuoterShareApplicationPageContainer />,
              },
              {
                path: '/quoter/application-success',
                element: <QuoterApplicationSuccessPage />,
              },
            ],
          },
          {
            path: '/policies/:id/endorsements/success',
            element: <MidtermEndorsementSuccessPage />,
          },
        ],
      },
    ],
  },

  {
    // - No protected or unprotected routes checks w/ standard layout
    element: <Layout type="standard" />,
    children: [
      {
        path: '/test',
        element: <TestPage />,
      },
      {
        path: '/help',
        element: <HelpPage />,
      },
      {
        // -- Not Found
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];
