import { Box, Link, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { InfoIconButtonToolTip } from '@/components/Button';
import { ControllerTextField } from '@/components/Inputs';
import { CurrencyInputMask } from '@/components/Inputs/TextField/CurrencyInputMask';

import { ICustomizeCoverageSchema } from '../CustomizeCoverageOption.schema';

interface IProps {
  control: Control<ICustomizeCoverageSchema>;
  size: 'sm' | 'md' | 'lg';
  onInfoClick?: () => void;
}

export function ParametricExpenseSection({
  control,
  size,
  onInfoClick,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: 'white', p: 3, borderRadius: 2, mt: 5, mb: 4 }}>
      <Typography
        variant="h2"
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingBottom: { xs: 2, sm: 1.5 },
        }}
      >
        {t(
          'pages.customizeCoveragePage.rightPanelTitle',
          'Additional Hurricane Parametric Expense',
        )}
        <InfoIconButtonToolTip onClick={onInfoClick} />
      </Typography>
      <Typography
        sx={{
          color: 'grey.90',
          display: size === 'lg' ? 'block' : 'none',
          fontSize: '0.9375rem',
          fontWeight: 400,
          lineHeight: '21.75px',
          maxWidth: '649px',
        }}
      >
        <Trans i18nKey="pages.customizeCoveragePage.parametricDescription">
          Hurricane parametric expense coverage pays any expenses incurred
          resulting from a hurricane. Thus is a parametric coverage{' '}
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault();
              onInfoClick?.();
            }}
          >
            (Link)
          </Link>{' '}
          based on wind speed and distance of a hurricane from your property.
          When eligible, loss is calculated based on a predefined table.
        </Trans>
      </Typography>
      <ControllerTextField
        sx={{
          mt: 2,
          width: size === 'lg' ? '400px' : '100%',
        }}
        control={control}
        label={t('common.labels.limit', 'Limit')}
        name="parametricLimit"
        InputProps={{
          inputComponent: CurrencyInputMask as any,
        }}
      />
    </Box>
  );
}
