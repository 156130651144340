import { Box, Stack } from '@mui/material';
import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { IEndorsementOption } from '@/components/EndorsementCartItem/EndorsementCartItem';
import {
  EndorsementOptionsCart,
  ICurrentCoverage,
} from '@/components/EndorsementOptionsCart/EndorsementOptionsCart';
import { QuoteStepper } from '@/components/QuoteStepper/QuoteStepper';
import { calculateDaysRemaining } from '@/helpers/calculations';
import { useEndorsementsStore } from '@/hooks/useEndorsementsStore';

export function MidtermEndorsementsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { input, reset } = useEndorsementsStore();

  const policyId = '123456789';
  const policyLocation = '123 Main St, Anytown, USA';
  const name = 'John Doe';
  const policyPremium = 1930.22;
  const startDate = '06/01/2022';
  const endDate = '06/01/2023';
  const remainingMonths = Math.floor(
    calculateDaysRemaining(new Date(), endDate) / 30,
  );

  useEffect(() => {
    reset();
    return () => reset();
  }, [reset]);

  const selectedEndorsements = input.length ? input : undefined;

  const getMatchingEndorsements = useMemo(() => {
    const policyCoverages = {
      dwelling: 500000,
      carport: 10000,
    };
    const coverages: any = [];
    Object.keys(policyCoverages).forEach((key) => {
      const endorsement = selectedEndorsements?.find(
        (item: any) => item.coverage === key,
      );
      if (endorsement) {
        coverages.push({
          coverage: key,
          description: `$${policyCoverages[
            key as keyof typeof policyCoverages
          ].toLocaleString()}`,
        });
      }
    });
    return coverages as ICurrentCoverage[];
  }, [selectedEndorsements]);

  const onCancelHandler = useCallback(() => {
    reset();

    navigate(`/policies/${policyId}`);
  }, [navigate, reset]);

  const onContinueHandler = useCallback(() => {
    navigate(`/policies/${policyId}/endorsements/review`);
  }, [navigate]);

  const hideCart = useMemo(
    () => location.pathname.includes('review'),
    [location.pathname],
  );

  return (
    <Box>
      <QuoteStepper
        appId={policyId}
        address={policyLocation}
        name={name}
        startDate={startDate}
        title={t('pages.midtermEndorsements.policyId', 'POLICY ID')}
      />
      <Stack
        direction="row"
        justifyContent={hideCart ? 'center' : 'space-between'}
      >
        <Outlet />
        {!hideCart && (
          <EndorsementOptionsCart
            remainingMonths={remainingMonths}
            currentPremium={policyPremium}
            currentCoverages={getMatchingEndorsements}
            selectedEndorsements={selectedEndorsements as IEndorsementOption[]}
            onCancel={onCancelHandler}
            onContinue={onContinueHandler}
          />
        )}
      </Stack>
    </Box>
  );
}
