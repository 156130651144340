import { Card, CardProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { shadowBoxes } from '@/config/theme/base';
import { IQuoteOption } from '@/store/quoter';

import { Accordion } from '../Accordion';

import { DisclosurePlaceholder } from './DisclosurePlaceholder';
import { SummaryBody } from './SummaryBody';
import { SummaryCardHeader } from './SummaryCardHeader';

const placeholderData = [
  { name: 'Dwelling Coverage', value: '$350,000.00', letter: 'I.' },
  { name: 'Other structure', value: '2% ($11,250)', letter: 'II.' },
  { name: 'Personal Property', value: '25% ($112,000)', letter: 'III.' },
  { name: 'Loss of Use', value: '$35,000.00', letter: 'IV.' },
  { name: 'Personal Liability', value: '15% ($67,500)', letter: 'V.' },
  { name: 'Medical Payments', value: '5% ($22,500)', letter: 'VI.' },
  { name: 'All Other Perils', value: '25% ($112,000', letter: null },
  { name: 'Hurricane', value: '15% ($67,500)', letter: null },
  { name: 'Mold', value: '$10,000.00', letter: null },
  { name: 'Carport Amount', value: '$5,000.00', letter: null },
];

interface IProps extends CardProps {
  quote: IQuoteOption;
  highlighted?: boolean;
}

export function SummaryCard({ highlighted, quote, title, ...props }: IProps) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: '375px',
        paddingTop: 2,
        borderRadius: '8px',
        border: 'solid 2px',
        borderColor: highlighted ? 'success.main' : 'transparent',
        boxShadow: highlighted ? shadowBoxes.type2 : '',
      }}
      elevation={0}
      {...props}
    >
      <SummaryCardHeader
        quoteId={quote.id}
        monthly={quote.prices?.monthlyPremium}
        premium={quote.prices?.fullPremium}
      />
      <SummaryBody
        summaryRowData={placeholderData}
        summaryTitle={t('common.labels.summary', 'Summary')}
        sx={{
          padding: 3,
        }}
      />
      <Accordion
        title="Disclosures"
        sx={{
          '& .MuiAccordionSummary-root': {
            minHeight: '57px',
          },
        }}
      >
        <DisclosurePlaceholder />
      </Accordion>
    </Card>
  );
}
