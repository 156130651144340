import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './components/App';
import './config/i18n';

import './index.css';

// eslint-disable-next-line import/no-named-as-default-member
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
