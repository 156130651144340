import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Close(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.43451 0.43451C0.746929 0.12209 1.25346 0.12209 1.56588 0.43451L5.0002 3.86882L8.43451 0.43451C8.74693 0.12209 9.25346 0.12209 9.56588 0.43451C9.8783 0.746929 9.8783 1.25346 9.56588 1.56588L6.13157 5.0002L9.56588 8.43451C9.8783 8.74693 9.8783 9.25346 9.56588 9.56588C9.25346 9.8783 8.74693 9.8783 8.43451 9.56588L5.0002 6.13157L1.56588 9.56588C1.25346 9.8783 0.746929 9.8783 0.43451 9.56588C0.12209 9.25346 0.12209 8.74693 0.43451 8.43451L3.86882 5.0002L0.43451 1.56588C0.12209 1.25346 0.12209 0.746929 0.43451 0.43451Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
