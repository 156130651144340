import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CompanyInfoCell,
  CompanyInfoRow,
} from '../../components/CompanyInfoRow';
import { EditCompanyAddressesModal } from '../../components/Modals';
import { SVG } from '../../components/SVGs/SVG';
import { OrgLocation } from '../../graphql/generated';
import { useUpdateOrganizationLocationAddressMutation } from '../../store/api';

import { ICompanyAddressesSchema } from './CompanyAddresses.schema';

interface IProps {
  loading: boolean;
  mailingLocation?: OrgLocation;
  orgId: string;
  physicalLocation?: OrgLocation;
  userIsAdmin: boolean;
}

export function CompanyAddressesContainer({
  loading,
  mailingLocation,
  orgId,
  physicalLocation,
  userIsAdmin,
}: IProps) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updateOrganizationLocationAddress] =
    useUpdateOrganizationLocationAddressMutation();

  const initialModalValues = {
    physical: {
      address1: physicalLocation?.address?.address1 || '',
      address2: physicalLocation?.address?.address2 || '',
      city: physicalLocation?.address?.city || '',
      state: physicalLocation?.address?.state || '',
      zipcode: physicalLocation?.address?.zipcode || '',
    },
    mailing: {
      address1: mailingLocation?.address?.address1 || '',
      address2: mailingLocation?.address?.address2 || '',
      city: mailingLocation?.address?.city || '',
      state: mailingLocation?.address?.state || '',
      zipcode: mailingLocation?.address?.zipcode || '',
    },
  } as ICompanyAddressesSchema;

  const onSubmitHandler = useCallback(
    async (values: ICompanyAddressesSchema) => {
      const { physical, mailing } = values;

      const physicalResponse = await updateOrganizationLocationAddress({
        organization: orgId,
        location: physicalLocation?._id || '',
        input: physical,
      });

      const mailingResponse = await updateOrganizationLocationAddress({
        organization: orgId,
        location: mailingLocation?._id || '',
        input: mailing,
      });

      if ('data' in physicalResponse && 'data' in mailingResponse) {
        setDialogOpen(false);
      } else {
        console.log('Error udpating addresses');
      }
    },
    [
      updateOrganizationLocationAddress,
      orgId,
      physicalLocation,
      mailingLocation,
    ],
  );

  return (
    <Fragment>
      <CompanyInfoRow
        icon={<SVG name="Home" height={24} />}
        loading={loading}
        onEdit={userIsAdmin ? () => setDialogOpen(true) : undefined}
      >
        <>
          {physicalLocation && (
            <CompanyInfoCell
              title={t(
                'pages.myInformationPage.physicalAddress',
                'Physical Address',
              )}
              rows={[
                physicalLocation.address?.address1 || '',
                physicalLocation.address?.address2 || '',
                `${physicalLocation.address?.city}, ${physicalLocation.address?.state}`,
                physicalLocation.address?.zipcode || '',
              ]}
            />
          )}
          {mailingLocation && (
            <CompanyInfoCell
              title={t(
                'pages.myInformationPage.mailingAddress',
                'Mailing Address',
              )}
              rows={[
                mailingLocation.address?.address1 || '',
                mailingLocation.address?.address2 || '',
                `${mailingLocation.address?.city}, ${mailingLocation.address?.state}`,
                mailingLocation.address?.zipcode || '',
              ]}
            />
          )}
        </>
      </CompanyInfoRow>
      <EditCompanyAddressesModal
        initialValues={initialModalValues}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={onSubmitHandler}
      />
    </Fragment>
  );
}
