import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Help(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.96 13.3c.28 0 .517-.097.71-.29a.965.965 0 0 0 .29-.71.965.965 0 0 0-.29-.71.966.966 0 0 0-.71-.29.966.966 0 0 0-.71.29.965.965 0 0 0-.29.71c0 .28.097.516.29.71.193.193.43.29.71.29Zm.12-8.24c.373 0 .673.103.9.31.227.207.34.477.34.81 0 .227-.077.457-.23.69-.153.233-.37.477-.65.73-.4.347-.693.68-.88 1-.187.32-.28.64-.28.96 0 .187.07.343.21.47a.723.723 0 0 0 .99-.01c.147-.133.24-.3.28-.5.04-.227.13-.437.27-.63s.37-.443.69-.75c.413-.387.704-.74.87-1.06.167-.32.25-.673.25-1.06 0-.68-.256-1.237-.77-1.67-.513-.433-1.177-.65-1.99-.65-.56 0-1.057.107-1.49.32-.433.213-.77.54-1.01.98a.793.793 0 0 0-.1.51c.027.167.12.303.28.41a.77.77 0 0 0 .57.1.8.8 0 0 0 .51-.34c.147-.2.323-.353.53-.46.207-.107.443-.16.71-.16ZM8 16.5a7.736 7.736 0 0 1-3.1-.63 8.19 8.19 0 0 1-2.55-1.71 7.987 7.987 0 0 1-1.72-2.54A7.786 7.786 0 0 1 0 8.5c0-1.107.21-2.147.63-3.12.42-.973.993-1.82 1.72-2.54A8.202 8.202 0 0 1 4.9 1.13 7.745 7.745 0 0 1 8 .5c1.12 0 2.167.21 3.14.63.973.42 1.82.99 2.54 1.71a7.941 7.941 0 0 1 1.7 2.54A7.9 7.9 0 0 1 16 8.5a7.899 7.899 0 0 1-.62 3.12 7.941 7.941 0 0 1-1.7 2.54 8.073 8.073 0 0 1-2.54 1.71c-.973.42-2.02.63-3.14.63Zm0-1.6c1.787 0 3.3-.623 4.54-1.87 1.24-1.246 1.86-2.757 1.86-4.53 0-1.773-.62-3.283-1.86-4.53C11.3 2.723 9.787 2.1 8 2.1c-1.747 0-3.25.623-4.51 1.87C2.23 5.217 1.6 6.727 1.6 8.5c0 1.773.63 3.284 1.89 4.53C4.75 14.277 6.253 14.9 8 14.9Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
