import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { theme } from '@/config';

import { ButtonLink } from '../ButtonLink';
import { SVG } from '../SVGs/SVG';

import { NavLink } from './NavLink';

export const HEADER_HEIGHT = '76px';
const MAX_WIDTH = '1440px';

const Wrapper = styled('header')`
  background: white;
  height: ${HEADER_HEIGHT};

  border-bottom: 1px solid ${theme.palette.secondary.main};
`;

const Container = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  margin: 0 auto;
  /* max-width: ${MAX_WIDTH}; */
  padding: 0;
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(5)};
  width: auto;
`;

interface IProps {
  loggedIn: boolean;
}

export function Header({ loggedIn = true }: IProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <Box>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Link to="/">
              <SVG name="Logo" height="100%" width="216px" />
            </Link>
            {loggedIn && (
              <NavLink to="/quoter/select-client">
                <strong>
                  {t('common.labels.createQuote', 'Create Quote')}
                </strong>
              </NavLink>
            )}
            {loggedIn && (
              <NavLink to="/quotes">
                <strong>
                  {t('common.labels.quotesAndPolicies', 'Quotes & Policies')}
                </strong>
              </NavLink>
            )}
            {loggedIn && (
              <NavLink to="/clients">
                <strong>{t('common.labels.clients', 'Clients')}</strong>
              </NavLink>
            )}
          </Stack>
        </Box>
        <Stack alignItems="center" direction="row" spacing={2}>
          <ButtonLink color="secondary" to="/help">
            <Typography fontWeight={700}>
              {t('common.labels.help', 'Help')}
            </Typography>
          </ButtonLink>
          {loggedIn && (
            <Link to="/account/my-information">
              <SVG
                name="Person"
                color={theme.palette.secondary.main}
                height={32}
                width={32}
              />
            </Link>
          )}
          {!loggedIn && (
            <ButtonLink to="/login">
              <Stack direction="row" alignItems="center" spacing={0.75}>
                <Typography fontWeight={700}>
                  {t('common.labels.signIn', 'Sign In')}
                </Typography>
                <SVG
                  name="Person"
                  color={theme.palette.secondary.main}
                  height={16}
                  width={16}
                />
              </Stack>
            </ButtonLink>
          )}
        </Stack>
      </Container>
    </Wrapper>
  );
}
