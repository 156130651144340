import { Box } from '@mui/material';

import { theme } from '@/config';

import { SVG } from '../SVGs/SVG';

const linearGradientBackground = theme.palette.gradient.main;

export function HomesBackground() {
  return (
    <Box
      sx={{
        alignItems: 'flex-end',
        background: `${linearGradientBackground}`,
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100vw',
        zIndex: -1,
      }}
    >
      <SVG
        name="Homes"
        style={{ position: 'relative', opacity: 0.1, width: '30%', zIndex: 1 }}
      />
      <SVG
        name="Homes"
        style={{ position: 'relative', opacity: 0.1, width: '30%', zIndex: 1 }}
      />
    </Box>
  );
}
