import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SVG } from '../../components/SVGs/SVG';
import { IStep } from '../../components/Stepper/PolicyVerticalProgressStepper';
import { calculateDaysRemaining } from '../../helpers/calculations';
import { formatAddress } from '../../helpers/formatters';

import { PolicyDetailsPage } from './PolicyDetailsPage';

const mockPolicyData = {
  policyId: '123456789',
  policyStatus: 'Active',
  startDate: '2022-03-31',
  endDate: '2023-03-31',
  premium: 22000,
  paid: true,
  clientName: 'John Doe',
  clientEmail: 'john@clientmarket.com',
  property: {
    address1: '123 Main St',
    city: 'New York',
    state: 'NY',
    zipcode: '10001',
  },
  coverages: [
    { name: 'Dwelling Coverage', value: '$400,000.00', letter: 'I.' },
    { name: 'Other structure', value: '2% ($11,250)', letter: 'II.' },
    { name: 'Personal Property', value: '25% ($112,000)', letter: 'III.' },
    { name: 'Loss of Use', value: '$35,000.00', letter: 'IV.' },
    { name: 'Peronal Liability', value: '$5,000', letter: 'V.' },
    { name: 'Medical Payments', value: '$5,000', letter: 'VI.' },
  ],
  deductibles: [
    { name: 'All Other Perils', value: '$10,000' },
    { name: 'Hurricane', value: '$15,000' },
    { name: 'Water', value: '$25,000' },
  ],
  endorsements: [
    { name: 'Carport Amount', value: '$10,000.00' },
    { name: 'Carport Age', value: '10 years' },
    { name: 'Total Premium', value: '$22,052.00' },
  ],
  history: [
    {
      label: 'Policy In-Force',
      description: undefined,
      eventDate: '04/01/2022',
      icon: <SVG name="PowerOff" width={15} />,
    },
    {
      label: 'Coverage changed',
      description: 'Added Carport - $5,000',
      eventDate: '05/01/2022',
      icon: <SVG name="Toggles" width={15} />,
    },
  ] as IStep[],
};

export function PolicyDetailsPageContainer() {
  const navigate = useNavigate();

  const onBackHandler = useCallback(() => {
    navigate('/quotes');
  }, [navigate]);

  const daysRemaining = calculateDaysRemaining(
    new Date(),
    mockPolicyData.endDate,
  );
  const propertyLocation = formatAddress(mockPolicyData.property);

  return (
    <PolicyDetailsPage
      clientEmail={mockPolicyData.clientEmail}
      clientName={mockPolicyData.clientName}
      daysRemaining={daysRemaining}
      endDate={mockPolicyData.endDate}
      onBack={onBackHandler}
      onRenew={() => console.log('Renew')}
      propertyLocation={propertyLocation}
      policyId={mockPolicyData.policyId}
      policyStatus={mockPolicyData.policyStatus}
      paid={mockPolicyData.paid}
      premium={mockPolicyData.premium}
      startDate={mockPolicyData.startDate}
      steps={mockPolicyData.history}
      coverages={mockPolicyData.coverages}
      deductibles={mockPolicyData.deductibles}
      endorsements={mockPolicyData.endorsements}
    />
  );
}
