import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SVG } from '../SVGs/SVG';

interface IProps {
  policyId: string;
  status: string;
  onViewPolicyDocsClick?: () => void;
  onAddEndorsementsClick?: () => void;
  onEditPolicyDetailsClick?: () => void;
  onGetHelpClick?: () => void;
}

const Label = styled(Typography)(({ theme }) => ({
  lineHeight: 1.45,
  color: theme.palette.grey[70],
  fontSize: '0.75rem',
  textTransform: 'uppercase',
}));

const Value = styled(Typography)(({ theme }) => ({
  lineHeight: 1.45,
  color: theme.palette.grey[90],
  fontSize: '1.0625rem',
}));

export function PolicyDetailsActionBar({
  policyId,
  status,
  onViewPolicyDocsClick,
  onAddEndorsementsClick,
  onEditPolicyDetailsClick,
  onGetHelpClick,
}: IProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ my: 2, ml: 4, mr: 2 }}
    >
      <Stack direction="row" spacing={5}>
        <Box>
          <Label>{t('common.labels.policyNumber', 'Policy Number')}</Label>
          <Value>{policyId}</Value>
        </Box>
        <Box>
          <Label>{t('common.labels.policyStatus', 'Policy Status')}</Label>
          <Value>{status}</Value>
        </Box>
      </Stack>
      <IconButton sx={{ borderRadius: 1.5 }} onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: { border: '1px solid', borderColor: 'grey.50', padding: 1 },
        }}
      >
        <MenuItem onClick={onViewPolicyDocsClick}>
          <SVG
            name="Document"
            sx={{ color: 'secondary.main', mr: 1.5 }}
            width="14"
          />
          {t('pages.policyDetails.viewPolicyDocs', 'View policy docs')}
        </MenuItem>
        <MenuItem onClick={onAddEndorsementsClick}>
          <SVG
            name="Plus"
            sx={{ color: 'secondary.main', mr: 1.5 }}
            width="16"
          />
          {t(
            'pages.policyDetails.addMidTermEndorsements',
            'Add mid-term endorsements',
          )}
        </MenuItem>
        <MenuItem onClick={onEditPolicyDetailsClick}>
          <SVG
            name="Pencil"
            sx={{ color: 'secondary.main', mr: 1.5 }}
            width="13"
          />
          {t('pages.policyDetails.editPolicyDetails', 'Edit policy details')}
        </MenuItem>
        <MenuItem onClick={onGetHelpClick}>
          <SVG
            name="Help"
            sx={{ color: 'secondary.main', mr: 1.5 }}
            width="13"
          />
          {t('pages.policyDetails.getHelp', 'Get help')}
        </MenuItem>
      </Menu>
    </Stack>
  );
}
