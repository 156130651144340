import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Document(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 8.62a.6.6 0 0 0 0 1.2h4.32a.6.6 0 1 0 0-1.2H5Zm0 2.88a.6.6 0 1 0 0 1.2h2.16a.6.6 0 1 0 0-1.2H5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84.7A2.04 2.04 0 0 0 .8 2.74v11.52a2.04 2.04 0 0 0 2.04 2.04h8.64a2.04 2.04 0 0 0 2.04-2.04V5.866a2.04 2.04 0 0 0-.614-1.458L9.709 1.282A2.04 2.04 0 0 0 8.283.7H2.84Zm-.594 1.446A.84.84 0 0 1 2.84 1.9H8v2.28a2.04 2.04 0 0 0 2.04 2.04h2.28v8.04a.84.84 0 0 1-.84.84H2.84a.84.84 0 0 1-.84-.84V2.74a.84.84 0 0 1 .246-.594Zm9.57 2.874L9.2 2.462V4.18a.84.84 0 0 0 .84.84h1.776Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
