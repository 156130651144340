import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IBindingInput, reset, setInput } from '../store/binding';
import { RootState } from '../store/configureStore';

export function useBindingStore() {
  const dispatch = useDispatch();

  const setInputEventHandler = useCallback(
    (payload: IBindingInput) => {
      dispatch(setInput(payload));
    },
    [dispatch],
  );

  const resetEventHandler = useCallback(() => {
    reset();
    dispatch(reset());
  }, [dispatch]);

  return {
    input: useSelector((state: RootState) => state.binding.input),
    setInput: setInputEventHandler,
    reset: resetEventHandler,
  };
}
