import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '@/config/config';

import { IAddress } from '../quoter';

import { addressRequestTemplate, parseUSPSAddressXML } from './helpers';
import { IUSPSAddressResponse } from './interfaces';

const validateAddressEndpoint = `?API=Verify&XML=`;

export const uspsApi = createApi({
  reducerPath: 'uspsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.usps.baseUrl,
  }),
  endpoints: (builder) => ({
    validateAddress: builder.query<IUSPSAddressResponse, IAddress>({
      transformResponse: (response: string) => {
        return parseUSPSAddressXML(response);
      },
      query: (address: IAddress) => ({
        url: `${validateAddressEndpoint}${encodeURI(
          addressRequestTemplate(address),
        )}`,
        responseHandler: 'text',
      }),
    }),
  }),
});

export const { useValidateAddressQuery, useLazyValidateAddressQuery } = uspsApi;
