import { Typography } from '@mui/material';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SignatureReceivedModal } from '../../components/Modals/Quoting/SignatureReceivedModal';
import { File as UploadedFile } from '../../graphql/generated';
import { useMockDataMutation } from '../../hooks';

import { QuoterAddSignaturePage } from './QuoterAddSignaturePage';

export const NEXT_PAGE_ROUTE = '/quoter/share-application';

const mockResponse = {
  _id: '12345678',
  bucketName: 'mockBucketName',
  signatureImage: 'client-signature.png',
  filepath: '/mockBucketName/12345678/client-signature.png',
} as UploadedFile;

export function QuoterAddSignaturePageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signatureReceivedOpen, setSignatureReceivedOpen] = useState(false);

  const [mutate, { isLoading }] = useMockDataMutation({
    mockDelay: 500,
    mockResponse: mockResponse,
  });

  // Handlers
  const onSubmitHandler = useCallback(
    async (signatureImageBase64: string) => {
      const result = await mutate(signatureImageBase64);
      console.log('result', result);

      setSignatureReceivedOpen(true);
    },
    [mutate],
  );

  const closeReceivedModal = useCallback(() => {
    setSignatureReceivedOpen(false);
    navigate(NEXT_PAGE_ROUTE);
  }, [navigate, setSignatureReceivedOpen]);

  return (
    <>
      <QuoterAddSignaturePage
        isLoading={isLoading}
        onSubmit={onSubmitHandler}
      />
      <SignatureReceivedModal
        open={signatureReceivedOpen}
        onClose={closeReceivedModal}
        title={t('pages.addSignature.signatureReceived', 'Signature Received')}
      >
        <Typography
          maxWidth={400}
          sx={{ color: 'grey.80', fontSize: '1.0625' }}
        >
          {t(
            'pages.addSignature.sendToClient',
            'Now let’s send this over to the client.',
          )}
        </Typography>
      </SignatureReceivedModal>
    </>
  );
}
