import { Container, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatCurrency } from '@/helpers/formatters';
import { useEndorsementsStore } from '@/hooks/useEndorsementsStore';
import {
  MidTermEndorsementKeys,
  MidTermEndorsements,
} from '@/store/midtermEndorsements';

import { Accordion } from '../Accordion';
import { Button } from '../Button';
import { Disclosure } from '../CustomCoverageOption/Disclosure';
import { SVG } from '../SVGs/SVG';

import { SimpleEndorsement } from './SimpleEndorsement';

const mockEndorsement = {
  description:
    'Dwelling insurance covers damage to your home’s main structure. Your structure is insured against most events, but not against any named exclusions. Unlike traditional HO-3 policies, this policy does not cover your home’s roof when damaged as a result of weather.',
  leftTitle: 'Limit',
  leftPrice: 50000,
  leftDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
  rightTitle: 'Related Medical Expenses',
  rightPrice: 5000,
  rightDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
};

const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[70],
  fontSize: '1rem',
  margin: theme.spacing(3, 0, 2, 0),
}));

const FooterAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderTop: '1px solid',
  borderColor: theme.palette.grey[50],
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(3, 0),
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.grey[60],
  },
}));

export function SimpleEndorsementContainer() {
  const { t } = useTranslation();
  const { input, setInput } = useEndorsementsStore();
  const location = useLocation();
  const navigate = useNavigate();

  const endorsementKey = location.pathname.split(
    '/endorsements/',
  )[1] as MidTermEndorsementKeys;

  const randomPrice = parseFloat((Math.random() * 200).toFixed(2));

  const isSelected = useMemo(() => {
    return input.some((item) => item.coverage === endorsementKey);
  }, [input, endorsementKey]);

  const handleRemove = useCallback(() => {
    const endorsements = input.filter(
      (item) => item.coverage !== endorsementKey,
    );
    setInput(endorsements);
    navigate(-1);
  }, [setInput, input, endorsementKey, navigate]);

  const handleSubmit = useCallback(() => {
    setInput([
      ...input,
      {
        coverage: endorsementKey,
        cost: randomPrice,
        description: t(
          'pages.midtermEndorsements.addsPriceToPremium',
          'Adds {{price}} to premium',
          { price: formatCurrency(randomPrice) },
        ),
      },
    ]);
    navigate(-1);
  }, [setInput, input, endorsementKey, randomPrice, t, navigate]);

  return (
    <Container disableGutters sx={{ width: 960 }}>
      <BackButton
        onClick={() => navigate(-1)}
        startIcon={<SVG name="LeftArrow" height={12} width={11} />}
      >
        {t('common.labels.coverageOptions', 'Coverage options')}
      </BackButton>
      <SimpleEndorsement
        title={MidTermEndorsements[endorsementKey]}
        description={mockEndorsement.description}
        isSelected={isSelected}
        leftTitle={mockEndorsement.leftTitle}
        leftPrice={mockEndorsement.leftPrice}
        leftDescription={mockEndorsement.leftDescription}
        rightTitle={mockEndorsement.rightTitle}
        rightPrice={mockEndorsement.rightPrice}
        rightDescription={mockEndorsement.rightDescription}
        onRemove={handleRemove}
        onSubmit={handleSubmit}
      />
      <FooterAccordion title="Disclosures">
        <Disclosure />
      </FooterAccordion>
    </Container>
  );
}
