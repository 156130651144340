// Percentage options
export const GENERIC_COVERAGE_PERCENTAGES = [
  0.0, 0.01, 0.02, 0.05, 0.1, 0.15, 0.2,
];

export const COVERAGE_B_PERCENTAGES = [0.0, 0.01, 0.02, 0.05, 0.1, 0.15, 0.2];
export const COVERAGE_C_PERCENTAGES = [
  0, 0.25, 0.26, 0.28, 0.3, 0.32, 0.34, 0.36, 0.38, 0.4, 0.42, 0.44, 0.46, 0.48,
  0.5, 0.55, 0.6, 0.65, 0.7, 0.75,
];

export const HURRICANE_DEDUCTIBLE_PERCENTAGES = [0.02, 0.05, 0.1];

export const ORDINANCE_OR_LAW_PERCENTAGES = [0.25, 0.5];

// Fixed dollar options

export const COVERAGE_E_OPTIONS = [100000, 300000, 500000];
export const COVERAGE_F_OPTIONS = [1000, 2000, 3000, 4000, 5000, 10000];

export const WATER_DAMAGE_OPTIONS = [
  0, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
];

export const ALL_OTHER_PERILS_DEDUCTIBLE_OPTIONS = [0, 500, 1000, 2500, 5000];
export const WATER_DEDUCTIBLE_OPTIONS = [0, 500, 1000, 2500, 5000];
export const HURRICANE_DEDUCTIBLE_OPTIONS = [0, 500, 1000, 2500, 5000];

export const MOLD_COMBO_OPTIONS = [
  [10000, 20000],
  [15000, 30000],
  [25000, 50000],
  [50000, 50000],
];

export const INCREASED_LOSS_ASSESSMENT_OPTIONS = [1000, 2000, 3000];
export const CARPORT_POOL_ENCLOSURE_ENDORSE_OPTIONS = [
  10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
];
