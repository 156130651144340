import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { BasicInputWithLabel } from '../../components/Inputs/BasicInputWithLabel';
import { BasicSelectWithLabel } from '../../components/Inputs/BasicSelectWithLabel';
import { YearInputMask } from '../../components/Inputs/TextField/YearInputMask';
import { QuoteStepperContainer } from '../../components/QuoteStepper';

import { PREV_PAGE_ROUTE } from './QuoterReviewPropertyDataPage.container';
import {
  getQuoterReviewPropertySchema,
  IQuoterReviewPropertySchema,
} from './QuoterReviewPropertyDataPage.schema';

interface IProps {
  onSubmit: (data: any) => void;
  initialValues?: Partial<IQuoterReviewPropertySchema>;
}

export function QuoterReviewPropertyDataPage({
  initialValues,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      constructionType: '',
      hasBurglaryAlarm: '',
      hasFireAlarm: '',
      hasSprinklerSystem: '',
      isSeasonalProperty: '',
      roofMaterial: '',
      roofYear: '',
      yearBuilt: '',
    },
    resolver: zodResolver(getQuoterReviewPropertySchema()),
  });

  const booleanOptions = useMemo(
    () => [
      { label: t('common.labels.true', 'True'), value: 'True' },
      { label: t('common.labels.false', 'False'), value: 'False' },
    ],
    [t],
  );

  const constructionTypes = useMemo(
    () => [
      {
        label: t('common.labels.constructionTypes1', 'Frame'),
        value: 'Frame',
      },
      {
        label: t('common.labels.constructionTypes2', 'Masonry'),
        value: 'Masonry',
      },
    ],
    [t],
  );

  const roofMaterials = useMemo(
    () => [
      {
        label: t('common.labels.roofMaterials1', 'Tile/Metal'),
        value: 'Tile_Metal',
      },
      {
        label: t('common.labels.roofMaterials2', 'Other'),
        value: 'Other',
      },
    ],
    [t],
  );

  return (
    <Box>
      <QuoteStepperContainer />
      <PageTitle
        title={t('pages.reviewPropertyDataPage.title', 'Review Property Data')}
      >
        <Typography
          mt={2}
          sx={{ lineHeight: 1.5, maxWidth: '644px', color: 'grey.80' }}
        >
          {t(
            'pages.reviewPropertyDataPage.description',
            'Please review and validate the below property characteristics, which will be used to quote the appropriate coverage options',
          )}
        </Typography>
      </PageTitle>
      <Paper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
          padding: 5,
        }}
      >
        <Box component="form" bgcolor="grey.10" p={4} borderRadius="8px">
          <Grid container spacing={2.5}>
            <Grid item xs={6}>
              <BasicInputWithLabel
                control={control}
                label={t('property.labels.yearBuilt', 'Year Built')}
                name="yearBuilt"
                placeholder={initialValues?.yearBuilt || ''}
                inputComponent={YearInputMask as any}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicSelectWithLabel
                control={control}
                label={t('property.labels.constructionType', 'Construction')}
                name="constructionType"
                placeholder={initialValues?.constructionType || ''}
                options={constructionTypes}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicSelectWithLabel
                control={control}
                label={t(
                  'property.labels.isSeasonalProperty',
                  'Seasonal Property',
                )}
                name="isSeasonalProperty"
                placeholder={initialValues?.isSeasonalProperty || ''}
                options={booleanOptions}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicSelectWithLabel
                control={control}
                label={t('property.labels.hasFireAlarm', 'Fire Alarm')}
                name="hasFireAlarm"
                placeholder={initialValues?.hasFireAlarm || ''}
                options={booleanOptions}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicSelectWithLabel
                control={control}
                name="hasBurglaryAlarm"
                label={t('property.labels.hasBurglaryAlarm', 'Burglar Alarm')}
                placeholder={initialValues?.hasBurglaryAlarm || ''}
                options={booleanOptions}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicSelectWithLabel
                control={control}
                label={t(
                  'property.labels.hasSprinklerSystem',
                  'Sprinkler System',
                )}
                name="hasSprinklerSystem"
                placeholder={initialValues?.hasSprinklerSystem || ''}
                options={booleanOptions}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicInputWithLabel
                control={control}
                label={t('property.labels.roofYear', 'Roof Year')}
                name="roofYear"
                placeholder={initialValues?.roofYear || ''}
                inputComponent={YearInputMask as any}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicSelectWithLabel
                control={control}
                label={t('property.labels.roofMaterial', 'Roof Material')}
                name="roofMaterial"
                placeholder={initialValues?.roofMaterial || ''}
                options={roofMaterials}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <QuoterActionBarContainer
        backText={t('pages.underwritingChecksPage.title')}
        onBack={PREV_PAGE_ROUTE}
      >
        <Button
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
        >
          {t('pages.reviewPropertyDataPage.submit', 'Looks correct, proceed')}
        </Button>
      </QuoterActionBarContainer>
    </Box>
  );
}
