import { Box } from '@mui/material';

import { TwoPanelPaper } from '../components/Paper';

export function TestPage() {
  return (
    <Box>
      <TwoPanelPaper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
        }}
        leftChildren={
          <div>
            <h1>Left side</h1>
          </div>
        }
        rightChildren={
          <div>
            <h1>Right side Right side Right side</h1>
            <div>RightsideRightsideRightsideRightsideRightside</div>
          </div>
        }
      />
    </Box>
  );
}
