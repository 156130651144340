import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useBindingStore } from '@/hooks/useBindingStore';
import { useQuoterStore } from '@/hooks/useQuoterStore';

// Utility Component, it's job is to do any setup or teardown when entering quoter flows
// Currently resets quoter store when entering quoter routes or leaving quoter routes
export function QuoterInitializer() {
  const { reset } = useQuoterStore();
  const { reset: resetBinding } = useBindingStore();

  useEffect(() => {
    reset();
    resetBinding();

    return () => {
      reset();
      resetBinding();
    };
  }, [reset, resetBinding]);

  return <Outlet />;
}
