import { useEffect, useRef, useState } from 'react';

export function useIntersectionObserver(
  triggerElement?: Element | HTMLElement | null,
  options?: IntersectionObserverInit,
) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  // using ref to avoid re-creating the options object on every render
  // causing more re-renders
  // this means options changes will not be reflected
  const customOptions = useRef(options).current;

  useEffect(() => {
    if (!triggerElement) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, customOptions);

    observer.observe(triggerElement);

    return () => {
      observer.disconnect();
    };
  }, [customOptions, triggerElement]);

  return isIntersecting;
}
