import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthUserPayload, Role } from '../graphql/generated';
import { api } from '../store/api';
import { confirm, login, logout } from '../store/auth';
import { RootState } from '../store/configureStore';

export function useAuth() {
  const dispatch = useDispatch();

  const confirmEventHandler = useCallback(
    (payload: AuthUserPayload) => {
      dispatch(confirm(payload));
    },
    [dispatch],
  );

  const loginEventHandler = useCallback(
    (payload: AuthUserPayload) => {
      dispatch(login(payload));
    },
    [dispatch],
  );

  const logoutEventHandler = useCallback(() => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  }, [dispatch]);

  return {
    awaitingCurrentUserQuery: useSelector(
      (state: RootState) => state.auth.awaitingCurrentUserQuery,
    ),
    confirm: confirmEventHandler,
    hasJustConfirmed: useSelector(
      (state: RootState) => state.auth.hasJustConfirmed,
    ),
    isConfirmed: useSelector((state: RootState) => state.auth.isConfirmed),
    isLoggedIn: useSelector((state: RootState) => state.auth.isLoggedIn),
    login: loginEventHandler,
    logout: logoutEventHandler,
    user: useSelector((state: RootState) => state.auth.user),
    userIsAdmin: useSelector(
      (state: RootState) =>
        state.auth.user?.role === Role.Admin ||
        state.auth.user?.role === Role.SuperAdmin,
    ),
  };
}
