import { DATE_FORMATS, formatAddress, formatDate } from '@/helpers/formatters';

import { MidtermEndorsementsReviewPage } from './MidtermEndorsementsReviewPage';

const mockPolicy = {
  policyId: '123456789',
  policyStatus: 'Active',
  startDate: '2022-03-31',
  endDate: '2023-03-31',
  premium: 22000,
  paid: true,
  clientName: 'John Doe',
  clientEmail: 'john@clientmarket.com',
  property: {
    address1: '123 Main St',
    city: 'New York',
    state: 'NY',
    zipcode: '10001',
  },
  coverages: [
    {
      name: 'I. Dwelling Coverage',
      value: '$400,000.00',
      description:
        'Dwelling insurance covers damage to your home’s main structure. Your structure is insured against most events, but not against any named exclusions. Unlike traditional HO-3 policies, this policy does not cover your home’s roof when damaged as a result of weather.',
    },
    {
      name: 'II. Other structure',
      value: '$11,250 (2%)',
      description:
        'Other Structures insurance covers any additional structures on your property like garages and fences. Your structure is insured against most events, but not against any named exclusions.',
    },
    {
      name: 'III. Personal Property',
      value: '$112,000 (25%)',
      description:
        'Personal Property insurance protects your belongings when stolen, lost, or damaged as a result of only the perils listed in your policy',
    },
    {
      name: 'IV. Loss of Use',
      value: '$35,000.00',
      description:
        'Loss of Use insurance pays any necessary increase in living expenses incurred as a result of a covered loss that makes your home not fit for living.',
    },
    {
      name: 'V. Personal Liability',
      value: '5,000',
      description:
        'Personal Liability insurance pays in the event that a claim is made against you due to bodily injury or property damage that occurs on your property',
    },
    {
      name: 'VI. Medical Payments',
      value: '$5,000',
      description:
        'Medical Payments to Others insurance pays necessary medical expenses incurred as a result of bodily injury.',
    },
    {
      name: 'Parametric Expense',
      value: '$25,000',
      description:
        'Additional Hurricane Parametric Expense insurance pays expenses incurred resulting from a hurricane. This is a parametric coverage link. When applicable, loss is calculated based on the distance of the hurricane from your home and on wind speed.',
    },
    {
      name: 'All Other Perils',
      value: '$10,000',
      description: 'Lorem ipsum...',
    },
    { name: 'Hurricane', value: '$15,000.00', description: 'Lorem ipsum...' },
    { name: 'Mold', value: '$15,000.00', description: 'Lorem ipsum...' },
    {
      name: 'Carport Amount',
      value: '$10,000.00',
      description: 'Lorem ipsum...',
    },
    { name: 'Carport Age', value: '10 years', description: 'Lorem ipsum...' },
  ],
};

export function MidtermEndorsementsReviewPageContainer() {
  return (
    <MidtermEndorsementsReviewPage
      policyId={mockPolicy.policyId}
      name={mockPolicy.clientName}
      address={formatAddress(mockPolicy.property)}
      startDate={formatDate(mockPolicy.startDate, DATE_FORMATS.LONG_FORMAT)}
      coverages={mockPolicy.coverages}
    />
  );
}
