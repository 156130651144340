import { Z_INDEXES } from '@/config/zIndexes';

import { ContainerLoader } from '.';

interface IProps {
  children?: React.ReactNode;
}

export function FullscreenLoader({ children }: IProps) {
  return (
    <ContainerLoader
      sticky={false}
      sx={{
        position: 'fixed',
        zIndex: Z_INDEXES.FULLSCREEN_LOADER,
      }}
    >
      {children}
    </ContainerLoader>
  );
}
