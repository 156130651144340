import { InputLabel, styled } from '@mui/material';

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  color: '#000',
  fontSize: '1rem',
  transform: 'translate(0, -4.5px) scale(1)',

  '&.Mui-focused': {
    color: `${theme.palette.secondary.main}`,
  },
}));
