import { IQuoterInput } from '../store/quoter';

export function quoterStoreToLastRoute(store: IQuoterInput) {
  const hasClient = store.selectClient !== undefined;
  const hasProperty = store.selectProperty !== undefined;
  const hasStartDate = store.startDate !== undefined;
  const hasAgreedToDiligentSearch = store.agreeToDiligentSearch !== null;
  const hasRanUnderwritingChecks = store.underwritingChecks !== undefined;

  const hasQuotes = store.quotes !== undefined && store.quotes.length > 0;

  // If the store has client, property, start date, diligent search, underwriting checks, and quotes
  // then we can assume that the user has stopped at the quotes page
  if (
    hasClient &&
    hasProperty &&
    hasStartDate &&
    hasAgreedToDiligentSearch &&
    hasRanUnderwritingChecks &&
    hasQuotes
  ) {
    return '/quoter/customize-coverage';
    // If the store has client, property, start date, diligent search, underwriting checks, and no quotes
    // then we can assume that the user has stopped at the review property page
  } else if (
    hasClient &&
    hasProperty &&
    hasStartDate &&
    hasAgreedToDiligentSearch &&
    hasRanUnderwritingChecks
  ) {
    return '/quoter/property-details';
    // If the store has client, property, start date, diligent search, and no underwriting checks
    // then we can assume that the user has stopped at the underwriting checks page
  } else if (
    hasClient &&
    hasProperty &&
    hasStartDate &&
    hasAgreedToDiligentSearch
  ) {
    return '/quoter/underwriting-checks';
    // If the store has client, property, start date, and no diligent search
    // then we can assume that the user has stopped at the diligent search page
  } else if (hasClient && hasProperty && hasStartDate) {
    return '/quoter/diligent-search';
    // If the store has client, property, and no start date
    // then we can assume that the user has stopped at the start date page
  } else if (hasClient && hasProperty) {
    return '/quoter/select-start-date';
    // If the store has client and no property
    // then we can assume that the user has stopped at the property page
  } else if (hasClient) {
    return '/quoter/select-property';
  }

  // no client, probably left off at client details
  // route should never hit
  console.error('No client saved, should not be saved?');
  return '/quoter/client-details';
}
