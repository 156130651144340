import { Box, BoxProps, Button, Stack, Typography } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { shadowBoxes } from '@/config/theme/base';
import { FontWeight } from '@/config/theme/typography';

import { ContainerLoader } from '../Loaders';

interface IProps extends BoxProps {
  hasSignature?: boolean;
  isLoading?: boolean;
  onClear?: () => void;
}

function SignaturePadWithForwardRef(
  { isLoading, hasSignature, onClear, ...props }: IProps,
  ref: ForwardedRef<HTMLCanvasElement>,
) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'grey.10',
        borderRadius: 3,
        boxShadow: shadowBoxes.type1,
        height: '207px',
        position: 'relative',
        width: '100%',
      }}
      {...props}
    >
      {isLoading && <ContainerLoader sx={{ borderRadius: 3 }} />}
      <canvas
        ref={ref}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
      <Stack alignItems="center">
        <Typography
          sx={{
            color: 'grey.70',
            fontSize: '1.0625rem',
            lineHeight: 1.45,
            mt: 2,
            textAlign: 'center',
          }}
        >
          {t(
            'pages.customerSignaturePage.drawSignature',
            'Draw your signature in the box',
          )}
        </Typography>
        {hasSignature && (
          <Button
            color="secondary"
            onClick={onClear}
            sx={{
              color: 'secondary.main',
              fontSize: '1rem',
              mt: 2,
            }}
            variant="text"
          >
            <Typography
              sx={{
                fontSize: '1.3125rem',
                fontWeight: FontWeight.medium,
                lineHeight: 1.45,
              }}
            >
              {t(
                'pages.customerSignaturePage.clearSignature',
                'Clear Signature',
              )}
            </Typography>
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export const SignaturePad = forwardRef(SignaturePadWithForwardRef);
