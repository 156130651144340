import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Search(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32612 1.8998C6.01958 1.8998 4.76655 2.41883 3.84269 3.34269C2.91883 4.26655 2.3998 5.51958 2.3998 6.82612C2.3998 8.13266 2.91883 9.38569 3.84269 10.3096C4.76655 11.2334 6.01958 11.7524 7.32612 11.7524C8.62599 11.7524 9.87289 11.2387 10.7954 10.3237C10.801 10.3177 10.8067 10.3119 10.8125 10.3061C10.8177 10.3009 10.8229 10.2958 10.8283 10.2907C11.7404 9.36872 12.2524 8.12381 12.2524 6.82612C12.2524 5.51958 11.7334 4.26655 10.8096 3.34269C9.88569 2.41883 8.63266 1.8998 7.32612 1.8998ZM12.4738 10.838C13.3626 9.69759 13.8524 8.28711 13.8524 6.82612C13.8524 5.09523 13.1648 3.43524 11.9409 2.21132C10.717 0.987397 9.05701 0.299805 7.32612 0.299805C5.59523 0.299805 3.93524 0.987397 2.71132 2.21132C1.4874 3.43524 0.799805 5.09523 0.799805 6.82612C0.799805 8.55701 1.4874 10.217 2.71132 11.4409C3.93524 12.6648 5.59523 13.3524 7.32612 13.3524C8.78924 13.3524 10.2017 12.8611 11.3429 11.9699L13.8336 14.465C14.1458 14.7777 14.6523 14.7781 14.965 14.466C15.2777 14.1539 15.2781 13.6473 14.966 13.3346L12.4738 10.838Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
