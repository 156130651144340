import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import { ControllerTextField } from './ControllerTextField';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label?: string;
}

export function ControllerPasswordField<T extends FieldValues>({
  control,
  label,
  name,
  defaultValue,
  ...inputProps
}: IProps<T> & TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <ControllerTextField
      control={control}
      name={name}
      label={label}
      {...inputProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Search" onClick={toggleShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
