import { Typography } from '@mui/material';

import { EmailSentModal } from './EmailSentModal';

interface IProps {
  onClose: () => void;
  open: boolean;
}

export function ResetPasswordSuccessModal({ onClose, open }: IProps) {
  return (
    <EmailSentModal title="Password updated" onClose={onClose} open={open}>
      <Typography maxWidth={400}>
        Password for your account has been successfully updated.
      </Typography>
    </EmailSentModal>
  );
}
