import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { HorizontalRuler } from '@/components/HorizontalRuler';
import { User } from '@/graphql/generated';

import { BaseModal } from '../BaseModal';

interface IProps {
  client: User | undefined;
  clientEmailSent?: boolean;
  isLoading?: boolean;
  onAddEmail?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClientSend: () => void;
  onClose?: () => void;
  onSubmit: () => void;
  open: boolean;
  shareEmails: string;
}

export function ShareQuoteModal({
  client,
  clientEmailSent,
  isLoading,
  onAddEmail,
  onClientSend,
  onClose,
  onSubmit,
  open = false,
  shareEmails,
}: IProps) {
  const { t } = useTranslation();

  return (
    <BaseModal
      disableBackdropClick={isLoading}
      hideCloseButton
      onClose={onClose}
      open={open}
      title={t('pages.sharePage.modalTitle', 'Share quote')}
      actionBar={
        onClose && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              onClick={onClose}
              variant="text"
              sx={{ color: 'grey.70', fontWeight: 600 }}
            >
              {t('common.labels.cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={isLoading}
              sx={{
                fontWeight: 600,
                height: '31px',
                width: '74px',
                '&.Mui-disabled': {
                  backgroundColor: 'grey.40',
                  color: 'grey.60',
                },
              }}
            >
              {t('common.labels.share', 'Share')}
            </Button>
          </Box>
        )
      }
    >
      <Box minWidth="450px">
        <Typography
          my={2}
          sx={{
            color: 'grey.80',
            fontSize: '1.0625rem',
            fontWeight: 400,
            my: 2,
            padding: 0,
          }}
        >
          {t(
            'pages.sharePage.modalDescription',
            "Send to client's email on file",
          )}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          mb={4}
        >
          <Stack direction="column" spacing={0.5}>
            {isLoading ? (
              <Skeleton variant="text" width={260} height={60} />
            ) : (
              <Fragment>
                <Typography fontWeight={600} variant="h4" color="grey.90">
                  {client?.profile?.firstName} {client?.profile?.lastName}
                </Typography>
                <Typography color="grey.80" fontSize="0.9375rem">
                  {client?.email}
                </Typography>
              </Fragment>
            )}
          </Stack>
          {isLoading ? (
            <Button
              sx={{
                backgroundColor: 'primary.main',
                color: 'grey.00',
                fontSize: '8px',
                fontWeight: 600,
                height: '31px',
                width: '69px',
              }}
            >
              <CircularProgress size={20} color="inherit" />
            </Button>
          ) : (
            <Button
              disabled={isLoading || clientEmailSent}
              variant="contained"
              onClick={onClientSend}
              sx={{ height: '31px', width: '69px', fontWeight: 600 }}
            >
              {clientEmailSent ? (
                <CheckIcon />
              ) : (
                t('common.labels.send', 'Send')
              )}
            </Button>
          )}
        </Stack>
        <HorizontalRuler />
        <Box mb={4}>
          <Typography my={2}>
            {t('common.labels.sendMultipleEmails', 'Send to multiple emails')}
          </Typography>
          {isLoading ? (
            <Skeleton variant="text" width={520} height={60} />
          ) : (
            <TextField
              fullWidth
              label="Email, comma separated"
              onChange={onAddEmail}
              value={shareEmails}
              variant="outlined"
            />
          )}
        </Box>
      </Box>
    </BaseModal>
  );
}
