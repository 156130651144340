import { Box, Container } from '@mui/material';

import { CompanyInfoContainer } from './CompanyInfo.container';
import { UserInfoContainer } from './UserInfo.container';

export function MyInformationPage() {
  return (
    <Container sx={{ paddingY: 5 }}>
      <UserInfoContainer />
      <Box padding={1} />
      <CompanyInfoContainer />
    </Container>
  );
}
