import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';
import { ControllerTextField } from '@/components/Inputs';
import { PhoneInputMask } from '@/components/Inputs/TextField/PhoneInputMask';
import {
  getCompanyContactsSchema,
  ICompanyContactsSchema,
} from '@/pages/MyInformationPage/CompanyContacts.schema';

import { BaseModal } from '../BaseModal';

interface ICompanyContactsFields {
  name: string;
  email: string;
  phone: string;
}

interface IInitialCompanyContactsValues {
  primary: ICompanyContactsFields;
  accounting: ICompanyContactsFields;
}
interface IProps {
  initialValues: IInitialCompanyContactsValues;
  onClose?: () => void;
  onSave: SubmitHandler<ICompanyContactsSchema>;
  open: boolean;
}

const StyledTextField = styled(ControllerTextField)<any>(({ theme }) => ({
  '.MuiOutlinedInput-input': {
    backgroundColor: theme.palette.grey['00'],
    borderRadius: '12px',
    border: `1px solid ${theme.palette.grey[20]}`,
    padding: '16px 14px',
  },
})) as typeof ControllerTextField;

export function EditCompanyContactsModal({
  initialValues,
  onClose,
  onSave,
  open,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(getCompanyContactsSchema()),
  });

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues, onClose]);

  return (
    <BaseModal
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '1024px',
        },
      }}
      open={open}
      onClose={onClose}
      title={t('modals.editCompanyContacts.title', 'Edit Contacts')}
      actionBar={
        onClose && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              onClick={onClose}
              sx={{ color: 'grey.100', fontWeight: 600 }}
            >
              {t('common.actions.cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSave)}
              sx={{
                fontWeight: 600,
                height: '31px',
                width: '74px',
                '&.Mui-disabled': {
                  backgroundColor: 'grey.40',
                  color: 'grey.60',
                },
              }}
            >
              {t('common.actions.save', 'Save')}
            </Button>
          </Box>
        )
      }
    >
      <Stack mt={3} direction="row" justifyContent="space-between" spacing={5}>
        <Stack
          padding={4}
          spacing={2}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
          }}
        >
          <Typography
            fontSize="1.3125rem"
            lineHeight="145%"
            color="secondary"
            mb={1}
          >
            {t('pages.myInformationPage.primaryContact', 'Primary Contact')}
          </Typography>
          <StyledTextField
            fullWidth
            name="primary.name"
            placeholder="Name"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="primary.email"
            placeholder="Email"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="primary.phone"
            placeholder="Phone"
            control={control}
            InputProps={{
              inputComponent: PhoneInputMask as any,
            }}
          />
        </Stack>
        <Stack
          padding={4}
          spacing={2}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
          }}
        >
          <Typography
            fontSize="1.3125rem"
            lineHeight="145%"
            color="secondary"
            mb={1}
          >
            {t(
              'pages.myInformationPage.accountingContact',
              'Accounting Contact',
            )}
          </Typography>
          <StyledTextField
            fullWidth
            name="accounting.name"
            placeholder="Name"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="accounting.email"
            placeholder="Email"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="accounting.phone"
            placeholder="Phone"
            control={control}
            InputProps={{
              inputComponent: PhoneInputMask as any,
            }}
          />
        </Stack>
      </Stack>
    </BaseModal>
  );
}
