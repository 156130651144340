import {
  GridCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { generateEmptyQuote } from '../../components/CustomCoverageOption/helpers';
import { PaginationInputs, SortDirection } from '../../graphql/generated';
import { useMockDataQuery } from '../../hooks';
import { IQuoteOption } from '../../store/quoter';

import { QuotesPage } from './QuotesPage';
import { rowFormatter, sortByMap } from './helpers';
const mockQuotesResponse = [
  generateEmptyQuote({ id: '2b4d7c22-33' }),
  generateEmptyQuote({ id: 'asdau123-5d' }),
  generateEmptyQuote({ id: 'ush63edb-s7' }),
  generateEmptyQuote({ id: '8syecyak-2x' }),
];

export function QuotesPageContainer() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [selectedQuoteIds, setSelectedQuoteIds] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>(sortByMap['createdAt']);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Desc,
  );

  const paginationInput: PaginationInputs = useMemo(
    () => ({
      page,
      limit,
      sortBy,
      sortDirection,
    }),
    [page, limit, sortBy, sortDirection],
  );

  const { data: quoteListResponse, isLoading: quoteListLoading } =
    useMockDataQuery({
      mockDelay: 1000,
      mockResponse: mockQuotesResponse,
    });

  // handlers
  const handlePageChange = useCallback((page: number) => {
    // dataGrid page prop is zero index-based while API page input accepts >= 1
    setPage(Math.max(page + 1, 1));
  }, []);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const sortField = sortModel[0].field as keyof typeof sortByMap;
      const formattedSortField = sortByMap[sortField];
      const formattedSortDir = sortModel[0].sort || sortDirection;
      const sortDir =
        SortDirection.Asc === formattedSortDir.toUpperCase()
          ? SortDirection.Asc
          : SortDirection.Desc;

      setSortBy(formattedSortField);
      setSortDirection(sortDir);
    },
    [sortDirection],
  );

  const onCheckedHandler = useCallback(
    (value: GridSelectionModel) => {
      setSelectedQuoteIds(value as string[]);
    },
    [setSelectedQuoteIds],
  );

  const onCancelHandler = useCallback(() => {
    setSelectedQuoteIds([]);
  }, [setSelectedQuoteIds]);

  const onDeleteHandler = useCallback(() => {
    // TODO: integrate with backend
    setSelectedQuoteIds([]);
    alert('deleting quotes!');
  }, [setSelectedQuoteIds]);

  const onClick = useCallback(
    (value: GridCellParams) => {
      if (value.field !== '__check__') {
        navigate(`/quotes/${value.id}`);
      }
    },
    [navigate],
  );

  // computed values
  const formattedRows = useMemo(() => {
    const quotes = (quoteListResponse ?? []) as IQuoteOption[];
    return rowFormatter(quotes);
  }, [quoteListResponse]);

  return (
    <QuotesPage
      handleSortModelChange={handleSortModelChange}
      onPageChange={handlePageChange}
      initialValues={formattedRows || []}
      page={page - 1}
      pageSize={limit}
      onCancel={onCancelHandler}
      onChecked={onCheckedHandler}
      onClick={onClick}
      onDelete={onDeleteHandler}
      quotesLoading={quoteListLoading}
      selectedRows={selectedQuoteIds}
    />
  );
}
