import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, TextFieldProps } from '@mui/material';
import { Control, FieldValues, Path } from 'react-hook-form';

import { ControllerTextField } from '..';

export interface IDropdownOption {
  value: number | string;
  label: string;
}

interface IProps<T extends FieldValues> {
  control: Control<T>;
  isDisabled?: boolean;
  label?: string;
  name: Path<T>;
  options: IDropdownOption[];
}

export function ControllerDropdown<T extends FieldValues>({
  control,
  isDisabled,
  label,
  name,
  options,
  ...inputProps
}: IProps<T> & TextFieldProps) {
  return (
    <ControllerTextField
      control={control}
      fullWidth
      label={label}
      name={name}
      select
      SelectProps={{
        IconComponent: ExpandMoreIcon,
      }}
      {...inputProps}
    >
      {options.map((option, index) => (
        <MenuItem key={`${index}-${option.value}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </ControllerTextField>
  );
}
