export const config = {
  api: {
    grapqlEndpoint:
      process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/graphql',
  },
  bing: {
    apiKey: process.env.REACT_APP_BING_API_KEY || 'NEED_TO_SET_BING_API_KEY',
    baseUrl: 'https://dev.virtualearth.net/REST/v1',
  },
  contact: {
    email: process.env.REACT_APP_CONTACT_EMAIL || 'sales@arbolmarket.com',
  },
  mui: {
    licenseKey: 'temp',
  },
  usps: {
    userId: process.env.REACT_APP_USPS_USER_ID || 'NEED_TO_SET_USPS_USER_ID',
    baseUrl: 'https://production.shippingapis.com/ShippingAPI.dll',
  },
};
