import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { ControllerDatePicker } from '../../components/DatePicker';
import { ControllerTextField } from '../../components/Inputs';
import { PhoneInputMask } from '../../components/Inputs/TextField/PhoneInputMask';
import { TwoPanelPaper } from '../../components/Paper';
import {
  IStackedListOption,
  SearchableStackedList,
} from '../../components/StackedList';
import { User } from '../../graphql/generated';

import {
  getQuoterSelectClientSchema,
  IQuoterSelectClientSchema,
} from './QuoterSelectClientPage.schema';

interface IProps {
  clientOptions: IStackedListOption<User>[];
  clientOptionsLoading?: boolean;
  errorMessage?: string;
  isSubmitting?: boolean;
  onCancel?: () => void;
  onClientSelect?: (client: User) => void;
  onSubmit: (values: IQuoterSelectClientSchema) => void;
}

export function QuoterSelectClientPage({
  clientOptions,
  clientOptionsLoading,
  errorMessage,
  isSubmitting,
  onCancel,
  onClientSelect,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<IQuoterSelectClientSchema>({
    defaultValues: {
      dateOfBirth: null as any,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    resolver: zodResolver(getQuoterSelectClientSchema()),
  });

  const onBackEventHandler = useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    reset({
      dateOfBirth: null as any,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    });
  }, [onCancel, reset]);

  // reset form on property click
  // set new fields to clicked property
  const onClientSelectedHandler = useCallback(
    (value: User) => {
      onClientSelect?.({ ...value });
    },
    [onClientSelect],
  );

  return (
    <Box>
      <PageTitle
        title={t('pages.quoterSelectClientPage.title', 'Add Your Client')}
      />
      {/* Panel */}
      <TwoPanelPaper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
        }}
        scrollableRight
        leftChildren={
          <Box margin="auto" maxWidth="448px">
            <Typography variant="h2">
              {t(
                'pages.quoterSelectClientPage.leftPanelTitle',
                'Add Your Client',
              )}
            </Typography>
            <Box mt={4} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={1}>
                <ControllerTextField
                  control={control}
                  name="firstName"
                  label={t('common.labels.firstName', 'First name')}
                />
                <ControllerTextField
                  control={control}
                  name="lastName"
                  label={t('common.labels.lastName', 'Last name')}
                />
                <ControllerTextField
                  control={control}
                  name="email"
                  label={t('common.labels.email', 'Email')}
                />
                <ControllerTextField
                  control={control}
                  name="phone"
                  label={t('common.labels.phone', 'Phone number')}
                  placeholder="(xxx) xxx-xxxx"
                  InputProps={{
                    inputComponent: PhoneInputMask as any,
                  }}
                />
                <ControllerDatePicker
                  control={control}
                  name="dateOfBirth"
                  label={t('common.labels.dateOfBirth', 'Date of Birth')}
                  disableFuture
                />
              </Stack>
              <FormHelperText error>{errorMessage}</FormHelperText>
              <Box mt={3} textAlign="right">
                <Button
                  disabled={isSubmitting}
                  color="secondary"
                  type="submit"
                  variant="contained"
                >
                  {t('common.actions.saveContinue', 'Save and Continue')}
                </Button>
              </Box>
            </Box>
          </Box>
        }
        rightChildren={
          <SearchableStackedList
            onSelected={onClientSelectedHandler}
            options={clientOptions}
            loading={clientOptionsLoading}
          />
        }
      />
      {/* Footer */}
      <QuoterActionBarContainer
        backText={null}
        hideSaveExit
        onBack={onBackEventHandler}
      />
    </Box>
  );
}
