import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthUserPayload, User } from '../../graphql/generated';
import { getLoginToken, setLoginToken } from '../../helpers';
import { api, setClientAuthorizationToken } from '../api';

export interface IAuthState {
  awaitingCurrentUserQuery: boolean;
  hasJustConfirmed: boolean;
  isConfirmed: boolean;
  isLoggedIn: boolean;
  token: string;
  user?: User;
}

export const initialAuthState: IAuthState = {
  awaitingCurrentUserQuery: false,
  hasJustConfirmed: false,
  isConfirmed: false,
  isLoggedIn: false,
  token: '',
  user: undefined,
};

function initializer() {
  const initialToken = getLoginToken();

  if (initialToken) {
    setClientAuthorizationToken(initialToken);
  }

  return {
    ...initialAuthState,
    awaitingCurrentUserQuery: !!initialToken,
    token: initialToken,
  };
}

function setLogginState(state: IAuthState, payload: AuthUserPayload) {
  const { token, user } = payload;

  setLoginToken(token);
  setClientAuthorizationToken(token);

  // save information
  state.token = token;
  state.user = user;

  // toggle flags
  state.awaitingCurrentUserQuery = false;
  state.isLoggedIn = true;
  state.isConfirmed = Boolean(user?.confirmedAt);
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initializer,
  reducers: {
    reset: () => initializer(),
    confirm: (state, action: PayloadAction<AuthUserPayload>) => {
      setLogginState(state, action.payload);
      state.hasJustConfirmed = true;
    },
    login: (state, action: PayloadAction<AuthUserPayload>) => {
      setLogginState(state, action.payload);
    },
    logout: () => {
      // clear the token in local storage
      // and clear the client authorization header
      setLoginToken('');
      setClientAuthorizationToken('');

      // reset state
      return {
        ...initialAuthState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.currentUser.matchFulfilled,
      (state, action) => {
        if (action.payload.currentUser) {
          const currentToken = state.token;

          setLogginState(state, {
            token: currentToken,
            user: action.payload.currentUser,
          });
        }
      },
    );
  },
});

export const { confirm, login, logout, reset } = authSlice.actions;
