import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function BackgroundSwoop(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      viewBox="0 0 414 224"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M807.5 0.228776C352.7 5.82878 72.3333 151.229 -11 223.229C234.857 33.2211 867 11.7285 1792.5 223.229V143.229C1653.67 93.2288 1262.3 -5.37122 807.5 0.228776Z"
        fill="#fff"
        fillOpacity="0.1"
      />
      <path
        d="M807.5 0.228776C352.7 5.82878 72.3333 151.229 -11 223.229C234.857 33.2211 781 -92.7715 1792.5 223.229V143.229C1653.67 93.2288 1262.3 -5.37122 807.5 0.228776Z"
        fill="#fff"
        fillOpacity="0.1"
      />
    </StyledSVG>
  );
}
