import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { Button } from '../../components/Button';
import { CompareAndShareQuotes } from '../../components/CompareAndShareQuotes';
import { generateEmptyQuote } from '../../components/CustomCoverageOption/helpers';
import { useBindingStore } from '../../hooks/useBindingStore';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { IQuoteOption } from '../../store/quoter';

export const PREV_PAGE_ROUTE = '/quoter/customize-coverage';

export function QuoterSelectAndBindPageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { input, setInput } = useQuoterStore();
  const { setInput: setBindingInput } = useBindingStore();

  const [quotes, setQuotes] = useState<IQuoteOption[]>(
    input?.quotes?.length
      ? input.quotes
      : [generateEmptyQuote(), generateEmptyQuote(), generateEmptyQuote()],
  );

  const onSelectQuoteHandler = useCallback(
    (quoteId: string) => {
      setBindingInput({ quoteId });
      const newQuotes = quotes.map((quote) => ({
        ...quote,
        selected: !quote.selected && quote.id === quoteId,
      }));
      setQuotes(newQuotes);
      setInput({
        quotes: [...newQuotes],
      });
    },
    [quotes, setInput, setBindingInput],
  );

  const onBindQuoteHandler = useCallback(() => {
    navigate('/quoter/confirm-start-date');
  }, [navigate]);
  return (
    <CompareAndShareQuotes
      title={t('pages.quoterSelectAndBindPage.title', 'Select quote to bind')}
      actionBar={
        <QuoterActionBarContainer
          backText={t('pages.customizeCoveragePage.title')}
          onBack={PREV_PAGE_ROUTE}
        >
          <Button
            color="secondary"
            disabled={!quotes.some((quote) => quote.selected)}
            onClick={onBindQuoteHandler}
            variant="contained"
          >
            {t('common.labels.bindQuote', 'Bind Quote')}
          </Button>
        </QuoterActionBarContainer>
      }
      onSelectQuote={onSelectQuoteHandler}
      quotes={quotes || []}
    />
  );
}
