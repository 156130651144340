import { Box, List, ListItem, Typography } from '@mui/material';

export function DisclosurePlaceholder() {
  // TODO: Placeholder Disclosure child component, also used in the Customize Coverage Page, need final copy
  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Typography variant="body1" paddingBottom={3} color="grey.80">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industrys standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Typography>
      <Typography variant="h5" fontWeight={600} paddingBottom={1}>
        Sub-title example
      </Typography>
      <Typography variant="body1" color="grey.80">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industrys standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Typography>
      <List sx={{ listStyleType: 'disc', padding: 2, color: 'grey.80' }}>
        <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
          <Typography color="grey.80">
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
          <Typography color="grey.80">
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
}
