import { useTranslation } from 'react-i18next';

import { formatAddress, formatDate } from '@/helpers/formatters';
import { IAddress } from '@/store/quoter';

import { BasicInfoCard } from '../BasicInfoCard';
import { SVG } from '../SVGs/SVG';

export function AddressInfoStrip({
  address,
  createdAt,
}: {
  address?: IAddress;
  createdAt?: Date | string;
}) {
  const { t } = useTranslation();

  return (
    <BasicInfoCard
      title={formatAddress(address)}
      startIcon={
        <SVG
          name="Home"
          sx={{ color: 'grey.60', height: '17px', width: '17px' }}
        />
      }
      sx={{
        backgroundColor: 'grey.10',
        borderRadius: 2,
        boxShadow: 'none',
        px: 2,
        py: 1,
      }}
    >
      {t('common.labels.created', 'Created')}:{' '}
      {createdAt ? formatDate(createdAt) : '--'}
    </BasicInfoCard>
  );
}
