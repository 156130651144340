import { Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { SVG } from '../SVGs/SVG';

interface IProps {
  left: string;
  middle?: string;
  middleColor?: string;
  right: string;
  href?: string;
  to?: string;
  onClick?: () => void;
}

const Container: React.FC<any> = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  textDecoration: 'none',
  cursor: 'pointer',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.grey[80],
  borderBottom: `1px solid ${theme.palette.grey[20]}`,
  '&:hover': {
    color: '#688A3F',
  },
  '&:hover > div > svg': {
    visibility: 'initial',
  },
}));

export function TableActionRow({
  left,
  middle,
  middleColor,
  right,
  href,
  to,
  onClick,
}: IProps) {
  const linkProps = useMemo(() => {
    if (to) {
      return { to }; // internal link
    } else if (!to && href) {
      return { href, target: '_blank' }; // external link
    } else if (onClick) {
      return { onClick }; // onClick to open modals, etc.
    } else {
      return undefined;
    }
  }, [to, href, onClick]);

  return (
    <Container component={to ? RouterLink : Link} {...linkProps}>
      <Typography
        color="inherit"
        fontSize="0.9375rem"
        lineHeight="1.45rem"
        flex={1}
      >
        {left}
      </Typography>
      <Typography
        flex={3}
        px={1}
        color={middleColor || 'inherit'}
        fontSize="0.9375rem"
        lineHeight="1.45rem"
      >
        {middle}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography color="inherit" fontSize="0.9375rem" lineHeight="1.45rem">
          {right}
        </Typography>
        {linkProps && (
          <SVG
            name="RightArrow"
            color="inherit"
            sx={{ visibility: 'hidden', marginLeft: '24px' }}
            height={14}
            width={8}
          />
        )}
      </Stack>
    </Container>
  );
}
