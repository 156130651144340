import { Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../helpers/formatters';

import { QuoteRow } from './QuoteRow';

interface IProps {
  quotes: any[];
}

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[70],
  fontSize: '13px',
  lineHeight: '18.85px',
  textAlign: 'left',
  minWidth: '100px',
  flex: 1,
  textTransform: 'uppercase',
}));

export function ClientQuotes({ quotes }: IProps) {
  const { t } = useTranslation();
  return (
    <Stack px={3} height="inherit">
      <Typography variant="h2">
        {t('pages.clientsPage.quotes', 'Quotes')}
      </Typography>
      <Stack gap={1}>
        <Stack direction="row" px={2} py={1} gap={4} mt={2}>
          <HeaderText>{t('pages.clientsPage.quotesTable.id', 'ID')}</HeaderText>
          <HeaderText>
            {t('pages.clientsPage.quotesTable.coverageI', 'COVERAGE I')}
          </HeaderText>
          <HeaderText>
            {t('pages.clientsPage.quotesTable.parametric', 'PARAMETRIC')}
          </HeaderText>
          <HeaderText sx={{ minWidth: '60px' }}>
            {t('pages.clientsPage.quotesTable.type', 'TYPE')}
          </HeaderText>
          <HeaderText>
            {t('pages.clientsPage.quotesTable.policyStart', 'POLICY START')}
          </HeaderText>
          <HeaderText sx={{ flex: 2 }}>
            {t('pages.clientsPage.quotesTable.status', 'STATUS')}
          </HeaderText>
          <HeaderText sx={{ textAlign: 'right' }}>
            {t('pages.clientsPage.quotesTable.created', 'CREATED')}
          </HeaderText>
        </Stack>
        {quotes.map((quote) => {
          return (
            <QuoteRow
              key={quote._id}
              id={quote._id}
              coverage={quote.coverage}
              parametric={quote.parametric}
              startDate={formatDate(quote.startDate)}
              status={quote.status}
              createdAt={formatDate(quote.createdAt)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
