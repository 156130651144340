import { z } from 'zod';

export function getLoginPageSchema() {
  return z.object({
    email: z
      .string()
      .min(1, 'email is required')
      .email('email must be a valid email address'),
    password: z.string().min(1, 'password is required'),
  });
}

export type ILoginPageSchema = z.infer<ReturnType<typeof getLoginPageSchema>>;
