import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../components/App/PageTitle';
import { QuoteStepperContainer } from '../../components/QuoteStepper';
import { FontWeight } from '../../config/theme/typography';

interface IProps {
  clientName: string;
  clientEmail: string;
  brokerName: string;
  brokerEmail: string;
  onPreview: () => void;
  onSubmit: (message: string) => void;
}

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.grey[80],
  fontWeight: FontWeight.regular,
  lineHeight: '145%',
  textTransform: 'uppercase',
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: FontWeight.semibold,
  lineHeight: '145%',
}));

const ChipText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[80],
  fontWeight: FontWeight.semibold,
  lineHeight: '145%',
}));

export function QuoterShareApplicationPage({
  clientName,
  clientEmail,
  brokerName,
  brokerEmail,
  onPreview,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();
  const templateMessage = `Hi ${clientName?.split(' ')[0]},\n${t(
    'pages.shareApplicationPage.templateMessage',
    'Attached is the application you need to sign for you new home insurance policy. Call me if you have any questions',
  )},\n${brokerName}`;

  const [message, setMessage] = useState(templateMessage);
  return (
    <Stack>
      <QuoteStepperContainer />
      <PageTitle
        title={t(
          'pages.shareApplicationPage.title',
          'Send Application to Client',
        )}
      >
        <Typography
          mt={2}
          fontSize="0.938rem"
          sx={{ lineHeight: '145%', maxWidth: '644px' }}
        >
          {t(
            'pages.shareApplicationPage.description',
            "The applicaiton will be sent to your client's email. They will be able to review and sign digitally.\n        Please verify the client information and include a message if desired.",
          )}
        </Typography>
      </PageTitle>

      <Stack
        sx={{
          margin: 'auto',
          width: '700px',
          backgroundColor: 'grey.00',
          borderRadius: 4,
          padding: 4,
        }}
      >
        <Box paddingBottom={2}>
          <Label>
            {t('pages.shareApplicationPage.toClient', 'To Client')}:
          </Label>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text>{clientName}</Text>
            <Chip
              label={<ChipText>{clientEmail}</ChipText>}
              sx={{ backgroundColor: 'grey.20' }}
            />
          </Stack>
        </Box>
        <Divider light />
        <Box py={2}>
          <Label>
            {t('pages.shareApplicationPage.fromBroker', 'From Broker')}:
          </Label>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text>{brokerName}</Text>
            <Chip
              label={<ChipText>{brokerEmail}</ChipText>}
              sx={{ backgroundColor: 'grey.20' }}
            />
          </Stack>
        </Box>
        <Divider light />
        <Box py={2}>
          <Label>
            {t('pages.shareApplicationPage.attachments', 'Attachments')}:
          </Label>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text>
              {t(
                'pages.shareApplicationPage.clientApplication',
                'Client Application',
              )}
            </Text>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onPreview}
              sx={{ borderColor: 'grey.50' }}
              startIcon={<VisibilityOutlinedIcon width="16px" />}
            >
              {t(
                'pages.shareApplicationPage.previewDocument',
                'Preview Document',
              )}
            </Button>
          </Stack>
        </Box>
        <Divider light />
        <Box py={2}>
          <Label>
            {t(
              'pages.shareApplicationPage.optionalMessage',
              'Optional Message',
            )}
            :
          </Label>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            InputProps={{
              sx: {
                backgroundColor: 'grey.10',
                paddingTop: 0,
                color: 'grey.80',
                '& .MuiOutlinedInput-input': {
                  fontSize: '17px',
                  lineHeight: '145%',
                  fontWeight: FontWeight.regular,
                },
              },
            }}
            sx={{
              marginTop: 2,
              borderRadius: 1,
              borderColor: 'transparent',
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onSubmit(message)}
          sx={{ width: '113px', alignSelf: 'flex-end' }}
        >
          {t('common.labels.send', 'Send')}
        </Button>
      </Stack>
    </Stack>
  );
}
