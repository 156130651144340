import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Z_INDEXES } from '@/config/zIndexes';

import { InfoIconButtonToolTip } from '../Button';
import { SVG } from '../SVGs/SVG';

import { BORDER_RADIUS } from './CustomCoverageOption';

export const COMPONENT_CLASS_IDENTIFER = 'custom-coverate-option-header';

const StickyWrapper = styled('div')`
  position: sticky;
  top: 0;

  background-color: ${({ theme }) => theme.palette.grey['00']};
  border-radius: ${({ theme }) => theme.spacing(BORDER_RADIUS)};

  padding: ${({ theme }) => theme.spacing(4, 5, 3.125)};

  z-index: ${Z_INDEXES.COVERAGE_OPTION_HEADER};
`;

interface IProps {
  coverageAmount?: number;
  isLoading?: boolean;
  monthlyPaymentAmount?: number;
  onRemoveQuote?: (requestedIndex: number) => void;
  optionNumber: number;
  quoteId: string;
  size: 'sm' | 'md' | 'lg';
}

export function OptionHeader({
  coverageAmount,
  isLoading,
  monthlyPaymentAmount,
  onRemoveQuote,
  optionNumber,
  size,
  quoteId,
}: IProps) {
  const { t } = useTranslation();

  return (
    <StickyWrapper className={COMPONENT_CLASS_IDENTIFER}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: 'relative', pr: 6 }}
        >
          <Typography
            variant="h2"
            sx={{
              color: 'grey.80',
              marginBottom: 0.5,
            }}
          >
            {t('common.labels.quote', 'Quote')}: {quoteId}
          </Typography>
          {isLoading && (
            <SVG
              name="AnimatedLoader"
              height="50px"
              sx={{ position: 'absolute', right: 0 }}
            />
          )}
        </Stack>
        {size === 'lg' ? (
          ''
        ) : (
          <IconButton
            disabled={isLoading}
            onClick={() => onRemoveQuote?.(optionNumber)}
            sx={{
              marginRight: -3,
              marginTop: -3,
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            <DeleteOutlineIcon sx={{ color: 'grey.70' }} />
          </IconButton>
        )}
      </Stack>
      <Stack alignItems="center" direction="row" flexWrap="wrap">
        <Typography
          variant="h2"
          sx={{
            color: 'grey.90',
            marginBottom: 0.5,
            paddingRight: '4px',
          }}
        >
          {coverageAmount
            ? t('common.currency.USD', '{{value, currency(currency: USD)}}', {
                minimumFractionDigits: 0,
                value: coverageAmount,
              })
            : '--'}
        </Typography>
        <Typography
          sx={{
            alignItems: 'center',
            color: 'grey.80',
            display: 'flex',
            fontSize: '1.0625rem',
            fontWeight: 500,
            paddingRight: '40px',
            position: 'relative',
          }}
        >
          {t(
            'pages.customizeCoveragePage.monthlyTitle',
            'or pay {{monthly}}/month for 12 months',
            {
              monthly: monthlyPaymentAmount
                ? t(
                    'common.currency.USD',
                    '{{value, currency(currency: USD)}}',
                    {
                      minimumFractionDigits: 0,
                      value: monthlyPaymentAmount,
                    },
                  )
                : '--',
            },
          )}
          <InfoIconButtonToolTip
            tooltipText="[PLACEHOLDER] Monthly payments adds 4.3% processing fees"
            sx={{
              width: '40px',
              height: '40px',
              position: 'absolute',
              right: 0,
            }}
          />
        </Typography>
      </Stack>
    </StickyWrapper>
  );
}
