import {
  GetOrganizationDocument,
  InviteOrgAgentDocument,
  UpdateOrganizationContactDocument,
  UpdateOrganizationLocationAddressDocument,
} from '@/graphql/generated';
import { generateEndpoint } from '@/helpers';

import { CacheTag } from '../CacheTags';

export const getOrganizationEndpoint = {
  ...generateEndpoint(GetOrganizationDocument),
  providesTags: [CacheTag.ORGANIZATION],
};

export const updateOrganizationLocationAddressEndpoint = {
  ...generateEndpoint(UpdateOrganizationLocationAddressDocument),
  invalidatesTags: [CacheTag.ORGANIZATION],
};

export const updateOrganizationContactEndpoint = {
  ...generateEndpoint(UpdateOrganizationContactDocument),
  invalidatesTags: [CacheTag.ORGANIZATION],
};

export const inviteOrgAgentEndpoint = {
  ...generateEndpoint(InviteOrgAgentDocument),
  invalidatesTags: [CacheTag.ORGANIZATION],
};
