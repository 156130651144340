export function calculateDateProgress(
  startDate: Date | string,
  endDate: Date | string,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const now = new Date();
  const total = end.getTime() - start.getTime();
  const current = now.getTime() - start.getTime();
  return (current / total) * 100;
}

export function calculateDaysRemaining(
  startDate: Date | string,
  endDate: Date | string,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const now = new Date();
  const total = end.getTime() - start.getTime();
  const current = now.getTime() - start.getTime();
  const remaining = total - current;
  return Math.floor(remaining / (1000 * 60 * 60 * 24));
}
