import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function PowerOff(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.18 8.577c0 .937-.184 1.833-.55 2.686a6.984 6.984 0 0 1-1.479 2.209 6.986 6.986 0 0 1-2.208 1.478 6.735 6.735 0 0 1-2.687.55 6.735 6.735 0 0 1-2.686-.55 6.986 6.986 0 0 1-2.209-1.478 6.984 6.984 0 0 1-1.478-2.209 6.735 6.735 0 0 1-.55-2.686 6.82 6.82 0 0 1 .726-3.092A6.79 6.79 0 0 1 3.1 3.051a1.14 1.14 0 0 1 .86-.225c.316.042.567.192.753.45.193.253.266.537.221.852-.045.316-.194.57-.446.762a4.603 4.603 0 0 0-1.366 1.632 4.534 4.534 0 0 0-.482 2.055c0 .625.122 1.221.365 1.79a4.65 4.65 0 0 0 .987 1.473 4.65 4.65 0 0 0 1.474.987 4.495 4.495 0 0 0 1.79.365c.624 0 1.22-.121 1.789-.365a4.65 4.65 0 0 0 1.474-.987 4.65 4.65 0 0 0 .987-1.474 4.496 4.496 0 0 0 .365-1.79c0-.726-.161-1.411-.483-2.054a4.603 4.603 0 0 0-1.365-1.632 1.122 1.122 0 0 1-.446-.762c-.046-.315.028-.6.22-.852.187-.258.44-.408.758-.45a1.12 1.12 0 0 1 .856.225 6.79 6.79 0 0 1 2.042 2.434 6.82 6.82 0 0 1 .725 3.092ZM8.41 1.654v5.77c0 .312-.114.582-.342.81a1.109 1.109 0 0 1-.812.343c-.312 0-.583-.114-.811-.343a1.109 1.109 0 0 1-.343-.81v-5.77c0-.313.115-.583.343-.811C6.673.614 6.944.5 7.256.5c.313 0 .583.114.812.343.228.228.342.498.342.81Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
