import { styled } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import React from 'react';

type StyledPickersDayComponent = React.ComponentType<PickersDayProps<Date>>;

export const StyledPickersDay = styled(PickersDay as StyledPickersDayComponent)`
  font-size: 0.9375rem;

  height: 40px;
  width: 40px;

  &.MuiPickersDay-today {
    border-color: ${({ theme }) => theme.palette.grey[50]};
  }

  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.palette.success.main};
    border-style: dashed;
    border-width: 1px;
  }

  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected:active,
  &.Mui-selected:focus {
    background-color: ${({ theme }) => theme.palette.success.main};
    border-color: ${({ theme }) => theme.palette.success.dark};
    border-style: solid;
    color: white;
  }
`;

export const renderStyledPickersDay = (
  date: Date,
  selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>,
) => {
  return <StyledPickersDay {...pickersDayProps} />;
};
