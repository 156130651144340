import { t } from 'i18next';
import random from 'lodash/random';

import { IQuoteOption } from '@/store/quoter';

import {
  ICustomizeCoverageSchemaDeepParial,
  ICustomizeCoverageSchema,
} from './CustomizeCoverageOption.schema';

export function getInitialQuoteValues(
  initialValues?: IQuoteOption,
): ICustomizeCoverageSchema {
  const mainHomeLimit = initialValues?.mainHomeLimit || '';
  const parametricLimit = initialValues?.parametricLimit || '';
  const waterDamageLimit = initialValues?.waterDamageLimit || '';

  const coverages = initialValues?.coverage || {};
  const deductibles = initialValues?.deductible || {};
  const endorsements = initialValues?.endorsements || {};

  return {
    id: initialValues?.id,

    // misc
    parametricLimit: String(parametricLimit || ''),
    waterDamageLimit: String(waterDamageLimit || ''),

    // coverages
    mainHomeCoverage: String(mainHomeLimit || ''), // coverageA
    otherStructureCoverage: String(coverages?.otherStructure || ''), // coverageB
    personalPropertyCoverage: String(coverages?.personalProperty || ''), // coverageC
    lossOfUseCoverage: String(coverages?.lossOfUse || ''), // coverageD
    personalLiabilityCoverage: String(coverages?.personalLiability || ''), // coverageE
    medicalPaymentsCoverage: String(coverages?.medicalPayments || ''), // coverageF

    // deductible
    allOtherPerilsDeductible: String(
      deductibles?.allOtherPerilsDeductible || '',
    ),
    hurricaneDeductible: String(deductibles?.hurricaneDeductible || ''),
    waterDeductible: String(deductibles?.waterDeductible || ''),

    // endorsemets
    endorsments: {
      animalLiability: {
        isActive: Boolean(endorsements?.animalLiability),
      },
      carportPoolEnclosure: {
        isActive: Boolean(endorsements?.carportPoolEnclosure),
        limit: String(endorsements?.carportPoolEnclosure || ''),
        year: String(endorsements?.carportYear || ''),
      },
      increasedLossAssessment: {
        isActive: Boolean(endorsements?.increasedLossAssessment),
        limit: String(endorsements?.increasedLossAssessment || ''),
      },
      mold: {
        isActive: Boolean(endorsements?.mold),
        limit: String(endorsements?.mold || ''),
      },
      ordinanceOrLaw: {
        isActive: Boolean(endorsements?.ordinanceOrLaw),
        limit: String(endorsements?.ordinanceOrLaw || ''),
      },
      personalInjury: {
        isActive: Boolean(endorsements?.personalInjury),
      },
      personalPropertyReplacement: {
        isActive: Boolean(endorsements?.personalPropertyReplacement),
      },
      waterBackupAndSump: {
        isActive: Boolean(endorsements?.waterBackupAndSump),
      },
    },
  };
}

function stripInactiveEndorsements(
  values: ICustomizeCoverageSchemaDeepParial['endorsments'],
) {
  if (!values) {
    return;
  }

  return Object.keys(values).reduce((accumulator, currentValue) => {
    const key = currentValue as keyof typeof values;
    const value = values[key as keyof typeof values];

    if (value?.isActive) {
      accumulator[key] = {
        ...value,
      };
    }

    return accumulator;
  }, {} as ICustomizeCoverageSchema['endorsments']);
}

function convertValue(value?: string) {
  return value !== undefined && value ? Number(value) : undefined;
}

function buildEndorsements(
  values: ICustomizeCoverageSchemaDeepParial['endorsments'],
): IQuoteOption['endorsements'] {
  const strippedValues = stripInactiveEndorsements(values);

  const carportPoolEnclosure = convertValue(
    strippedValues?.carportPoolEnclosure?.limit,
  );

  return {
    animalLiability: strippedValues?.animalLiability?.isActive || false,
    carportYear: carportPoolEnclosure
      ? convertValue(strippedValues?.carportPoolEnclosure?.year)
      : undefined,
    carportPoolEnclosure,
    increasedLossAssessment: convertValue(
      strippedValues?.increasedLossAssessment?.limit,
    ),
    ordinanceOrLaw: convertValue(strippedValues?.ordinanceOrLaw?.limit),
    mold: convertValue(strippedValues?.mold?.limit),
    personalInjury: strippedValues?.personalInjury?.isActive || false,
    personalPropertyReplacement:
      values?.personalPropertyReplacement?.isActive || false,
    waterBackupAndSump: values?.waterBackupAndSump?.isActive || false,
  };
}

export function getQuoteModelFromQuoteSchema(
  values: ICustomizeCoverageSchemaDeepParial,
  prices?: IQuoteOption['prices'],
): IQuoteOption {
  const waterDamageLimit = convertValue(values.waterDamageLimit);

  return {
    id: values?.id || '',
    mainHomeLimit: convertValue(values.mainHomeCoverage),
    waterDamageLimit,
    parametricLimit: convertValue(values.parametricLimit),
    coverage: {
      lossOfUse: convertValue(values.lossOfUseCoverage),
      medicalPayments: convertValue(values.medicalPaymentsCoverage),
      otherStructure: convertValue(values.otherStructureCoverage),
      personalLiability: convertValue(values.personalLiabilityCoverage),
      personalProperty: convertValue(values.personalPropertyCoverage),
    },
    deductible: {
      allOtherPerilsDeductible: convertValue(values.allOtherPerilsDeductible),
      hurricaneDeductible: convertValue(values.hurricaneDeductible),
      waterDeductible:
        waterDamageLimit && waterDamageLimit !== 0
          ? convertValue(values.waterDeductible)
          : undefined,
    }, // TODO test this code!!!!!
    endorsements: buildEndorsements(values.endorsments),
    prices: {
      fullPremium: prices?.fullPremium || 0,
      monthlyPremium: prices?.monthlyPremium || 0,
    },
  };
}

export function mockPrices(low = 5000, high = 8000) {
  const fullPremium = random(low, high);
  const monthlyPremium = Math.round((fullPremium * 1.15) / 12);

  return {
    fullPremium,
    monthlyPremium,
  };
}

// export function generateEmptyQuote(dwellingPreset = 300000): IQuoteOption {
export function generateEmptyQuote(overrides?: IQuoteOption): IQuoteOption {
  return {
    id: Math.random().toString(36).substring(2, 11),
    mainHomeLimit: 300000,
    waterDamageLimit: undefined,
    coverage: {
      lossOfUse: 35000,
      medicalPayments: 1000,
      otherStructure: undefined,
      personalLiability: 100000,
      personalProperty: undefined,
      ...overrides?.coverage,
    },
    deductible: {
      allOtherPerilsDeductible: undefined,
      hurricaneDeductible: undefined,
      waterDeductible: undefined,
      ...overrides?.deductible,
    },
    endorsements: {
      animalLiability: undefined, // change to boolean
      carportPoolEnclosure: undefined,
      carportYear: undefined,
      increasedLossAssessment: undefined,
      mold: undefined,
      ordinanceOrLaw: undefined,
      personalInjury: undefined, // change to boolean
      personalPropertyReplacement: undefined,
      waterBackupAndSump: undefined, // change to boolean
      ...overrides?.endorsements,
    },
    prices: {
      fullPremium: 7000,
      monthlyPremium: undefined,
      ...overrides?.prices,
    },
    ...overrides,
  };
}

export function formatPercentageOptions(
  mainHomeValue: number,
  percentages: number[],
) {
  return percentages.map((percentage) => {
    return {
      value: percentage,
      label: `${(percentage * 100).toFixed(0)}% (${t('common.currency.USD', {
        minimumFractionDigits: 0,
        value: percentage * mainHomeValue,
      })})`,
    };
  });
}

export function formatCurrencyOptions(values: number[]) {
  return values.map((value) => {
    return {
      value: value,
      label: t('common.currency.USD', {
        minimumFractionDigits: 0,
        value: value,
      }),
    };
  });
}

export function formatCurrencyComboOptions(values: number[][]) {
  return values.map(([leftValue, rightValue]) => {
    const leftFormatted = t('common.currency.USD', {
      minimumFractionDigits: 0,
      value: leftValue,
    });
    const rightFormatted = t('common.currency.USD', {
      minimumFractionDigits: 0,
      value: rightValue,
    });

    const value =
      leftValue === rightValue
        ? leftValue.toFixed(0)
        : `${leftValue}-${rightValue}`;

    return {
      label: `${leftFormatted}/${rightFormatted}`,
      value,
    };
  });
}

// Helpers
const containerWidths = {
  lg: '960px',
  md: '575px',
  sm: '400px',
};
export const getContainerWidthBySize = (size: keyof typeof containerWidths) =>
  containerWidths[size];
