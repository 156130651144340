import { forwardRef, RefCallback } from 'react';
import { IMaskInput } from 'react-imask';

interface IProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const YearInputMask = forwardRef<HTMLElement, IProps>(
  function YearInputMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="#000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  },
);
