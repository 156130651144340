import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FullscreenLoader } from '../../components/Loaders';
import { InviteNewTeamMemberModal } from '../../components/Modals';
import { SVG } from '../../components/SVGs/SVG';
import { useInviteOrgAgentMutation } from '../../store/api';
import { IInviteNewTeamMemberSchema } from '../MyInformationPage/MyInformationpage.schema';

interface IProps {
  loading?: boolean;
  orgId: string;
}

export function InviteNewTeamMemberContainer({ loading, orgId }: IProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const [inviteOrgAgentMutation, { isLoading: inviteLoading }] =
    useInviteOrgAgentMutation();

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const onSubmit = useCallback(
    async (values: IInviteNewTeamMemberSchema) => {
      await inviteOrgAgentMutation({ inputs: values, org: orgId });
      closeDialog();
    },
    [closeDialog, inviteOrgAgentMutation, orgId],
  );

  return (
    <Stack>
      <Stack pt={2} alignItems="center">
        <Button
          disabled={loading}
          onClick={() => setDialogOpen(true)}
          sx={{
            width: '50%',
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <SVG name="Plus" width="16px" color="#688A3F" />
          <Typography color="#688A3F" px={1} fontWeight={600}>
            {t('pages.myTeamPage.inviteMember', 'Invite New Team Member')}
          </Typography>
        </Button>
      </Stack>
      {inviteLoading ? (
        <FullscreenLoader>Sending invite</FullscreenLoader>
      ) : (
        <InviteNewTeamMemberModal
          open={dialogOpen}
          onClose={closeDialog}
          onSubmit={onSubmit}
        />
      )}
    </Stack>
  );
}
