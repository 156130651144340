import { Button } from '@mui/material';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { theme } from '@/config';

interface IProps {
  children: React.ReactNode;
  to?: string;
}

const navLinkActiveStyle = {
  color: theme.palette.secondary.main,
};

const navLinkHoverStlye = {
  backgroundColor: 'grey.10',
};

export function NavLink({ children, to, ...props }: IProps) {
  return (
    <Button
      {...{
        component: RouterNavLink,
        to,
        style: (({ isActive }: any) =>
          isActive ? navLinkActiveStyle : undefined) as any,
      }}
      disableElevation
      disableRipple
      disableTouchRipple
      sx={{
        color: 'grey.80',
        '&:hover': navLinkHoverStlye,
      }}
      variant="text"
      {...props}
    >
      {children}
    </Button>
  );
}
