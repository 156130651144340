import { useMockDataQuery } from '../../hooks';

import { AgreementsNoticesPage } from './AgreementsNoticesPage';

const getAgreementDocuments = [
  {
    title: 'Producer Registration Form',
    dateSigned: new Date(),
    url: 'https://arbolmarket.com',
  },
  {
    title: 'Referral Agreement Form',
    dateSigned: undefined,
    url: 'https://arbolmarket.com',
  },
];

export function AgreementsNoticesPageContainer() {
  const { data: agreements, isLoading } = useMockDataQuery({
    mockResponse: getAgreementDocuments,
    mockDelay: 470,
  });
  return (
    <AgreementsNoticesPage agreements={agreements || []} loading={isLoading} />
  );
}
