import { format as dateFormatFunc } from 'date-fns';

import i18n from '../config/i18n';
import { Property } from '../graphql/generated';

export enum DATE_FORMATS {
  DEFAULT = 'MM/dd/yyyy',
  LONG_FORMAT = 'MMMM dd, yyyy', // January 1, 2022
}

export function formatDate(
  date: string | Date,
  format: DATE_FORMATS = DATE_FORMATS.DEFAULT,
) {
  return dateFormatFunc(
    typeof date === 'string' ? new Date(date) : date,
    format,
  );
}

export function formatAddress(address: Property['address']) {
  const { address1, address2, city, state, zipcode } = address || {};

  return `${address1 || ''}${address2 ? ` ${address2}` : ''}, ${city || ''}, ${
    state || ''
  } ${zipcode || ''}`;
}

export function formatAddressMultiLine(address: Property['address']) {
  const { address1, address2, city, state, zipcode } = address || {};

  return [
    address1 || '',
    address2 || '',
    `${city || ''}, ${state || ''} ${zipcode || ''}`,
  ];
}

export function formatPhoneNumber(value?: string | null) {
  if (!value) return '';
  return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '';
}

export function formatCurrency(value?: number | string) {
  if (!value) return '';

  return i18n.t('common.currency.USD', '{{value, currency(currency: USD)}}', {
    value: value,
  });
}
