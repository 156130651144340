import { diffJson } from 'diff';
import { useCallback, useMemo } from 'react';

import { useMockDataMutation } from '@/hooks';
import { IQuoteOption } from '@/store/quoter';

import { CustomCoverageOption } from './CustomCoverageOption';
import { ICustomizeCoverageSchemaDeepParial } from './CustomizeCoverageOption.schema';
import {
  getInitialQuoteValues,
  getQuoteModelFromQuoteSchema,
  mockPrices,
} from './helpers';

const mockResponse = {};

interface IProps {
  quote?: IQuoteOption;
  onChange?: (values: IQuoteOption) => void;
  onRemoveQuote?: (requestedIndex: number) => void;
  optionNumber: number;
  size: ISize;
}

export function CustomCoverageOptionContainer({
  onChange,
  onRemoveQuote,
  optionNumber,
  quote,
  size = 'lg',
}: IProps) {
  // hooks
  const [mutate, { isLoading }] = useMockDataMutation({
    mockDelay: 500,
    mockResponse: mockResponse,
  });

  // computed values
  const coverageAmount = quote?.prices?.fullPremium;
  const monthlyPaymentAmount = quote?.prices?.monthlyPremium;

  // callbacks
  const onQuoteValuesChange = useCallback(
    async (values: ICustomizeCoverageSchemaDeepParial) => {
      const newQuote = getQuoteModelFromQuoteSchema(values, quote?.prices);

      const results = diffJson(quote || {}, newQuote, {
        ignoreWhitespace: true,
        newlineIsToken: false,
        ignoreCase: true,
      });

      if (results.length > 1) {
        await mutate({});
        newQuote.prices = mockPrices();
        onChange?.(newQuote);
      }
    },
    [mutate, quote, onChange],
  );

  const initialValues = useMemo(() => getInitialQuoteValues(quote), [quote]);

  return (
    <CustomCoverageOption
      coverageAmount={coverageAmount}
      initialValues={initialValues}
      isLoading={isLoading}
      monthlyPaymentAmount={monthlyPaymentAmount}
      onChange={onQuoteValuesChange}
      onRemoveQuote={onRemoveQuote}
      optionNumber={optionNumber}
      size={size}
    />
  );
}
