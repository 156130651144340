import { RadioGroupProps } from '@mui/material';
import { useMemo } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlternatingRadioRow } from './AlternatingRadioRow';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: Path<T>;
}

export function ControllerTrueFalseRadioRow<T extends FieldValues>({
  control,
  label,
  name,
  ...inputProps
}: IProps<T> & RadioGroupProps) {
  const { t } = useTranslation();

  const radioOptions = useMemo(
    () => [
      { label: t('common.labels.yes', 'Yes'), value: true },
      { label: t('common.labels.no', 'No'), value: false },
    ],
    [t],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <AlternatingRadioRow
          {...field}
          {...inputProps}
          label={label}
          radioOptions={radioOptions}
        />
      )}
    />
  );
}
