import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Share(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.566.834a.8.8 0 0 0-1.132 0l-2.56 2.56a.8.8 0 1 0 1.132 1.132L7.2 3.33v5.75a.8.8 0 1 0 1.6 0V3.33l1.194 1.195a.8.8 0 1 0 1.132-1.132L8.566.834Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 10.2c0 .667.16 2 .8 2h9.6c.267 0 .8-.4.8-2 .133-.267.56-.72 1.2-.4.133 0 .4.16.4.8 0 .8 0 3.2-2.4 3.2H3.2c-.8 0-2.4-.64-2.4-3.2 0-.133.08-.48.4-.8.4-.4 1.2 0 1.2.4Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
