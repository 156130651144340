import { Box, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoIconButtonToolTip } from '@/components/Button';
import { ControllerTextField } from '@/components/Inputs';
import { CurrencyInputMask } from '@/components/Inputs/TextField/CurrencyInputMask';

import { ICustomizeCoverageSchema } from '../CustomizeCoverageOption.schema';

interface IProps {
  control: Control<ICustomizeCoverageSchema>;
  size: 'sm' | 'md' | 'lg';
  onInfoClick?: () => void;
}

export function MainCoverageSection({ size, control, onInfoClick }: IProps) {
  const { t } = useTranslation();

  return (
    <Box marginTop={size === 'sm' ? 1 : 4} pb={5}>
      <Typography
        variant="h2"
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingBottom: { xs: 2, sm: 1.5 },
        }}
      >
        {t(
          'pages.customizeCoveragePage.mainCoveragelTitle',
          'Dwelling Coverage',
        )}
        <InfoIconButtonToolTip onClick={onInfoClick} />
      </Typography>
      <Typography
        sx={{
          color: 'grey.90',
          display: size === 'lg' ? 'block' : 'none',
          fontSize: '0.9375rem',
          fontWeight: 400,
          lineHeight: '21.75px',
          maxWidth: '649px',
        }}
      >
        {t(
          'pages.customizeCoveragePage.mainCoveragelDescription',
          'Dwelling insurance covers damage to your home’s main structure. Your structure is insured against most events, but not against any named exclusions. Unlike traditional HO-3 policies, this policy does not cover your home’s roof when damaged as a result of weather.',
        )}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
        mt={2}
        spacing={1}
      >
        <Typography sx={{ fontSize: '0.9375rem', color: 'grey.80' }}>
          I.
        </Typography>
        <ControllerTextField
          control={control}
          label={t('common.labels.dwelling', 'Dwelling')}
          name="mainHomeCoverage"
          InputProps={{
            inputComponent: CurrencyInputMask as any,
          }}
          sx={{
            width: size === 'lg' ? '390px' : '100%',
          }}
        />
      </Stack>
    </Box>
  );
}
