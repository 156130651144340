import { Box } from '@mui/material';
import { useCallback, useState } from 'react';

import { ResendEmailConfirmationModal } from '../../components/Modals';
import { getTranslatedErrorMessage } from '../../helpers';
import { useAuth } from '../../hooks';
import {
  setClientAuthorizationToken,
  useLoginMutation,
  useResendConfirmMutation,
} from '../../store/api';

import { LoginPage } from './LoginPage';
import { ILoginPageSchema } from './LoginPage.schema';

export function LoginPageContainer() {
  // Resending confirmation setup
  const [
    resendConfirmation,
    { isLoading: isLoadingResend, reset, isSuccess: isSuccessResend },
  ] = useResendConfirmMutation();

  const [resendEmailConfirmationOpen, setResendEmailConfirmationOpen] =
    useState(false);

  const closeResendModal = () => {
    setResendEmailConfirmationOpen(false);
    // unset client authorization token
    setClientAuthorizationToken(undefined);
    // reset mutation to be tried again
    reset();
  };

  const onResendEmailConfirmation = () => {
    resendConfirmation({});
  };

  // logging in setup
  const { login } = useAuth();
  const [mutate, { error }] = useLoginMutation();

  const onSubmit = useCallback(
    async (data: ILoginPageSchema) => {
      const results = await mutate({
        email: data.email,
        password: data.password,
      });

      if ('data' in results && results.data?.login) {
        const isConfirmed = results.data?.login?.user?.confirmedAt;

        if (isConfirmed) {
          login(results.data.login);
        } else {
          // set token to be sent when they push the "resend" button
          setClientAuthorizationToken(results.data.login.token);
          setResendEmailConfirmationOpen(true);
        }
      }
    },
    [login, mutate],
  );

  const errorMessage = getTranslatedErrorMessage(error);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      minHeight="80vh"
    >
      <LoginPage onSubmit={onSubmit} errorMessage={errorMessage} />
      <ResendEmailConfirmationModal
        isLoading={isLoadingResend}
        onClose={closeResendModal}
        onResend={onResendEmailConfirmation}
        open={resendEmailConfirmationOpen}
        sent={isSuccessResend}
      />
    </Box>
  );
}
