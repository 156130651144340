import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Container, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseActionBar } from '@/components/ActionBar';
import { EndorsementsReviewActionBar } from '@/components/ActionBar/EndorsementsReviewActionBar';
import { PageTitle } from '@/components/App/PageTitle';
import { ExitConfirmationModal } from '@/components/Modals/ExitConfirmationModal';
import { shadowBoxes } from '@/config/theme/base';
import { FontWeight } from '@/config/theme/typography';
import { useEndorsementsStore } from '@/hooks/useEndorsementsStore';

import { EndorsementsReviewCoverageRow } from './EndorsementsReviewCoverageRow';

interface IProps {
  onNext?: () => void;
  policyId: string;
  name: string;
  address: string;
  startDate: string;
  coverages: any[];
}

export function MidtermEndorsementsReviewPage({
  policyId,
  name,
  address,
  startDate,
  coverages,
}: IProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { input, reset } = useEndorsementsStore();
  const navigate = useNavigate();

  const handleModalSubmit = useCallback(() => {
    reset();
    navigate(`/policies/${policyId}`);
  }, [navigate, policyId, reset]);

  const handleModalCancel = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSendToClient = useCallback(() => {
    navigate(`/policies/${policyId}/endorsements/success`);
    // TODO: call mutation to send email to client
  }, [navigate, policyId]);

  return (
    <Box>
      <PageTitle
        title={t(
          'pages.midtermEndorsementsReviewPage.title',
          'Review the changes',
        )}
      />
      <Container
        disableGutters
        sx={{
          boxShadow: shadowBoxes.type1,
          backgroundColor: 'grey.10',
          marginBottom: 20,
          borderRadius: 3,
          width: '960px',
        }}
      >
        <EndorsementsReviewActionBar
          name={name}
          address={address}
          inceptionDate={startDate}
          sx={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottom: '1px solid',
            borderColor: 'grey.30',
            borderRadius: 3,
            height: '94px',
          }}
        />
        <Box sx={{ px: 5, py: 4 }}>
          <Typography
            sx={{
              fontWeight: FontWeight.semibold,
              color: 'secondary.main',
              fontSize: '1.3125rem',
              lineHeight: 1.45,
              pb: 1,
            }}
          >
            {t('pages.midtermEndorsementsReviewPage.subtitle', 'Coverages')}
          </Typography>
          {coverages.map((option, index) => (
            <EndorsementsReviewCoverageRow
              key={index}
              name={option.name}
              description={option.description}
              value={option.value}
              // TODO: compare option data with redux store data & fill next 4 props
              hasChanged={index % 2 === 0}
              changeDescription="Coverage will increase by $100,000"
              monthlyCost="$600 ($55 / month)"
            />
          ))}
        </Box>
      </Container>
      <BaseActionBar
        backText="Back to editing"
        onBack={() => navigate(-1)}
        position="bottom"
        middleSlot={
          <Button
            onClick={() => setModalOpen(true)}
            color="secondary"
            size="small"
            sx={{ margin: 'auto', fontSize: '1.0625rem' }}
          >
            {t('common.labels.cancel', 'Canel')}
          </Button>
        }
        StartIcon={
          <ChevronLeftIcon
            height="10px"
            sx={{
              mr: 1,
            }}
          />
        }
      >
        <Button variant="contained" onClick={() => handleSendToClient()}>
          {t(
            'pages.midtermEndorsementsReviewPage.sendForClientReview',
            'Send for client review',
          )}
        </Button>
      </BaseActionBar>
      <ExitConfirmationModal
        open={modalOpen}
        onClose={handleModalCancel}
        onSubmit={handleModalSubmit}
      />
    </Box>
  );
}
