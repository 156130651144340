import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface IProps {
  title: string;
  rows: Array<string | undefined>;
}

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[60],
  fontSize: '0.938rem',
  lineHeight: '145%',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[90],
  lineHeight: '145%',
  fontSize: '1.0625rem',
}));

export function CompanyInfoCell({ title, rows }: IProps) {
  return (
    <Stack sx={{ width: 300 }}>
      <Title>{title}</Title>
      {rows.map((row, index) => (
        <InfoText key={index}>{row}</InfoText>
      ))}
    </Stack>
  );
}
