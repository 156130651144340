import {
  Box,
  LinearProgress,
  Stack,
  styled,
  Typography,
  linearProgressClasses,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FontWeight } from '@/config/theme/typography';
import { calculateDateProgress } from '@/helpers/calculations';

import { SVG } from '../SVGs/SVG';

const Container = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.grey['00'],
  border: '1px solid',
  borderColor: theme.palette.grey[20],
  borderRadius: theme.spacing(2),
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[60],
  textTransform: 'uppercase',
  lineHeight: '23.2px',
}));

interface IProps {
  endDate: string;
  nickname?: string;
  policyId: string;
  policyNumber: string;
  startDate: string;
  status: string;
}

export function ActivePolicyRow({
  endDate,
  nickname = 'Home',
  policyId,
  policyNumber,
  startDate,
  status,
}: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Stack justifyContent="space-between" direction="row">
        <Box display="flex" alignItems="center">
          <SVG
            name="Home"
            width="20.83"
            height="18.75"
            sx={{ color: 'grey.60' }}
          />
          <Typography
            variant="subtitle1"
            fontSize="17px"
            mx={1}
            lineHeight="24.65px"
          >
            {nickname}
          </Typography>
        </Box>
        <Stack alignItems="flex-end">
          <Label variant="body2">{t('common.labels.status', 'Status')}</Label>
          <Typography fontWeight={FontWeight.semibold} lineHeight="23.2px">
            {status}
          </Typography>
        </Stack>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={calculateDateProgress(startDate, endDate)}
        sx={{
          height: '10px',
          borderRadius: '22px',
          backgroundColor: 'grey.30',
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: 'success.light',
          },
        }}
      />
      <Stack
        direction="row"
        spacing={5}
        alignItems="flex-end"
        position="relative"
      >
        <Box>
          <Label>{t('common.labels.policyNumber', 'Policy Number')}</Label>
          <Typography lineHeight="23.2px">{policyNumber}</Typography>
        </Box>
        <Box>
          <Label>{t('common.labels.policyPeriod', 'Policy Period')}</Label>
          <Typography lineHeight="23.2px">
            {startDate + ' - ' + endDate}
          </Typography>
        </Box>
        <Button
          onClick={() => navigate(`/policies/${policyId}`)}
          sx={{ width: '114px', position: 'absolute', right: 0 }}
        >
          {t('common.buttons.viewPolicy', 'View Policy')}
        </Button>
      </Stack>
    </Container>
  );
}
