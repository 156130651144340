import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HouseSelectedPNG } from '@/helpers/publicAssetLinks';

import { SlideContent, SlideSubTitlte } from './SlideContent';

export function DrawerDeductiblesContent() {
  const { t } = useTranslation();

  return (
    <SlideContent
      title={t('common.labels.deductibles', 'Deductibles')}
      src={HouseSelectedPNG}
    >
      <SlideSubTitlte>{t('common.labels.allOtherPerils')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.deductibles.allOtherPerils.description',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut purus maximus, lobortis nisi eget, venenatis nisl. Fusce id ex sed leo finibus blandit eget at libero. ',
        )}
      </Typography>
      <SlideSubTitlte>{t('common.labels.water')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.deductibles.water.description',
          'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce facilisis eros eget ex consequat, non sollicitudin sem faucibus. Sed finibus libero eu nibh pretium, ac facilisis dolor vulputate. Quisque in ligula quis tellus lobortis blandit. Quisque vitae tempus mi. ',
        )}
      </Typography>
      <SlideSubTitlte>{t('common.labels.hurricane')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.deductibles.hurricane.description',
          'Sed ornare sed erat semper suscipit. Quisque ac eros et neque bibendum ornare ac et tortor. Mauris porta arcu eget enim vestibulum, at dignissim enim viverra. Suspendisse gravida velit odio. Proin a finibus nunc, eu fermentum felis.',
        )}
      </Typography>
    </SlideContent>
  );
}
