import { Stack, Box } from '@mui/material';
import { styled } from '@mui/system';

import { EditButton } from '../EditButton';

import { CompanyInfoCellSkeleton } from './CompanyInfoCellSkeleton';

const Container = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[40]}`,
  borderRadius: '6px',
  alignItems: 'center',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  margin: '18px',
  color: theme.palette.grey[50],
}));

interface IProps {
  children?: React.ReactNode;
  icon: React.ReactNode;
  loading?: boolean;
  onEdit?: () => void;
}

export function CompanyInfoRow({ icon, onEdit, loading, children }: IProps) {
  return (
    <Container direction="row" py={3} px={4}>
      <IconWrapper>{icon}</IconWrapper>
      <Stack direction="row" justifyContent="flex-start" flex={2} px={4}>
        {loading ? <CompanyInfoCellSkeleton /> : children}
      </Stack>
      {!loading && onEdit && <EditButton onClick={onEdit} />}
    </Container>
  );
}
