import { Check } from '@mui/icons-material';
import { StepIconProps } from '@mui/material';

import { SVG } from '../SVGs/SVG';

import { StepIconRoot } from './StyledStepperComps';

export function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <Check /> : ''}
    </StepIconRoot>
  );
}

export function LastIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot
      ownerState={{ completed, active, last: true }}
      className={className}
    >
      <SVG name="PowerOff" width={15} />
    </StepIconRoot>
  );
}
