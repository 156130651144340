import i18n from '@/config/i18n';

export function getAccountLinks() {
  return [
    {
      path: '/account/my-information',
      title: i18n.t(
        'components.accountSideBar.myInformation',
        'My Information',
      ),
    },
    // {
    //   path: '/account/payment-information',
    //   title: i18n.t(
    //     'components.accountSideBar.paymentInformation',
    //     'Payment Information',
    //   ),
    // },
    {
      path: '/account/my-team',
      title: i18n.t('components.accountSideBar.myTeam', 'My Team'),
    },
    {
      path: '/account/agreements-notices',
      title: i18n.t(
        'components.accountSideBar.agreementsNotices',
        'Agreements & Notices',
      ),
    },
  ];
}
