import { Stack, Typography } from '@mui/material';

interface IProps {
  name: string;
  letter?: string | null;
  value: string;
}

export function SummaryCardRow({ letter = null, name, value }: IProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      sx={{
        borderBottom: 'solid 1px #DBE1E0',
        height: '42px',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: '0.9375rem' }}>
        {letter} {name}
      </Typography>
      <Typography
        sx={{ fontWeight: 600, fontSize: '0.9375rem', color: 'grey.90' }}
      >
        {value}
      </Typography>
    </Stack>
  );
}
