import { Container, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { HEADER_HEIGHT } from '../../components/Header';
import { FullscreenLoader } from '../../components/Loaders';
import { fullNameSelector } from '../../helpers/selectors/user';
import { useAuth } from '../../hooks';
import { useGetUsersQuery } from '../../store/api';

import { ClientListSidebar } from './ClientListSidebar';

export function ClientsPageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { data: clientListResponse, isLoading: clientListLoading } =
    useGetUsersQuery({});

  const clientOptions = useMemo(() => {
    if (clientListResponse) {
      const userId = user?._id;

      return clientListResponse.getUsers?.items
        ?.map((user) => ({
          label: fullNameSelector(user, '--'),
          value: user,
        }))
        .filter((user) => user.value._id !== userId);
    }

    return [];
  }, [clientListResponse, user]);

  useEffect(() => {
    if (
      location.pathname === '/clients' &&
      !clientListLoading &&
      (clientOptions?.length ?? 0) > 0
    ) {
      navigate(`/clients/${clientOptions?.[0].value._id}`, { replace: true });
    }
  }, [clientListLoading, clientOptions, location.pathname, navigate]);

  return (
    <Container
      fixed
      sx={{
        marginTop: 3,
        height: `calc(100vh - ${HEADER_HEIGHT} - 48px)`,
        '&.MuiContainer-root': {
          padding: 0,
        },
      }}
    >
      {location.pathname === '/clients' || clientListLoading ? (
        <FullscreenLoader>
          {t('pages.clientsPage.retrievingClients', 'Retrieving clients')}
        </FullscreenLoader>
      ) : (
        <Stack
          direction="row"
          sx={{
            height: '100%',
            width: '1240px',
            border: '1px solid',
            borderColor: 'grey.30',
            borderRadius: 4,
          }}
        >
          <ClientListSidebar clientOptions={clientOptions || []} />
          <Outlet />
        </Stack>
      )}
    </Container>
  );
}
