import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControllerTextField } from '@/components/Inputs';
import {
  getCompanyAddressesSchema,
  ICompanyAddressesSchema,
} from '@/pages/MyInformationPage/CompanyAddresses.schema';

import { BaseModal } from '../BaseModal';

interface ICompanyAddressesFields {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
}
interface IInitialCompanyAddressValues {
  physical: ICompanyAddressesFields;
  mailing: ICompanyAddressesFields;
}
interface IProps {
  initialValues: IInitialCompanyAddressValues;
  onClose?: () => void;
  onSave: SubmitHandler<ICompanyAddressesSchema>;
  open: boolean;
}

const StyledTextField = styled(ControllerTextField)<any>(({ theme }) => ({
  '.MuiOutlinedInput-input': {
    backgroundColor: theme.palette.grey['00'],
    borderRadius: '12px',
    border: `1px solid ${theme.palette.grey[20]}`,
    padding: '16px 14px',
  },
})) as typeof ControllerTextField;

export function EditCompanyAddressesModal({
  initialValues,
  onClose,
  onSave,
  open,
}: IProps) {
  const { t } = useTranslation();
  const [sameAsPhysical, setSameAsPhysical] = useState(false);

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(getCompanyAddressesSchema()),
  });

  const physical = useWatch({ control, name: 'physical' });

  useEffect(() => {
    if (sameAsPhysical) {
      setValue('mailing.address1', physical?.address1 || '');
      setValue('mailing.address2', physical?.address2 || '');
      setValue('mailing.city', physical?.city || '');
      setValue('mailing.state', physical?.state || '');
      setValue('mailing.zipcode', physical?.zipcode || '');
    }
  }, [physical, sameAsPhysical, setValue]);

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues, onClose]);

  return (
    <BaseModal
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '1024px',
        },
      }}
      open={open}
      onClose={onClose}
      title={t('modals.editCompanyAddresses.title', 'Edit Addresses')}
      actionBar={
        onClose && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              onClick={onClose}
              sx={{ color: 'grey.100', fontWeight: 600 }}
            >
              {t('common.actions.cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSave)}
              sx={{
                fontWeight: 600,
                height: '31px',
                width: '74px',
                '&.Mui-disabled': {
                  backgroundColor: 'grey.40',
                  color: 'grey.60',
                },
              }}
            >
              {t('common.actions.save', 'Save')}
            </Button>
          </Box>
        )
      }
    >
      <Stack mt={3} direction="row" justifyContent="space-between" spacing={5}>
        <Stack
          padding={4}
          spacing={2}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
          }}
        >
          <Typography
            fontSize="1.3125rem"
            lineHeight="145%"
            color="secondary"
            mb={1}
          >
            {t('pages.myInformationPage.physicalAddress', 'Physical Address')}
          </Typography>
          <StyledTextField
            fullWidth
            name="physical.address1"
            placeholder="Street 1"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="physical.address2"
            placeholder="Street 2"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="physical.city"
            placeholder="City"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="physical.state"
            placeholder="State"
            control={control}
          />
          <StyledTextField
            fullWidth
            name="physical.zipcode"
            placeholder="Zip Code"
            control={control}
          />
        </Stack>
        <Stack
          padding={4}
          spacing={2}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontSize="1.3125rem"
              lineHeight="145%"
              color="secondary"
              mb={1}
            >
              {t('pages.myInformationPage.mailingAddress', 'Mailing Address')}
            </Typography>
            <Checkbox
              disableFocusRipple
              disableRipple
              disableTouchRipple
              checked={sameAsPhysical}
              sx={{
                color: 'grey.30',
                '&.Mui-checked': {
                  color: '#5D5FEF',
                },
                paddingTop: 0,
              }}
              onChange={(event) => setSameAsPhysical(event.target.checked)}
            />
            <Typography variant="h5" mb={1}>
              Same as physical address
            </Typography>
          </Stack>
          <StyledTextField
            fullWidth
            name="mailing.address1"
            placeholder="Street 1"
            disabled={sameAsPhysical}
            control={control}
          />
          <StyledTextField
            fullWidth
            name="mailing.address2"
            placeholder="Street 2"
            disabled={sameAsPhysical}
            control={control}
          />
          <StyledTextField
            fullWidth
            name="mailing.city"
            placeholder="City"
            disabled={sameAsPhysical}
            control={control}
          />
          <StyledTextField
            fullWidth
            name="mailing.state"
            placeholder="State"
            disabled={sameAsPhysical}
            control={control}
          />
          <StyledTextField
            fullWidth
            name="mailing.zipcode"
            placeholder="Zip Code"
            disabled={sameAsPhysical}
            control={control}
          />
        </Stack>
      </Stack>
    </BaseModal>
  );
}
