import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { Button } from '@/components/Button';
import { HEADER_HEIGHT } from '@/components/Header';
import { NavLink } from '@/components/Header/NavLink';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  justifyContent: 'space-between',
  margin: '4px 0px',
  padding: '4px 16px',
  borderRadius: theme.spacing(5),
  '&:hover': {
    backgroundColor: theme.palette.grey[20],
  },
  '&.active': {
    backgroundColor: theme.palette.grey[80],
  },
  '&.active > p': {
    color: theme.palette.grey['00'],
  },
}));

interface IProps {
  links: Array<{ path: string; title: string }>;
  onLogout: () => void;
}

export function AccountSideBar({ links, onLogout }: IProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRight: ({ palette }) => `1px solid ${palette.grey[10]}`,
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        width: '265px',
      }}
    >
      <Stack pt={5} px={3} height="100%">
        {links.map((route, index) => (
          <StyledNavLink to={route.path} key={index}>
            <Typography
              color="secondary.main"
              fontSize="1.0625rem"
              fontWeight={600}
              lineHeight="145%"
            >
              {route.title}
            </Typography>
            <ChevronRightIcon
              fontSize="small"
              sx={{
                color: ({ palette }) => palette.grey['00'],
                position: 'absolute',
                right: '4px',
              }}
            />
          </StyledNavLink>
        ))}
        <Box justifySelf="flex-end" marginTop="auto" py={4}>
          <Button
            color="error"
            fullWidth
            onClick={onLogout}
            size="large"
            variant="contained"
          >
            <strong>Log out</strong>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
