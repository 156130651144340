import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { theme } from '@/config';
import { FontWeight } from '@/config/theme/typography';

const ChangesContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey['00'],
  borderRadius: theme.spacing(0.5),
  border: '1px solid',
  borderColor: theme.palette.grey[30],
  padding: theme.spacing(1.5, 2),
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const AccordionSummaryContainer = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginBottom: '10px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginBottom: '5px',
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexDirection: 'row',
  },
}));

interface IProps {
  name?: string;
  description?: string;
  value?: string;
  hasChanged?: boolean;
  changeDescription?: string;
  monthlyCost?: string;
}

export function EndorsementsReviewCoverageRow({
  name,
  description,
  value,
  hasChanged,
  changeDescription,
  monthlyCost,
}: IProps) {
  const [expanded, setExpanded] = useState(Boolean(hasChanged));

  return (
    <Grid
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        pb: 3,
        borderBottom: `1px solid ${theme.palette.grey[30]}`,
        py: 3.3125,
      }}
    >
      <Grid item xs={9}>
        <Accordion
          disableGutters
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          sx={{ background: 'transparent', boxShadow: 'none' }}
        >
          <AccordionSummaryContainer
            expandIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
          >
            <Typography
              sx={{
                fontSize: '1.0625rem',
                fontWeight: FontWeight.semibold,
                color: hasChanged ? 'primary.20' : 'grey.90',
                lineHeight: 1.45,
                pb: '10.5px',
                ':hover': {
                  color: 'primary.20',
                },
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.0625rem',
                fontWeight: FontWeight.semibold,
                color: hasChanged ? 'primary.20' : 'grey.90',
                lineHeight: 1.45,
                textAlign: 'right',
              }}
            >
              {value}
            </Typography>
          </AccordionSummaryContainer>
          <AccordionDetails sx={{ padding: 0 }}>
            <Typography
              sx={{
                fontSize: '0.9375rem',
                color: 'grey.70',
                lineHeight: 1.45,
                paddingLeft: 3,
              }}
            >
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {hasChanged && (
          <ChangesContainer direction="row">
            <Stack direction="row" gap={1.5} alignItems="center">
              <InfoIcon sx={{ color: 'grey.60' }} />
              <Typography>{changeDescription}</Typography>
            </Stack>
            <Typography>{monthlyCost}</Typography>
          </ChangesContainer>
        )}
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}
