import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { QuoteStepperContainer } from '../../components/QuoteStepper';
import { diligentSearchPDF } from '../../helpers/publicAssetLinks';

import {
  getDiligentSearchSchema,
  IQuoterDiligentSearchPageSchema,
} from './QuoteDiligentSearchPage.schema';
import { PREV_PAGE_ROUTE } from './QuoterDiligentSearchPage.container';

interface IProps {
  initialValues?: IQuoterDiligentSearchPageSchema;
  onSubmit: (values: IQuoterDiligentSearchPageSchema) => void;
}

export function QuoterDiligentSearchPage({ initialValues, onSubmit }: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      agreeToDiligentSearch: initialValues?.agreeToDiligentSearch || false,
    },
    resolver: zodResolver(getDiligentSearchSchema()),
  });

  const isDisabled = !watch('agreeToDiligentSearch');

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <QuoteStepperContainer />
      <PageTitle title={t('pages.diligentSearchPage.title', 'Diligent Search')}>
        <Typography
          mt={2}
          sx={{ lineHeight: 1.5, maxWidth: '644px', color: 'grey.80' }}
        >
          {t(
            'pages.diligentSearchPage.description',
            'Before you continue, we want to provide a heads up on a key prerequisite to binding coverage on our platform.',
          )}
        </Typography>
      </PageTitle>
      <Paper
        sx={{
          borderRadius: 4,
          margin: 'auto',
          maxWidth: '960px',
          px: 7.25,
          py: 4,
        }}
      >
        <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
          {t(
            'pages.quoterDiligentSearch.title.whatWeNeedFromYou',
            'What we need from you',
          )}
        </Typography>
        <Box mb={3} sx={{ '& p': { lineHeight: 1.5 } }}>
          <Trans i18nKey="pages.quoterDiligentSearch.whatWeNeedFromYou.description">
            <Typography sx={{ mb: '1rem' }}>
              Before binding coverage through our platform, you must submit a
              form to us verifying that you searched for this insurance product
              on the admitted market but that admitted Florida homeowners
              insurers declined to write the requested coverage. You will have
              to list 3 admitted insurers from which you received declinations
              and the reasons for declinations, unless the home being insures
              has a replacement cost of $700,000 or more, in which case one
              declination will suffice.
            </Typography>
            <Typography>
              For more information, please visit{' '}
              <Typography
                component="a"
                href="https://www.fslso.com/forms-reports/forms/statement-of-diligent-effort"
                target="_blank"
                rel="noreferrer noopener"
                sx={{
                  display: 'inline-block',
                  color: 'primary.main',
                  mb: '1rem',
                  textDecoration: 'none',
                }}
              >
                https://www.fslso.com/forms-reports/forms/statement-of-diligent-effort.
              </Typography>
            </Typography>

            <Typography>
              You can download the Diligent Effort form here to review and/or
              get started. We will also provide you the form again and ask you
              to upload a complete version later in the quoting process.
              Providing a completed, signed form is a prerequisite to binding.
            </Typography>
          </Trans>
          <Button
            color="secondary"
            component="a"
            download
            href={diligentSearchPDF}
            size="small"
            sx={{ mt: 2 }}
            variant="contained"
          >
            <strong>
              {t(
                'common.labels.downloadDiligentSearchForm',
                'Download Diligent search form',
              )}
            </strong>
          </Button>
        </Box>
        <Box sx={{ p: 3, bgcolor: 'grey.10', mb: 3 }}>
          <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
            {t('common.labels.acknowledgement', 'Acknowledgement')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              sx={{ fontSize: '0.9375rem', fontWeight: 400 }}
              control={
                <Controller
                  control={control}
                  name="agreeToDiligentSearch"
                  render={({ field }) => (
                    <Checkbox
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      sx={{
                        color: 'grey.30',
                        '&.Mui-checked': {
                          color: '#5D5FEF',
                        },
                      }}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={
                <Typography sx={{ display: 'inline', lineHeight: 1.5 }}>
                  {t(
                    'pages.quoterDiligentSearch.acknowledgementLabel',
                    'By checking this box, I confirm my understanding that in order to bind coverage, I will have to confirm that an effort was first made to place the required insurance with companies authorized in Florida to write homeowners insurance. ',
                  )}
                </Typography>
              }
            />
          </FormGroup>
        </Box>
        <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
          {t(
            'pages.quoterDiligentSearch.title.whatIsDiligentSearch',
            'What is Diligent Search?',
          )}
        </Typography>
        <Typography
          sx={{ mb: 2, maxWidth: '844px', fontWeight: 400, lineHeight: 1.5 }}
        >
          {t(
            'pages.quoterDiligentSearch.description.whatIsDiligentSearch',
            'According to F.S. 626.914, diligent effort is defined as “seeking coverage from and having been rejected by at least three authorized insurers currently writing this type of coverage and documenting these rejections. However, if the residential structure has a dwelling replacement cost of $700,000 or more, the term means seeking coverage from and having been rejected by at least one authorized insurer currently writing this type of coverage and documenting this rejection.”',
          )}
        </Typography>
      </Paper>
      <QuoterActionBarContainer
        backText={t('pages.quoterSelectStartDatePage.title')}
        onBack={PREV_PAGE_ROUTE}
      >
        <Button
          onClick={handleSubmit(onSubmit)}
          color="secondary"
          disabled={isDisabled}
          size="small"
          sx={{ fontSize: '1rem' }}
          type="submit"
          variant="contained"
        >
          {t('common.labels.acknowledgeProceed', 'I Acknowledge, lets proceed')}
        </Button>
      </QuoterActionBarContainer>
    </Box>
  );
}
