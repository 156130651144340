import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Download(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.929 3.293a5.085 5.085 0 0 1 1.855 2.479 4.237 4.237 0 0 1 2.61 7.059.847.847 0 0 1-1.484-.51.848.848 0 0 1 .213-.617 2.543 2.543 0 0 0-1.906-4.238h-.051a.848.848 0 0 1-.848-.678 3.39 3.39 0 0 0-6.643 0 .847.847 0 0 1-.848.678h-.085a2.542 2.542 0 0 0-1.906 4.237.847.847 0 1 1-1.271 1.077 4.237 4.237 0 0 1 2.61-7.008 5.085 5.085 0 0 1 7.754-2.48Z"
        fill="currentColor"
      />
      <path
        d="m10.827 14.788 1.11-1.068a.847.847 0 1 1 1.17 1.22l-2.543 2.458a.848.848 0 0 1-1.186-.008l-2.542-2.543a.852.852 0 0 1 .601-1.452.85.85 0 0 1 .602.249l1.093 1.102v-4.738a.847.847 0 1 1 1.695 0v4.78Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
