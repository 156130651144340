export const MidTermEndorsements = {
  dwelling: 'Dwelling Coverage',
  'other-structures': 'Other Structures Coverage',
  'ordinance-or-law': 'Ordinance or Law Coverage',
  'personal-property': 'Personal Property Replacement Cost',
  carport: 'Carport Coverage',
  'animal-liability': 'Animal Liability',
};

export type MidTermEndorsementKeys = keyof typeof MidTermEndorsements;

export interface IEndorsementsInput {
  coverage: MidTermEndorsementKeys;
  cost?: number;
  description?: string;
}

export interface IEndorsementsState {
  input: IEndorsementsInput[];
}
