import { z } from 'zod';

import i18n from '../../config/i18n';

export function getCompanyAddressesSchema() {
  return z.object({
    physical: z.object({
      address1: z
        .string()
        .min(1, i18n.t('validation.address.required', 'address is required')),
      address2: z.string(),
      city: z
        .string()
        .min(1, i18n.t('validation.city.required', 'city is required')),
      state: z
        .string()
        .min(1, i18n.t('validation.state.required', 'state is required')),
      zipcode: z
        .string()
        .min(1, i18n.t('validation.zipcode.required', 'zipcode is required')),
    }),
    mailing: z.object({
      address1: z
        .string()
        .min(1, i18n.t('validation.address.required', 'address is required')),
      address2: z.string(),
      city: z
        .string()
        .min(1, i18n.t('validation.city.required', 'city is required')),
      state: z
        .string()
        .min(1, i18n.t('validation.state.required', 'state is required')),
      zipcode: z
        .string()
        .min(1, i18n.t('validation.zipcode.required', 'zipcode is required')),
    }),
  });
}

export type ICompanyAddressesSchema = z.infer<
  ReturnType<typeof getCompanyAddressesSchema>
>;
