import {
  StepContent,
  StepLabel,
  styled,
  StepConnector,
  stepConnectorClasses,
  stepLabelClasses,
  StepLabelProps,
} from '@mui/material';

import { FontWeight } from '@/config/theme/typography';

export const StyledConnector = styled(StepConnector)<{ gradient?: boolean }>(
  ({ theme, gradient = false }) => ({
    [`& .${stepConnectorClasses.lineVertical}`]: {
      borderColor: 'transparent',
      position: 'relative',
      minHeight: '36px',
      borderLeftWidth: 2,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-4px',
        bottom: '-4px',
        width: '2px',
        left: 0,
        background: gradient
          ? 'linear-gradient(180deg, #DBE1E0 0%, rgba(219, 225, 224, 0) 100%)'
          : theme.palette.grey[30],
        borderRadius: '2px',
        transform: 'translateX(50%)',
      },
    },
  }),
);

export const StyledStepContent = styled(StepContent)(() => ({
  borderLeft: 'none',
  position: 'absolute',
}));

export const StepIconRoot = styled('div')<{
  ownerState: {
    completed?: boolean;
    active?: boolean;
    last?: boolean;
    first?: boolean;
  };
}>(({ theme, ownerState }) => ({
  border: `3px solid ${theme.palette.grey[30]}`,
  backgroundColor: theme.palette.grey[30],
  color: theme.palette.secondary.main,
  zIndex: 1,
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.first && {
    color: '#fff',
    border: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.last && {
    color: '#fff',
    border: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const StyledStepLabel = styled(StepLabel)<StepLabelProps>(() => ({
  [`& .${stepLabelClasses.label}`]: {
    fontWeight: FontWeight.semibold,
    padding: '2px 0',
  },
  [`& .${stepLabelClasses.active}`]: {
    fontWeight: FontWeight.semibold,
  },
}));
