import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { IStackedListOption } from '../../components/StackedList';
import { Property } from '../../graphql/generated';
import { getTranslatedErrorMessage } from '../../helpers';
import { formatAddressMultiLine } from '../../helpers/formatters';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { useAddPropertyMutation, useGetPropertiesQuery } from '../../store/api';
import { useLazyValidateAddressQuery } from '../../store/uspsApi';

import { QuoterSelectPropertyPage } from './QuoterSelectPropertyPage';
import { IQuoterSelectPropertySchema } from './QuoterSelectPropertyPage.schema';

export const PREV_PAGE_ROUTE = '/quoter/select-client';
const NEXT_PAGE_ROUTE = '/quoter/select-start-date';

export function QuoterSelectPropertyPageContainer() {
  // hookes
  const navigate = useNavigate();
  const { input, setInput } = useQuoterStore();

  // Redirect to previous page if no client is selected
  useEffect(() => {
    if (!input?.selectClient?._id) {
      navigate(PREV_PAGE_ROUTE);
    }
  }, [input?.selectClient, navigate]);

  const selectedClient = input.selectClient;
  const { data: propertiesResponse, isLoading: propertiesLoading } =
    useGetPropertiesQuery({
      userIds: [selectedClient?._id || ''],
    });

  const [
    addProperty,
    { isLoading: addPropertyLoading, error: addPropertyError },
  ] = useAddPropertyMutation();

  // -- Userd to check address validity
  const [validateAddress] = useLazyValidateAddressQuery();

  // computed values
  const name = input?.selectClient?.profile?.firstName || 'Client';

  // -- transform server response into a list of options
  const propertyOptions = useMemo((): IStackedListOption<Property>[] => {
    if (!propertiesResponse?.getProperties?.items?.length) {
      return [];
    }

    return propertiesResponse.getProperties.items.map((property) => {
      const [address1, , bottomLine] = formatAddressMultiLine(property.address);

      return {
        label: address1,
        subLabel: bottomLine,
        value: property,
      };
    });
  }, [propertiesResponse]);

  // handlers
  const onPropertySelectedHandler = useCallback(
    (property: Property) => {
      setInput({
        selectProperty: { ...property },
      });
      navigate(NEXT_PAGE_ROUTE);
    },
    [navigate, setInput],
  );

  const onSubmit = useCallback(
    async (value: IQuoterSelectPropertySchema) => {
      const { data } = await validateAddress(value);

      if (data?.hasError) {
        alert(data?.errorMessage || 'Error validating address');
        return;
      }

      const results = await addProperty({
        clientId: selectedClient?._id || '',
        address1: value.address1,
        address2: value.address2,
        city: value.city,
        state: value.state,
        zipcode: value.zipcode,
        country: value.country,
      });

      if ('data' in results) {
        const addedProperty = results.data?.addProperty;
        setInput({
          selectProperty: { ...addedProperty },
        });

        navigate(NEXT_PAGE_ROUTE);
      }
    },
    [addProperty, navigate, selectedClient, setInput, validateAddress],
  );

  const errorMessage = getTranslatedErrorMessage(addPropertyError);

  return (
    <QuoterSelectPropertyPage
      errorMessage={errorMessage}
      isSubmitting={addPropertyLoading}
      name={name}
      onPropertySelected={onPropertySelectedHandler}
      onSubmit={onSubmit}
      propertyOptions={propertyOptions || []}
      propertyOptionsLoading={propertiesLoading}
    />
  );
}
