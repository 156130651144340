import { useMemo } from 'react';

import { generateEmptyQuote } from '../../components/CustomCoverageOption/helpers';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { IQuoteOption } from '../../store/quoter';

import { QuoterSuccessPage } from './QuoterSuccessPage';

export function QuoterSuccessPageContainer() {
  const { input } = useQuoterStore();

  const quotes = useMemo<IQuoteOption[]>(
    () =>
      input?.quotes?.length
        ? input.quotes.filter((quote) => quote.selected)
        : [generateEmptyQuote(), generateEmptyQuote(), generateEmptyQuote()],
    [input?.quotes],
  );

  return <QuoterSuccessPage quotes={quotes || []} />;
}
