import { z } from 'zod';

import i18n from '../../config/i18n';

export function getCompanyContactsSchema() {
  return z.object({
    primary: z.object({
      name: z
        .string()
        .min(1, i18n.t('validation.name.required', 'name is required')),
      email: z
        .string()
        .email(i18n.t('validation.email.valid', 'valid email is required'))
        .min(1, i18n.t('validation.email.required', 'email is required')),
      phone: z
        .string()
        .min(1, i18n.t('validation.phone.required', 'phone is required')),
    }),
    accounting: z.object({
      name: z
        .string()
        .min(1, i18n.t('validation.name.required', 'name is required')),
      email: z
        .string()
        .email(i18n.t('validation.email.valid', 'valid email is required'))
        .min(1, i18n.t('validation.email.required', 'email is required')),
      phone: z
        .string()
        .min(1, i18n.t('validation.phone.required', 'phone is required')),
    }),
  });
}

export type ICompanyContactsSchema = z.infer<
  ReturnType<typeof getCompanyContactsSchema>
>;
