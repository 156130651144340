import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography, Button, Paper, Link } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import {
  ControllerPasswordField,
  ControllerTextField,
} from '../../components/Inputs';

import { ILoginPageSchema, getLoginPageSchema } from './LoginPage.schema';

interface IProps {
  errorMessage?: string;
  onSubmit: SubmitHandler<ILoginPageSchema>;
}

export function LoginPage({ errorMessage, onSubmit }: IProps) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(getLoginPageSchema()),
  });

  return (
    <Paper elevation={24} sx={{ maxWidth: 500, px: 4, py: 4, width: '100%' }}>
      <Typography textAlign="center" variant="h2">
        Login
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        marginX="auto"
        marginTop="20px"
        minWidth={300}
        maxWidth={400}
        width="100%"
      >
        <Stack spacing={2} marginBottom={4}>
          <ControllerTextField
            autoComplete="username"
            name="email"
            control={control}
            label={'Email'}
            fullWidth
          />
          <ControllerPasswordField
            autoComplete="current-password"
            name="password"
            control={control}
            label={'Password'}
            fullWidth
          />
          {errorMessage && (
            <Typography marginBottom={1} color="error">
              {errorMessage}
            </Typography>
          )}
          <Link
            to="/forgot-password"
            color="primary"
            component={RouterLink}
            sx={{ textDecoration: 'none' }}
          >
            Forgot password?
          </Link>
        </Stack>

        <Button
          color="secondary"
          variant="contained"
          size="large"
          type="submit"
          fullWidth
        >
          Log in
        </Button>
      </Box>
    </Paper>
  );
}
