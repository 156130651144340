import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Plus(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 2.9a.8.8 0 0 1 .8.8v4h4a.8.8 0 0 1 0 1.6h-4v4a.8.8 0 0 1-1.6 0v-4h-4a.8.8 0 1 1 0-1.6h4v-4a.8.8 0 0 1 .8-.8Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
