import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Paper, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { ControllerTrueFalseRadioRow } from '../../components/Inputs';
import { IRadioOptions } from '../../components/Inputs/interfaces';
import { UnderwriteCheckConfirmationModal } from '../../components/Modals';
import { QuoteStepperContainer } from '../../components/QuoteStepper';

import { PREV_PAGE_ROUTE } from './QuoterUnderwritingChecksPage.container';
import {
  getQuoterUnderwritingChecksSchema,
  getUnderwriteCheckOptions,
  IQuoterUnderwritingChecksSchema,
} from './QuoterUnderwritingChecksPage.schema';
import {
  filterIneligibleUnderwritingChecks,
  generateEmptyUnderwritingChecks,
  remapIneligibleUnderwritingChecks,
} from './helpers';

interface IProps {
  initialValues: IQuoterUnderwritingChecksSchema | undefined;
  onSubmit: (data: IQuoterUnderwritingChecksSchema) => void;
}

export function QuoterUnderwritingChecksPage({
  initialValues,
  onSubmit,
}: IProps) {
  // hooks
  const { t } = useTranslation();

  const { control, handleSubmit, formState } =
    useForm<IQuoterUnderwritingChecksSchema>({
      defaultValues: {
        ...generateEmptyUnderwritingChecks(),
        ...initialValues,
      },
      mode: 'onChange',
      resolver: zodResolver(getQuoterUnderwritingChecksSchema()),
    });

  // states
  const [ineligibleChecks, setIneligibleChecks] = useState<
    IRadioOptions<boolean>[]
  >([]);

  // computed values
  const isValid = formState?.isValid;

  const radioOptions = useMemo(getUnderwriteCheckOptions, []);

  const [primaryOptions, secondaryOptions, classificationOptions] =
    useMemo(() => {
      return [
        radioOptions.filter((option) => option.group === 'primary'),
        radioOptions.filter((option) => option.group === 'secondary'),
        radioOptions.filter((option) => option.group === 'classification'),
      ];
    }, [radioOptions]);

  // handlers
  const onSubmitCustomValidationHandler = useCallback(
    (data: IQuoterUnderwritingChecksSchema) => {
      const ineligibleChecks = filterIneligibleUnderwritingChecks(data);

      if (ineligibleChecks.length) {
        const remappedIneligibleChecks = remapIneligibleUnderwritingChecks(
          ineligibleChecks,
          radioOptions,
        );

        setIneligibleChecks(remappedIneligibleChecks);
        return;
      }

      onSubmit(data);
    },
    [onSubmit, radioOptions],
  );

  return (
    <Box component="form">
      <QuoteStepperContainer />
      <UnderwriteCheckConfirmationModal
        ineligibleChecks={ineligibleChecks}
        onClose={() => setIneligibleChecks([])}
        onProceed={handleSubmit(onSubmit)}
        open={Boolean(ineligibleChecks.length)}
      />
      <PageTitle
        title={t('pages.underwritingChecksPage.title', 'Application Questions')}
      >
        <Typography
          mt={2}
          sx={{ lineHeight: 1.5, maxWidth: '644px', color: 'grey.80' }}
        >
          {t(
            'pages.underwritingChecksPage.description',
            'Please fill out the below underwriting questions to complete your client’s application for homeowners coverage',
          )}
        </Typography>
      </PageTitle>
      <Paper
        sx={{
          borderRadius: 4,
          margin: 'auto',
          maxWidth: '960px',
          pb: 5,
          pt: 7,
          px: 5,
        }}
      >
        <Box>
          <Typography fontSize="1.0625rem" fontWeight="600">
            {t(
              'common.labels.primaryPropertyDetails',
              'Primary Property Details',
            )}
          </Typography>
          <Box sx={{ pb: 3 }}>
            {primaryOptions?.map((option) => (
              <ControllerTrueFalseRadioRow
                key={option.name}
                control={control}
                label={option.label}
                name={option.name}
              />
            ))}
          </Box>
          <Typography fontSize="1.0625rem" fontWeight="600">
            {t(
              'common.labels.secondaryPropertyDetails',
              'Secondary Property Details',
            )}
          </Typography>
          <Box sx={{ pb: 3 }}>
            {secondaryOptions?.map((option) => (
              <ControllerTrueFalseRadioRow
                key={option.name}
                control={control}
                label={option.label}
                name={option.name}
              />
            ))}
          </Box>
          <Typography fontSize="1.0625rem" fontWeight="600">
            {t(
              'common.labels.propertyClassification',
              'Property Classification',
            )}
          </Typography>
          <Box sx={{ pb: 3 }}>
            {classificationOptions?.map((option) => (
              <ControllerTrueFalseRadioRow
                key={option.name}
                control={control}
                label={option.label}
                name={option.name}
              />
            ))}
          </Box>
        </Box>
      </Paper>
      <QuoterActionBarContainer
        backText={t('pages.diligentSearchPage.title')}
        onBack={PREV_PAGE_ROUTE}
      >
        <Button
          color="secondary"
          disabled={!isValid}
          onClick={handleSubmit(onSubmitCustomValidationHandler)}
          type="submit"
          variant="contained"
          sx={{ fontSize: '1rem' }}
        >
          {t('common.actions.continue', 'Continue')}
        </Button>
      </QuoterActionBarContainer>
    </Box>
  );
}
