import { Box, StackProps, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '../../config/theme/typography';

const StyledBox = styled(Box)(({ theme }) => ({
  borderBottom: 'solid 1px',
  borderColor: theme.palette.grey[30],
  paddingBottom: theme.spacing(2),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[70],
  fontSize: '0.75rem',
  paddingBottom: '4px',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.0625rem',
  fontWeight: FontWeight.semibold,
  lineHeight: '24.65px',
  width: '161px',
  wordBreak: 'break-word',
}));

const StyledSubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[80],
  fontSize: '0.9375rem',
  fontWeight: FontWeight.regular,
  lineHeight: '21.75px',
}));

interface IProps {
  quoteId?: string;
}

export function QuoteDetailSpecific({
  quoteId,
  ...props
}: IProps & StackProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} {...props}>
      <StyledBox>
        <StyledLabel>
          {t('pages.quoteDetails.clientLabel', 'CLIENT')}
        </StyledLabel>
        <StyledText>Savannah Nguyen</StyledText>
        <StyledSubText>Savannah.Nguyen@arbolmarket.com</StyledSubText>
      </StyledBox>
      <StyledBox>
        <StyledLabel>
          {t('pages.quoteDetails.propertyLocationLabel', 'PROPERTY LOCATION')}
        </StyledLabel>
        <StyledText>2008 Jellyfish Hwy Key West, FL, 33040</StyledText>
      </StyledBox>
      <StyledBox>
        <StyledLabel>
          {t('pages.quoteDetails.startDateLabel', 'START DATE')}
        </StyledLabel>
        <StyledText>06/12/2022</StyledText>
      </StyledBox>
      <StyledBox>
        <StyledLabel>
          {t('pages.quoteDetails.premiumLabel', 'PREMIUM')}
        </StyledLabel>
        <StyledText>
          {t('common.currency.USD', '{{value, currency(currency: USD)}}', {
            value: 1052.89,
          })}
        </StyledText>
        <StyledSubText>
          {t(
            'pages.customizeCoveragePage.monthlyTitle',
            'or pay {{monthly}}/month for 12 months',
            {
              monthly: t('common.currency.USD', {
                value: 189.74,
              }),
            },
          )}
        </StyledSubText>
      </StyledBox>
    </Stack>
  );
}
