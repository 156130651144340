import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { StyledInput } from '../StyledInput';
import { StyledLabel } from '../StyledLabel';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  label?: string;
  name: Path<T>;
  options?: Array<{ value: string; label: string }>;
  placeholder?: string;
}

const getPlaceholderStyle = (value: string) => ({
  color: 'grey.70',
  content: `"${value}"`,
});

const emptyStyle = {};

export function BasicSelectWithLabel<T extends FieldValues>({
  control,
  label,
  name,
  options = [],
  placeholder,
  ...inputProps
}: IProps<T> & Partial<SelectInputProps>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth>
          <StyledLabel htmlFor={`${name}-basic-select`} shrink>
            {label}
          </StyledLabel>
          <Select
            id={`${name}-basic-select`}
            IconComponent={ExpandMoreIcon}
            input={<StyledInput />}
            placeholder="Test"
            variant="outlined"
            {...field}
            {...inputProps}
            MenuProps={{
              PaperProps: {
                sx: {
                  border: '1px solid black',
                  borderColor: 'grey.30',
                  borderRadius: 1,
                },
              },
            }}
            sx={{
              ...inputProps?.sx,
              '& .MuiSelect-select .notranslate::after': placeholder
                ? getPlaceholderStyle(placeholder)
                : emptyStyle,
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
