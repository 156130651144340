import { configureStore } from '@reduxjs/toolkit';

import { api } from './api';
import { authSlice } from './auth';
import { bindingSlice } from './binding';
import { bingMapApi } from './bingMapApi';
import { endorsementsSlice } from './midtermEndorsements';
import { quoterSlice } from './quoter';
import { uspsApi } from './uspsApi';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [authSlice.name]: authSlice.reducer,
    [bingMapApi.reducerPath]: bingMapApi.reducer,
    [bindingSlice.name]: bindingSlice.reducer,
    [endorsementsSlice.name]: endorsementsSlice.reducer,
    [quoterSlice.name]: quoterSlice.reducer,
    [uspsApi.reducerPath]: uspsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      bingMapApi.middleware,
      uspsApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
