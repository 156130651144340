/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme, ThemeOptions } from '@mui/material/styles';

import { baseTheme } from './base';
import { components } from './components';
import { typography } from './typography';

const themeOptions: ThemeOptions = {
  components,
  typography,
};

export const theme = createTheme(baseTheme, themeOptions);
