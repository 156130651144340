import { SwitchProps } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Switch } from './Switch';

interface IProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
}

export function ControllerSwitch<T extends FieldValues>({
  name,
  control,
  ...props
}: IProps<T> & SwitchProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Switch
          onChange={field.onChange}
          checked={Boolean(field.value)}
          {...props}
        />
      )}
    />
  );
}
