import {
  StepContent,
  StepLabel,
  styled,
  StepConnector,
  stepConnectorClasses,
  stepLabelClasses,
} from '@mui/material';

import { FontWeight } from '@/config/theme/typography';

export const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      minHeight: '30px',
    },
  },
  [`&.${stepConnectorClasses.active} + .MuiStep-vertical + .MuiStepConnector-vertical`]:
    {
      [`& .${stepConnectorClasses.line}`]: {
        minHeight: '30px',
      },
    },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    borderColor: 'transparent',
    position: 'relative',
    minHeight: '11px',
    borderLeftWidth: 2,
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-4px',
      bottom: '-4px',
      width: '2px',
      left: 0,
      background: theme.palette.grey[30],
      borderRadius: '2px',
      transform: 'translateX(50%)',
    },
  },
}));

export const StyledStepContent = styled(StepContent)(({ theme }) => ({
  borderLeft: 'none',
  position: 'absolute',
}));

export const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean; last?: boolean };
}>(({ theme, ownerState }) => ({
  border: `3px solid ${theme.palette.grey[30]}`,
  zIndex: 1,
  color: '#fff',
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    border: `3px solid ${theme.palette.primary.main}`,
    background: 'none',
  }),
  ...(ownerState.completed && {
    border: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.last && {
    border: `3px solid ${theme.palette.grey[30]}`,
    backgroundColor: theme.palette.grey[30],
    color: theme.palette.grey[50],
  }),
}));

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    fontWeight: FontWeight.semibold,
    padding: '2px 0',
  },
  [`& .${stepLabelClasses.active}`]: {
    fontWeight: FontWeight.semibold,
  },
}));
