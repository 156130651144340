import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { ControllerTextField } from '../../components/Inputs';
import { ControllerLocationAutocomplete } from '../../components/LocationAutocomplete/ControllerLocationAutocomplete';
import { TwoPanelPaper } from '../../components/Paper';
import { QuoteStepperContainer } from '../../components/QuoteStepper';
import { IStackedListOption, StackedList } from '../../components/StackedList';
import { Property } from '../../graphql/generated';
import { IBingAddress } from '../../store/bingMapApi/interfaces';

import { PREV_PAGE_ROUTE } from './QuoterSelectPropertyPage.container';
import {
  getQuoterSelectPropertySchema,
  IQuoterSelectPropertySchema,
} from './QuoterSelectPropertyPage.schema';

interface IProps {
  errorMessage?: string;
  isSubmitting?: boolean;
  name?: string;
  onPropertySelected?: (property: Property) => void;
  onSubmit: (values: IQuoterSelectPropertySchema) => void;
  propertyOptions: IStackedListOption<Property>[];
  propertyOptionsLoading?: boolean;
}

export function QuoterSelectPropertyPage({
  errorMessage,
  isSubmitting,
  name,
  onPropertySelected,
  onSubmit,
  propertyOptions,
  propertyOptionsLoading,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset, setError } =
    useForm<IQuoterSelectPropertySchema>({
      defaultValues: {
        address1: '',
        address2: '',
        city: '',
        state: 'Florida',
        zipcode: '',
        country: 'US',
      },
      resolver: zodResolver(getQuoterSelectPropertySchema()),
    });

  const onAddressSelectedHandler = useCallback(
    (value: Property) => {
      onPropertySelected?.({
        ...value,
      });
    },
    [onPropertySelected],
  );

  const onSelectedHandler = useCallback(
    (selected: Partial<IBingAddress>) => {
      const state = selected.adminDistrict;
      const isFlorida = state === 'FL' || state === 'Florida';

      if (!isFlorida) {
        setError('state', {
          message: t(
            'quoter.selectPropertyPage.errors.state',
            "We're sorry, we only offer quotes in Florida.",
          ),
        });

        return;
      }

      reset({
        address1: selected.addressLine,
        address2: '',
        city: selected.locality,
        country: selected.countryRegionIso2 || 'US',
        state,
        zipcode: selected.postalCode,
      });
    },
    [reset, setError, t],
  );

  return (
    <Box>
      <QuoteStepperContainer />
      <PageTitle
        title={t('pages.quoterSelectPropertyPage.title', 'Add Client Property')}
      />
      {/* Panel */}
      <TwoPanelPaper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
        }}
        leftChildren={
          <Box margin="auto" maxWidth="448px">
            <Typography variant="h2">
              {t(
                'pages.quoterSelectPropertyPage.leftPanelTitle',
                'New Property',
              )}
            </Typography>
            <Box mt={4} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={1}>
                <ControllerLocationAutocomplete
                  control={control}
                  name="address1"
                  label={t('common.labels.address1', 'Address 1')}
                  onSelected={onSelectedHandler}
                  optionLabel="addressLine"
                />
                <ControllerTextField
                  control={control}
                  name="address2"
                  label={t('common.labels.address2', 'Address 2')}
                />
                <ControllerTextField
                  control={control}
                  name="city"
                  label={t('common.labels.city', 'City')}
                />
                <ControllerTextField
                  control={control}
                  disabled
                  name="state"
                  label={t('common.labels.state', 'State')}
                />
                <ControllerTextField
                  control={control}
                  name="zipcode"
                  label={t('common.labels.zipcode', 'Zip Code')}
                />
              </Stack>
              <FormHelperText error>{errorMessage}</FormHelperText>
              <Box mt={3} textAlign="right">
                <Button
                  disabled={isSubmitting}
                  color="secondary"
                  type="submit"
                  variant="contained"
                >
                  {t('common.actions.saveContinue', 'Save and Continue')}
                </Button>
              </Box>
            </Box>
          </Box>
        }
        rightChildren={
          <Box>
            <Typography variant="h2">
              {t(
                'pages.quoterSelectPropertyPage.rightPanelTitle',
                "{{name}}'s properties",
                { name },
              )}
            </Typography>
            <StackedList
              onSelected={onAddressSelectedHandler}
              options={propertyOptions}
              loading={propertyOptionsLoading}
              sx={{ mt: 3 }}
            />
          </Box>
        }
      />
      {/* Footer */}
      <QuoterActionBarContainer
        onBack={PREV_PAGE_ROUTE}
        backText={t('pages.quoterSelectClientPage.title')}
        hideSaveExit
      />
    </Box>
  );
}
