import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { generateEmptyQuote } from '../../components/CustomCoverageOption/helpers';
import { useBindingStore } from '../../hooks/useBindingStore';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { IQuoteOption } from '../../store/quoter';

import { QuoterCustomizeCoveragePage } from './QuoterCustomizeCoveragePage';

export const PREV_PAGE_ROUTE = '/quoter/property-details';

export function QuoterCustomizeCoveragePageContainer() {
  const navigate = useNavigate();
  const { input, setInput } = useQuoterStore();
  const { setInput: setBindingInput } = useBindingStore();

  // Should be at least one quote
  useEffect(() => {
    if (!input.quotes?.length) {
      setInput({
        quotes: [generateEmptyQuote()],
      });
    }
  }, [input.quotes?.length, setInput]);

  const onQuoteChange = useCallback(
    (values: IQuoteOption, index: number) => {
      const quotes = input.quotes || [];

      const newQuotes = [...quotes];
      newQuotes[index] = {
        ...newQuotes[index],
        ...values,
      };

      setInput({
        quotes: [...newQuotes],
      });
    },
    [input, setInput],
  );

  const onAddQuoteHandler = useCallback(() => {
    const quotes = input.quotes || [];
    const { id, ...lastQuote } = input?.quotes?.[input.quotes.length - 1] || {};

    const newQuote = {
      ...generateEmptyQuote(),
      ...cloneDeep(lastQuote),
    };

    setInput({
      quotes: [...quotes, newQuote],
    });
  }, [input, setInput]);

  const onBindQuote = useCallback(() => {
    const quotes = input.quotes || [];

    if (quotes?.length > 1) {
      navigate('/quoter/select-and-bind');
    } else {
      setBindingInput({
        quoteId: input.quotes?.[0].id,
      });

      navigate('/quoter/confirm-start-date');
    }
  }, [navigate, input.quotes, setBindingInput]);

  const onRemoveQuoteHandler = useCallback(
    (requestedIndex: number) => {
      const quotes = input.quotes || [];
      setInput({
        quotes: quotes.filter((_, index) => index !== requestedIndex),
      });
    },
    [input, setInput],
  );

  const onShare = useCallback(() => {
    navigate('/quoter/share');
  }, [navigate]);

  const quotes = input.quotes || [];

  return (
    <QuoterCustomizeCoveragePage
      onAddQuote={onAddQuoteHandler}
      onBindQuote={onBindQuote}
      onQuoteChange={onQuoteChange}
      onRemoveQuote={onRemoveQuoteHandler}
      onShare={onShare}
      quotes={quotes || []}
    />
  );
}
