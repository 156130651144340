import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function LeftArrow(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 12"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.965.635a.8.8 0 0 1 0 1.131L2.731 6l4.234 4.235a.8.8 0 0 1-1.13 1.131l-4.8-4.8a.8.8 0 0 1 0-1.131l4.8-4.8a.8.8 0 0 1 1.13 0Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
