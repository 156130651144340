import { Box, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';

import { DotStepper } from '../Stepper';

import { DrawerCoverageContent } from './DrawerCoverageContent';
import { DrawerDeductiblesContent } from './DrawerDeductiblesContent';
import { DrawerParametricExpenseContent } from './DrawerParametricExpenseContent';

const BOX_SHADOW =
  '0px 60px 232px rgba(0, 0, 0, 0.11), 0px 25.0666px 96.9241px rgba(0, 0, 0, 0.079074), 0px 13.4018px 51.8202px rgba(0, 0, 0, 0.0655718), 0px 7.51293px 29.05px rgba(0, 0, 0, 0.055), 0px 3.99006px 15.4282px rgba(0, 0, 0, 0.0444282), 0px 1.66035px 6.42003px rgba(0, 0, 0, 0.030926), inset 1px 0px 0px #0D1329;';

export enum DrawerContent {
  Closed = -1,
  Coverage,
  ParametricExpense,
  Deductibles,
}

interface IProps {
  onClose?: () => void;
  open?: DrawerContent;
}

export function CoverageOptionsInfoDrawer({ open, onClose }: IProps) {
  const [activeStep, setActiveStep] = useState(-1);

  useEffect(() => {
    if (open !== undefined) {
      setActiveStep(open);
    }
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open !== undefined && open !== DrawerContent.Closed}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 0,
          boxShadow: BOX_SHADOW,
        },
      }}
    >
      <Box
        sx={{
          px: 4,
          pt: 0,
          pb: 4,
          width: '654px',
        }}
      >
        <Box my={2.5}>
          <DotStepper
            steps={3}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>
        {activeStep === DrawerContent.Coverage && <DrawerCoverageContent />}
        {activeStep === DrawerContent.Deductibles && (
          <DrawerDeductiblesContent />
        )}
        {activeStep === DrawerContent.ParametricExpense && (
          <DrawerParametricExpenseContent />
        )}
      </Box>
    </Drawer>
  );
}
