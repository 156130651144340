import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEndorsementsInput, IEndorsementsState } from './interfaces';

export const initialEndorsementsState: IEndorsementsState = {
  input: [],
};

export const endorsementsSlice = createSlice({
  name: 'endorsements',
  initialState: initialEndorsementsState,
  reducers: {
    reset: () => ({ ...initialEndorsementsState }),
    setInput: (state, action: PayloadAction<IEndorsementsInput[]>) => {
      const { payload } = action;
      state.input = payload;

      return state;
    },
  },
});

export const { reset, setInput } = endorsementsSlice.actions;
