import { Stack, Typography } from '@mui/material';

import { CalendarDateRange } from '../SVGs/CalendarDateRange';
import { Home } from '../SVGs/Home';
import { Person } from '../SVGs/Person';

const icons = {
  home: Home,
  person: Person,
  calendar: CalendarDateRange,
};

interface IProps {
  children: React.ReactNode;
  color?: 'primary.main' | 'secondary.main' | 'grey.50';
  icon: keyof typeof icons;
}

export function QuoteStepperIconItem({
  children,
  color = 'secondary.main',
  icon,
}: IProps) {
  const Icon = icons[icon];

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Icon
        height={24}
        width={24}
        sx={{
          color,
        }}
      />
      <Typography color="#3F5F69">{children}</Typography>
    </Stack>
  );
}
