import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IQuoteOption } from '@/store/quoter';

import { PageTitle } from '../App/PageTitle';
import { Button } from '../Button';
import { SummaryCard, DisclosurePlaceholder } from '../SummaryCard';

interface IProps {
  actionBar?: React.ReactNode;
  onSelectQuote?: (quote: string) => void;
  quotes: IQuoteOption[];
  quotesLoading?: boolean;
  title: string;
}

export function CompareAndShareQuotes({
  actionBar,
  onSelectQuote,
  quotes,
  quotesLoading,
  title,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <PageTitle title={title}></PageTitle>
      <Box component="form">
        <Stack direction="row" spacing={3} justifyContent="center">
          {!quotesLoading &&
            quotes.map((quote, index) => (
              <Stack direction="column" alignItems="center" key={quote.id}>
                <Button
                  variant="outlined"
                  onClick={() => onSelectQuote?.(quote.id)}
                  sx={{
                    backgroundColor: quote.selected ? 'success.main' : '',
                    borderColor: 'grey.50',
                    borderRadius: 9,
                    color: quote.selected ? 'grey.00' : 'secondary.main',
                    fontWeight: 600,
                    height: '31px',
                    mb: 3,
                    width: '111px',
                    '&:hover': {
                      color: 'secondary.main',
                    },
                  }}
                >
                  {!quote.selected
                    ? t('common.labels.select', 'Select')
                    : t('common.labels.unselect', 'Unselect')}
                </Button>
                <SummaryCard quote={quote} highlighted={quote.selected}>
                  <DisclosurePlaceholder />
                </SummaryCard>
              </Stack>
            ))}
        </Stack>
      </Box>
      {actionBar}
    </Box>
  );
}
