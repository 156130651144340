import { AnimatedLoader } from './AnimatedLoader';
import { BackgroundSwoop } from './BackgroundSwoop';
import { CalendarDateRange } from './CalendarDateRange';
import { CheckMark } from './CheckMark';
import { Close } from './Close';
import { Document } from './Document';
import { DownArrow } from './DownArrow';
import { Download } from './Download';
import { Help } from './Help';
import { Home } from './Home';
import { Homes } from './Homes';
import { HouseOutline } from './HouseOutline';
import { LeftArrow } from './LeftArrow';
import { LightBulb } from './LightBulb';
import { Logo } from './Logo';
import { OutlinedRightArrow } from './OutlinedRightArrow';
import { Paper } from './Paper';
import { Pencil } from './Pencil';
import { Person } from './Person';
import { Plus } from './Plus';
import { PowerOff } from './PowerOff';
import { RightArrow } from './RightArrow';
import { Search } from './Search';
import { Share } from './Share';
import { StyledSVGProps } from './StyledSVG';
import { Toggles } from './Toggles';
import { TrashCan } from './TrashCan';
import { Upload } from './Upload';

export const SVG_COMPONENTS_TABLE = {
  AnimatedLoader: AnimatedLoader,
  BackgroundSwoop: BackgroundSwoop,
  CalendarDateRange: CalendarDateRange,
  CheckMark: CheckMark,
  Close: Close,
  Document: Document,
  DownArrow: DownArrow,
  Download: Download,
  Help: Help,
  Home: Home,
  Homes: Homes,
  HouseOutline: HouseOutline,
  LeftArrow: LeftArrow,
  LightBulb: LightBulb,
  Logo: Logo,
  OutlinedRightArrow: OutlinedRightArrow,
  Paper: Paper,
  Pencil: Pencil,
  Person: Person,
  Plus: Plus,
  PowerOff: PowerOff,
  RightArrow: RightArrow,
  Search: Search,
  Share: Share,
  Toggles: Toggles,
  TrashCan: TrashCan,
  Upload: Upload,
};

interface IProps extends StyledSVGProps {
  name: keyof typeof SVG_COMPONENTS_TABLE;
}

export function SVG({ name, ...props }: IProps) {
  const Component = SVG_COMPONENTS_TABLE[name];
  return <Component {...props} />;
}
