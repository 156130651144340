import { useMemo } from 'react';

import { formatAddress, formatDate } from '@/helpers/formatters';
import { fullNameSelector } from '@/helpers/selectors/user';
import { useQuoterStore } from '@/hooks/useQuoterStore';

import { QuoteStepper } from './QuoteStepper';

export function QuoteStepperContainer() {
  const { input } = useQuoterStore();

  const fullName = fullNameSelector(input?.selectClient);
  const address = input?.selectProperty?.address;
  const formattedAddress = address ? formatAddress(address) : '';
  const appId = '8ba106d7-9d';

  const formattedStartDate = useMemo(() => {
    return input?.startDate ? formatDate(input.startDate) : '';
  }, [input.startDate]);

  return (
    <QuoteStepper
      appId={appId}
      address={formattedAddress}
      name={fullName}
      startDate={formattedStartDate}
    />
  );
}
