import {
  AuthorizeDocument,
  ChangePasswordDocument,
  ConfirmUserDocument,
  ForgotPasswordDocument,
  LoginDocument,
  RegisterUserDocument,
  ResendConfirmDocument,
  ResetPasswordDocument,
} from '@/graphql/generated';
import { generateEndpoint } from '@/helpers';

export const authorizeEndpoint = generateEndpoint(AuthorizeDocument);
export const changePasswordEndpoint = generateEndpoint(ChangePasswordDocument);
export const confirmUserEndpoint = generateEndpoint(ConfirmUserDocument);
export const forgotPasswordEndpoint = generateEndpoint(ForgotPasswordDocument);
export const loginEndpoint = generateEndpoint(LoginDocument);
export const registerUserEndpoint = generateEndpoint(RegisterUserDocument);
export const resendConfirmEndpoint = generateEndpoint(ResendConfirmDocument);
export const resetPasswordEndpoint = generateEndpoint(ResetPasswordDocument);
