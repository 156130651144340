import { TypographyOptions } from '@mui/material/styles/createTypography';

import { baseTheme } from './base';

const primaryFontFamily = "'Roobert', sans-serif";
const primaryFontLineHeight = 1.2;

export enum FontWeight {
  light = 300,
  regular = 400,
  medium = 500,
  semibold = 600,
  bold = 700,
}

export const typography: TypographyOptions = {
  fontFamily: primaryFontFamily,
  h1: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1.75rem',
    fontWeight: FontWeight.semibold,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  h2: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1.3125rem',
    fontWeight: FontWeight.semibold,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  h3: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1.5rem',
    fontWeight: FontWeight.regular,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  h4: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1.5rem',
    fontWeight: FontWeight.semibold,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  h5: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1rem',
    fontWeight: FontWeight.regular,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  h6: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '0.875rem',
    fontWeight: FontWeight.bold,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
    textTransform: 'none',
  },
  subtitle1: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1.125rem',
    fontWeight: FontWeight.semibold,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  subtitle2: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1.125rem',
    fontWeight: FontWeight.regular,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  body1: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1rem',
    fontWeight: FontWeight.medium,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  body2: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '0.875rem',
    fontWeight: FontWeight.medium,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  button: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '1rem',
    fontWeight: FontWeight.semibold,
    letterSpacing: 'normal',
    lineHeight: 1.714,
    textTransform: 'none',
  },
  caption: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '0.75rem',
    fontWeight: FontWeight.regular,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
  },
  overline: {
    color: baseTheme.palette.grey[100],
    fontFamily: primaryFontFamily,
    fontSize: '0.875rem',
    fontWeight: FontWeight.regular,
    letterSpacing: 'normal',
    lineHeight: primaryFontLineHeight,
    textTransform: 'none',
  },
};
