import {
  Box,
  Container,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Status, TeamMemberRow } from '../../components/TeamMemberRow';
import { Team } from '../../graphql/generated';

import { InviteNewTeamMemberContainer } from './InviteNewTeamMember.container';

interface IProps {
  loading?: boolean;
  orgId: string;
  team?: Team[];
  userIsAdmin: boolean;
}

const getInviteStatus = (agent: Team) => {
  if (agent.disabledAt) {
    return Status.Inactive;
  } else if (!agent.userId) {
    return Status.Invited;
  } else {
    return Status.Active;
  }
};

export function MyTeamPage({ loading, orgId, team, userIsAdmin }: IProps) {
  const { t } = useTranslation();
  return (
    <Container sx={{ paddingY: 5 }}>
      <Paper sx={{ padding: 5 }} elevation={0}>
        <Typography variant="h1" lineHeight="145%">
          {t('pages.myTeamPage.yourTeam', 'Your Team')}
        </Typography>
        <Box padding={2.5} />
        {loading ? (
          <>
            <Skeleton width="100%" height={65} />
            <Skeleton width="100%" height={65} />
            <Skeleton width="100%" height={65} />
          </>
        ) : (
          <Stack spacing={2}>
            {team?.map((agent, index) => (
              <TeamMemberRow
                key={index}
                email={agent?.invitedEmail || ''}
                isPrimary={false}
                name={agent?.invitedFirstName + ' ' + agent?.invitedLastName}
                status={getInviteStatus(agent)}
              />
            ))}
          </Stack>
        )}
        {userIsAdmin && (
          <InviteNewTeamMemberContainer loading={loading} orgId={orgId} />
        )}
      </Paper>
    </Container>
  );
}
