import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { IBingAddress } from '@/store/bingMapApi/interfaces';

import { LocationAutocomplete } from '.';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  label?: string;
  name: Path<T>;
  onSelected?: (selected: Partial<IBingAddress>) => void;
  optionLabel?: keyof IBingAddress;
  placeholder?: string;
}

export function ControllerLocationAutocomplete<T extends FieldValues>({
  control,
  label = 'Address',
  name,
  onSelected,
  optionLabel = 'streetName',
  placeholder,
}: IProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      // remove ref from props since LocationAutocomplete doesn't support it
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <LocationAutocomplete
          label={label}
          optionLabel={optionLabel}
          onSelected={onSelected}
          placeholder={placeholder}
          errorMessage={error?.message}
          {...field}
        />
      )}
    />
  );
}
