import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { CustomCoverageOptionContainer } from '../../components/CustomCoverageOption';
import { Floater } from '../../components/CustomCoverageOption/Floater';
import { QuoteStepperContainer } from '../../components/QuoteStepper/QuoteStepperContainer';
import { IQuoteOption } from '../../store/quoter';

import { PREV_PAGE_ROUTE } from './QuoterCustomizeCoveragePage.container';

interface IProps {
  onAddQuote?: () => void;
  onBindQuote?: () => void;
  onRemoveQuote?: (requestedIndex: number) => void;
  onShare?: () => void;
  quoteOptionsLoading?: boolean;
  quotes: IQuoteOption[];
  onQuoteChange?: (values: IQuoteOption, index: number) => void;
}

const getSizeByQuoteCount = (quoteCount: number): ISize =>
  ['lg', 'md', 'sm'][quoteCount - 1] as ISize;

export function QuoterCustomizeCoveragePage({
  onAddQuote,
  onBindQuote,
  onQuoteChange,
  onRemoveQuote,
  onShare,
  quotes,
}: IProps) {
  const { t } = useTranslation();

  const hasMaxQuotes = quotes.length >= 3;
  const quoteCount = quotes.length > 1 ? `(${quotes.length})` : '';
  const size = getSizeByQuoteCount(quotes.length);

  return (
    <Box>
      <QuoteStepperContainer />
      <Floater triggerUpdate={quotes.length} />
      <Box>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            margin: 'auto',
            pt: 4.25,
            width: quotes.length === 1 ? '960px' : '1300px',
          }}
        >
          <Grid item xs></Grid>
          <Grid item xs={12} sm={8}>
            <PageTitle
              title={t(
                'pages.customizeCoveragePage.title',
                'Customize Coverage',
              )}
            >
              <Typography
                mt={2}
                sx={{ lineHeight: 1.5, maxWidth: '644px', color: 'grey.80' }}
              >
                {t(
                  'pages.customizeCoveragePage.description',
                  'Please customize the terms of coverage below. Please note that the descriptions of coverage provided are general in nature and that terms vary by policy. Refer to the terms and conditions of the policy for specific coverage information.',
                )}
              </Typography>
            </PageTitle>
          </Grid>
          <Grid item xs alignSelf="flex-start" pt="15px">
            {!hasMaxQuotes ? (
              <Button
                sx={{
                  color: 'grey.70',
                  borderColor: 'grey.70',
                  width: '160px',
                }}
                onClick={onAddQuote}
                startIcon={<AddCircleOutlineIcon />}
                variant="dashed"
              >
                {t('common.labels.addOption', 'Add option')}
              </Button>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="center"
          spacing={!hasMaxQuotes ? 6 : 4}
        >
          {quotes.map((quote, index) => (
            <CustomCoverageOptionContainer
              key={quote.id}
              onChange={(values) => onQuoteChange?.(values, index)}
              onRemoveQuote={onRemoveQuote}
              optionNumber={index}
              quote={quote}
              size={size}
            />
          ))}
        </Stack>
      </Box>
      <QuoterActionBarContainer
        backText={t('pages.reviewPropertyDataPage.title')}
        onBack={PREV_PAGE_ROUTE}
      >
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            size="small"
            sx={{ fontSize: '1rem' }}
            variant="outlined"
          >
            {t('common.labels.download', 'Download {{quoteCount}}', {
              quoteCount,
            })}
          </Button>
          <Button
            color="secondary"
            onClick={onBindQuote}
            disabled={quotes.length < 1}
            size="small"
            sx={{ fontSize: '1rem' }}
            variant="outlined"
          >
            {quotes.length > 1
              ? t('common.labels.selectAndBindQuote', 'Select & Bind')
              : t('common.labels.bindQuote', 'Bind Quote')}
          </Button>
          <Button
            color="secondary"
            onClick={onShare}
            size="small"
            sx={{ fontSize: '1rem' }}
            variant="contained"
          >
            {t('common.labels.shareClient', 'Share with client')}
          </Button>
        </Stack>
      </QuoterActionBarContainer>
    </Box>
  );
}
