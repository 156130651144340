import { Box, Typography } from '@mui/material';

import { Image } from '../Image';

interface IProps {
  children: React.ReactNode;
  title?: string;
  src?: string;
}

export const SlideSubTitlte = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h4" sx={{ fontSize: '1.25rem', mb: '0.5rem' }}>
    {children}
  </Typography>
);

export function SlideContent({ children, title, src }: IProps) {
  return (
    <Box
      sx={{
        '& p': {
          color: 'grey.80',
          mb: '1rem',
        },
      }}
    >
      <Image src={src} alt="house" sx={{ maxHeight: '301px', width: '100%' }} />
      <Typography
        variant="h3"
        sx={{ fontSize: '1.75rem', fontWeight: 600, my: 2 }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
}
