import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HouseWindForcePNG } from '@/helpers/publicAssetLinks';

import { SlideContent } from './SlideContent';

export function DrawerParametricExpenseContent() {
  const { t } = useTranslation();

  return (
    <SlideContent
      title={t(
        'common.labels.additionalParametricExpense',
        'Additional Hurricane Parametric Expense',
      )}
      src={HouseWindForcePNG}
    >
      <Typography>
        {t(
          'coverage.parametricExpense.description.part1',
          `Hurricane parametric expense coverage pays any expenses incurred
          resulting from a hurricane. Thus is a parametric coverage based on
          wind speed and distance of a hurricane from your property. When
          eligible, loss is calculated based on a predefined table.`,
        )}
      </Typography>
      <Typography
        color="primary"
        component="a"
        href="https://www.arbolmarket.com/about"
        rel="noreferrer noopener"
        target="_blank"
      >
        Learn More
        <OpenInNewIcon sx={{ height: '1rem', width: '1rem' }} />
      </Typography>
    </SlideContent>
  );
}
