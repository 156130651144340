import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExistingQuoteModal } from '../../components/Modals';
import { redirects } from '../../config/redirects';
import { User } from '../../graphql/generated';
import { getQuoterInput, getTranslatedErrorMessage } from '../../helpers';
import { quoterStoreToLastRoute } from '../../helpers/quoter';
import { fullNameSelector } from '../../helpers/selectors/user';
import { useAuth } from '../../hooks';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { useCreateClientUserMutation, useGetUsersQuery } from '../../store/api';
import { IQuoterInput } from '../../store/quoter';

import { QuoterSelectClientPage } from './QuoterSelectClientPage';
import { IQuoterSelectClientSchema } from './QuoterSelectClientPage.schema';

const NEXT_PAGE_ROUTE = '/quoter/select-property';

export function QuoterSelectClientPageContainer() {
  // hooks
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setInput, reset, input } = useQuoterStore();
  const [previousInput, setPreviousInput] = useState<IQuoterInput | null>(null);

  const { data: clientListResponse, isLoading: clientListLoading } =
    useGetUsersQuery({});

  const [
    createClientMutate,
    { error: createUsererror, isLoading: creatingUserLoading },
  ] = useCreateClientUserMutation();

  // computed values
  const clientOptions = useMemo(() => {
    if (clientListResponse) {
      const userId = user?._id;

      return clientListResponse.getUsers?.items
        ?.map((user) => ({
          label: `${user?.profile?.firstName || '--'} ${
            user?.profile?.lastName || '--'
          }`,
          subLabel: user.email,
          value: user,
        }))
        .filter((user) => user.value._id !== userId);
    }

    return [];
  }, [clientListResponse, user]);

  const previousClientName = useMemo(
    () => fullNameSelector(previousInput?.selectClient),
    [previousInput?.selectClient],
  );

  // callbacks
  const onClientSelect = useCallback(
    (client: User) => {
      // if not same client as lingering store data, we need to clear it
      if (client._id !== input?.selectClient?._id) {
        reset();
      }

      setInput({ selectClient: client });

      const previousQuoterInput = getQuoterInput(client._id || '');

      if (previousQuoterInput) {
        setPreviousInput(previousQuoterInput);
        return;
      }

      navigate(NEXT_PAGE_ROUTE);
    },
    [input?.selectClient?._id, navigate, reset, setInput],
  );

  const onSubmitHandler = useCallback(
    async (value: IQuoterSelectClientSchema) => {
      const results = await createClientMutate({
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        phone: value.phone,
        dateOfBirth: value.dateOfBirth.toISOString(),
      });

      if ('data' in results) {
        const user = results.data?.createClientUser;

        setInput({
          selectClient: {
            ...user,
            dateOfBirth: value.dateOfBirth.toISOString(),
          },
        });

        navigate(NEXT_PAGE_ROUTE);
      }
    },
    [createClientMutate, navigate, setInput],
  );

  const onCancelHandler = useCallback(() => {
    reset();

    // redirect to signed in user's dashboard
    navigate(redirects.protectedRoute);
  }, [navigate, reset]);

  const createClientErrorMessage = getTranslatedErrorMessage(createUsererror);

  return (
    <Fragment>
      <QuoterSelectClientPage
        clientOptions={clientOptions || []}
        clientOptionsLoading={clientListLoading}
        isSubmitting={creatingUserLoading}
        errorMessage={createClientErrorMessage}
        onCancel={onCancelHandler}
        onClientSelect={onClientSelect}
        onSubmit={onSubmitHandler}
      />
      <ExistingQuoteModal
        createdAt={previousInput?.createdAt}
        address={previousInput?.selectProperty?.address}
        name={previousClientName}
        open={!!previousInput}
        onCancel={() => setPreviousInput(null)}
        onCreateNewQuote={() => {
          reset({
            selectClient: input?.selectClient,
          });
          navigate(NEXT_PAGE_ROUTE);
        }}
        onUseExistingQuote={() => {
          if (previousInput) {
            setInput(previousInput);
            const lastRoute = quoterStoreToLastRoute(previousInput);
            navigate(lastRoute);
          } else {
            navigate(NEXT_PAGE_ROUTE);
          }
        }}
      />
    </Fragment>
  );
}
