import { Box, BoxProps } from '@mui/material';

export function HorizontalRuler(props: BoxProps<'hr'>) {
  return (
    <Box
      component="hr"
      border="none"
      borderTop="1px solid"
      borderColor="grey.40"
      margin={0}
      {...props}
    />
  );
}
