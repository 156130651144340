import { useCallback, useState } from 'react';

import { useAuth } from '../../hooks';
import { useUpdateUserMutation } from '../../store/api';

import { IUserInfoSchema } from './MyInformationpage.schema';
import { UserInfo } from './UserInfo';

export function UserInfoContainer() {
  const { user } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);

  const initialModalValues = {
    firstName: user?.profile?.firstName || '',
    lastName: user?.profile?.lastName || '',
    email: user?.email || '',
    phone: user?.profile?.phone || '',
  };

  // hooks
  const [updateUserInfoMutation, { isLoading: updateUserInfoLoading }] =
    useUpdateUserMutation();

  // handler
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const onSave = useCallback(
    async (values: IUserInfoSchema) => {
      const results = await updateUserInfoMutation({
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
        },
      });
      if ('data' in results) {
        closeDialog();
      }
    },
    [updateUserInfoMutation, closeDialog],
  );
  return (
    <UserInfo
      initialValues={initialModalValues}
      loading={updateUserInfoLoading}
      onClose={closeDialog}
      onSave={onSave}
      open={dialogOpen}
      openDialog={() => setDialogOpen(true)}
    />
  );
}
