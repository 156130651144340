import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AddPropertyMutation,
  AddPropertyMutationVariables,
  AuthorizeMutation,
  AuthorizeMutationVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  ConfirmUserMutation,
  ConfirmUserMutationVariables,
  CreateClientUserMutation,
  CreateClientUserMutationVariables,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  GetPropertiesQuery,
  GetPropertiesQueryVariables,
  GetPropertySpecsQuery,
  GetPropertySpecsQueryVariables,
  GetQuotesQuery,
  GetQuotesQueryVariables,
  GetUsersQuery,
  GetUsersQueryVariables,
  InviteOrgAgentMutation,
  InviteOrgAgentMutationVariables,
  LoginMutation,
  LoginMutationVariables,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  ResendConfirmMutation,
  ResendConfirmMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ReviewPropertyMutation,
  ReviewPropertyMutationVariables,
  UnderwriteCheckMutation,
  UnderwriteCheckMutationVariables,
  UpdateOrganizationContactMutation,
  UpdateOrganizationContactMutationVariables,
  UpdateOrganizationLocationAddressMutation,
  UpdateOrganizationLocationAddressMutationVariables,
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../../graphql/generated';

import { CacheTag } from './CacheTags';
import {
  addPropertyEndpoint,
  authorizeEndpoint,
  changePasswordEndpoint,
  confirmUserEndpoint,
  createClientUserEndpoints,
  currentUserEndpoint,
  forgotPasswordEndpoint,
  getOrganizationEndpoint,
  getPropertiesEndpoint,
  getPropertySpecsEndpoint,
  getQuotesEndpoint,
  getUserEndpoint,
  inviteOrgAgentEndpoint,
  loginEndpoint,
  registerUserEndpoint,
  resendConfirmEndpoint,
  resetPasswordEndpoint,
  reviewPropertyEndpoint,
  underwriteCheckEndpoint,
  updateOrganizationContactEndpoint,
  updateOrganizationLocationAddressEndpoint,
  updatePropertyEndpoint,
  updateUserEndpoint,
} from './endpoints';
import { graphqlRequestQuery } from './graphqlRequestQuery';

export const api = createApi({
  baseQuery: graphqlRequestQuery,
  reducerPath: 'api',
  tagTypes: [...Object.values(CacheTag)],
  endpoints: (builder) => ({
    addProperty: builder.mutation<
      AddPropertyMutation,
      AddPropertyMutationVariables
    >({
      ...addPropertyEndpoint,
    }),
    authorize: builder.mutation<AuthorizeMutation, AuthorizeMutationVariables>({
      ...authorizeEndpoint,
    }),
    changePassword: builder.mutation<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >({ ...changePasswordEndpoint }),
    confirmUser: builder.mutation<
      ConfirmUserMutation,
      ConfirmUserMutationVariables
    >({
      ...confirmUserEndpoint,
    }),
    createClientUser: builder.mutation<
      CreateClientUserMutation,
      CreateClientUserMutationVariables
    >({
      ...createClientUserEndpoints,
    }),
    currentUser: builder.query<CurrentUserQuery, CurrentUserQueryVariables>({
      ...currentUserEndpoint,
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >({
      ...forgotPasswordEndpoint,
    }),
    getOrganization: builder.query<
      GetOrganizationQuery,
      GetOrganizationQueryVariables
    >({
      ...getOrganizationEndpoint,
    }),
    getProperties: builder.query<
      GetPropertiesQuery,
      GetPropertiesQueryVariables
    >({
      ...getPropertiesEndpoint,
    }),
    getPropertySpecs: builder.query<
      GetPropertySpecsQuery,
      GetPropertySpecsQueryVariables
    >({
      ...getPropertySpecsEndpoint,
    }),
    getQuotes: builder.query<GetQuotesQuery, GetQuotesQueryVariables>({
      ...getQuotesEndpoint,
    }),
    getUsers: builder.query<GetUsersQuery, GetUsersQueryVariables>({
      ...getUserEndpoint,
    }),
    inviteOrgAgent: builder.mutation<
      InviteOrgAgentMutation,
      InviteOrgAgentMutationVariables
    >({
      ...inviteOrgAgentEndpoint,
    }),
    login: builder.mutation<LoginMutation, LoginMutationVariables>({
      ...loginEndpoint,
    }),
    registerUser: builder.mutation<
      RegisterUserMutation,
      RegisterUserMutationVariables
    >({
      ...registerUserEndpoint,
    }),
    resendConfirm: builder.mutation<
      ResendConfirmMutation,
      ResendConfirmMutationVariables
    >({
      ...resendConfirmEndpoint,
    }),
    resetPassword: builder.mutation<
      ResetPasswordMutation,
      ResetPasswordMutationVariables
    >({
      ...resetPasswordEndpoint,
    }),
    reviewProperty: builder.mutation<
      ReviewPropertyMutation,
      ReviewPropertyMutationVariables
    >({
      ...reviewPropertyEndpoint,
    }),
    underwriteCheck: builder.mutation<
      UnderwriteCheckMutation,
      UnderwriteCheckMutationVariables
    >({
      ...underwriteCheckEndpoint,
    }),
    updateOrganizationContact: builder.mutation<
      UpdateOrganizationContactMutation,
      UpdateOrganizationContactMutationVariables
    >({
      ...updateOrganizationContactEndpoint,
    }),
    updateOrganizationLocationAddress: builder.mutation<
      UpdateOrganizationLocationAddressMutation,
      UpdateOrganizationLocationAddressMutationVariables
    >({
      ...updateOrganizationLocationAddressEndpoint,
    }),
    updateProperty: builder.mutation<
      UpdatePropertyMutation,
      UpdatePropertyMutationVariables
    >({
      ...updatePropertyEndpoint,
    }),
    updateUser: builder.mutation<
      UpdateUserMutation,
      UpdateUserMutationVariables
    >({
      ...updateUserEndpoint,
    }),
  }),
});
