import {
  DataGridProps,
  GridCallbackDetails,
  GridColDef,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';

import { StyledDataTable } from './StyledDataTable';

interface IProps extends DataGridProps {
  columns: GridColDef[];
  isLoading?: boolean;
  handleSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onSelectionModelChange?: (model: GridSelectionModel) => void;
  page?: number;
  pageSize?: number;
  rowCount: number;
}

export function QuotesDataTable({
  columns,
  handleSortModelChange,
  isLoading,
  onPageChange,
  onSelectionModelChange,
  page,
  pageSize,
  rowCount,
  rows,
  ...rest
}: IProps) {
  return (
    <StyledDataTable
      {...rest}
      autoHeight
      columns={columns}
      disableColumnMenu
      disableSelectionOnClick
      loading={isLoading}
      onPageChange={onPageChange}
      onSelectionModelChange={onSelectionModelChange}
      onSortModelChange={handleSortModelChange}
      page={page}
      pageSize={pageSize}
      paginationMode="server"
      rowCount={rowCount}
      rows={rows}
      sortingMode="server"
      sx={{
        width: '100%',
      }}
    />
  );
}
