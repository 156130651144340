import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuoterInput, IQuoterState } from './interfaces';

export const initialQuoterState: IQuoterState = {
  input: {
    startDate: undefined,
    selectClient: undefined,
    selectProperty: undefined,
    agreeToDiligentSearch: null,
    underwritingChecks: undefined,
    quotes: [],
  },
};

export const quoterSlice = createSlice({
  name: 'quoter',
  initialState: initialQuoterState,
  reducers: {
    reset: (_, action: PayloadAction<IQuoterInput | undefined>) => ({
      ...initialQuoterState,
      input: {
        ...initialQuoterState.input,
        ...action?.payload,
      },
    }),
    setInput: (state, action: PayloadAction<IQuoterInput>) => {
      const { payload } = action;
      state.input = {
        ...state.input,
        ...payload,
      };

      return state;
    },
  },
});

export const { reset, setInput } = quoterSlice.actions;
