import { Box, Button, Stack } from '@mui/material';
import {
  GridCallbackDetails,
  GridCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { BaseActionBar } from '../../components/ActionBar';
import { QuotesDataTable } from '../../components/DataTable';
import { SVG } from '../../components/SVGs/SVG';

import { quoteColumns, QuoteTableRowFormat } from './helpers';

interface IProps {
  handleSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void;
  initialValues: QuoteTableRowFormat;
  onCancel?: () => void;
  onChecked?: (value: GridSelectionModel) => void;
  onClick?: (value: GridCellParams) => void;
  onDelete?: () => void;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  page?: number;
  pageSize?: number;
  quotesLoading?: boolean;
  selectedRows?: string[];
}

export function QuotesPage({
  handleSortModelChange,
  initialValues,
  onCancel,
  onChecked,
  onClick,
  onDelete,
  onPageChange,
  page,
  pageSize,
  quotesLoading,
  selectedRows,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <QuotesDataTable
        checkboxSelection
        columns={quoteColumns}
        disableSelectionOnClick
        handleSortModelChange={handleSortModelChange}
        isLoading={quotesLoading}
        onCellClick={onClick}
        onPageChange={onPageChange}
        onSelectionModelChange={onChecked}
        page={page}
        pageSize={pageSize}
        rowCount={initialValues.length}
        rows={initialValues}
        rowsPerPageOptions={[10]}
        selectionModel={selectedRows}
        sortingMode="server"
      />
      {selectedRows && selectedRows?.length > 0 && (
        <BaseActionBar backText={null} onBack={onCancel}>
          <Stack direction="row" spacing={2}>
            <Button
              color="error"
              onClick={onDelete}
              size="small"
              variant="contained"
              startIcon={<SVG name="TrashCan" width="15px" />}
              sx={{ fontSize: '1rem' }}
            >
              {t(
                'common.labels.deleteSelectedQuotes',
                'Delete selected quotes',
              )}
            </Button>
          </Stack>
        </BaseActionBar>
      )}
    </Box>
  );
}
