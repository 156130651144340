import {
  CreateClientUserDocument,
  CurrentUserDocument,
  GetUsersDocument,
  GetUsersQuery,
  UpdateUserDocument,
} from '@/graphql/generated';
import { generateEndpoint, getGenericProvidedTages } from '@/helpers';

import { CacheTag } from '../CacheTags';

export const createClientUserEndpoints = {
  ...generateEndpoint(CreateClientUserDocument),
  invalidatesTags: [CacheTag.USER],
};

export const currentUserEndpoint = {
  ...generateEndpoint(CurrentUserDocument),
  providesTags: [CacheTag.CURRENT_USER],
};

export const getUserEndpoint = {
  ...generateEndpoint(GetUsersDocument),
  providesTags: getGenericProvidedTages<GetUsersQuery>(
    'getUsers',
    CacheTag.USER,
  ),
};

export const updateUserEndpoint = {
  ...generateEndpoint(UpdateUserDocument),
  invalidatesTags: [CacheTag.CURRENT_USER],
};
