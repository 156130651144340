import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function AnimatedLoader(props: StyledSVGProps) {
  return (
    <StyledSVG
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.875s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(45 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.75s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(90 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.625s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(135 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(180 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.375s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(225 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.25s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(270 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.125s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47"
        y="23.5"
        rx="3"
        ry="3.25"
        width="6"
        height="13"
        fill="#0d1329"
        transform="rotate(315 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </StyledSVG>
  );
}
