import { Box, Chip, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[10],
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: theme.spacing(),
}));

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
  Invited = 'invited',
}

const StatusStyles = {
  active: {
    color: '#366B24',
    backgroundColor: 'rgba(89, 230, 155, 0.27)',
    text: 'Active',
  },
  inactive: {
    color: 'error.dark',
    backgroundColor: 'rgba(251, 137, 130, 0.31)',
    text: 'Retired',
  },
  invited: {
    color: 'warning.dark',
    backgroundColor: 'rgba(241, 187, 111, 0.2)',
    text: 'Invite Sent',
  },
};

interface IProps {
  email: string;
  isPrimary: boolean;
  name: string;
  status: Status;
}

export function TeamMemberRow({ email, isPrimary, name, status }: IProps) {
  return (
    <Container direction="row" px={2} py={1}>
      <Stack>
        <Typography fontSize="1.0625rem" lineHeight="1.45rem">
          {name}
        </Typography>
        <Typography variant="body2" color="grey.80" lineHeight="1.45rem">
          {email}
        </Typography>
      </Stack>
      <Box flex={2} px={5}>
        <Chip
          label={
            <Typography
              variant="body2"
              lineHeight="1.45rem"
              color={StatusStyles[status].color}
            >
              {StatusStyles[status].text}
            </Typography>
          }
          sx={{
            backgroundColor: StatusStyles[status].backgroundColor,
            borderRadius: 1,
          }}
        />
      </Box>
      <Typography
        variant="body2"
        lineHeight="1.45rem"
        color="#1E67AB"
        fontWeight="600"
      >
        {`${isPrimary ? 'Primary ' : ''}Agent`}
      </Typography>
    </Container>
  );
}
