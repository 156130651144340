import { ListItemButton, Skeleton } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import List from '@mui/material/List';

import { BasicInfoCard } from '../BasicInfoCard';

export interface IStackedListOption<T> {
  label: string;
  subLabel: string;
  value: T;
}
interface IProps<T> extends BoxProps {
  options: IStackedListOption<T>[];
  loading?: boolean;
  onSelected?: (value: T) => void;
  searchPlaceholder?: string;
}

export function StackedList<T>({
  options,
  loading,
  onSelected,
  ...boxProps
}: IProps<T>) {
  return (
    <Box {...boxProps}>
      <List disablePadding>
        {loading ? (
          <Box>
            <Skeleton height={80} width="100%" />
            <Skeleton height={80} width="100%" />
            <Skeleton height={80} width="100%" />
          </Box>
        ) : (
          options.map((option, index) => (
            <ListItemButton
              key={index}
              disableRipple
              disableTouchRipple
              disableGutters
              onClick={() => {
                if (onSelected) {
                  onSelected(option.value);
                }
              }}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <BasicInfoCard title={option.label}>
                {option.subLabel}
              </BasicInfoCard>
            </ListItemButton>
          ))
        )}
      </List>
    </Box>
  );
}
