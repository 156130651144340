import { Avatar } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';

import { formatDate } from '../../helpers/formatters';
import { IQuoteOption } from '../../store/quoter';

// TODO: integrate sortability with real data, api needs to support these string paths
export const sortByMap = {
  clientName: 'customerCounterparty.profile.lastName',
  createdAt: 'createdAt',
  address: 'coverageStructure.property.address.address1',
  agent: 'salesAgent.profile.lastName',
};

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: 'grey.20',
      color: 'secondary.main',
      fontSize: '0.9375rem',
      fontWeight: 600,
      height: '28px',
      width: '28px',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

// TODO: integrate with real data
export function rowFormatter(rows: IQuoteOption[], quoteId?: string) {
  return rows.map((row) => ({
    ...row,
    createdAt: formatDate(new Date()),
    id: row.id,
    clientName: 'Damien Maya',
    address: '3551 NW 36th Ave',
    premium: t('common.currency.USD', '{{value, currency(currency: USD)}}', {
      value: row.prices?.fullPremium,
    }),
    dwelling: t('common.currency.USD', '{{value, currency(currency: USD)}}', {
      value: row.mainHomeLimit,
    }),
    parametricLimit: t(
      'common.currency.USD',
      '{{value, currency(currency: USD)}}',
      {
        value: row.mainHomeLimit,
      },
    ),
    status: 'Status',
    agent: 'John Coleman',
    quoteId: quoteId,
  }));
}

export type QuoteTableRowFormat = ReturnType<typeof rowFormatter>;

export const quoteColumns: GridColDef[] = [
  {
    align: 'center',
    field: 'createdAt',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'CREATED ON',
    minWidth: 130,
    type: 'date',
  },
  {
    align: 'left',
    field: 'id',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'ID',
    minWidth: 90,
  },
  {
    align: 'left',
    field: 'clientName',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'CLIENT',
    minWidth: 150,
  },
  {
    align: 'left',
    field: 'address',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'ADDRESS',
    minWidth: 170,
  },
  {
    align: 'left',
    field: 'premium',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'PREMIUM',
    minWidth: 150,
  },
  {
    align: 'left',
    field: 'dwelling',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'DWELLING',
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'parametricLimit',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'PARAMETRIC LIMIT',
    minWidth: 150,
  },
  {
    align: 'center',
    cellClassName: 'status-cell',
    field: 'status',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'STATUS',
    minWidth: 80,
  },
  {
    align: 'center',
    field: 'agent',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'AGENT',
    minWidth: 80,
    renderCell: (params: GridRenderCellParams<string>) => {
      return <Avatar {...stringAvatar(params.value || '')} />;
    },
  },
];
