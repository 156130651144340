import { Stack } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FullscreenLoader } from '../../components/Loaders';
import { fullNameSelector } from '../../helpers/selectors/user';
import { useMockDataQuery } from '../../hooks';

import { ClientActivePolicies } from './ClientActivePolicies';
import { ClientContactHeader } from './ClientContactHeader';
import { ClientQuotes } from './ClientQuotes';

const mockClientDetailsResponse = {
  client: {
    _id: '123',
    email: 'johndoe@arbol.io',
    profile: {
      firstName: 'John',
      lastName: 'Doe',
      phone: '123-456-7890',
    },
  },
  properties: [
    {
      _id: '123',
      address1: '1922 Timber Oak Road',
      address2: undefined,
      city: 'Miami',
      state: 'FL',
      zipcode: '10001',
    },
  ],
  policies: [
    {
      _id: '123456789',
      policyNumber: '9c41e4c4-b8',
      startDate: '2022-04-01T00:00:00.000+00:00',
      endDate: '2023-04-01T00:00:00.000+00:00',
      status: 'Policy In-Force',
      nickname: 'Home',
    },
    {
      _id: '987654321',
      policyNumber: '3a88v1h0-b8',
      startDate: '2022-07-01T00:00:00.000+00:00',
      endDate: '2023-07-01T00:00:00.000+00:00',
      status: 'Policy In-Force',
      nickname: 'Rental',
    },
  ],
  quotes: [
    {
      _id: '2b4d7c22-33',
      coverage: '1509.32',
      parametric: '25000.00',
      startDate: '2022-12-10',
      status: 'Saved',
      createdAt: '2022-12-01',
    },
    {
      _id: '8ba106d7-9d',
      coverage: '1509.32',
      parametric: '25000.00',
      startDate: '2022-12-10',
      status: 'Coverage Bound',
      createdAt: '2022-12-01',
    },
    {
      _id: 'b5a0a057-e7',
      coverage: '1509.32',
      parametric: '25000.00',
      startDate: '2022-12-10',
      status: 'Expired',
      createdAt: '2022-12-01',
    },
  ],
};

export function ClientDetailsContainer() {
  const { t } = useTranslation();
  const { data: clientDetails, isLoading } = useMockDataQuery({
    mockDelay: 1000,
    mockResponse: mockClientDetailsResponse,
  });

  return (
    <Stack
      flex={1}
      sx={{
        height: '100%',
        backgroundColor: 'grey.20',
        borderRadius: '0px 16px 16px 0px',
        overflowY: 'auto',
      }}
      spacing={4}
    >
      {isLoading || !clientDetails ? (
        <FullscreenLoader>
          {t(
            'pages.clientsPage.loadingClientDetails',
            'Loading client details...',
          )}
        </FullscreenLoader>
      ) : (
        <Fragment>
          <ClientContactHeader
            fullName={fullNameSelector(clientDetails?.client, '--')}
            phone={clientDetails?.client?.profile?.phone}
            email={clientDetails?.client?.email}
            properties={clientDetails?.properties}
          />
          <ClientActivePolicies policies={clientDetails?.policies} />
          <ClientQuotes quotes={clientDetails?.quotes || []} />
        </Fragment>
      )}
    </Stack>
  );
}
