import { z } from 'zod';

export function getForgotPasswordPageSchema() {
  return z.object({
    email: z
      .string()
      .min(1, 'email is required')
      .email('email must be a valid email address'),
  });
}

export type IForgotPasswordPageSchema = z.infer<
  ReturnType<typeof getForgotPasswordPageSchema>
>;
