import { z } from 'zod';

import i18n from '../../config/i18n';

export function getQuoterUnderwritingChecksSchema() {
  return z
    .object({
      condemnedProperty: z.string(),
      farmOrRanch: z.string(),
      commercialProperty: z.string(),
      homemadeOrRebuilt: z.string(),
      nonHabitational: z.string(),
      fraternityOrSorority: z.string(),
      fourOrMoreMortgages: z.string(),
      underConstruction: z.string(),
      mobileOrPrefabricatedHome: z.string(),
      divingBoard: z.string(),
      businessProperty: z.string(),
      misrepresentationArsonOrFraud: z.string(),
    })
    .refine(
      (data) =>
        Object.values(data).every(
          (value) => value === 'true' || value === 'false',
        ),
      { message: 'Invalid form' },
    );
}

export type IQuoterUnderwritingChecksSchema = z.infer<
  ReturnType<typeof getQuoterUnderwritingChecksSchema>
>;

export function getUnderwriteCheckOptions(): Array<{
  group: 'primary' | 'secondary' | 'classification';
  label: string;
  name: keyof IQuoterUnderwritingChecksSchema;
}> {
  return [
    // Primary Property Details
    {
      label: i18n.t(
        'common.labels.isCondemnedProperty',
        'Is this property a condemned property?',
      ),
      name: 'condemnedProperty',
      group: 'primary',
    },
    {
      label: i18n.t(
        'common.labels.isPropertyFarm',
        'Is this property a farm or ranch?',
      ),
      name: 'farmOrRanch',
      group: 'primary',
    },
    {
      label: i18n.t(
        'common.labels.isCommercialProperty',
        'Is this property a commercial property?',
      ),
      name: 'commercialProperty',
      group: 'primary',
    },
    {
      label: i18n.t(
        'common.labels.isRebuiltProperty',
        'Is this a homemade or rebuilt property?',
      ),
      name: 'homemadeOrRebuilt',
      group: 'primary',
    },
    {
      label: i18n.t(
        'common.labels.isPropertyNonHabitational',
        'Is this property non-habitational?',
      ),
      name: 'nonHabitational',
      group: 'primary',
    },
    {
      label: i18n.t(
        'common.labels.isPropertyFraternityOrSorority',
        'Is this property a fraternity or sorority or any similar housing arrangement?',
      ),
      name: 'fraternityOrSorority',
      group: 'primary',
    },

    // Secondary Property Details
    {
      label: i18n.t(
        'common.labels.fourOrMoreMortgages',
        'Does this property have four or more mortgages?',
      ),
      name: 'fourOrMoreMortgages',
      group: 'secondary',
    },
    {
      label: i18n.t(
        'common.labels.isPropertyUnderConstruction',
        'Is this dwelling under construction with a completion date more than 30 days out?',
      ),
      name: 'underConstruction',
      group: 'secondary',
    },
    {
      label: i18n.t(
        'common.labels.isPropertyMobileHomeOrPrefabricatedHome',
        'Is this property a mobile home or prefabricated home?',
      ),
      name: 'mobileOrPrefabricatedHome',
      group: 'secondary',
    },
    {
      label: i18n.t(
        'common.labels.doesPropertyHavePoolWithDivingBoard',
        'Does this property have a swimming with a diving board?',
      ),
      name: 'divingBoard',
      group: 'secondary',
    },

    // Property Classification
    {
      label: i18n.t(
        'common.labels.isBusinessProperty',
        'Properties where a business is conducted?',
      ),
      name: 'businessProperty',
      group: 'classification',
    },
    {
      label: i18n.t(
        'common.labels.isMisrepresentationArsonOrFraud',
        'Material Misrepresentation, Arson or Insurance Fraud (Client data)?',
      ),
      name: 'misrepresentationArsonOrFraud',
      group: 'classification',
    },
  ];
}
