import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ControllerPasswordField } from '../../components/Inputs';

import {
  getResetPasswordPageSchema,
  IResetPasswordPageSchema,
} from './ResetPasswordPage.schema';

interface IProps {
  errorMessage?: string;
  isLoading?: boolean;
  onSubmit: SubmitHandler<IResetPasswordPageSchema>;
}

export function PasswordResetPage({
  errorMessage,
  isLoading,
  onSubmit,
}: IProps) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(getResetPasswordPageSchema()),
  });

  return (
    <Paper elevation={0} sx={{ maxWidth: 500, px: 4, py: 4, width: '100%' }}>
      <Typography textAlign="center" variant="h2">
        Reset Password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        marginX="auto"
        marginTop="20px"
        minWidth={300}
        maxWidth={400}
        width="100%"
      >
        <Stack spacing={2} marginBottom={4}>
          <ControllerPasswordField
            control={control}
            name="password"
            label="New Password"
            fullWidth
          />
          <ControllerPasswordField
            control={control}
            name="confirmPassword"
            label="Confirm Password"
            fullWidth
          />
          {errorMessage && (
            <Typography marginBottom={1} color="error">
              {errorMessage}
            </Typography>
          )}
        </Stack>
        <Button
          color="secondary"
          disabled={isLoading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </Box>
    </Paper>
  );
}
