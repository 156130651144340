import { baseTheme } from './base';

// MUI AutoComplete overrides
const borderRadius = baseTheme.spacing(1);

const popperPaperBaseStlye = {
  borderRadius: '0',
  '::before': {
    content: '""',
    backgroundColor: baseTheme.palette.success.light,
    height: '1px',
    left: '20px',
    position: 'absolute',
    right: '20px',
  },
};

export const popperPaperDirectionBottomStyles = {
  ...popperPaperBaseStlye,
  borderTop: 'none',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  top: '-6px',
  '::before': {
    ...popperPaperBaseStlye['::before'],
    top: '1px',
  },
};

export const popperPaperDirectionTopStyles = {
  ...popperPaperBaseStlye,
  borderBottom: 'none',
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  top: '6px',
  '::before': {
    ...popperPaperBaseStlye['::before'],
    bottom: '1px',
  },
};

export const muiAutocompleteOverrides = {
  inputRoot: {
    padding: '18px 4px 2px 8px',
    '&.Mui-focused': {
      borderRadius: borderRadius,
      borderColor: baseTheme.palette.success.light,
    },
  },
  paper: {
    borderColor: baseTheme.palette.success.light,
    borderRadius: baseTheme.spacing(1),
    borderStyle: 'solid',
    borderWidth: '2px',
    position: 'relative' as any,
  },
  popper: {
    "&[data-popper-placement='bottom']": {
      '.MuiAutocomplete-paper': {
        ...popperPaperDirectionBottomStyles,
      },
    },
    "&[data-popper-placement='top']": {
      '& .MuiAutocomplete-paper': {
        ...popperPaperDirectionTopStyles,
      },
    },
  },
};
