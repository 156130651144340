import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function DownArrow(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 7"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.615.554a.8.8 0 0 1 1.13-.039l4.598 4.29L10.94.516a.8.8 0 1 1 1.091 1.17l-5.142 4.8a.8.8 0 0 1-1.092 0l-5.143-4.8A.8.8 0 0 1 .615.554Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
