import { Button, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FontWeight } from '@/config/theme/typography';

export function MidtermEndorsementSuccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Container
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 11,
        width: '782px',
      }}
    >
      <Stack gap={2}>
        <Typography variant="h1" fontSize="40px" lineHeight="56px">
          {t('pages.midtermEndorsementsSuccessPage.title', 'Success!')}
        </Typography>
        <Typography variant="subtitle2" lineHeight="24.65px">
          {t(
            'pages.midtermEndorsementsSuccessPage.description1',
            'The application has been successfully sent to your client. Please ensure they received it.',
          )}
        </Typography>
        <Typography variant="subtitle2" lineHeight="24.65px">
          {t(
            'pages.midtermEndorsementsSuccessPage.description2',
            'If your client informs you they have not received the email, instruct them to check spam folders and reach out to support for more help.',
          )}
        </Typography>
      </Stack>
      <Stack gap={4} direction="row" mt={8}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ width: '194px', borderColor: 'secondary.main' }}
          onClick={() => navigate(`/policies/${id}`)}
        >
          <Typography fontWeight={FontWeight.semibold} lineHeight="145%">
            {t(
              'pages.midtermEndorsementsSuccessPage.viewPolicyDetails',
              'View policy details',
            )}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ width: '194px', borderColor: 'secondary.main' }}
          onClick={() => navigate('/')}
        >
          <Typography fontWeight={FontWeight.semibold} lineHeight="145%">
            {t(
              'pages.midtermEndorsementsSuccessPage.returnHome',
              'Return home',
            )}
          </Typography>
        </Button>
      </Stack>
    </Container>
  );
}
