import EditIcon from '@mui/icons-material/Edit';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SVG } from '@/components/SVGs/SVG';

import { Button } from '../Button';

import { CardActionBar } from './CardActionBar';

interface IProps {
  children?: React.ReactNode;
  quoteId?: string;
  status?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  onShare?: () => void;
}

export function QuoteDetailsActionBar({
  quoteId,
  status,
  onDelete,
  onEdit,
  onShare,
}: IProps) {
  const { t } = useTranslation();

  return (
    <CardActionBar
      leftSlot={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ my: 2, ml: 4 }}
        >
          <Box>
            <Typography
              sx={{ lineHeight: 1.45, color: 'grey.70', fontSize: '0.75rem' }}
            >
              {t('common.labels.quoteId', 'QUOTE ID')}
            </Typography>
            <Typography
              sx={{
                lineHeight: 1.45,
                color: 'grey.90',
                fontSize: '1.0625rem',
              }}
            >
              {quoteId}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ lineHeight: 1.45, color: 'grey.70', fontSize: '0.75rem' }}
            >
              {t('common.labels.quoteStatus', 'QUOTE STATUS')}
            </Typography>
            <Typography
              sx={{
                lineHeight: 1.45,
                color: 'grey.90',
                fontSize: '1.0625rem',
              }}
            >
              {status}
            </Typography>
          </Box>
        </Stack>
      }
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ my: 2, mr: 4 }}
      >
        <Button
          color="secondary"
          onClick={onEdit}
          variant="text"
          startIcon={<EditIcon />}
        >
          {t('common.labels.edit', 'Edit')}
        </Button>
        <Button
          color="secondary"
          onClick={onShare}
          variant="text"
          startIcon={<SVG name="Share" width={16} height={15} />}
        >
          {t('common.labels.share', 'Share')}
        </Button>
        <Button
          color="secondary"
          onClick={onDelete}
          variant="text"
          startIcon={<SVG name="TrashCan" width={16} height={15} />}
        >
          {t('common.labels.delete', 'Delete')}
        </Button>
      </Stack>
    </CardActionBar>
  );
}
