import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoIconButtonToolTip } from '@/components/Button';
import { ControllerDropdown } from '@/components/Inputs';
import { ISelectOptions } from '@/components/Inputs/interfaces';

import { ICustomizeCoverageSchema } from '../CustomizeCoverageOption.schema';
import {
  ALL_OTHER_PERILS_DEDUCTIBLE_OPTIONS,
  WATER_DEDUCTIBLE_OPTIONS,
} from '../constants';
import { formatCurrencyOptions } from '../helpers';

interface IProps {
  control: Control<ICustomizeCoverageSchema>;
  hurricaneDeductibleOptions: Array<ISelectOptions<number>>;
  size: 'sm' | 'md' | 'lg';
  onInfoClick?: () => void;
}

export function DeductibleSection({
  control,
  hurricaneDeductibleOptions,
  size,
  onInfoClick,
}: IProps) {
  const { t } = useTranslation();

  const waterDamageLimit = useWatch({
    control,
    name: 'waterDamageLimit',
  });

  const allOtherPerilsDeductibleOptions = useMemo(
    () => formatCurrencyOptions(ALL_OTHER_PERILS_DEDUCTIBLE_OPTIONS),
    [],
  );
  const waterDeductible = useMemo(
    () => formatCurrencyOptions(WATER_DEDUCTIBLE_OPTIONS),
    [],
  );

  const hasWaterDamageLimit = waterDamageLimit && Number(waterDamageLimit) > 0;

  const waterDeductibleTooltip = !hasWaterDamageLimit
    ? t(
        'coverage.deductibles.waterTooltip.disabled',
        'Disabled when "Water Damage" is excluded',
      )
    : '';

  return (
    <Box marginTop={{ sm: 5, xs: 4 }} pb={5}>
      <Typography
        variant="h2"
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingBottom: { xs: 2, sm: 1.5 },
        }}
      >
        {t('pages.customizeCoveragePage.deductibleTitle', 'Deductibles')}
        <InfoIconButtonToolTip onClick={onInfoClick} />
      </Typography>
      <Grid
        container
        direction={size === 'lg' ? 'row' : 'column'}
        spacing={size === 'lg' ? 3 : 1}
      >
        <Grid item xs={6}>
          <ControllerDropdown
            fullWidth
            control={control}
            label={t('common.labels.allOtherPerils', 'All Other Perils')}
            name="allOtherPerilsDeductible"
            options={allOtherPerilsDeductibleOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerDropdown
            fullWidth
            control={control}
            label={t('common.labels.hurricane', 'Hurricane')}
            name="hurricaneDeductible"
            options={hurricaneDeductibleOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Tooltip title={waterDeductibleTooltip}>
            <Box
            // Box used to forward ref for Tooltip
            >
              <ControllerDropdown
                disabled={!hasWaterDamageLimit}
                fullWidth
                control={control}
                label={t('common.labels.water', 'Water')}
                name="waterDeductible"
                options={waterDeductible}
              />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
}
