import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';

import { BaseModal } from '../BaseModal';

interface IProps {
  children?: React.ReactNode;
  hideCloseButton?: boolean;
  onClose?: () => void;
  open: boolean;
  title: string;
}

export function SignatureReceivedModal({
  children,
  hideCloseButton = true,
  onClose,
  open,
  title,
}: IProps) {
  const { t } = useTranslation();

  return (
    <BaseModal
      hideCloseButton={hideCloseButton}
      onClose={onClose}
      open={open}
      title={title}
      PaperProps={{
        sx: {
          width: '450px',
        },
      }}
      actionBar={
        <Button
          color="secondary"
          onClick={onClose}
          variant="contained"
          sx={{ width: '152px' }}
        >
          {t('common.actions.continue', 'Continue')}
        </Button>
      }
    >
      {children}
    </BaseModal>
  );
}
