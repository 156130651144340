import {
  Box,
  BoxProps,
  Step,
  StepLabelProps,
  Stepper,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { CustomStepIcon, LastIcon } from './CustomStepIcon';
import {
  StyledConnector,
  StyledStepContent,
  StyledStepLabel,
} from './StyledStepperComps';

export interface IStep extends StepLabelProps {
  label: string;
  description?: string;
}

interface IProps {
  steps: IStep[];
  activeStep: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  title: string;
}

export function VerticalProgressStepper({
  activeStep,
  steps,
  title,
  ...props
}: IProps & BoxProps) {
  return (
    <Box {...props}>
      <Typography
        sx={{ fontWeight: 600, fontSize: '1.75rem', lineHeight: 1.45 }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '0.8125rem',
          color: 'grey.80',
          lineHeight: 1.45,
          pb: 4,
        }}
      >
        {`${activeStep} of ${steps.length} Steps Completed`}
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StyledConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index} expanded={!!step.description}>
            {index !== steps.length - 1 ? (
              <StyledStepLabel StepIconComponent={CustomStepIcon}>
                {step.label}
              </StyledStepLabel>
            ) : (
              <StyledStepLabel StepIconComponent={LastIcon}>
                {step.label}
              </StyledStepLabel>
            )}
            <StyledStepContent>
              <Typography
                sx={{
                  color: 'grey.70',
                  fontSize: '0.8125rem',
                  fontWeight: 400,
                  paddingLeft: 1,
                  marginTop: -1.2,
                }}
              >
                {step.description}
              </Typography>
            </StyledStepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
