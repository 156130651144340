import { Stack, styled, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { SearchBar } from '../../components/Inputs';
import { SVG } from '../../components/SVGs/SVG';
import { IStackedListOption } from '../../components/StackedList';
import { theme } from '../../config';
import { User } from '../../graphql/generated';
import { getFuseResults } from '../../helpers/fuse';

const StyledSearchBar = styled(SearchBar)`
  border-radius: 33px;
  padding: 4px 16px;
  background-color: ${theme.palette.grey[20]};

  & .MuiInput-root:before,
  & .MuiInput-root:hover:not(.Mui-disabled):before,
  & .MuiInput-root.Mui-focused:before {
    border-bottom: none;
  }
`;

const Container = styled(Stack)(({ theme }) => ({
  width: '290px',
  borderRadius: '16px 0px 0px 16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[10],
  height: '100%',
  borderRight: '1px solid',
  borderColor: theme.palette.grey[30],
  overflow: 'auto',
}));

const StyledButtonLink = styled(ButtonLink)(({ theme }) => ({
  padding: '4px 12px 4px 16px',
  borderRadius: theme.spacing(5),
  justifyContent: 'space-between',
  '&.active': {
    backgroundColor: theme.palette.success.main,
    '&:hover > svg, & h6': {
      color: theme.palette.grey['00'],
    },
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  '&:hover > svg': {
    color: theme.palette.secondary,
  },
}));

interface IProps {
  clientOptions: Omit<IStackedListOption<User>, 'subLabel'>[];
}

export function ClientListSidebar({ clientOptions }: IProps) {
  const { t } = useTranslation();
  const [filteredOptions, setFilteredOptions] =
    useState<Omit<IStackedListOption<User>, 'subLabel'>[]>(clientOptions);
  const [searchValue, setSearchValue] = useState('');

  const reset = useCallback(() => {
    setFilteredOptions(clientOptions);
    setSearchValue('');
  }, [clientOptions]);

  useEffect(() => {
    // reset filtered options if options change
    reset();
  }, [clientOptions, reset]);

  const searchFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const keyword = event.target.value;

      if (keyword !== '') {
        const newFilteresOptionsgetFuseResults = getFuseResults<
          Omit<IStackedListOption<User>, 'subLabel'>
        >(clientOptions, keyword);
        setFilteredOptions(newFilteresOptionsgetFuseResults);
        setSearchValue(keyword);
      } else {
        reset();
      }
    },
    [clientOptions, reset],
  );

  return (
    <Container>
      <StyledSearchBar
        searchFilter={searchFilter}
        searchValue={searchValue}
        hideEndAdornment
        startAdornment={
          <SVG name="Search" sx={{ color: 'secondary.main' }} width="15px" />
        }
      />
      <Stack
        mt="28px"
        spacing={1}
        sx={{ overflowX: 'auto', overflowY: 'auto', flex: 'auto' }}
      >
        {filteredOptions.map((option) => (
          <StyledButtonLink
            key={option.label}
            component={NavLink}
            to={'/clients/' + option.value._id}
            fullWidth
            disableRipple
          >
            <>
              <Typography
                variant="subtitle1"
                lineHeight="24.65px"
                color="secondary"
              >
                {option.label}
              </Typography>
              <SVG
                name="RightArrow"
                color={theme.palette.grey['00']}
                width="8px"
              />
            </>
          </StyledButtonLink>
        ))}
      </Stack>
      <Button
        sx={{
          color: 'primary.dark',
          justifyContent: 'flex-start',
        }}
        startIcon={<SVG name="Plus" width="16px" />}
        onClick={() => alert('ADD NEW CLIENT')}
      >
        {t('pages.clientsPage.addNewClient', 'Add new client')}
      </Button>
    </Container>
  );
}
