import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PolicyDetailsActionBarContainer } from '../../components/ActionBar/PolicyDetailsActionBar.container';
import { Button } from '../../components/Button';
import { PolicyProgressBar } from '../../components/ProgressBar';
import { SVG } from '../../components/SVGs/SVG';
import {
  IStep,
  PolicyVerticalProgressStepper,
} from '../../components/Stepper/PolicyVerticalProgressStepper';
import { SummaryBody } from '../../components/SummaryCard';
import { DATE_FORMATS, formatDate } from '../../helpers/formatters';

import { PolicyDetailSpecific } from './PolicyDetailsSpecific';

interface IProps {
  clientEmail: string;
  clientName: string;
  daysRemaining: number;
  endDate: string;
  onBack: () => void;
  onRenew: () => void;
  paid?: boolean;
  policyId: string;
  policyStatus: string;
  premium: number;
  propertyLocation: string;
  startDate: string;
  steps: IStep[];
  coverages: any[];
  deductibles: any[];
  endorsements: any[];
}

export function PolicyDetailsPage({
  clientEmail,
  clientName,
  daysRemaining,
  endDate,
  onBack,
  onRenew,
  paid,
  policyId,
  policyStatus,
  premium,
  propertyLocation,
  startDate,
  steps,
  coverages,
  deductibles,
  endorsements,
}: IProps) {
  const { t } = useTranslation();
  return (
    <Container disableGutters sx={{ paddingY: 3, minWidth: '1244px' }}>
      <Button
        color="secondary"
        size="small"
        sx={{ fontSize: '1rem', marginBottom: 1 }}
        variant="text"
        onClick={onBack}
        startIcon={<SVG name="LeftArrow" height={12} width={11} />}
      >
        {t('pages.policyDetails.backButtonLabel', 'Back to Policies')}
      </Button>
      <Stack
        sx={{
          backgroundColor: 'grey.00',
          borderRadius: (theme) => theme.spacing(2),
        }}
      >
        <PolicyDetailsActionBarContainer
          policyId={policyId}
          status={policyStatus}
        />
        <PolicyProgressBar
          startDate={formatDate(startDate, DATE_FORMATS.LONG_FORMAT)}
          endDate={formatDate(endDate, DATE_FORMATS.LONG_FORMAT)}
          daysRemaining={daysRemaining}
          onRenew={onRenew}
        />
        <Paper
          sx={{
            backgroundColor: 'grey.20',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack
              flex="1"
              sx={{
                borderBottomLeftRadius: (theme) => theme.spacing(2),
                padding: (theme) => theme.spacing(3, 5),
                backgroundColor: 'grey.00',
              }}
            >
              <Box
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'grey.30',
                  paddingBottom: 1,
                  marginBottom: 3,
                  width: '100%',
                }}
              >
                <Typography variant="h1" lineHeight="40.6px">
                  {t(
                    'pages.policyDetails.policyDetailsTitle',
                    'Policy details',
                  )}
                </Typography>
              </Box>
              <Stack direction="row" justifyContent="space-between">
                <PolicyDetailSpecific
                  address={propertyLocation}
                  email={clientEmail}
                  name={clientName}
                  paid={paid}
                  premium={premium}
                  sx={{
                    borderRight: '1px solid',
                    borderColor: 'grey.20',
                    width: '300px',
                  }}
                />
                <Stack spacing={4} sx={{ minWidth: '444px' }}>
                  <SummaryBody
                    summaryRowData={coverages}
                    summaryTitle={t(
                      'pages.policyDetails.coveragesTitle',
                      'Coverages',
                    )}
                    sx={{ '& > :first-of-type > p': { paddingBottom: 1 } }}
                  />
                  <SummaryBody
                    summaryRowData={deductibles}
                    summaryTitle={t(
                      'pages.policyDetails.deductiblesTitle',
                      'Deductibles',
                    )}
                    sx={{ '& > :first-of-type > p': { paddingBottom: 1 } }}
                  />
                  <SummaryBody
                    summaryRowData={endorsements}
                    summaryTitle={t(
                      'pages.policyDetails.endorsementsTitle',
                      'Endorsements',
                    )}
                    sx={{
                      '& > :first-of-type > p': { paddingBottom: 1 },
                      '& > :last-child': {
                        borderBottom: 'none',
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <PolicyVerticalProgressStepper
              steps={steps}
              title="Policy Activity"
              sx={{
                borderBottomRightRadius: (theme) => theme.spacing(2),
                padding: (theme) => theme.spacing(5, 6),
                backgroundColor: 'grey.00',
                width: '340px',
              }}
            />
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
