import { Box, Typography } from '@mui/material';

import { HEADER_HEIGHT } from '../../components/Header';
import { Image } from '../../components/Image';
import { ThisPageIsEmptyPNG } from '../../helpers/publicAssetLinks';

export function NotFoundPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
        backgroundColor: 'common.white',
        padding: 4,
      }}
    >
      <Typography
        fontSize="calc(1.5rem + 2vw)"
        sx={{ writingMode: 'vertical-rl', textOrientation: 'upright' }}
      >
        404 -{' '}
      </Typography>
      <Image
        src={ThisPageIsEmptyPNG}
        sx={{
          height: 'auto',
          maxWidth: '100%',
        }}
      />
    </Box>
  );
}
