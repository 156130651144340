import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { redirects } from '@/config/redirects';

import { BaseActionBar } from './BaseActionBar';

interface IProps {
  backText?: string | null;
  children?: React.ReactNode;
  hideSaveExit?: boolean;
  onBack?: (() => void) | string;
  onSaveExit?: () => void;
}

export function QuoterActionBar({
  backText,
  children,
  hideSaveExit,
  onBack,
  onSaveExit,
}: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const backIsCancel = backText === null;
  const showSaveExit = !hideSaveExit;

  return (
    <BaseActionBar
      backText={backText}
      onBack={() => {
        if (typeof onBack === 'string') {
          navigate(onBack);
        } else if (onBack) {
          onBack();
        }

        if (!backIsCancel && !onBack) {
          navigate(-1);
        }
      }}
      position="bottom"
      middleSlot={
        showSaveExit && (
          <Button
            color="secondary"
            size="small"
            sx={{ margin: 'auto', fontSize: '1.0625rem' }}
            onClick={() => {
              onSaveExit?.();
              navigate(redirects.protectedRoute);
            }}
          >
            {t('common.actions.saveAndExit', 'Save and Exit')}
          </Button>
        )
      }
      StartIcon={
        !backIsCancel && (
          <ChevronLeftIcon
            height="10px"
            sx={{
              mr: 1,
            }}
          />
        )
      }
    >
      {children}
    </BaseActionBar>
  );
}
