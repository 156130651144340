import {
  IconButton,
  InputAdornment,
  inputLabelClasses,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { ChangeEventHandler } from 'react';

import { SVG } from '@/components/SVGs/SVG';
import { theme } from '@/config';

const StyledSearchBar = styled(TextField)`
  & .${inputLabelClasses.standard}:not(.${inputLabelClasses.shrink}) {
    transform: translate(32px, 5px) scale(1);
  }
  & .MuiInput-root {
    margin-top: 0;
  }

  & .MuiInput-root:before,
  & .MuiInput-root:hover:not(.Mui-disabled):before,
  & .MuiInput-root.Mui-focused:before {
    border-bottom: ${theme.palette.success.main} 2px solid;
  }

  & .MuiInput-root:after,
  & .MuiInput-root.Mui-focused:after {
    border-bottom: none;
  }
`;

interface IProps {
  onClear?: () => void;
  searchValue?: string;
  searchFilter?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  hideEndAdornment?: boolean;
  startAdornment?: JSX.Element;
  placeholder?: string;
}

export function SearchBar({
  onClear,
  searchFilter,
  searchValue,
  startAdornment,
  hideEndAdornment,
  placeholder,
  ...textFieldProps
}: IProps & TextFieldProps) {
  const shrink = searchValue ? searchValue.length > 0 : false;

  return (
    <StyledSearchBar
      {...textFieldProps}
      label={placeholder}
      variant="standard"
      fullWidth
      autoFocus
      value={searchValue}
      onChange={searchFilter}
      InputLabelProps={{
        shrink: shrink,
        sx: {
          display: shrink ? 'none' : 'initial',
          color: 'grey.50',
          '&.MuiFormLabel-root.Mui-focused': {
            color: 'grey.50',
          },
        },
      }}
      InputProps={{
        ...textFieldProps.InputProps,
        startAdornment: (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        ...(!hideEndAdornment && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disableRipple
                onClick={() => {
                  if (onClear) onClear();
                }}
              >
                <SVG
                  name="Close"
                  color={theme.palette.secondary.main}
                  width="10px"
                />
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
    />
  );
}
