import { config } from '../../config/config';
import { Property } from '../../graphql/generated';

import { IUSPSAddressResponse } from './interfaces';

export function addressRequestTemplate(address: Property['address']) {
  const { address1, address2, city, state, zipcode } = address || {};
  return `<AddressValidateRequest USERID="${config.usps.userId}"><Address
  ID="0"><Address1>${address1}</Address1>
  <Address2>${address2}</Address2><City>${city}</City><State>${state}</State>
  <Zip5>${zipcode}</Zip5><Zip4></Zip4></Address></AddressValidateRequest>`.replace(
    /#/g,
    '',
  );
}

export function parseUSPSAddressXML(response: string): IUSPSAddressResponse {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(response, 'text/xml');

  const address = xmlDoc.getElementsByTagName('Address')[0];

  // parse the response into IAddress
  const address2 =
    address?.getElementsByTagName('Address1')[0]?.textContent || undefined;
  const address1 =
    address?.getElementsByTagName('Address2')[0]?.textContent || undefined;

  const city =
    address?.getElementsByTagName('City')[0]?.textContent || undefined;
  const state =
    address?.getElementsByTagName('State')[0]?.textContent || undefined;
  const zipcode =
    address?.getElementsByTagName('Zip5')[0]?.textContent || undefined;

  // Detect if the address is invalid
  const error = address.getElementsByTagName('Error')[0];
  const errorMessage =
    error?.getElementsByTagName('Description')[0]?.textContent || undefined;

  return {
    errorMessage,
    hasError: Boolean(error),
    address: {
      address1,
      address2,
      city,
      state,
      zipcode,
    },
  };
}
