import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuoterStore } from '@/hooks/useQuoterStore';
import { ShareQuoteModalContainer } from '@/pages/QuoterSharePage/QuoterSharePage.modal.container';
import { IQuoteOption } from '@/store/quoter';

import { EditQuoteModal } from '../Modals/Quoting/EditQuoteModal';

import { QuoteDetailsActionBar } from './QuoteDetailsActionBar';
const QUOTES_PAGE_ROUTE = '/quotes';

interface IProps {
  children?: React.ReactNode;
  quoteId?: string;
  status?: string;
}

export function QuoteDetailsActionBarContainer({
  children,
  quoteId,
  status,
}: IProps) {
  const navigate = useNavigate();
  const { input } = useQuoterStore();
  const [shareQuotesOpen, setShareQuotesOpen] = useState(false);
  const [editQuoteOpen, setEditQuoteOpen] = useState(false);

  // hooks
  const client = useMemo(() => {
    return input?.selectClient
      ? input.selectClient
      : {
          email: 'Marvin.McKinney@arbolmarket.com',
          profile: { firstName: 'Marvin', lastName: 'McKinney' },
        };
  }, [input.selectClient]);

  const selectedQuote = useMemo(() => {
    return input?.quotes?.find(
      (quote) => quote?.id === quoteId,
    ) as IQuoteOption;
  }, [quoteId, input.quotes]);

  // Handlers
  const onEditHandler = useCallback(() => {
    setEditQuoteOpen(true);
  }, []);

  const closeEditModal = useCallback(() => {
    setEditQuoteOpen(false);
  }, [setEditQuoteOpen]);

  const onDeleteHandler = useCallback(() => {
    // TODO: integrate delete quote mutation
    alert(`Delete Quote ${quoteId}`);
    navigate(QUOTES_PAGE_ROUTE);
  }, [quoteId, navigate]);

  const onShareHandler = useCallback(() => {
    setShareQuotesOpen(true);
  }, []);

  return (
    <Fragment>
      <QuoteDetailsActionBar
        quoteId={quoteId}
        status={status}
        onEdit={onEditHandler}
        onShare={onShareHandler}
        onDelete={onDeleteHandler}
      />
      <ShareQuoteModalContainer
        client={client}
        open={shareQuotesOpen}
        setShareQuotesOpen={setShareQuotesOpen}
        quotes={[selectedQuote]}
      />
      <EditQuoteModal open={editQuoteOpen} onClose={closeEditModal} />
    </Fragment>
  );
}
