import DateRangeIcon from '@mui/icons-material/DateRange';
import { PaperProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { theme } from '@/config';

import {
  StyledLeftArrowButton,
  StyledRightArrowButton,
} from './StyledArrowButton';
import { renderStyledPickersDay } from './StyledPickersDay';

export const DatePickerStylePaperProps: PaperProps = {
  sx: {
    '& .PrivatePickersYear-yearButton:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      borderColor: theme.palette.success.main,
      borderStyle: 'dashed',
      borderWidth: '1px',
    },
    '& .PrivatePickersYear-yearButton.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.dark,
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    '& .MuiTypography-caption': {
      fontSize: '0.9375rem',
      width: '40px',
    },
    '& .PrivatePickersFadeTransitionGroup-root': {
      fontWeight: 600,
    },
    '& .PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition': {
      minHeight: '255px',
    },
  },
};

interface IProps<T extends FieldValues>
  extends Omit<
    DatePickerProps<Date, Date>,
    'renderInput' | 'onChange' | 'value'
  > {
  control: Control<T>;
  label?: string;
  name: Path<T>;
}

export function ControllerDatePicker<T extends FieldValues>({
  control,
  label,
  name,
  ...datePickerProps
}: IProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <MUIDatePicker
          {...field}
          {...(datePickerProps as DatePickerProps<Date, Date>)}
          PaperProps={DatePickerStylePaperProps}
          components={{
            OpenPickerIcon: DateRangeIcon,
            LeftArrowButton: StyledLeftArrowButton,
            RightArrowButton: StyledRightArrowButton,
          }}
          label={label}
          renderDay={renderStyledPickersDay}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                sx={{
                  backgroundColor: 'grey.00',
                  color: 'grey.70',
                  borderRadius: 2,
                }}
                error={Boolean(error)}
                helperText={error?.message}
              />
            );
          }}
        />
      )}
    />
  );
}
