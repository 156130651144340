import { useCallback } from 'react';

import { saveQuoterInput } from '@/helpers';
import { useQuoterStore } from '@/hooks/useQuoterStore';

import { QuoterActionBar } from './QuoterActionBar';

interface IProps {
  children?: React.ReactNode;
  backText?: null | string;
  onBack?: (() => void) | string;
  hideSaveExit?: boolean;
}

export function QuoterActionBarContainer({
  backText = 'Add Your Client',
  children,
  hideSaveExit,
  onBack,
}: IProps) {
  const { input, reset } = useQuoterStore();

  const onSaveExitHandler = useCallback(() => {
    if (input?.selectClient?._id) {
      saveQuoterInput(input, input?.selectClient?._id);
      reset();
    }
  }, [input, reset]);

  return (
    <QuoterActionBar
      backText={backText}
      hideSaveExit={hideSaveExit}
      onBack={onBack}
      onSaveExit={onSaveExitHandler}
    >
      {children}
    </QuoterActionBar>
  );
}
