import { Box, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { QuoteStepperContainer } from '../../components/QuoteStepper';
import { SignaturePad } from '../../components/SignaturePad/SignaturePad';
import { useSignaturePad } from '../../components/SignaturePad/useSignaturePad';
export const PREV_PAGE_ROUTE = '/quoter/review-coverage';

interface IProps {
  isLoading: boolean;
  onSubmit: (value: string) => void;
}

export function QuoterAddSignaturePage({ isLoading, onSubmit }: IProps) {
  const signaturePadCanvas = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation();
  const { clear, getDataURL, hasSignature } =
    useSignaturePad(signaturePadCanvas);
  const pageTitle = !hasSignature
    ? t('pages.addSignature.title', 'Add Signature')
    : t('pages.addSignature.altTitle', 'Confirm Signature');

  const onSubmitHandler = () => {
    const signatureImage = getDataURL();
    onSubmit?.(signatureImage);
  };

  return (
    <Box>
      <QuoteStepperContainer />
      <Box>
        <PageTitle title={pageTitle}>
          <Typography
            sx={{
              color: 'grey.80',
              lineHeight: 1.5,
              maxWidth: '520px',
              mt: 2,
              mx: 'auto',
            }}
          >
            {t(
              'pages.addSignature.description',
              'Please sign below to affix your signature to the policy’s facing page.',
            )}
          </Typography>
        </PageTitle>
        <Box
          sx={{
            mx: 'auto',
            my: 15.125,
            textAlign: 'center',
            width: '520px',
          }}
        >
          <SignaturePad
            ref={signaturePadCanvas}
            onClear={clear}
            hasSignature={hasSignature}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <QuoterActionBarContainer
        backText={t('pages.reviewCoveragePage.title', 'Review Selections')}
        onBack={PREV_PAGE_ROUTE}
        hideSaveExit={true}
      >
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            disabled={!hasSignature}
            onClick={onSubmitHandler}
            size="small"
            sx={{ fontSize: '1rem' }}
            variant="contained"
          >
            {t('common.labels.submit', 'Submit')}
          </Button>
        </Stack>
      </QuoterActionBarContainer>
    </Box>
  );
}
