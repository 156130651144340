import { Box, ContainerProps, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CardActionBar } from './CardActionBar';

export const StyledLabel = styled(Typography)(({ theme }) => ({
  lineHeight: 1.45,
  color: theme.palette.grey[70],
  fontSize: '0.8125rem',
}));

export const StyledSubLabel = styled(Typography)(({ theme }) => ({
  lineHeight: 1.45,
  color: theme.palette.grey[90],
  fontSize: '1.0625rem',
}));

interface IProps {
  children?: React.ReactNode;
  name?: string;
  address?: string;
  inceptionDate?: string;
}

export function EndorsementsReviewActionBar({
  name,
  address,
  inceptionDate,
  ...props
}: IProps & ContainerProps) {
  const { t } = useTranslation();

  return (
    <CardActionBar
      {...props}
      leftSlot={
        <Stack direction="row" alignItems="center" sx={{ my: 2, ml: 5 }}>
          <Box>
            <StyledLabel>
              {t('common.labels.nameOnPolicy', 'NAME ON POLICY')}
            </StyledLabel>
            <StyledSubLabel>{name}</StyledSubLabel>
          </Box>
        </Stack>
      }
      middleSlot={
        <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
          <Box>
            <StyledLabel sx={{ textAlign: 'center' }}>
              {t('common.labels.addressInsured', 'ADDRESS BEING INSURED')}
            </StyledLabel>
            <StyledSubLabel>{address}</StyledSubLabel>
          </Box>
        </Stack>
      }
    >
      <Stack direction="row" alignItems="center" sx={{ my: 2, mr: 5 }}>
        <Box>
          <StyledLabel>
            {t('common.labels.policyInEffectDate', 'POLICY INCEPTION DATE')}
          </StyledLabel>
          <StyledSubLabel sx={{ textAlign: 'right' }}>
            {inceptionDate}
          </StyledSubLabel>
        </Box>
      </Stack>
    </CardActionBar>
  );
}
