import { AuthorizeDocument, AuthorizeMutation } from '../../graphql/generated';

export const getTokenFromAuthorize = (data: AuthorizeMutation) => {
  return data?.authorize?.token as string;
};

export function getAuthorizeArgs(token: string) {
  return {
    document: AuthorizeDocument,
    variables: {
      token,
    },
  };
}
