import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FullscreenLoader } from '../../components/Loaders';
import { ConstructionType, RoofMaterialType } from '../../graphql/generated';
import { booleanParser } from '../../helpers/parsers';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import {
  useGetPropertySpecsQuery,
  useReviewPropertyMutation,
  useUpdatePropertyMutation,
} from '../../store/api';

import { QuoterReviewPropertyDataPage } from './QuoterReviewPropertyDataPage';
import { IQuoterReviewPropertySchema } from './QuoterReviewPropertyDataPage.schema';

const FIRST_PAGE_ROUTE = '/quoter/select-client';
export const PREV_PAGE_ROUTE = '/quoter/underwriting-checks';
const NEXT_PAGE_ROUTE = '/quoter/customize-coverage';

export function QuoterReviewPropertyDataPageContainer() {
  const navigate = useNavigate();
  const { input, reset } = useQuoterStore();

  const propertyId = input?.selectProperty?._id;

  // hooks
  const { data: propertyData, isLoading: propertyDataLoading } =
    useGetPropertySpecsQuery({
      propertyId: propertyId || '',
    });

  const [updateProperty, { isLoading: updatingPropertyLoading }] =
    useUpdatePropertyMutation();

  const [reviewProperty, { isLoading: reviewingPropertyLoading }] =
    useReviewPropertyMutation();

  // computed values
  const initialValues: IQuoterReviewPropertySchema | undefined = useMemo(() => {
    if (propertyData) {
      const specs = propertyData?.getProperty?.specs;

      return {
        constructionType: specs?.construction,
        hasBurglaryAlarm: specs?.burglarAlarm ? 'True' : 'False',
        hasFireAlarm: specs?.fireAlarm ? 'True' : 'False',
        hasSprinklerSystem: specs?.sprinklerSystem ? 'True' : 'False',
        isSeasonalProperty: specs?.seasonalProperty ? 'True' : 'False',
        roofMaterial: specs?.roofMaterial,
        roofYear: String(specs?.roofYear),
        yearBuilt: String(specs?.yearBuilt),
      } as IQuoterReviewPropertySchema;
    }
  }, [propertyData]);

  // handlers
  const onSubmitHandler = useCallback(
    async (values: IQuoterReviewPropertySchema) => {
      const hasChanges = Object.keys(values).some(
        (key) =>
          values[key as keyof IQuoterReviewPropertySchema] &&
          values[key as keyof IQuoterReviewPropertySchema] !==
            initialValues?.[key as keyof IQuoterReviewPropertySchema],
      );

      if (hasChanges) {
        const input = {
          construction:
            (values.constructionType as ConstructionType) || undefined,
          burglarAlarm: booleanParser(values.hasBurglaryAlarm),
          fireAlarm: booleanParser(values.hasFireAlarm),
          sprinklerSystem: booleanParser(values.hasSprinklerSystem),
          seasonalProperty: booleanParser(values.isSeasonalProperty),
          roofMaterial: (values.roofMaterial as RoofMaterialType) || undefined,
          roofYear: values.roofYear ? parseInt(values.roofYear) : undefined,
          yearBuilt: values.yearBuilt ? parseInt(values.yearBuilt) : undefined,
        };

        const updatedProperty = await updateProperty({
          propertyId: propertyId || '',
          input,
        });

        console.log('updatedProperty', updatedProperty);
      }

      const reviewedProperty = await reviewProperty({
        propertyId: propertyId || '',
      });

      if ('data' in reviewedProperty) {
        if (reviewedProperty.data?.reviewProperty?.success) {
          navigate(NEXT_PAGE_ROUTE);
        } else {
          alert(reviewedProperty?.data?.reviewProperty?.message);
          console.error(
            reviewedProperty?.data?.reviewProperty?.failedQuestions,
          );

          reset();
          navigate(FIRST_PAGE_ROUTE);
        }
      }
    },

    [
      initialValues,
      navigate,
      propertyId,
      reset,
      reviewProperty,
      updateProperty,
    ],
  );

  return (
    <Fragment>
      {propertyDataLoading && (
        <FullscreenLoader>Retrieving property data</FullscreenLoader>
      )}
      {updatingPropertyLoading && (
        <FullscreenLoader>Updating property data ...</FullscreenLoader>
      )}
      {reviewingPropertyLoading && (
        <FullscreenLoader>Reviewing property data ...</FullscreenLoader>
      )}
      <QuoterReviewPropertyDataPage
        onSubmit={onSubmitHandler}
        initialValues={initialValues}
      />
    </Fragment>
  );
}
