import { z } from 'zod';

import i18n from '../../config/i18n';

export function getQuoterSelectStartDateSchema() {
  return z
    .object({
      startDate: z.date().nullable(),
    })
    .refine((data) => (data?.startDate ? data.startDate > new Date() : false), {
      message: i18n.t(
        'validation.date.past',
        'must be a valid date in the future',
      ),
      path: ['startDate'],
    });
}

export type IQuoterSelectStartDateSchema = z.infer<
  ReturnType<typeof getQuoterSelectStartDateSchema>
>;
