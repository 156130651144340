import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Home } from '@/components/SVGs/Home';
import { Person } from '@/components/SVGs/Person';
import { Sliders } from '@/components/SVGs/Sliders';
import { FontWeight } from '@/config/theme/typography';

import { BaseModal } from '../BaseModal';
interface IProps {
  onClose?: () => void;
  open: boolean;
}

export function EditQuoteModal({ onClose, open }: IProps) {
  const { t } = useTranslation();

  return (
    <BaseModal
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '691px',
        },
      }}
      open={open}
      onClose={onClose}
      title={t('modals.editQuote.title', 'Edit Quote')}
      actionBar={
        onClose && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              onClick={onClose}
              sx={{ color: 'grey.100', fontWeight: FontWeight.bold }}
            >
              {t('common.actions.cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{
                fontWeight: FontWeight.bold,
                height: '31px',
                width: '100px',
                '&.Mui-disabled': {
                  backgroundColor: 'grey.40',
                  color: 'grey.60',
                },
              }}
            >
              {t('common.actions.continue', 'Continue')}
            </Button>
          </Box>
        )
      }
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '1.3125rem',
        }}
      >
        {t(
          'modals.editQuote.description',
          'What section would you like to edit?',
        )}
      </Typography>
      <Stack mt={3} direction="row" justifyContent="space-between" spacing={5}>
        <Stack
          padding={2}
          spacing={1}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
            alignItems: 'center',
          }}
        >
          <Person sx={{ height: '20px', width: '20px', color: 'grey.60' }} />
          <Typography
            sx={{
              fontWeight: FontWeight.bold,
              fontSize: '1rem',
              lineHeight: 1.45,
              color: 'secondary',
            }}
          >
            {t('common.labels.client', 'Client')}
          </Typography>
        </Stack>
        <Stack
          padding={2}
          spacing={1}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
            alignItems: 'center',
          }}
        >
          <Home sx={{ height: '20px', width: '20px', color: 'grey.60' }} />
          <Typography
            sx={{
              fontWeight: FontWeight.bold,
              fontSize: '1rem',
              lineHeight: 1.45,
              color: 'secondary',
            }}
          >
            {t('common.labels.property', 'Property')}
          </Typography>
        </Stack>
        <Stack
          padding={2}
          spacing={1}
          width="100%"
          sx={{
            backgroundColor: 'grey.10',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.20',
            alignItems: 'center',
          }}
        >
          <Sliders sx={{ height: '20px', width: '20px', color: 'grey.60' }} />
          <Typography
            sx={{
              fontWeight: FontWeight.bold,
              fontSize: '1rem',
              lineHeight: 1.45,
              color: 'secondary',
            }}
          >
            {t('common.labels.coverageOptions', 'Coverage options')}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        sx={{
          mt: 3,
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.45,
          color: 'grey.80',
        }}
      >
        {t(
          'modals.editQuote.footer',
          'Please note that if you change any information a new quote will have to be generated which may affect the quote price.',
        )}
      </Typography>
    </BaseModal>
  );
}
