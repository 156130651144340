import { InputBase, styled } from '@mui/material';

export const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[30]}`,
    borderRadius: '8px',
    color: `${theme.palette.secondary.main}`,
    fontSize: '1rem',
    fontWeight: 500,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },

  '& .MuiInputBase-input::placeholder': {
    color: `${theme.palette.grey[70]}`,
    opacity: 1,
  },
}));
