import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FullscreenLoader } from '../../components/Loaders';
import { File as UploadedFile } from '../../graphql/generated';
import { useMockDataMutation } from '../../hooks';
import { useBindingStore } from '../../hooks/useBindingStore';

import { QuoterUploadDiligentSearchPage } from './QuoterUploadDiligentSearchPage';

const mockResponse = {
  _id: '123412341',
  bucketName: 'mockBucketName',
  filename: 'diligent-search.pdf',
  filepath: '/mockBucketName/10292384756/diligent-search.pdf',
} as UploadedFile;

export function QuoterUploadDiligentSearchPageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { input, setInput } = useBindingStore();

  const initialValue = input?.diligentSearchUpload;

  const [mutate, { isLoading }] = useMockDataMutation({
    mockDelay: 500,
    mockResponse: mockResponse,
  });

  const onSubmitHandler = useCallback(
    async (values: File) => {
      const result = await mutate(values);

      setInput({
        diligentSearchUpload: result as UploadedFile,
      });

      navigate('/quoter/review-coverage');
    },
    [navigate, setInput, mutate],
  );

  const onRemoveHandler = useCallback(() => {
    setInput({
      diligentSearchUpload: null,
    });
  }, [setInput]);
  return (
    <>
      {isLoading && (
        <FullscreenLoader>
          {t('pages.quoterUploadDiligentSearchPage.loading', 'Uploading')}
        </FullscreenLoader>
      )}
      <QuoterUploadDiligentSearchPage
        initialValue={initialValue}
        onRemove={onRemoveHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
}
