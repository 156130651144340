import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme } from '../../config';
import { FontWeight } from '../../config/theme/typography';
import { SVG } from '../SVGs/SVG';

interface IProps {
  title: string;
  description?: string;
  onClick?: () => void;
  onRemove?: () => void;
  selected?: boolean;
}
const BOX_BACKGROUND_GRADIENT =
  'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(181.61deg, #6AB96A 19.83%, #1E253F 92.05%)';

export function MidtermSelectBox({
  title,
  description,
  onClick,
  onRemove,
  selected = false,
}: IProps) {
  const { t } = useTranslation();
  // Separates the dollar amount from the description text so it can be styled differently
  const descriptionSplitArray = description?.split(/(\$\d*,?\d*\.?\d*)/g);

  const containerBorder = selected
    ? '2px solid transparent'
    : `2px dashed ${theme.palette.grey[50]}`;
  return (
    <Box
      sx={{
        background: selected ? BOX_BACKGROUND_GRADIENT : 'transparent',
        border: containerBorder,
        borderRadius: 3,
        height: '145px',
        width: '468px',
        padding: 0,
        '&:hover': {
          border: `2px solid ${theme.palette.success.main}`,
          cursor: selected ? 'default' : 'pointer',
        },
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" height="100%">
        <Stack
          sx={{ cursor: 'pointer' }}
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          flex={1}
          onClick={onClick}
        >
          <Typography
            fontWeight={FontWeight.semibold}
            lineHeight="24.65px"
            fontSize="17px"
          >
            {title}
          </Typography>
        </Stack>
        {selected && description && (
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            width="100%"
            sx={{
              borderTop: '1px solid',
              borderColor: 'grey.30',
              paddingX: 2,
              paddingY: 1,
            }}
          >
            <Stack direction="row" alignItems="baseline" gap={0.5}>
              {descriptionSplitArray?.map((text, index) => {
                if (text.includes('$')) {
                  return (
                    <Typography
                      lineHeight="23.2px"
                      fontWeight={600}
                      key={index}
                    >
                      {text}
                    </Typography>
                  );
                }
                return (
                  <Typography
                    lineHeight="21.75px"
                    fontSize="0.9375rem"
                    color="grey.60"
                    key={index}
                  >
                    {text}
                  </Typography>
                );
              })}
            </Stack>
            <Button
              endIcon={<SVG name="TrashCan" width="14" />}
              onClick={() => onRemove?.()}
              variant="contained"
              color="error"
              size="small"
            >
              {t('common.labels.remove', 'Remove')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
