import { RouteObject, useRoutes } from 'react-router-dom';

import { routes as defaultRoutes } from './routes';

interface IProps {
  routes?: RouteObject[];
}

export function Router({ routes = defaultRoutes }: IProps) {
  return useRoutes(routes);
}
