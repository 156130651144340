import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function HouseOutline(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 381 214"
      {...props}
    >
      <path
        d="M81.269 48.198H77.29L1 117.374m80.269-69.176V37.03l5.785-1.441m-5.785 12.61v15.853M1 117.374v2.882l38.327-1.081M1 117.374l43.027-2.882-4.7 4.683m0 0-11.57 11.169m0 0 1.084 2.883 91.477-3.603m-92.562.72 90.393-5.044m2.169 4.324 23.503-27.022v-6.125m-23.503 33.147-2.169-4.324m25.672-28.823-78.461 6.846v-1.802m78.461-5.044L118.149 125.3m-52.79-23.779 153.307-14.412M65.36 101.522l-19.525 1.081-2.17-3.243m175.001-12.25 48.812-68.094m-48.812 68.094-2.17-4.683m50.982-63.412 33.626 74.941v4.323l12.655-.586m-46.281-78.677 8.316-11.89 37.965 87.91v2.657M275.794 1l40.858 93.676v2.882l-2.893.134M275.794 1 118.872 28.742l-15.186 15.133M275.794 1l-59.298 81.426m-112.81-38.551v-3.603l-16.632-4.684m16.632 8.287v12.25L87.054 72.338m0-36.75v36.75m-5.785-8.287L43.665 99.359M81.27 64.051v8.287h5.785M43.665 99.359l172.831-16.933M17.367 120.173l-1.922 67.468m19.991 8.659H15.2l.246-8.659m19.991 8.659v2.886l29.634.722M35.436 196.3v-6.855l4.337.138m25.297 10.325v1.082l38.667 1.082m-38.667-2.164v-4.33l4.336.121m34.331 6.373 8.312-.721m-8.312.721v-5.412m8.312 4.691h3.614m-3.614 0v-10.102m3.614 10.102 20.237-.722m-20.237.722v-10.102m20.237 9.38 46.98 1.443 27.826-9.38m-74.806 7.937v-9.741m74.806 1.804V88.422m0 104.27-74.806-1.804m0 0-18.069.322m-14.094 5.45-34.331-.961m34.331.961h3.975v-5.411m-38.306 4.45v-5.172m0 0-24.574-.783m24.574.783 5.06.095m33.246.627h4.337m-4.337 0-28.549-.538m32.886.538h1.084m2.53 0 2.168-.039m-2.168.039h-2.53m-97.688-3.608h24.328m0 0 1.445-54.48h4.337l-.723 56.583m-5.06-2.103v1.942m5.06.161-5.06-.161m34.693 1.039.723-58.904h5.06l-1.085 58.993m-4.698-.089 4.698.089m38.668.499.723-61.296-5.059.361-.362 60.974"
        stroke="#6F8A90"
        strokeLinejoin="round"
      />
      <path
        d="m210.906 121.489 53.704-21.062 82.37-5.448m-136.074 26.51 91.804-4.358m-91.804 4.358v5.084M346.98 94.98 380 130.567v4.721m-33.02-40.309-44.27 22.152M380 135.288h-16.329m16.329 0-33.383-35.225-11.612 6.106m28.666 29.119 1.452 52.655m-1.452-52.655v-5.81h-4.813m6.265 58.465h2.903v8.353L247.918 213m117.205-25.057-4.717.44M247.918 213l-86.362-3.631 49.35-16.342M247.918 213l39.189-17.794m-76.201-2.179v-66.454m0 66.454 76.201 2.179m-76.201-2.179 81.644-5.084m10.16-70.812 1.089 5.447m0 0-6.169.265m6.169-.265 3.453-1.816m-96.346 5.811 80.555-3.464m-4.354 72.097 5.443-.507m0-6.756v6.756m0-6.756-1.089-64.834m1.089 71.59 6.169-.574m-7.258-71.016 6.169-.266m0 0 .029 1.914m1.06 69.368 32.295-3.006m-32.295 3.006-.106-6.908m-.954-62.46 32.266 5.276m-32.266-5.276.954 62.46m8.639-66.455 22.673 3.809m-22.673-3.809 22.673-11.922m5.08-2.671-5.08 2.671m5.08-2.671.326 18.951m1.126 65.492-5.443.507m5.443-.507-.059-3.395m.059 3.395 23.949-2.229m-29.392 2.736-.07-3.902m-1.019-57.184 3.266.534v-5.447l-3.266-.549m0 5.462.094 5.255m-.094-10.717V108.84m5.406 16.28.1 5.81m-.1-5.81 23.527 4.358m.096 5.81h2.903v-5.255l-2.999-.555m.096 5.81-23.523-4.358m23.523 4.358h-23.448m23.448 0 1.452 52.655m-24.975-57.013.075 4.358m0 0 .892 51.929m-6.379-51.929H305.25l.726 51.929m24.043-51.929.925 51.929m-24.968 0h-7.363m7.363 0h24.968m5.454 0 24.008.726m0 0v.44M175.951 129.35l15.095-.495v39.341h-15.095m0-38.846v38.846m0-38.846-2.945.096m2.945 38.75h-2.945m0-38.75-14.727.482v38.268h14.727m0-38.75v38.75M248.934 97.91l-7.646-1.442V65.475l7.646 2.883m0 29.551 7.645 1.442V71.24l-7.645-2.883m0 29.551V68.358"
        stroke="#6F8A90"
        strokeLinejoin="round"
      />
      <path
        d="m273.244 77.13 7.364 2.55v20.035l-7.364.447m0-23.031-4.651-1.821v25.134l4.651-.282m0-23.031v23.031"
        stroke="#6F8A90"
        strokeLinejoin="round"
      />
    </StyledSVG>
  );
}
