import { Box, Button, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme } from '@/config';
import { shadowBoxes } from '@/config/theme/base';

const BAR_HEIGHT = '70px';
const SPACING = theme.spacing(4);

const Wrapper = styled(Box)`
  height: calc(${BAR_HEIGHT} + ${SPACING});
`;

const FloatingWrapper = styled(Box)`
  position: fixed;
  height: ${BAR_HEIGHT};

  left: 0;
  right: 0;
  width: 100vw;

  background: #fff;
  box-shadow: ${shadowBoxes.type2};

  padding: 0 40px;

  z-index: 1;
`;

const Container = styled(Box)`
  display: flex;

  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: auto;
`;

interface IProps {
  backText?: string | null;
  children?: React.ReactNode;
  middleSlot?: React.ReactNode;
  onBack?: () => void;
  position?: 'top' | 'bottom';
  StartIcon?: React.ReactNode;
}

export function BaseActionBar({
  backText,
  children,
  middleSlot,
  onBack,
  position = 'bottom',
  StartIcon,
}: IProps) {
  const { t } = useTranslation();
  const backIsCancel = backText === null;

  return (
    <Wrapper>
      <FloatingWrapper
        sx={{
          bottom: position === 'bottom' ? 0 : 'auto',
          top: position === 'top' ? 0 : 'auto',
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={4} display="flex" alignItems="flex-start">
              <Button
                color="secondary"
                size="small"
                sx={{ fontSize: '1.0625rem' }}
                variant="text"
                onClick={onBack}
              >
                {StartIcon}
                <Box component="span">
                  {backIsCancel
                    ? t('common.actions.cancel', 'Cancel')
                    : backText}
                </Box>
              </Button>
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center">
              {middleSlot}
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center">
              <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
            </Grid>
          </Grid>
        </Container>
      </FloatingWrapper>
    </Wrapper>
  );
}
