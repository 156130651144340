import { Button, ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';

interface IProps extends ButtonProps {
  children: React.ReactNode;
  to?: string;
  component?: React.ElementType;
}

export function ButtonLink({
  children,
  to,
  component = Link,
  ...props
}: IProps) {
  return (
    <Button
      {...{
        component,
        to,
      }}
      variant="text"
      {...props}
    >
      {children}
    </Button>
  );
}
