import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControllerTextField } from '@/components/Inputs';
import {
  getUserInfoSchema,
  IInviteNewTeamMemberSchema,
} from '@/pages/MyInformationPage/MyInformationpage.schema';

import { BaseModal } from '../BaseModal';

interface IProps {
  onClose?: () => void;
  onSubmit: (values: IInviteNewTeamMemberSchema) => void;
  open: boolean;
}

const StyledTextField = styled(ControllerTextField)<any>(({ theme }) => ({
  '.MuiOutlinedInput-input': {
    backgroundColor: theme.palette.grey['00'],
    borderRadius: '12px',
    border: `1px solid ${theme.palette.grey[20]}`,
    padding: '16px 14px',
  },
})) as typeof ControllerTextField;

export function InviteNewTeamMemberModal({ onClose, onSubmit, open }: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    resolver: zodResolver(getUserInfoSchema().omit({ phone: true })),
  });

  const onCloseHandler = useCallback(() => {
    if (onClose) {
      onClose();
    }

    reset({ firstName: '', lastName: '', email: '' });
  }, [onClose, reset]);

  return (
    <BaseModal
      PaperProps={{
        sx: {
          width: '524px',
        },
      }}
      open={open}
      onClose={onCloseHandler}
      title={t('modals.inviteNewTeamMember.title', 'Invite New Team Member')}
      actionBar={
        onClose && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              onClick={onCloseHandler}
              sx={{ color: 'grey.100', fontWeight: 600 }}
            >
              {t('common.actions.cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{
                fontWeight: 600,
                height: '31px',
                '&.Mui-disabled': {
                  backgroundColor: 'grey.40',
                  color: 'grey.60',
                },
              }}
            >
              {t('modals.inviteNewTeamMember.submit', 'Send Invite')}
            </Button>
          </Box>
        )
      }
    >
      <Stack
        mt={4}
        padding={4}
        spacing={2}
        sx={{
          backgroundColor: 'grey.10',
          borderRadius: '12px',
          border: '1px solid',
          borderColor: 'grey.20',
        }}
      >
        <StyledTextField
          fullWidth
          name="firstName"
          placeholder="First Name"
          control={control}
        />
        <StyledTextField
          fullWidth
          name="lastName"
          placeholder="Last Name"
          control={control}
        />
        <StyledTextField
          fullWidth
          name="email"
          placeholder="Email"
          control={control}
        />
      </Stack>
    </BaseModal>
  );
}
