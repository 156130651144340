import { Box } from '@mui/material';

import { theme } from '@/config';

const linearGradientBackground = theme.palette.gradient.main;

export function StandardBackground() {
  return (
    <Box
      sx={{
        background: `${linearGradientBackground}`,
        bottom: 0,
        height: '100%',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: -1,
      }}
    />
  );
}
