import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function CalendarDateRange(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.8 10.1a.775.775 0 0 1-.57-.23A.773.773 0 0 1 4 9.3c0-.227.076-.417.23-.57a.776.776 0 0 1 .57-.23c.226 0 .417.077.57.23.153.153.23.343.23.57a.773.773 0 0 1-.23.57.775.775 0 0 1-.57.23Zm3.2 0a.772.772 0 0 1-.57-.23.772.772 0 0 1-.23-.57c0-.227.077-.417.23-.57A.773.773 0 0 1 8 8.5c.226 0 .417.077.57.23.153.153.23.343.23.57a.773.773 0 0 1-.23.57.775.775 0 0 1-.57.23Zm3.2 0a.772.772 0 0 1-.57-.23.772.772 0 0 1-.23-.57c0-.227.077-.417.23-.57a.773.773 0 0 1 .57-.23c.227 0 .416.077.57.23.153.153.23.343.23.57a.772.772 0 0 1-.23.57.772.772 0 0 1-.57.23Zm-8.8 6.4c-.44 0-.817-.157-1.13-.47A1.543 1.543 0 0 1 .8 14.9V3.7c0-.44.156-.817.47-1.13.313-.313.69-.47 1.13-.47h.8v-.8a.8.8 0 1 1 1.6 0v.8h6.4v-.8a.8.8 0 0 1 1.6 0v.8h.8c.44 0 .817.157 1.13.47.313.313.47.69.47 1.13v11.2c0 .44-.157.817-.47 1.13-.313.313-.69.47-1.13.47H2.4Zm0-2.6a1 1 0 0 0 1 1h9.2a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H3.4a1 1 0 0 0-1 1v6Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
