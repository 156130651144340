import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '@/hooks';

import { AccountSideBar } from './AccountSideBar';
import { getAccountLinks } from './GetAccountLinks';

export function AccountSideBarContainer() {
  const { logout } = useAuth();
  const accountLinks = useMemo(getAccountLinks, []);
  return (
    <Stack direction="row">
      <AccountSideBar links={accountLinks} onLogout={logout} />
      <Outlet />
    </Stack>
  );
}
