import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';

import { useLazyAutosuggestQuery } from '@/store/bingMapApi';
import { IBingAddress } from '@/store/bingMapApi/interfaces';

interface IProps {
  errorMessage?: string;
  label?: string;
  name?: string;
  onChange?: (value: IBingAddress[keyof IBingAddress]) => void;
  onSelected?: (selected: Partial<IBingAddress>) => void;
  optionLabel?: keyof IBingAddress;
  placeholder?: string;
}

export function LocationAutocomplete({
  errorMessage,
  label = 'Address',
  name,
  onChange,
  onSelected,
  optionLabel = 'streetName',
  placeholder,
}: IProps) {
  const [lazyQuery, { isLoading }] = useLazyAutosuggestQuery();
  const [value, setValue] = useState<IBingAddress | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<IBingAddress[]>([]);

  const lazyQueryThrottled = useMemo(
    () =>
      throttle((query: string) => lazyQuery(query, true), 50, {
        trailing: false,
        leading: true,
      }),
    [lazyQuery],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    lazyQueryThrottled(inputValue)?.then((results) => {
      if (active) {
        let newOptions: IBingAddress[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results?.data?.resourceSets?.[0]?.resources) {
          const fetchedOptions =
            results?.data?.resourceSets?.[0]?.resources[0]?.value;

          const parsedOptions = fetchedOptions?.map(({ address }) => address);

          newOptions = [...newOptions, ...(parsedOptions || [])];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, lazyQueryThrottled, value]);

  const isEmpty = options.length === 0;

  return (
    <Autocomplete
      id="bing-map-geocoder-service"
      data-testid="bing-map-geocoder-service"
      autoComplete
      componentsProps={{
        paper: {
          sx: isEmpty ? { display: 'none' } : undefined,
        },
      }}
      disableClearable={isEmpty}
      filterOptions={(x) => x}
      filterSelectedOptions
      freeSolo
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        return (
          (optionLabel ? option[optionLabel] : option.formattedAddress) || ''
        );
      }}
      includeInputInList
      loading={isLoading}
      options={options}
      value={value}
      onChange={(event: any, newValue) => {
        if (newValue && typeof newValue !== 'string') {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onSelected?.(newValue);
        }
      }}
      onInputChange={(event, newInputValue: string) => {
        setInputValue(newInputValue);

        if (onChange) {
          onChange(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          placeholder={placeholder}
          fullWidth
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
      )}
      renderOption={(props, option) => {
        // Highlight text
        const matches = match(option.formattedAddress, inputValue);
        const parts = parse(option.formattedAddress, matches);

        // eslint-disable-next-line react/prop-types
        const key = `${props.id}-${option.formattedAddress}`;

        return (
          <li {...props} key={key}>
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
