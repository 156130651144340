import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { fullNameSelector } from '../../helpers/selectors/user';
import { useAuth } from '../../hooks';
import { useQuoterStore } from '../../hooks/useQuoterStore';

import { QuoterShareApplicationPage } from './QuoterShareApplicationPage';

export function QuoterShareApplicationPageContainer() {
  const navigate = useNavigate();
  const { input } = useQuoterStore();
  const { user } = useAuth();

  const clientName = fullNameSelector(input?.selectClient);
  const clientEmail = input?.selectClient?.email || '';
  const brokerName = fullNameSelector(user);
  const brokerEmail = user?.email || '';

  const onSubmitHandler = useCallback(
    (message: string) => {
      const payload = {
        clientEmail,
        brokerEmail,
        message,
      };

      // alert(JSON.stringify(payload, null, 2));

      navigate('/quoter/application-success');
    },
    [navigate, clientEmail, brokerEmail],
  );

  return (
    <QuoterShareApplicationPage
      clientName={clientName}
      clientEmail={clientEmail}
      brokerName={brokerName}
      brokerEmail={brokerEmail}
      onPreview={() => alert('PREVIEW')}
      onSubmit={onSubmitHandler}
    />
  );
}
