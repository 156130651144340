import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Pencil(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        d="M.6 10.234V12.9h2.666l7.864-7.864L8.464 2.37.6 10.234Zm12.592-7.26a.708.708 0 0 0 0-1.002L11.528.308a.708.708 0 0 0-1.002 0L9.225 1.61l2.666 2.667 1.301-1.302Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
