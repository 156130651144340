import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function CheckMark(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.966 2.634a.8.8 0 0 1 0 1.132l-8.8 8.8a.8.8 0 0 1-1.132 0l-4-4a.8.8 0 1 1 1.131-1.132L5.6 10.87l8.234-8.235a.8.8 0 0 1 1.132 0Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
