import { Stack, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const StyledSkeleton = styled(Skeleton)`
  width: 50%;
  height: 20px;
`;

export function CompanyInfoCellSkeleton() {
  return (
    <>
      <Stack direction="column" width="40%">
        <StyledSkeleton />
        <StyledSkeleton />
        <StyledSkeleton />
        <StyledSkeleton />
      </Stack>
      <Stack direction="column" width="40%">
        <StyledSkeleton />
        <StyledSkeleton />
        <StyledSkeleton />
        <StyledSkeleton />
      </Stack>
    </>
  );
}
