import { SerializedError } from '@reduxjs/toolkit';
import { GraphQLError } from 'graphql';
import { GraphQLError as OtherGraphQLError } from 'graphql-request/dist/types';

import i18n from '../config/i18n';

export enum ErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_EMAIL_OR_PASSWORD = 'INVALID_EMAIL_OR_PASSWORD',
}

export function firstGraphqlError(
  error: SerializedError,
): GraphQLError | OtherGraphQLError | undefined {
  if (error?.graphQLErrors) {
    return error.graphQLErrors[0];
  }
}

export function checkUnauthorizedError(
  errors: GraphQLError[] | OtherGraphQLError[] = [],
) {
  return errors.some(
    (error) => error.extensions.code === ErrorCodes.UNAUTHORIZED,
  );
}

export function getTranslatedErrorMessage(error?: SerializedError) {
  if (!error) {
    return undefined;
  }

  const errorCode = firstGraphqlError(error)?.extensions?.code;

  switch (errorCode) {
    case 'PROPERTY_ALREADY_EXISTS':
      return i18n.t(
        'errors.propertyAlreadyExists',
        'Property already exists with this client',
      );
    case 'INVALID_TOKEN':
      return i18n.t('error.invalidToken', 'Invalid token');
    case 'INVALID_EMAIL_OR_PASSWORD':
      return i18n.t('error.invalidLogin', 'Invalid email or password');
    case 'INVALID_PASSWORD':
      return i18n.t('error.invalidPassword', 'Invalid password');
    case 'INVALID_USER':
      return i18n.t('error.invalidUser', 'Invalid email');
    case 'INVALID_PERMISSIONS':
      return i18n.t(
        'error.invalidPermission',
        'You do not have permission to perform this action.',
      );
    default:
      return i18n.t('error.unknown', 'Internal Server Error');
  }
}
