import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HouseBodySelectedPNG } from '@/helpers/publicAssetLinks';

import { SlideContent, SlideSubTitlte } from './SlideContent';

export function DrawerCoverageContent() {
  const { t } = useTranslation();

  return (
    <SlideContent title="Coverages" src={HouseBodySelectedPNG}>
      <SlideSubTitlte>I. {t('common.labels.dwelling')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.dwelling.description',
          'Dwelling insurance covers damage to your home’s main structure. Your structure is insured against most events, but not against any named exclusions. Unlike traditional HO-3 policies, this policy does not cover your home’s roof when damaged as a result of weather.',
        )}
      </Typography>
      <SlideSubTitlte>II. {t('common.labels.otherStructure')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.otherStructure.description',
          'Other Structures insurance covers any additional structures on your property like garages and fences. Your structure is insured against most events, but not against any named exclusions.',
        )}
      </Typography>
      <SlideSubTitlte>
        III. {t('common.labels.personalProperty')}
      </SlideSubTitlte>
      <Typography>
        {t(
          'coverage.personalProperty.description',
          'Personal Property insurance protects your belongings when stolen, lost, or damaged as a result of only the perils listed in your policy.',
        )}
      </Typography>
      <SlideSubTitlte>IV. {t('common.labels.lossOfUse')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.lossOfUse.description',
          'Loss of Use insurance pays any necessary increase in living expenses incurred as a result of a covered loss that makes your home not fit for living.',
        )}
      </Typography>
      <SlideSubTitlte>V. {t('common.labels.personalLiability')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.personalLiability.description',
          'Personal Liability insurance pays in the event that a claim is made against you due to bodily injury or property damage that occurs on your property.',
        )}
      </Typography>
      <SlideSubTitlte>VI. {t('common.labels.medical')}</SlideSubTitlte>
      <Typography>
        {t(
          'coverage.medical.description',
          'Medical Payments to Others insurance pays necessary medical expenses incurred as a result of bodily injury.',
        )}
      </Typography>
    </SlideContent>
  );
}
