import { Box, Stack, Typography } from '@mui/material';
import { Control, FieldValues, Path, useWatch } from 'react-hook-form';

import { InfoIconButtonToolTip } from '../Button';
import { ControllerSwitch } from '../Switch/ControllerSwitch';

interface IProps<T extends FieldValues> {
  children?: React.ReactNode;
  label: string;
  name: Path<T>;
  control: Control<T>;
  tooltip?: string;
}

export function Endorsement<T extends FieldValues>({
  children,
  control,
  label,
  name,
  tooltip = '',
}: IProps<T>) {
  const isExpanded = useWatch({ control, name });

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '64px',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '1.0625rem',
          }}
        >
          {label}
          <InfoIconButtonToolTip tooltipText={tooltip} />
        </Typography>
        <ControllerSwitch control={control} name={name} sx={{ m: 1 }} />
      </Stack>
      {children && isExpanded && <Box pb={4}>{children}</Box>}
    </Box>
  );
}
