import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme } from '@/config';
import { FontWeight } from '@/config/theme/typography';
import { calculateDateProgress } from '@/helpers/calculations';

import { Button } from '../Button';

const DaysRemainingLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'showWarning',
})<{ showWarning: boolean }>(({ showWarning, theme }) => ({
  fontWeight: showWarning ? FontWeight.semibold : FontWeight.medium,
  fontSize: showWarning ? '1.75rem' : '1.0625rem',
  background: showWarning
    ? '-webkit-linear-gradient(129.1deg, #FB8982 12.56%, #C46660 79.68%)'
    : theme.palette.secondary.main,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'showWarning',
})<{ showWarning: boolean }>(({ showWarning, theme }) => ({
  height: '10px',
  borderRadius: '22px',
  backgroundColor: theme.palette.grey[30],
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.success.light,
    ...(showWarning && {
      background: 'linear-gradient(129.1deg, #91CBEC 90.81%, #FB8982 100%)',
    }),
  },
}));

interface IProps {
  endDate: string;
  startDate: string;
  daysRemaining: number;
  onRenew: () => void;
}

export function PolicyProgressBar({
  daysRemaining,
  endDate,
  startDate,
  onRenew,
}: IProps) {
  const { t } = useTranslation();
  const showWarning = daysRemaining < 30;

  return (
    <Box
      sx={{
        backgroundColor: 'grey.10',
        padding: showWarning ? theme.spacing(2, 5, 2) : theme.spacing(2, 5, 4),
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ paddingBottom: '0.875rem' }}
      >
        <Typography variant="body2" fontWeight={FontWeight.semibold}>
          {startDate}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'grey.70',
              fontSize: '0.9375rem',
              fontWeight: FontWeight.medium,
              textTransform: 'uppercase',
            }}
          >
            {t('common.labels.policyPeriod', 'Policy Period')}
          </Typography>
          <DaysRemainingLabel showWarning={showWarning}>
            {t(
              'pages.policyDetails.expiresIn',
              'Expires in {{daysRemaining}} Days',
              { daysRemaining },
            )}
          </DaysRemainingLabel>
        </Box>
        <Typography variant="body2" fontWeight={FontWeight.semibold}>
          {endDate}
        </Typography>
      </Stack>
      <StyledLinearProgress
        variant="determinate"
        value={calculateDateProgress(startDate, endDate)}
        showWarning={showWarning}
      />
      {showWarning && (
        <Box sx={{ width: '100%', marginTop: 2, textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{ borderRadius: 6, height: '31px' }}
            onClick={() => alert('Start Renewal Process')}
          >
            {t('pages.policyDetails.startRenewal', 'Start Renewal Process')}
          </Button>
        </Box>
      )}
    </Box>
  );
}
