import Fuse from 'fuse.js';

const baseOptions = {
  keys: ['label'],
};

export function getFuseResults<T>(
  options: T[],
  keyword: string,
  fuseOptions = baseOptions,
) {
  const fuse = new Fuse(options, fuseOptions);
  return fuse.search(keyword).map(({ item }) => item);
}
