// array reducer to sum values
export const sumReducer = (accumulator: number, currentValue: number) =>
  accumulator + currentValue;

// array reduces to max value
export const maxReducer = (accumulator: number, currentValue: number) =>
  Math.max(accumulator, currentValue);

// array map to get values by key with generic type
export const mapByKey =
  <T, K extends keyof T>(key: K) =>
  (obj: T) =>
    obj[key];
