import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function RightArrow(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      viewBox="0 0 8 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.034.635a.8.8 0 0 1 1.131 0l4.8 4.8a.8.8 0 0 1 0 1.131l-4.8 4.8a.8.8 0 0 1-1.13-1.131L5.267 6 1.034 1.766a.8.8 0 0 1 0-1.131Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
