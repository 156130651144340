import { Button, Container, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paperBoxShadow } from '@/config/theme/components';
import { FontWeight } from '@/config/theme/typography';
import { formatCurrency } from '@/helpers/formatters';

import { SVG } from '../SVGs/SVG';

const DetailText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[90],
  fontSize: '0.9375rem',
  lineHeight: '145%',
  fontWeight: FontWeight.regular,
}));

interface IProps {
  title: string;
  description: string;
  isSelected: boolean;
  leftTitle: string;
  leftPrice: number;
  leftDescription: string;
  rightTitle: string;
  rightPrice: number;
  rightDescription: string;
  onRemove: () => void;
  onSubmit: () => void;
}
export function SimpleEndorsement({
  title,
  description,
  isSelected,
  leftTitle,
  leftPrice,
  leftDescription,
  rightTitle,
  rightPrice,
  rightDescription,
  onRemove,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();
  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: 'grey.00',
        boxShadow: paperBoxShadow,
        borderRadius: 4,
        marginBottom: 7,
      }}
    >
      <Stack
        direction="column"
        padding={4}
        sx={{ borderBottom: '1px solid', borderColor: 'grey.30' }}
      >
        <Typography variant="h2" lineHeight="145%" pb={1}>
          {title}
        </Typography>
        <DetailText>{description}</DetailText>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ backgroundColor: 'grey.10', padding: 4 }}
      >
        <Stack sx={{ width: '45%' }}>
          <Typography
            color="grey.80"
            lineHeight="145%"
            fontWeight={FontWeight.semibold}
          >
            {leftTitle}
          </Typography>
          <Typography variant="h2" color="grey.90" pb={0.5}>
            {formatCurrency(leftPrice)}
          </Typography>
          <DetailText>{leftDescription}</DetailText>
        </Stack>
        <Stack sx={{ width: '45%' }}>
          <Typography
            color="grey.80"
            lineHeight="145%"
            fontWeight={FontWeight.semibold}
          >
            {rightTitle}
          </Typography>
          <Typography variant="h2" color="grey.90" pb={0.5}>
            {formatCurrency(rightPrice)}
          </Typography>
          <DetailText>{rightDescription}</DetailText>
        </Stack>
      </Stack>
      <Stack
        padding={3}
        alignItems="flex-end"
        sx={{ borderTop: '1px solid', borderColor: 'grey.30' }}
      >
        {isSelected ? (
          <Button
            endIcon={<SVG name="TrashCan" width="14" />}
            onClick={onRemove}
            variant="contained"
            color="error"
            sx={{ width: 180 }}
          >
            {t('common.labels.remove', 'Remove')}
          </Button>
        ) : (
          <Button variant="contained" sx={{ width: 180 }} onClick={onSubmit}>
            {t('common.labels.addEndorsement', 'Add Endorsement')}
          </Button>
        )}
      </Stack>
    </Container>
  );
}
