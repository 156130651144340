import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config/config';

import { IBingLocationResponse } from './interfaces';

const BING_API_BASE_URL = config.bing.baseUrl;
const BING_API_KEY = config.bing.apiKey;

const MAX_RESULTS = 7;

const FLORIDA_CENTER = '27.6648,-81.5158';

export const getGeocoderUrl = (query: string) =>
  `Locations?key=${BING_API_KEY}&maxResults=5&query=${query}`;
export const getReverseGeocoderUrl = (query: string) =>
  `Locations/${query}?key=${BING_API_KEY}`;

export const getAutosuggestUrl = (query: string) =>
  `Autosuggest?query=${query}&includeEntityTypes=Address&key=${BING_API_KEY}&maxResults=${MAX_RESULTS}&userLocation=${FLORIDA_CENTER}`;

export const bingMapApi = createApi({
  reducerPath: 'bingMapApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BING_API_BASE_URL,
  }),
  endpoints: (builder) => ({
    autosuggest: builder.query<IBingLocationResponse, string>({
      query: (query) => getAutosuggestUrl(query),
    }),
    queryBingGeocoder: builder.query<IBingLocationResponse, string>({
      query: getGeocoderUrl,
    }),
    reverseGeocoder: builder.query<IBingLocationResponse, string>({
      query: getReverseGeocoderUrl,
    }),
  }),
});

export const {
  useLazyQueryBingGeocoderQuery,
  useLazyReverseGeocoderQuery,
  useQueryBingGeocoderQuery,
  useReverseGeocoderQuery,
  useAutosuggestQuery,
  useLazyAutosuggestQuery,
} = bingMapApi;
