import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { QuoterReviewActionBar } from '../../components/ActionBar/QuoterReviewActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { OptionFooter } from '../../components/CustomCoverageOption';
import { QuoteStepperContainer } from '../../components/QuoteStepper';
import { QuoterReviewCoverageRow } from '../../components/QuoterReviewCoverageRow';
import { shadowBoxes } from '../../config/theme/base';
import { FontWeight } from '../../config/theme/typography';
export const PREV_PAGE_ROUTE = '/quoter/upload-diligent-search';

interface IProps {
  onNext: () => void;
  options: any[];
}

export function QuoterReviewCoveragePage({ onNext, options }: IProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <QuoteStepperContainer />
      <Box>
        <PageTitle
          title={t('pages.reviewCoveragePage.title', 'Review Selections')}
        >
          <Typography
            sx={{
              lineHeight: 1.5,
              maxWidth: '520px',
              color: 'grey.80',
              mt: 2,
              mx: 'auto',
            }}
          >
            {t(
              'pages.reviewCoveragePage.description',
              'If everything looks correct, please sign below to affix your signature to the policy’s facing page.',
            )}
          </Typography>
        </PageTitle>
        <Container
          disableGutters
          sx={{
            width: '960px',
            backgroundColor: 'grey.10',
            boxShadow: shadowBoxes.type1,
            borderRadius: 3,
            marginBottom: 20,
          }}
        >
          <QuoterReviewActionBar
            name="Savannah Nguyen"
            address="2008 Jellyfish Hwy Key West, FL, 33040"
            inceptionDate="January 1, 2023"
            sx={{
              height: '94px',
              borderBottom: '1px solid',
              borderColor: 'grey.30',
              borderRadius: 3,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <Box sx={{ px: 5, py: 4 }}>
            <Typography
              sx={{
                fontSize: '1.3125rem',
                fontWeight: FontWeight.semibold,
                color: 'secondary.main',
                lineHeight: 1.45,
                pb: 1,
              }}
            >
              {t('pages.reviewCoveragePage.subtitle', 'Coverage')}
            </Typography>
            {options.map((option, index) => (
              <QuoterReviewCoverageRow
                key={index}
                name={option.name}
                description={option.description}
                value={option.value}
              />
            ))}
          </Box>
          <OptionFooter />
        </Container>
      </Box>
      <QuoterActionBarContainer
        backText={t('pages.diligentSearchPage.title', 'Diligent Search')}
        onBack={PREV_PAGE_ROUTE}
        hideSaveExit={true}
      >
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            onClick={onNext}
            size="small"
            sx={{ fontSize: '1rem' }}
            variant="contained"
          >
            {t('pages.reviewCoveragePage.submit', 'Looks good, add signature')}
          </Button>
        </Stack>
      </QuoterActionBarContainer>
    </Box>
  );
}
