import { GridCellParams, GridSelectionModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { generateEmptyQuote } from '../../components/CustomCoverageOption/helpers';
import { IStep } from '../../components/Stepper';
import { useMockDataQuery } from '../../hooks';
import { IQuoteOption } from '../../store/quoter';
import { rowFormatter } from '../QuotesPage/helpers';

import { QuotesDetailPage } from './QuoteDetailPage';

export const QUOTES_SHARE_ROUTE = '/quotes/share?ids=';
const mockQuotesResponse = [
  generateEmptyQuote({ id: '2b4d7c22-33' }),
  generateEmptyQuote({ id: 'asdau123-5d' }),
  generateEmptyQuote({ id: 'ush63edb-s7' }),
  generateEmptyQuote({ id: '8syecyak-2x' }),
];
// TODO: create helper to convert quote saved data into this step structure
const steps: IStep[] = [
  {
    label: 'Quote Created',
    description: '10/16/2022',
  },
  {
    label: 'Customer Signature',
    description: 'Sent October 16th, 2022',
  },
  {
    label: 'Upload Diligent Search',
    description: undefined,
  },
  {
    label: 'Submit Payment',
    description: undefined,
  },
  {
    label: 'Policy Begins',
    description: '11/01/2022',
  },
];

// TODO: create helper to convert quote coverage data into this structure
const placeholderData = [
  { name: 'Dwelling Coverage', value: '$400,000.00', letter: null },
  { name: 'Coverage II', value: '2% ($11,250)', letter: null },
  { name: 'Coverage III', value: '25% ($112,000)', letter: null },
  { name: 'Coverage IV', value: '$35,000.00', letter: null },
  { name: 'Coverage V', value: '$5,000', letter: null },
  { name: 'Coverage VI', value: '$5,000', letter: null },
  { name: 'All Other Perils', value: '$10,000', letter: null },
  { name: 'Hurricane', value: '$15,000', letter: null },
  { name: 'Parametric Expense', value: '$25,000', letter: null },
  { name: 'Mold', value: '$15,000.00', letter: null },
  { name: 'Carport Amount', value: '$10,000.00', letter: null },
  { name: 'Carport Age', value: '10 years', letter: null },
  { name: 'Total Premium', value: '$22,052.00', letter: null },
];

export function QuoteDetailPageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: quoteId } = useParams();
  const [selectedQuoteIds, setSelectedQuoteIds] = useState<string[]>([]);

  // TODO: query quote with id param and replace hard-coded data below (like quoteId and status)

  const { data: quoteListResponse, isLoading: quoteListLoading } =
    useMockDataQuery({
      mockDelay: 1000,
      mockResponse: mockQuotesResponse,
    });

  // Handlers
  const onBackHandler = useCallback(() => {
    navigate('/quotes');
  }, [navigate]);

  const onCheckHandler = useCallback(
    (value: GridSelectionModel) => {
      if (value.length <= 3) {
        setSelectedQuoteIds(value as string[]);
      }
    },
    [setSelectedQuoteIds],
  );

  const onClick = useCallback(
    (value: GridCellParams) => {
      if (value.field !== '__check__') {
        navigate(`/quotes/${value.id}`);
      }
    },
    [navigate],
  );

  const onCompare = useCallback(() => {
    navigate(QUOTES_SHARE_ROUTE + selectedQuoteIds.join(','));
  }, [navigate, selectedQuoteIds]);

  // computed values
  const formattedRows = useMemo(() => {
    const quotes = (quoteListResponse ?? []) as IQuoteOption[];
    return rowFormatter(quotes, quoteId || '');
  }, [quoteListResponse, quoteId]);

  return (
    <QuotesDetailPage
      activeStep={1}
      initialValues={formattedRows || []}
      isLoading={quoteListLoading}
      onBack={onBackHandler}
      onCheck={onCheckHandler}
      onClick={onClick}
      onCompare={onCompare}
      quoteId={quoteId}
      selectedRows={selectedQuoteIds || []}
      status="Saved"
      steps={steps}
      summaryRowData={placeholderData}
      title={t('pages.quoteDetails.QuoteProgressTitle', 'Quote Progress')}
    />
  );
}
