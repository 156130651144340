import {
  Box,
  StackProps,
  Stack,
  Typography,
  styled,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme } from '../../config';
import { FontWeight } from '../../config/theme/typography';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[70],
  fontSize: '0.9375rem',
  paddingBottom: '4px',
  textTransform: 'uppercase',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[90],
  fontSize: '1.0625rem',
  fontWeight: FontWeight.semibold,
  lineHeight: '24.65px',
  width: '161px',
  wordBreak: 'break-word',
}));

const StyledSubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[80],
  fontSize: '0.9375rem',
  fontWeight: FontWeight.regular,
  lineHeight: '21.75px',
}));

interface IProps {
  address: string;
  email: string;
  name: string;
  premium: number;
  paid?: boolean;
}

export function PolicyDetailSpecific({
  address,
  email,
  name,
  paid,
  premium,
  ...props
}: IProps & StackProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} {...props}>
      <StyledBox>
        <StyledLabel>
          {t('pages.policyDetails.clientLabel', 'Client')}
        </StyledLabel>
        <StyledText>{name}</StyledText>
        <StyledSubText>{email}</StyledSubText>
      </StyledBox>
      <StyledBox>
        <StyledLabel>
          {t('pages.policyDetails.propertyLocationLabel', 'Property Location')}
        </StyledLabel>
        <StyledText>{address}</StyledText>
      </StyledBox>
      <StyledBox>
        <StyledLabel>
          {t('pages.quoteDetails.premiumLabel', 'PREMIUM')}
        </StyledLabel>
        <Typography
          fontSize="1.3125rem"
          lineHeight="30.45px"
          fontWeight={FontWeight.semibold}
          sx={{ color: 'grey.90' }}
        >
          {t('common.currency.USD', '{{value, currency(currency: USD)}}', {
            value: premium,
          })}
        </Typography>
        {paid && (
          <Chip
            label="Paid in full"
            sx={{
              marginTop: 0.5,
              backgroundColor: theme.palette.grey['20'],
              borderRadius: 2,
              '& .MuiChip-label': { fontSize: '15px' },
            }}
          />
        )}
      </StyledBox>
    </Stack>
  );
}
