import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Toggles(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.833 7.5V5.833h2.5V2.5H15v3.333h2.5V7.5h-6.667Zm2.5 10V9.167H15V17.5h-1.667ZM5 17.5v-3.333H2.5V12.5h6.667v1.667h-2.5V17.5H5Zm0-6.667V2.5h1.667v8.333H5Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
