import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import SignaturePad, { Options } from 'signature_pad';

function onBeginStrokeFunc(setHasSignature: Dispatch<SetStateAction<boolean>>) {
  return () => setHasSignature(true);
}

function onResizeCanvasFunc(
  canvasRef: RefObject<HTMLCanvasElement>,
  signaturePad: SignaturePad,
) {
  return () => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    // used resize solution from
    // https://github.com/szimek/signature_pad/blob/gh-pages/js/app.js#L18

    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    // This part causes the canvas to be cleared
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d')?.scale(ratio, ratio);

    // This library does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, you
    // have to clear it manually.
    signaturePad.clear();
  };
}

export function useSignaturePad(
  canvasRef: RefObject<HTMLCanvasElement>,
  options?: Options,
) {
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
  const [hasSignature, setHasSignature] = useState<boolean>(false);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    // create new signature pad instance onmount or when options change
    const newSignaturePad = new SignaturePad(canvasRef.current, options);
    setSignaturePad(newSignaturePad);
  }, [canvasRef, options]);

  // set up event handlers
  useEffect(() => {
    if (!signaturePad) {
      return;
    }

    const onBeginStroke = onBeginStrokeFunc(setHasSignature);
    const onResize = onResizeCanvasFunc(canvasRef, signaturePad);

    onResize();
    signaturePad.addEventListener('beginStroke', onBeginStroke, {
      once: true,
    });
    window.addEventListener('resize', onResize);

    return () => {
      signaturePad.off();

      signaturePad.removeEventListener('beginStroke', onBeginStroke);
      window.removeEventListener('resize', onResize);
    };
  }, [canvasRef, signaturePad]);

  // action handlers
  const clearHandler = useCallback(() => {
    signaturePad?.clear();
    setHasSignature(false);

    if (!canvasRef.current) {
      return;
    }

    // create new signature pad instance to attach new event listeners
    // kicks off useEffect above for event listeners
    const newSignaturePad = new SignaturePad(canvasRef.current, options);
    setSignaturePad(newSignaturePad);
  }, [canvasRef, options, signaturePad]);

  const getDataURLHandler = useCallback(() => {
    if (!signaturePad) {
      return '';
    }

    return signaturePad.toDataURL();
  }, [signaturePad]);

  return {
    clear: clearHandler,
    getDataURL: getDataURLHandler,
    hasSignature,
    instance: signaturePad,
  };
}
