import { z } from 'zod';

export function getDiligentSearchSchema() {
  return z.object({
    agreeToDiligentSearch: z.boolean(),
  });
}

export type IQuoterDiligentSearchPageSchema = z.infer<
  ReturnType<typeof getDiligentSearchSchema>
>;
