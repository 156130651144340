import { createTheme } from '@mui/material';

export const gradients = {
  main: 'linear-gradient(0deg, rgba(34, 102, 98, 0.06), rgba(34, 102, 98, 0.06)), linear-gradient(180deg, #F1F8F7 0%, #E2F3E9 100%)',
};

export const shadowBoxes = {
  type1:
    '0px 57px 86px rgba(0, 0, 0, 0.02), 0px 23.8132px 35.9287px rgba(0, 0, 0, 0.0143771), 0px 12.7317px 19.2092px rgba(0, 0, 0, 0.0119221), 0px 7.13728px 10.7685px rgba(0, 0, 0, 0.01), 0px 3.79056px 5.71909px rgba(0, 0, 0, 0.00807786), 0px 1.57734px 2.37984px rgba(0, 0, 0, 0.00562291)',
  type2:
    '0px -7px 98px rgba(0, 0, 0, 0.04), 0px -2.92443px 40.9421px rgba(0, 0, 0, 0.0287542), 0px -1.56354px 21.8896px rgba(0, 0, 0, 0.0238443), 0px -0.876509px 12.2711px rgba(0, 0, 0, 0.02), 0px -0.465507px 6.5171px rgba(0, 0, 0, 0.0161557), 0px -0.193708px 2.71191px rgba(0, 0, 0, 0.0112458)',
};

export const baseTheme = createTheme({
  palette: {
    action: {
      disabledBackground: '#C7D1D1',
      disabled: '#6F8A90',
    },
    gradient: {
      main: gradients.main,
    },
    primary: {
      dark: '#688A3F',
      main: '#2BB047',
      20: '#2C712A',
    },
    secondary: {
      main: '#0D1329',
    },
    success: {
      dark: '#1E67AB',
      main: '#4683DE',
      light: '#91CBEC',
    },
    warning: {
      main: '#FFA726', // Default MUI color. The 'main' value must be defined in order to customize 'dark'.
      dark: '#B16F10',
    },
    error: {
      main: '#C46660',
      dark: '#8A433F',
    },
    grey: {
      '00': '#FFFFFF',
      10: '#FAFAFA',
      20: '#EAF2F0',
      30: '#DBE1E0',
      40: '#C7D1D1',
      50: '#76BEBE',
      60: '#6F8A90',
      70: '#647C82',
      80: '#3F5F69',
      90: '#2D313E',
      100: '#0D1329',
    },
  },
});
