import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  styled,
} from '@mui/material';

import { IRadioOptions } from '../interfaces';

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'alternateBackground',
})<{ alternateBackground: 'even' | 'odd' }>(
  ({ alternateBackground, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [`&:nth-of-type(${alternateBackground})`]: {
      backgroundColor: theme.palette.grey[10],
    },
  }),
);

interface IProps<T> {
  alternateBackground?: 'odd' | 'even';
  label: string;
  radioOptions: T[];
  size?: 'medium' | 'large';
  value?: T[keyof T];
}

export function AlternatingRadioRow<T extends IRadioOptions<T[keyof T]>>({
  alternateBackground = 'even',
  label,
  radioOptions,
  size = 'medium',
  ...inputProps
}: IProps<T> & RadioGroupProps) {
  const pxSize = size === 'large' ? 3 : 2;
  const pySize = size === 'large' ? 3.75 : 2;

  return (
    <Wrapper alternateBackground={alternateBackground}>
      <FormLabel
        sx={{ fontSize: '0.9375rem', px: pxSize, py: pySize, maxWidth: '70%' }}
      >
        {label}
      </FormLabel>
      <RadioGroup row {...inputProps}>
        {radioOptions.map((radioOption, index) => (
          <FormControlLabel
            key={index}
            label={radioOption.label}
            value={radioOption.value}
            control={
              <Radio
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  color: 'grey.30',
                  '&.Mui-checked': {
                    color: '#5D5FEF',
                  },
                }}
              />
            }
          />
        ))}
      </RadioGroup>
    </Wrapper>
  );
}
