import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuoterStore } from '../../hooks/useQuoterStore';

import { IQuoterDiligentSearchPageSchema } from './QuoteDiligentSearchPage.schema';
import { QuoterDiligentSearchPage } from './QuoterDiligentSearchPage';

export const PREV_PAGE_ROUTE = '/quoter/select-start-date';

export function QuoterDiligentSearchPageContainer() {
  const navigate = useNavigate();
  const { input, setInput } = useQuoterStore();

  const onSubmitHandler = useCallback(
    ({ agreeToDiligentSearch }: IQuoterDiligentSearchPageSchema) => {
      setInput({
        agreeToDiligentSearch,
      });

      navigate('/quoter/underwriting-checks');
    },
    [navigate, setInput],
  );

  return (
    <QuoterDiligentSearchPage
      initialValues={{
        agreeToDiligentSearch: input?.agreeToDiligentSearch || false,
      }}
      onSubmit={onSubmitHandler}
    />
  );
}
