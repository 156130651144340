import {
  GetQuotesDocument,
  UnderwriteCheckDocument,
} from '@/graphql/generated';
import { generateEndpoint } from '@/helpers';

export const getQuotesEndpoint = generateEndpoint(GetQuotesDocument);

export const underwriteCheckEndpoint = generateEndpoint(
  UnderwriteCheckDocument,
);
