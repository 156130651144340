import { Box, Typography } from '@mui/material';

interface IProps {
  children?: React.ReactNode;
  title?: string;
}

export function PageTitle({ children, title }: IProps) {
  return (
    <Box mx="auto" mt={3} mb={3} textAlign="center" maxWidth="644px">
      <Typography variant="h1">{title}</Typography>
      {children}
    </Box>
  );
}
