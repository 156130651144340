import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HorizontalRuler } from '@/components/HorizontalRuler';
import { ControllerDropdown, ControllerTextField } from '@/components/Inputs';
import { YearInputMask } from '@/components/Inputs/TextField/YearInputMask';
import { ISelectOptions } from '@/components/Inputs/interfaces';

import { ICustomizeCoverageSchema } from '../CustomizeCoverageOption.schema';
import { Endorsement } from '../Endorsement';
import {
  CARPORT_POOL_ENCLOSURE_ENDORSE_OPTIONS,
  INCREASED_LOSS_ASSESSMENT_OPTIONS,
  MOLD_COMBO_OPTIONS,
} from '../constants';
import { formatCurrencyComboOptions, formatCurrencyOptions } from '../helpers';

interface IProps {
  control: Control<ICustomizeCoverageSchema>;
  percentageOfAOptions: Array<ISelectOptions<number>>;
  ordinanceOrlawPercentages: Array<ISelectOptions<number>>;
  size: ISize;
}

export function EndorsementSection({
  control,
  percentageOfAOptions,
  ordinanceOrlawPercentages,
  size,
}: IProps) {
  const { t } = useTranslation();
  const inputWidth = size === 'lg' ? '428px' : '100%';

  const carportPoolEnclosureEndorseOptions = useMemo(
    () => formatCurrencyOptions(CARPORT_POOL_ENCLOSURE_ENDORSE_OPTIONS),
    [],
  );
  const increasedLossAssessmentOptions = useMemo(
    () => formatCurrencyOptions(INCREASED_LOSS_ASSESSMENT_OPTIONS),
    [],
  );

  const moldOptions = useMemo(
    () => formatCurrencyComboOptions(MOLD_COMBO_OPTIONS),
    [],
  );

  return (
    <Box mt={5}>
      <Typography
        variant="h2"
        sx={{
          pb: { xs: 2, sm: 1.5 },
        }}
      >
        {t('pages.customizeCoveragePage.endorsementsTitle', 'Endorsements')}
      </Typography>
      <Stack>
        <Endorsement
          control={control}
          name="endorsments.mold.isActive"
          label={t('common.labels.mold', 'Mold')}
          tooltip={t(
            'common.tooltips.moldEndorsement',
            'Our default policy includes $10,000 in coverage for fungi (mold), wet or dry rot or bacteria. You may increase the limit here.',
          )}
        >
          <ControllerDropdown
            sx={{
              width: inputWidth,
            }}
            control={control}
            label={t('common.labels.limit', 'Limit')}
            name="endorsments.mold.limit"
            options={moldOptions}
          />
        </Endorsement>
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.ordinanceOrLaw.isActive"
          label={t('common.labels.ordinanceOrLaw', 'Ordinance or Law')}
          tooltip={t(
            'common.tooltips.ordinanceOrLawEndorsement',
            'You may increase coverage or increased costs of reconstruction, repair, and demolition of property that result from local laws or ordinances governing the same.',
          )}
        >
          <ControllerDropdown
            sx={{
              width: inputWidth,
            }}
            control={control}
            label={t('common.labels.limit', 'Limit')}
            name="endorsments.ordinanceOrLaw.limit"
            options={ordinanceOrlawPercentages}
          />
        </Endorsement>
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.increasedLossAssessment.isActive"
          label={t(
            'common.labels.increasedLossAssessment',
            'Increased Loss Assessment',
          )}
          tooltip={t(
            'common.tooltips.increasedLossAssessmentEndorsement',
            'You may increase coverage for covered loss assessments relating to the residence premises.',
          )}
        >
          <ControllerDropdown
            sx={{
              width: inputWidth,
            }}
            control={control}
            label={t('common.labels.limit', 'Limit')}
            name="endorsments.increasedLossAssessment.limit"
            options={increasedLossAssessmentOptions}
          />
        </Endorsement>
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.carportPoolEnclosure.isActive"
          label={t(
            'common.labels.carportPoolEnclosure',
            'Carport, pool cage, and screened enclosure',
          )}
          tooltip={t(
            'common.tooltips.carportPoolEnclosureEndorsement',
            'You may add coverage for loss caused by hurricane to aluminum framing for screened enclosures and carports permanently attached to the dwelling and coverage for screen material or costs associated with removing or replacing screens. This endorsement does not increase the limit of liability for Coverage I.',
          )}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <ControllerDropdown
              sx={{
                width: inputWidth,
              }}
              control={control}
              label={t('common.labels.limit', 'Limit')}
              name="endorsments.carportPoolEnclosure.limit"
              options={carportPoolEnclosureEndorseOptions}
            />
            <ControllerTextField
              sx={{
                width: inputWidth,
              }}
              control={control}
              label={t(
                'common.labels.carportYearInput',
                'Carport year built (if applicable)',
              )}
              name="endorsments.carportPoolEnclosure.year"
              InputProps={{
                inputComponent: YearInputMask as any,
              }}
            />
          </Stack>
        </Endorsement>
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.animalLiability.isActive"
          label={t('common.labels.animalLiability', 'Animal Liability')}
          tooltip={t(
            'common.tooltips.animalLiabilityEndorsement',
            'Animal liability coverage includes a 50k sublimit for personal liability and 5k sublimit on medical payments.',
          )}
        />
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.waterBackupAndSump.isActive"
          label={t(
            'common.labels.waterBackupAndSump',
            'Water Back Up and Sump overflow',
          )}
          tooltip={t(
            'common.tooltips.waterBackupAndSumpEndorsement',
            'Water back up and sump overflow coverage includes a sublimit of 5k subject to a $250 deductible.',
          )}
        />
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.personalInjury.isActive"
          label={t('common.labels.personalInjury', 'Personal Injury')}
          tooltip={t(
            'common.tooltips.personalInjuryEndorsement',
            'You may add coverage for personal injuries, including false arrests, wrongful eviction or entry, invasion of privacy, slander, and defamation.',
          )}
        />
        <HorizontalRuler />
        <Endorsement
          control={control}
          name="endorsments.personalPropertyReplacement.isActive"
          label={t(
            'common.labels.personalPropertyReplacement',
            'Personal Property Replacement Cost',
          )}
          tooltip={t(
            'common.tooltips.personalPropertyReplacementEndorsement',
            'Our base policy provides for payment on an actual cash value (ACV) basis for personal property. You may choose to be reimbursed on a replacement cost value basis instead.',
          )}
        />
      </Stack>
    </Box>
  );
}
