import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EnhancedStore } from '@reduxjs/toolkit';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { theme as defaultTheme } from '@/config';
import { RootState, store as defaultStore } from '@/store/configureStore';

import { HeaderContainer } from '../Header/Header.container';
import { Initializer } from '../Initializer';
import { Router } from '../Router';

interface IProps {
  store?: EnhancedStore<RootState>;
  theme?: Theme;
}

export function App({ store = defaultStore, theme = defaultTheme }: IProps) {
  return (
    <Suspense fallback="Loading">
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Initializer>
              <BrowserRouter>
                <HeaderContainer />
                <Router />
              </BrowserRouter>
            </Initializer>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </Suspense>
  );
}
