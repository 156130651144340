import { styled, Switch as MUISwitch } from '@mui/material';

const DURATION = 500;

export const Switch = styled(MUISwitch)(({ theme }) => ({
  height: 28,
  overflow: 'unset',
  padding: 0,
  width: 54,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      color: theme.palette.grey['00'],
      transform: 'translateX(26px)',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.success.main,
        border: `1px solid ${theme.palette.success.main}`,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb': {
        border: `1px solid ${theme.palette.success.main}`,
        boxShadow: '-2px 2px 5px rgba(0, 0, 0, 0.3)',
        transition: `box-shadow ${DURATION * 0.6}ms ease-in-out`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    border: `1px solid ${theme.palette.grey[40]}`,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    height: 28,
    width: 28,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey['00'],
    border: `1px solid ${theme.palette.grey[40]}`,
    borderRadius: 28 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: DURATION,
    }),
  },
}));
