import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { PageTitle } from '../../components/App/PageTitle';
import { QuoteStepperContainer } from '../../components/QuoteStepper';
import { SVG } from '../../components/SVGs/SVG';
import { shadowBoxes } from '../../config/theme/base';
import { FontWeight } from '../../config/theme/typography';
import { diligentSearchPDF } from '../../helpers/publicAssetLinks';

const DROPZONE_BOX_SHADOW = 'inset 0px 0px 4px 2px rgba(255, 255, 255, 0.93)';

interface IProps {
  initialValue?: any;
  onRemove: () => void;
  onSubmit: (values: any) => void;
}

export function QuoterUploadDiligentSearchPage({
  initialValue,
  onRemove,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<any>(initialValue);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: (acceptedFiles) => {
      setUploadedFile(acceptedFiles[0]);
    },
  });

  const onRemoveHandler = () => {
    onRemove();
    setUploadedFile(false);
  };

  return (
    <Stack>
      <QuoteStepperContainer />
      <PageTitle
        title={t(
          'pages.quoterUploadDiligentSearchPage.title',
          'Upload Diligent Search',
        )}
      >
        <Typography mt={2} sx={{ lineHeight: '145%', maxWidth: '644px' }}>
          {t(
            'pages.quoterUploadDiligentSearchPage.description',
            'We require a completed copy of our Diligent Search form. Please upload your completed copy below.',
          )}
        </Typography>
      </PageTitle>
      <Button
        component="a"
        download
        href={diligentSearchPDF}
        sx={{
          color: 'primary.dark',
          width: '274px',
          margin: 'auto',
        }}
        startIcon={
          <SVG
            name="Download"
            sx={{ width: '1.5rem', color: 'primary.dark' }}
          />
        }
      >
        {t(
          'common.labels.downloadDiligentSearchForm',
          'Download Diligent search form',
        )}
      </Button>
      <Box component="form" display="flex" justifyContent="center" mt={5}>
        {uploadedFile ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{
              width: '700px',
              backgroundColor: 'grey.00',
              boxShadow: shadowBoxes.type1,
              borderRadius: 4,
            }}
          >
            <Stack direction="row" maxWidth="75%" alignItems="baseline">
              <Typography
                variant="h2"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  direction: 'rtl',
                }}
              >
                {uploadedFile.name}
              </Typography>
              <SVG
                name="CheckMark"
                width="24px"
                sx={{
                  marginLeft: 2,
                  padding: 0.25,
                  color: 'grey.00',
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                }}
              />
            </Stack>
            <IconButton
              size="small"
              onClick={() => onRemoveHandler()}
              disableRipple
            >
              <SVG name="TrashCan" width="21px" sx={{ color: 'grey.70' }} />
            </IconButton>
          </Stack>
        ) : (
          <Stack
            {...getRootProps()}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '612px',
              height: '212px',
              border: '1px dashed',
              borderColor: 'success.main',
              borderRadius: 3,
              backgroundColor: 'rgba(255, 255, 255, 0.34)',
              boxShadow: DROPZONE_BOX_SHADOW,
              gap: '10px',
            }}
          >
            <input {...getInputProps()} multiple={false} />
            <Stack
              justifyContent="center"
              direction="row"
              sx={{
                width: 32,
                height: 32,
                backgroundColor: '#59ABE626',
                borderRadius: '6px',
              }}
            >
              <SVG name="Upload" width="18px" sx={{ color: 'success.main' }} />
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography
                fontSize="1.3125rem"
                fontWeight={FontWeight.medium}
                lineHeight="145%"
                color="grey.80"
              >
                Drag and drop file, or
              </Typography>
              <Typography
                color="success.main"
                fontSize="1.3125rem"
                fontWeight={FontWeight.medium}
                lineHeight="145%"
                sx={{ cursor: 'pointer' }}
              >
                Upload
              </Typography>
            </Stack>
            <Typography
              fontSize="15px"
              fontWeight={FontWeight.regular}
              color="grey.70"
              lineHeight="21.75px"
            >
              Only PDF files are supported
            </Typography>
          </Stack>
        )}
      </Box>
      <QuoterActionBarContainer
        backText="Confirm Start Date"
        hideSaveExit={true}
        onBack="/quoter/confirm-start-date"
      >
        <Button
          color="secondary"
          disabled={!uploadedFile}
          onClick={() => onSubmit(uploadedFile)}
          variant="contained"
        >
          {t('common.actions.continue')}
        </Button>
      </QuoterActionBarContainer>
    </Stack>
  );
}
