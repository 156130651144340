import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QuoterActionBarContainer } from '../../components/ActionBar';
import { Button } from '../../components/Button';
import { CompareAndShareQuotes } from '../../components/CompareAndShareQuotes';
import { generateEmptyQuote } from '../../components/CustomCoverageOption/helpers';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { IQuoteOption } from '../../store/quoter';

import { ShareQuoteModalContainer } from './QuoterSharePage.modal.container';

export const PREV_PAGE_ROUTE = '/quoter/customize-coverage';

export function QuoterSharePageContainer() {
  const { t } = useTranslation();
  const { input, setInput } = useQuoterStore();
  const [shareQuotesOpen, setShareQuotesOpen] = useState(false);

  // hooks
  const client = useMemo(() => {
    return input?.selectClient
      ? input.selectClient
      : {
          email: 'Marvin.McKinney@arbolmarket.com',
          profile: { firstName: 'Marvin', lastName: 'McKinney' },
        };
  }, [input.selectClient]);

  const [quotes, setQuotes] = useState<IQuoteOption[]>(
    input?.quotes?.length
      ? input.quotes
      : [generateEmptyQuote(), generateEmptyQuote(), generateEmptyQuote()],
  );

  // handlers
  const openShareModal = useCallback(() => {
    setShareQuotesOpen(true);
  }, []);

  const onSelectQuoteHandler = useCallback(
    (quoteId: string) => {
      const index = quotes.findIndex((quote) => quote.id === quoteId);
      const newQuotes = [...quotes];
      newQuotes[index] = {
        ...newQuotes[index],
        selected: !newQuotes[index].selected,
      };
      setQuotes(newQuotes);
      setInput({
        quotes: [...newQuotes],
      });
    },
    [quotes, setInput],
  );

  return (
    <Fragment>
      <CompareAndShareQuotes
        title={t('pages.sharePage.title', 'Select options to share')}
        actionBar={
          <QuoterActionBarContainer
            backText={t('pages.customizeCoveragePage.title')}
            onBack={PREV_PAGE_ROUTE}
          >
            <Button
              color="secondary"
              onClick={openShareModal}
              variant="contained"
            >
              {t('pages.sharePage.submit', 'Share with client')}
            </Button>
          </QuoterActionBarContainer>
        }
        onSelectQuote={onSelectQuoteHandler}
        quotes={quotes || []}
      />
      <ShareQuoteModalContainer
        client={client}
        open={shareQuotesOpen}
        setShareQuotesOpen={setShareQuotesOpen}
        quotes={quotes}
      />
    </Fragment>
  );
}
