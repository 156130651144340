import { Box, Stack } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressInfoStrip } from '@/components/AddressInfoStrip';
import { Button } from '@/components/Button';
import { IAddress } from '@/store/quoter';

import { BaseModal } from '../BaseModal';

interface IProps {
  address?: IAddress;
  name?: string;
  createdAt?: string;
  onCreateNewQuote: () => void;
  onUseExistingQuote: () => void;
  onCancel: () => void;
}

export function ExistingQuoteModal({
  address,
  createdAt,
  name,
  onCancel,
  onCreateNewQuote,
  onUseExistingQuote,
  ...baseModal
}: IProps & ComponentProps<typeof BaseModal>): JSX.Element {
  const { t } = useTranslation();

  return (
    <BaseModal
      {...baseModal}
      onClose={onCancel}
      title={t('modals.existingQuote.title', 'Pick up where you left off?')}
      actionBar={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Button onClick={onCancel} variant="text" sx={{ color: 'grey.70' }}>
            {t('common.actions.cancel')}
          </Button>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Button
              onClick={onCreateNewQuote}
              color="primary"
              sx={{ color: 'primary.dark' }}
              variant="text"
            >
              {t('modals.existingQuote.newProspectRequest', 'Create new quote')}
            </Button>
            <Button
              onClick={onUseExistingQuote}
              color="primary"
              variant="contained"
              sx={{ py: 0, px: 1.5 }}
            >
              {t(
                'modals.existingQuote.reuseProspectRequest',
                'Pick up where I left off',
              )}
            </Button>
          </Stack>
        </Box>
      }
    >
      {t(
        'modals.existingQuote.description',
        'You already have a quote started for {{name}}, would you like to pick up where you left off for property',
        {
          name,
        },
      )}
      <Box mt={4}>
        <AddressInfoStrip createdAt={createdAt} address={address} />
      </Box>
    </BaseModal>
  );
}
