import { Box, Stack, Typography } from '@mui/material';

import { SVG } from '../../components/SVGs/SVG';
import { formatAddress } from '../../helpers/formatters';
import { IAddress } from '../../store/quoter';

interface IProps {
  address: IAddress;
  nickname?: string;
}
export function PropertyCard({ address, nickname = 'Home' }: IProps) {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'grey.30',
        padding: 1.5,
        borderRadius: 2,
        marginRight: 4,
      }}
    >
      <Stack direction="row" alignItems="center">
        <SVG
          name="Home"
          width="15.83px"
          height="14.25px"
          sx={{ color: 'grey.60' }}
        />
        <Typography
          variant="body2"
          color="grey.60"
          mx="5px"
          lineHeight="23.2px"
          textTransform="uppercase"
        >
          {nickname}
        </Typography>
      </Stack>
      <Typography>{formatAddress(address)}</Typography>
    </Box>
  );
}
