import { styled } from '@mui/material';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { Z_INDEXES } from '@/config/zIndexes';
import { mapByKey, maxReducer } from '@/helpers';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';

import { COMPONENT_CLASS_IDENTIFER } from './OptionHeader';

const DEFAULT_MIN_HEIGHT = 115;

// HELPERS
const getTriggerElement = () =>
  document.querySelector(`.${COMPONENT_CLASS_IDENTIFER}`);

const getTriggerElements = () =>
  document.querySelectorAll(`.${COMPONENT_CLASS_IDENTIFER}`);

const getMinHeight = (elements: NodeListOf<HTMLElement>) =>
  elements
    ? Array.from(elements).map(mapByKey('offsetHeight')).reduce(maxReducer, 0)
    : DEFAULT_MIN_HEIGHT;

const FloaterBlock = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey['00']};

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: ${Z_INDEXES.COVERAGE_OPTION_HEADER - 1};
`;

interface IProps {
  triggerUpdate: string | number | boolean;
}

export function Floater({ triggerUpdate }: IProps) {
  const [minHeight, setMinHeight] = useState(DEFAULT_MIN_HEIGHT);
  const triggerElement = getTriggerElement();

  const isIntersecting = useIntersectionObserver(triggerElement, {
    root: null,
    rootMargin: '0px 0px -100%',
    threshold: 0,
  });

  const onResize = useCallback(() => {
    const triggerElements = getTriggerElements();
    const newMinHeight = getMinHeight(
      triggerElements as NodeListOf<HTMLElement>,
    );
    setMinHeight(newMinHeight);
  }, []);

  // Resize on window resize
  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  // Resize on triggerUpdate (quote count change)
  useLayoutEffect(() => {
    if (triggerUpdate) {
      onResize();
    }
  }, [onResize, triggerUpdate]);

  return (
    <FloaterBlock
      sx={{
        minHeight: minHeight,
        opacity: isIntersecting ? 1 : 0,
        transform: isIntersecting ? 'translateY(0)' : 'translateY(-20%)',
        pointerEvents: 'none',
        transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in',
      }}
    />
  );
}
