import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActivePolicyRow } from '../../components/ActivePolicyRow';
import { formatDate } from '../../helpers/formatters';

interface IPolicy {
  _id: string;
  endDate: string;
  nickname?: string;
  policyNumber: string;
  startDate: string;
  status: string;
}

interface IProps {
  policies?: IPolicy[];
}

export function ClientActivePolicies({ policies }: IProps) {
  const { t } = useTranslation();
  return (
    <Stack px={3}>
      <Typography variant="h2">
        {t('pages.clientsPage.activePolicies', 'Active Policies')}
      </Typography>
      {policies ? (
        policies.map((policy) => (
          <ActivePolicyRow
            key={policy._id}
            endDate={formatDate(policy.endDate)}
            startDate={formatDate(policy.startDate)}
            policyNumber={policy.policyNumber}
            policyId={policy._id}
            status={policy.status}
            nickname={policy.nickname}
          />
        ))
      ) : (
        <Typography>NO ACTIVE POLICIES</Typography>
      )}
    </Stack>
  );
}
