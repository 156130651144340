import { Paper, Stack, Typography } from '@mui/material';
import {
  DataGridProps,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { shadowBoxes } from '@/config/theme/base';
import { FontWeight } from '@/config/theme/typography';

import { Button } from '../Button';

import { StyledDataTable } from './StyledDataTable';

interface IProps extends DataGridProps {
  columns: GridColDef[];
  isLoading?: boolean;
  name: string;
  onCheck?: (model: GridSelectionModel, event: any) => void;
  onCompare?: () => void;
  rowCount: number;
  selectedRows: string[];
}

export function CompareQuotesDataTable({
  columns,
  isLoading,
  name,
  onCheck,
  onCompare,
  rowCount,
  rows,
  selectedRows,
  ...rest
}: IProps) {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        boxShadow: shadowBoxes.type1,
        borderRadius: 2,
        marginBottom: 3,
        padding: 4,
      }}
    >
      <Typography
        sx={{
          color: 'grey.80',
          fontSize: '1.75rem',
          fontWeight: FontWeight.semibold,
          marginBottom: 2,
          paddingLeft: 3,
        }}
      >
        {name}&apos;s {t('common.labels.quotes', 'Quotes')}
      </Typography>
      <StyledDataTable
        {...rest}
        autoHeight
        checkboxSelection
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        hideFooter
        loading={isLoading}
        onSelectionModelChange={onCheck}
        rowCount={rowCount}
        rows={rows}
        selectionModel={selectedRows}
        sx={{
          width: '100%',
        }}
      />
      <Stack direction="row" alignItems="center" pt={3}>
        <Button
          disabled={selectedRows?.length < 2}
          onClick={onCompare}
          variant="contained"
          sx={{
            fontWeight: FontWeight.semibold,
            height: '31px',
            width: '195px',
            '&.Mui-disabled': {
              backgroundColor: 'grey.40',
              color: 'grey.60',
            },
          }}
        >
          {t('pages.quoteDetails.compareSideBySide', 'Compare side-by-side')}
        </Button>
        <Typography
          sx={{
            color: 'grey.80',
            fontSize: '1rem',
            lineHeight: '23.2px',
            paddingLeft: 3,
          }}
        >
          {t(
            'pages.quoteDetails.selectUpToThree',
            'Select up to three Quotes to View Comparison',
          )}
        </Typography>
      </Stack>
    </Paper>
  );
}
