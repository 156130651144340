import { Grid, Typography } from '@mui/material';

import { theme } from '@/config';
import { FontWeight } from '@/config/theme/typography';

interface IProps {
  name?: string;
  description?: string;
  value?: string;
}

export function QuoterReviewCoverageRow({ name, description, value }: IProps) {
  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        pb: 3,
        borderBottom: `1px solid ${theme.palette.grey[30]}`,
        py: 3.3125,
      }}
    >
      <Grid item xs={9}>
        <Typography
          sx={{
            fontSize: '1.0625rem',
            fontWeight: FontWeight.medium,
            color: 'grey.90',
            lineHeight: 1.45,
            pb: '10.5px',
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: '0.9375rem',
            color: 'grey.70',
            lineHeight: 1.45,
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          sx={{
            fontSize: '1.3125rem',
            fontWeight: FontWeight.semibold,
            color: 'grey.90',
            lineHeight: 1.45,
            textAlign: 'right',
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}
