import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuoterStore } from '../../hooks/useQuoterStore';

import { QuoterSelectStartDatePage } from './QuoterSelectStartDatePage';
import { IQuoterSelectStartDateSchema } from './QuoterSelectStartDatePage.schema';

interface IProps {
  confirmation?: boolean;
}

export function QuoterSelectStartDatePageContainer({
  confirmation = false,
}: IProps) {
  const navigate = useNavigate();
  const { input, setInput } = useQuoterStore();

  const initialValue = input?.startDate;

  const nextRoute = confirmation
    ? '/quoter/upload-diligent-search'
    : '/quoter/diligent-search';

  const onSubmitHandler = useCallback(
    (values: IQuoterSelectStartDateSchema) => {
      const startDate = values.startDate?.toISOString();

      setInput({
        startDate,
      });

      navigate(nextRoute);
    },
    [navigate, setInput, nextRoute],
  );

  return (
    <QuoterSelectStartDatePage
      confirmation={confirmation}
      onSubmit={onSubmitHandler}
      initialValue={initialValue}
    />
  );
}
