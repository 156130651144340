import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CompanyInfoCell,
  CompanyInfoRow,
} from '../../components/CompanyInfoRow';
import { EditCompanyContactsModal } from '../../components/Modals';
import { SVG } from '../../components/SVGs/SVG';
import { OrgContact } from '../../graphql/generated';
import { formatPhoneNumber } from '../../helpers/formatters';
import { useUpdateOrganizationContactMutation } from '../../store/api';

import { ICompanyContactsSchema } from './CompanyContacts.schema';

interface IProps {
  loading: boolean;
  accountingContact?: OrgContact;
  orgId: string;
  primaryContact?: OrgContact;
  userIsAdmin: boolean;
}

export function CompanyContactsContainer({
  loading,
  accountingContact,
  orgId,
  primaryContact,
  userIsAdmin,
}: IProps) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updateOrganizationContact] = useUpdateOrganizationContactMutation();

  const initialModalValues = {
    accounting: {
      name: accountingContact?.name || '',
      email: accountingContact?.email || '',
      phone: accountingContact?.phone || '',
    },
    primary: {
      name: primaryContact?.name || '',
      email: primaryContact?.email || '',
      phone: primaryContact?.phone || '',
    },
  } as ICompanyContactsSchema;

  const onSubmitHandler = useCallback(
    async (values: ICompanyContactsSchema) => {
      const { primary, accounting } = values;

      const physicalResponse = await updateOrganizationContact({
        organization: orgId,
        contact: primaryContact?._id || '',
        input: primary,
      });

      const mailingResponse = await updateOrganizationContact({
        organization: orgId,
        contact: accountingContact?._id || '',
        input: accounting,
      });

      if ('data' in physicalResponse && 'data' in mailingResponse) {
        setDialogOpen(false);
      } else {
        console.log('Error udpating contacts');
      }
    },
    [updateOrganizationContact, orgId, primaryContact, accountingContact],
  );

  return (
    <Fragment>
      <CompanyInfoRow
        icon={<SVG name="Person" height={32} />}
        loading={loading}
        onEdit={userIsAdmin ? () => setDialogOpen(true) : undefined}
      >
        {primaryContact && (
          <CompanyInfoCell
            title={t(
              'pages.myInformationPage.primaryContact',
              'Primary Contact',
            )}
            rows={[
              primaryContact?.name || '',
              primaryContact?.email || '',
              formatPhoneNumber(primaryContact.phone),
            ]}
          />
        )}
        {accountingContact && (
          <CompanyInfoCell
            title={t(
              'pages.myInformationPage.accountingContact',
              'Accounting Contact',
            )}
            rows={[
              accountingContact?.name || '',
              accountingContact?.email || '',
              formatPhoneNumber(accountingContact.phone),
            ]}
          />
        )}
      </CompanyInfoRow>
      <EditCompanyContactsModal
        initialValues={initialModalValues}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={onSubmitHandler}
      />
    </Fragment>
  );
}
