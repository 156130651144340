import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export const compareQuoteColumns: GridColDef[] = [
  {
    align: 'center',
    field: 'createdAt',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'CREATED ON',
    hideSortIcons: true,
    minWidth: 130,
    type: 'date',
  },
  {
    align: 'left',
    field: 'id',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'ID',
    hideSortIcons: true,
    minWidth: 90,
  },
  {
    align: 'left',
    field: 'premium',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'PREMIUM',
    hideSortIcons: true,
    minWidth: 150,
  },
  {
    align: 'left',
    field: 'dwelling',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'custom-header-class',
    headerName: 'DWELLING',
    hideSortIcons: true,
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'parametricLimit',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'PARAMETRIC LIMIT',
    hideSortIcons: true,
    minWidth: 150,
  },
  {
    align: 'center',
    cellClassName: 'status-cell',
    field: 'status',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'custom-header-class',
    headerName: 'STATUS',
    hideSortIcons: true,
    minWidth: 80,
  },
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    cellClassName: 'actions',
    renderCell: (params: GridRenderCellParams<string>) => {
      return params.id === params.row.quoteId ? (
        <VisibilityOutlinedIcon
          sx={{ width: '16px', color: 'secondary.main' }}
        />
      ) : (
        ''
      );
    },
  },
];
