import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Upload(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 17.25a.75.75 0 1 1-1.5 0v-5.69l-1.72 1.721a.751.751 0 0 1-1.062-1.062l3-3a.75.75 0 0 1 1.062 0l3 3a.75.75 0 0 1-1.061 1.062l-1.72-1.72v5.689Z"
        fill="currentColor"
      />
      <path
        d="M18 21V6.75L11.25 0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3ZM11.25 4.5a2.25 2.25 0 0 0 2.25 2.25h3V21a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 21V3A1.5 1.5 0 0 1 3 1.5h8.25v3Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
