import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBindingInput, IBindingState } from './interfaces';

export const initialBindingState: IBindingState = {
  input: {
    diligentSearchUpload: null,
    signatureImage: null,
    quoteId: null,
  },
};

export const bindingSlice = createSlice({
  name: 'binding',
  initialState: initialBindingState,
  reducers: {
    reset: () => ({ ...initialBindingState }),
    setInput: (state, action: PayloadAction<IBindingInput>) => {
      const { payload } = action;
      state.input = {
        ...state.input,
        ...payload,
      };

      return state;
    },
  },
});

export const { reset, setInput } = bindingSlice.actions;
