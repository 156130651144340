import { Box, Button, Typography } from '@mui/material';

import { EmailSentModal } from './EmailSentModal';

interface IProps {
  isLoading?: boolean;
  onClose?: () => void;
  onResend: () => void;
  open: boolean;
  sent?: boolean;
}

export function ResendEmailConfirmationModal({
  isLoading,
  onResend,
  open,
  sent,
  onClose,
}: IProps) {
  const hideActionBar = !sent;

  return (
    <EmailSentModal
      hideActionBar={hideActionBar}
      hideCloseButton={sent}
      onClose={onClose}
      open={open}
      title={sent ? 'Email sent' : 'Resend confirmation?'}
    >
      <Box minWidth="325px">
        <Typography sx={{ color: 'grey.80' }}>
          {!sent &&
            "Didn't receive a confirmation email? Please click the button below to resend."}
          {sent && 'Verification email has been resent.'}
        </Typography>
        {!sent && (
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              onClick={onResend}
              disabled={isLoading || sent}
              sx={{ mt: 2 }}
            >
              Resend
            </Button>
          </Box>
        )}
      </Box>
    </EmailSentModal>
  );
}
