import { api } from './api';

export const {
  useAddPropertyMutation,
  useAuthorizeMutation,
  useChangePasswordMutation,
  useConfirmUserMutation,
  useCreateClientUserMutation,
  useCurrentUserQuery,
  useForgotPasswordMutation,
  useGetOrganizationQuery,
  useGetPropertiesQuery,
  useGetPropertySpecsQuery,
  useGetQuotesQuery,
  useGetUsersQuery,
  useInviteOrgAgentMutation,
  useLazyCurrentUserQuery,
  useLazyGetPropertiesQuery,
  useLazyGetPropertySpecsQuery,
  useLazyGetQuotesQuery,
  useLazyGetUsersQuery,
  useLoginMutation,
  useRegisterUserMutation,
  useResendConfirmMutation,
  useResetPasswordMutation,
  useReviewPropertyMutation,
  useUnderwriteCheckMutation,
  useUpdateOrganizationContactMutation,
  useUpdateOrganizationLocationAddressMutation,
  useUpdatePropertyMutation,
  useUpdateUserMutation,
} = api;
