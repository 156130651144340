import { z } from 'zod';

import i18n from '../../config/i18n';

export function getQuoterReviewPropertySchema() {
  const booleanSchema = z
    .string()
    .refine(
      (value) =>
        value === '' ||
        value.toLowerCase() === 'true' ||
        value.toLowerCase() === 'false',
      {
        message: i18n.t(
          'validation.boolean.invalid',
          'Invalid value. Expected True | False',
        ),
      },
    );

  return z.object({
    constructionType: z.string().optional(),
    hasBurglaryAlarm: booleanSchema.optional(),
    hasFireAlarm: booleanSchema.optional(),
    hasSprinklerSystem: booleanSchema.optional(),
    isSeasonalProperty: booleanSchema.optional(),
    roofMaterial: z.string().optional(),
    roofYear: z.string().optional(),
    yearBuilt: z.string().optional(),
  });
}

export type IQuoterReviewPropertySchema = z.infer<
  ReturnType<typeof getQuoterReviewPropertySchema>
>;
