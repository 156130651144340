import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function Sliders(props: StyledSVGProps) {
  return (
    <StyledSVG
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.333 5.5V3.833h2.5V.5H12.5v3.333H15V5.5H8.333Zm2.5 10V7.167H12.5V15.5h-1.667Zm-8.333 0v-3.333H0V10.5h6.667v1.667h-2.5V15.5H2.5Zm0-6.667V.5h1.667v8.333H2.5Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
