import { z } from 'zod';

export function getCustomizeCoverageSchema() {
  return z.object({
    id: z.string().optional(),

    // coverages
    mainHomeCoverage: z.string(), // coverageA
    otherStructureCoverage: z.string(), // coverageB
    personalPropertyCoverage: z.string(), // coverageC
    lossOfUseCoverage: z.string(), // coverageD
    personalLiabilityCoverage: z.string(), // coverageE
    medicalPaymentsCoverage: z.string(), // coverageF

    // floaters
    parametricLimit: z.string(),
    waterDamageLimit: z.string(),

    // deductible
    allOtherPerilsDeductible: z.string(),
    hurricaneDeductible: z.string(),
    waterDeductible: z.string(),

    endorsments: z.object({
      animalLiability: z.object({
        isActive: z.boolean().optional(),
      }),
      carportPoolEnclosure: z.object({
        isActive: z.boolean().optional(),
        limit: z.string().optional(),
        year: z.string().optional(),
      }),
      mold: z.object({
        isActive: z.boolean().optional(),
        limit: z.string().optional(),
      }),
      increasedLossAssessment: z.object({
        isActive: z.boolean().optional(),
        limit: z.string().optional(),
      }),
      ordinanceOrLaw: z.object({
        isActive: z.boolean().optional(),
        limit: z.string().optional(),
      }),
      personalInjury: z.object({
        isActive: z.boolean().optional(),
      }),
      personalPropertyReplacement: z.object({
        isActive: z.boolean().optional(),
      }),
      waterBackupAndSump: z.object({
        isActive: z.boolean().optional(),
      }),
    }),
  });
}

export type ICustomizeCoverageSchema = z.infer<
  ReturnType<typeof getCustomizeCoverageSchema>
>;

export type ICustomizeCoverageSchemaDeepParial = Partial<
  Omit<ICustomizeCoverageSchema, 'endorsments'>
> & {
  endorsments?: Partial<ICustomizeCoverageSchema['endorsments']>;
};
