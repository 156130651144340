import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { redirects } from '@/config/redirects';
import { useAuth } from '@/hooks';

export function ProtectedRouteCheck() {
  const { isLoggedIn, isConfirmed } = useAuth();
  const location = useLocation();

  if (!isLoggedIn || !isConfirmed) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={redirects.unprotectedRoute}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
}
