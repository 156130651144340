import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { QuoterReviewCoveragePage } from './QuoterReviewCoveragePage';

// TODO: replace with real data
const coveragePlaceholderData = [
  {
    name: 'Dwelling / Main Home / Coverage I',
    value: '$400,000.00',
    description:
      'Dwelling insurance covers damage to your home’s main structure. Your structure is insured against most events, but not against any named exclusions. Unlike traditional HO-3 policies, this policy does not cover your home’s roof when damaged as a result of weather.',
  },
  {
    name: 'Other structure / Coverage II',
    value: '$11,250 (2%)',
    description:
      'Other Structures insurance covers any additional structures on your property like garages and fences. Your structure is insured against most events, but not against any named exclusions.',
  },
  {
    name: 'Personal Property / Coverage III',
    value: '$112,000 (25%)',
    description:
      'Personal Property insurance protects your belongings when stolen, lost, or damaged as a result of only the perils listed in your policy',
  },
  {
    name: 'Loss of Use / Coverage IV',
    value: '$35,000.00',
    description:
      'Loss of Use insurance pays any necessary increase in living expenses incurred as a result of a covered loss that makes your home not fit for living.',
  },
  {
    name: 'Personal Liability / Coverage V',
    value: '5,000',
    description:
      'Personal Liability insurance pays in the event that a claim is made against you due to bodily injury or property damage that occurs on your property',
  },
  {
    name: 'Medical Payments to Others / Coverage VI',
    value: '$5,000)',
    description:
      'Medical Payments to Others insurance pays necessary medical expenses incurred as a result of bodily injury.',
  },
  {
    name: 'Parametric Expense',
    value: '$25,000',
    description:
      'Additional Hurricane Parametric Expense insurance pays expenses incurred resulting from a hurricane. This is a parametric coverage link. When applicable, loss is calculated based on the distance of the hurricane from your home and on wind speed.',
  },
  { name: 'All Other Perils', value: '$10,000', description: null },
  { name: 'Hurricane', value: '$15,000.00', description: null },
  { name: 'Mold', value: '$15,000.00', description: null },
  { name: 'Carport Amount', value: '$10,000.00', description: null },
  { name: 'Carport Age', value: '10 years', description: null },
];

export function QuoterReviewCoveragePageContainer() {
  const navigate = useNavigate();

  // Handlers
  const onNextHandler = useCallback(() => {
    navigate('/quoter/add-signature');
  }, [navigate]);

  return (
    <QuoterReviewCoveragePage
      options={coveragePlaceholderData}
      onNext={onNextHandler}
    />
  );
}
