import { forwardRef, RefCallback } from 'react';
import { NumericFormat } from 'react-number-format';

interface IProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const CurrencyInputMask = forwardRef<HTMLElement, IProps>(
  function CurrencyInputMask(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        valueIsNumericString
        onValueChange={(values: any) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        getInputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
        decimalScale={2}
        thousandSeparator
        prefix="$"
      />
    );
  },
);
