import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useMemo } from 'react';

import {
  OrgContact,
  OrgContactType,
  OrgLocation,
  OrgLocationType,
} from '../../graphql/generated';
import { useAuth } from '../../hooks';
import { useGetOrganizationQuery } from '../../store/api';

import { CompanyAddressesContainer } from './CompanyAddresses.container';
import { CompanyContactsContainer } from './CompanyContacts.container';

const DetailText = styled(Typography)(({ theme }) => ({
  fontSize: '1.0625rem',
  lineHeight: '145%',
  color: theme.palette.grey[90],
}));

export function CompanyInfoContainer() {
  const { user: { organization } = {}, userIsAdmin } = useAuth();

  const orgId = organization?._id || '';

  const { data: { getOrganization: company } = { data: {} }, isLoading } =
    useGetOrganizationQuery({
      id: orgId,
    });

  const {
    physicalLocation,
    mailingLocation,
    primaryContact,
    accountingContact,
  } = useMemo(() => {
    const physicalLocation = company?.locations?.find(
      (location) => location?.type === OrgLocationType.Physical,
    ) as OrgLocation;

    const mailingLocation = company?.locations?.find(
      (location) => location?.type === OrgLocationType.Mailing,
    ) as OrgLocation;

    const primaryLocation = company?.locations?.find(
      (location) => location?.primaryLocation === true,
    ) as OrgLocation;
    const primaryContact = primaryLocation?.contacts?.find(
      (contact) => contact?.type === OrgContactType.Primary,
    ) as OrgContact;

    const accountingContact = primaryLocation?.contacts?.find(
      (contact) => contact?.type === OrgContactType.Accounting,
    ) as OrgContact;
    return {
      physicalLocation,
      mailingLocation,
      primaryContact,
      accountingContact,
    };
  }, [company]);

  if (!isLoading && !company) return null;

  return (
    <Paper sx={{ padding: 5 }} elevation={0}>
      <Typography variant="h1" lineHeight="145%" sx={{ paddingBottom: 1 }}>
        {isLoading ? <Skeleton width="25%" /> : company?.name}
      </Typography>
      <DetailText>
        {isLoading ? <Skeleton width="25%" /> : company?.website}
      </DetailText>
      <Stack pt={4} spacing={3}>
        <CompanyAddressesContainer
          loading={isLoading}
          mailingLocation={mailingLocation}
          orgId={orgId}
          physicalLocation={physicalLocation}
          userIsAdmin={userIsAdmin}
        />
        <CompanyContactsContainer
          loading={isLoading}
          accountingContact={accountingContact}
          orgId={orgId}
          primaryContact={primaryContact}
          userIsAdmin={userIsAdmin}
        />
      </Stack>
    </Paper>
  );
}
