import { Box, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useEndorsementsStore } from '@/hooks/useEndorsementsStore';
import {
  MidTermEndorsements,
  MidTermEndorsementKeys,
} from '@/store/midtermEndorsements';

import { PageTitle } from '../App/PageTitle';
import { MidtermSelectBox } from '../MidtermSelectBox/MidtermSelectBox';

export function MidtermEndorsementOptions() {
  const { t } = useTranslation();
  const { input, setInput } = useEndorsementsStore();
  const navigate = useNavigate();

  const handleRemove = useCallback(
    (type: MidTermEndorsementKeys) => {
      const endorsements = input.filter((item) => item.coverage !== type);
      setInput(endorsements);
    },
    [setInput, input],
  );

  const endorsementOptions = useMemo(() => {
    return Object.keys(MidTermEndorsements).map((key) => ({
      title: MidTermEndorsements[key as MidTermEndorsementKeys],
      coverage: key,
    }));
  }, []);

  return (
    <Box py={3} px={9}>
      <PageTitle
        title={t('pages.midtermEndorsements.title', 'Mid-term Endorsements')}
      />
      <Stack justifyContent="center" direction="row" flexWrap="wrap" gap={3}>
        {endorsementOptions.map((option) => (
          <MidtermSelectBox
            key={option.title}
            title={option.title}
            description={
              input.find((item) => item.coverage === option.coverage)
                ?.description
            }
            selected={input.some((item) => item.coverage === option.coverage)}
            onRemove={() =>
              handleRemove(option.coverage as MidTermEndorsementKeys)
            }
            onClick={() => navigate(option.coverage)}
          />
        ))}
      </Stack>
    </Box>
  );
}
