import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';

import { Button } from './../Button';
import { BaseModal } from './BaseModal';

interface IProps {
  children?: React.ReactNode;
  onClose?: () => void;
  onSubmit?: () => void;
  open: boolean;
}

export function AddEndorsementsConfirmationModal({
  onClose,
  onSubmit,
  open,
}: IProps) {
  const { t } = useTranslation();
  return (
    <BaseModal
      hideCloseButton={true}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
        },
      }}
      title={t(
        'modals.addEndorsementsConfirmation.title',
        'Add mid-term endorsements',
      )}
      actionBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          pb={1}
        >
          <Button
            onClick={onClose}
            variant="text"
            sx={{
              fontWeight: FontWeight.medium,
              color: 'grey.70',
              fontSize: '1.3125rem',
            }}
          >
            {t('common.actions.cancel', 'Cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            sx={{
              padding: (theme) => theme.spacing(1, 4),
              fontWeight: FontWeight.medium,
              fontSize: '1.3125rem',
            }}
          >
            {t('common.actions.continue', 'Continue')}
          </Button>
        </Stack>
      }
    >
      <Typography
        fontWeight={FontWeight.regular}
        lineHeight="145%"
        sx={{ color: 'grey.80' }}
      >
        {t(
          'modals.addEndorsementsConfirmation.description',
          'Please note that if you change any information a new quote will have to be generated which may affect the quote price.',
        )}
      </Typography>
    </BaseModal>
  );
}
