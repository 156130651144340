import { Box, Grid, GridProps, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoIconButtonToolTip } from '@/components/Button';
import { ControllerDropdown, ControllerTextField } from '@/components/Inputs';
import { CurrencyInputMask } from '@/components/Inputs/TextField/CurrencyInputMask';
import { ISelectOptions } from '@/components/Inputs/interfaces';

import { ICustomizeCoverageSchema } from '../CustomizeCoverageOption.schema';
import { COVERAGE_E_OPTIONS, COVERAGE_F_OPTIONS } from '../constants';
import { formatCurrencyOptions } from '../helpers';

const gridItemProps: GridProps = {
  alignItems: 'center',
  container: true,
  direction: 'row',
  gap: 1,
  item: true,
  xs: 6,
};

interface IProps {
  control: Control<ICustomizeCoverageSchema>;
  isLoading?: boolean;
  coverageBOptions: Array<ISelectOptions<number>>;
  coverageCOptions: Array<ISelectOptions<number>>;
  size: ISize;
  onInfoClick?: () => void;
}

export function CoverageSection({
  control,
  isLoading,
  coverageBOptions,
  coverageCOptions,
  size,
  onInfoClick,
}: IProps) {
  const { t } = useTranslation();

  const isLarge = size === 'lg';

  const coverageEOptions = useMemo(
    () => formatCurrencyOptions(COVERAGE_E_OPTIONS),
    [],
  );
  const coverageFOptions = useMemo(
    () => formatCurrencyOptions(COVERAGE_F_OPTIONS),
    [],
  );

  return (
    <Box mt={isLarge ? 4 : 5} pb={5}>
      <Typography
        variant="h2"
        sx={{
          alignItems: 'center',
          display: 'flex',
          pb: { xs: 2, sm: 1.5 },
        }}
      >
        {t('pages.customizeCoveragePage.coveragesTitle', 'Coverages')}

        <InfoIconButtonToolTip onClick={onInfoClick} />
      </Typography>
      <Grid
        container
        direction={'column'}
        spacing={isLarge ? 3 : 1}
        sx={{
          maxHeight: isLarge ? '250px' : 'none',
          '& .MuiGrid-item': {
            width: isLarge ? '50%' : '100%',
          },
        }}
      >
        <Grid {...gridItemProps}>
          <Tooltip title={t('coverage.otherStructure.description') as string}>
            <Typography color="#3F5F69">II.</Typography>
          </Tooltip>

          <ControllerDropdown
            disabled={isLoading}
            control={control}
            name="otherStructureCoverage"
            fullWidth
            label={t('common.labels.otherStructure', 'Other Structure')}
            options={coverageBOptions}
            sx={{ flex: 1 }}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Tooltip title={t('coverage.personalProperty.description') as string}>
            <Typography color="#3F5F69">III.</Typography>
          </Tooltip>
          <ControllerDropdown
            disabled={isLoading}
            control={control}
            name="personalPropertyCoverage"
            fullWidth
            label={t('common.labels.personalProperty', 'Personal Property')}
            options={coverageCOptions}
            sx={{ flex: 1 }}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Tooltip title={t('coverage.lossOfUse.description') as string}>
            <Typography color="#3F5F69">IV.</Typography>
          </Tooltip>
          <ControllerTextField
            disabled
            control={control}
            fullWidth
            label={t('common.labels.lossOfUse', 'Loss of Use')}
            name="lossOfUseCoverage"
            InputProps={{
              inputComponent: CurrencyInputMask as any,
            }}
            sx={{ flex: 1 }}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Tooltip
            title={t('coverage.personalLiability.description') as string}
          >
            <Typography color="#3F5F69">V.</Typography>
          </Tooltip>
          <ControllerDropdown
            disabled={isLoading}
            control={control}
            name="personalLiabilityCoverage"
            label={t('common.labels.personalLiability', 'Personal Liability')}
            options={coverageEOptions}
            sx={{ flex: 1 }}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Tooltip title={t('coverage.medical.description') as string}>
            <Typography color="#3F5F69">VI.</Typography>
          </Tooltip>
          <ControllerDropdown
            disabled={isLoading}
            control={control}
            label={t(
              'common.labels.medicalPayments',
              'Medical Payments to Others',
            )}
            name="medicalPaymentsCoverage"
            options={coverageFOptions}
            sx={{ flex: 1 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
