import { z } from 'zod';

export function getResetPasswordPageSchema() {
  return z
    .object({
      password: z
        .string()
        .min(1, 'password is required')
        .min(8, 'password requires minimum 8 characters'),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: 'passwords must match',
      path: ['confirmPassword'],
    });
}

export type IResetPasswordPageSchema = z.infer<
  ReturnType<typeof getResetPasswordPageSchema>
>;
