import {
  AddPropertyDocument,
  GetPropertiesDocument,
  GetPropertiesQuery,
  GetPropertySpecsQuery,
  GetPropertySpecsDocument,
  UpdatePropertyDocument,
  ReviewPropertyDocument,
} from '@/graphql/generated';
import { generateEndpoint, getGenericProvidedTages } from '@/helpers';

import { CacheTag } from '../CacheTags';

export const addPropertyEndpoint = {
  ...generateEndpoint(AddPropertyDocument),
  invalidatesTags: [CacheTag.PROPERTY],
};

export const getPropertiesEndpoint = {
  ...generateEndpoint(GetPropertiesDocument),
  providesTags: getGenericProvidedTages<GetPropertiesQuery>(
    'getProperties',
    CacheTag.PROPERTY,
  ),
};

export const getPropertySpecsEndpoint = {
  ...generateEndpoint(GetPropertySpecsDocument),
  providesTags: getGenericProvidedTages<GetPropertySpecsQuery>(
    'getProperty',
    CacheTag.SPECS,
  ),
};

export const reviewPropertyEndpoint = {
  ...generateEndpoint(ReviewPropertyDocument),
};

export const updatePropertyEndpoint = {
  ...generateEndpoint(UpdatePropertyDocument),
  invalidatesTags: [CacheTag.SPECS],
};
