import { GraphQLClient } from 'graphql-request';

import { config } from '../../config/config';
import { getLoginToken } from '../../helpers';

const endpoint = config.api.grapqlEndpoint;
// setup
export const client = new GraphQLClient(endpoint);

export function setClientAuthorizationToken(token?: string) {
  client.setHeader('Authorization', token ? `Bearer ${token}` : '');
}

export function setAuthorizationTokenFromLocalStorage() {
  setClientAuthorizationToken(getLoginToken());
}
