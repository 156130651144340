import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  PaperProps,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';

import { SVG } from '../SVGs/SVG';

interface IProps {
  actionBar?: React.ReactNode;
  children?: React.ReactNode;
  disableBackdropClick?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  open?: boolean;
  startIcon?: React.ReactNode;
  title?: string;
  PaperProps?: PaperProps;
}

export function BaseModal({
  actionBar,
  children,
  disableBackdropClick,
  hideCloseButton,
  onClose,
  open = false,
  startIcon,
  title,
  PaperProps,
}: IProps) {
  const showCloseButton = !hideCloseButton && onClose;
  const backdropOnClick = disableBackdropClick ? undefined : onClose;

  return (
    <Dialog
      PaperProps={{
        ...PaperProps,
        sx: {
          padding: 3,
          position: 'relative',
          ...(PaperProps?.sx || {}),
        },
      }}
      open={open}
      onClose={backdropOnClick}
    >
      <DialogTitle>
        <Stack
          direction="row"
          position="relative"
          spacing={1}
          width="100%"
          pr="1.5rem"
        >
          {startIcon && (
            <Box color="grey.50" mt={0.5} sx={{ '& svg': { height: '30px' } }}>
              {startIcon}
            </Box>
          )}
          <Typography fontSize="1.75rem" fontWeight={600} variant="h3">
            {title}
          </Typography>
          {/* Close Button */}
          {showCloseButton && (
            <Box
              position="absolute"
              right="0"
              sx={{ transform: 'translate(25%, 0%)' }}
              top="0"
            >
              <IconButton onClick={onClose} sx={{ mt: 'calc(0.875rem / 3)' }}>
                <SVG
                  name="Close"
                  height="0.875rem"
                  sx={{ color: 'secondary.main' }}
                  width="0.875rem"
                />
              </IconButton>
            </Box>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ color: 'grey.80' }}>{children}</DialogContent>
      {actionBar && <DialogActions sx={{ px: 3 }}>{actionBar}</DialogActions>}
    </Dialog>
  );
}
