import { Box } from '@mui/material';
import { useCallback } from 'react';

import { ForgotPasswordSuccessModal } from '../../components/Modals/Account/ForgotPasswordSuccessModal';
import { useForgotPasswordMutation } from '../../store/api';

import { IForgotPasswordPageSchema } from './ForgotPassword.schema';
import { ForgotPasswordPage } from './ForgotPasswordPage';

export function ForgotPasswordPageContainer() {
  const [mutate, { isLoading, isSuccess, reset, originalArgs }] =
    useForgotPasswordMutation();

  const onSubmit = useCallback(
    async (values: IForgotPasswordPageSchema) => {
      const email = values.email;

      await mutate({ email });
    },
    [mutate],
  );

  const closeModal = () => {
    reset();
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      minHeight="80vh"
    >
      <ForgotPasswordPage isLoading={isLoading} onSubmit={onSubmit} />
      <ForgotPasswordSuccessModal
        email={originalArgs?.email}
        onClose={closeModal}
        open={isSuccess}
      />
    </Box>
  );
}
