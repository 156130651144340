import { Button, Stack, styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';
import { useEndorsementsStore } from '@/hooks/useEndorsementsStore';
import {
  MidTermEndorsementKeys,
  MidTermEndorsements,
} from '@/store/midtermEndorsements';

import {
  EndorsementCartItem,
  IEndorsementOption,
} from '../EndorsementCartItem/EndorsementCartItem';
import { HEADER_HEIGHT } from '../Header';
import { QUOTE_STEPPER_HEIGHT } from '../QuoteStepper/QuoteStepper';
import { SVG } from '../SVGs/SVG';

const TopContainer = styled(Stack)(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: theme.palette.grey['30'],
  marginLeft: theme.spacing(3),
  padding: theme.spacing(3, 0, 2.5, 0),
}));

const BottomContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 3, 2, 3),
  borderTop: '4px solid',
  borderColor: theme.palette.grey[20],
  marginTop: 'auto',
}));

interface IProps {
  currentCoverages?: ICurrentCoverage[];
  currentPremium: number;
  remainingMonths: number;
  selectedEndorsements?: IEndorsementOption[];
  onCancel?: () => void;
  onContinue?: () => void;
}

export interface ICurrentCoverage {
  coverage: string;
  description: string;
}

export function EndorsementOptionsCart({
  currentCoverages,
  currentPremium,
  remainingMonths,
  selectedEndorsements,
  onCancel,
  onContinue,
}: IProps) {
  const { t } = useTranslation();
  const { input, setInput } = useEndorsementsStore();

  const handleRemoveEndorsement = (type: MidTermEndorsementKeys) => {
    const endorsements = input.filter((item) => item.coverage !== type);
    setInput(endorsements);
  };

  const additionalCosts = useMemo(() => {
    return selectedEndorsements?.reduce(
      (acc, endorsement) => acc + endorsement.cost,
      0,
    );
  }, [selectedEndorsements]);

  const newPremium = useMemo(
    () =>
      additionalCosts
        ? `$${(currentPremium + additionalCosts).toLocaleString()}`
        : '$ - -',
    [additionalCosts, currentPremium],
  );

  const hasSelectedEndorsements =
    selectedEndorsements && selectedEndorsements?.length > 0;

  return (
    <Stack
      minWidth="335px"
      maxWidth="335px"
      minHeight="700px"
      height={`calc(100vh - ${QUOTE_STEPPER_HEIGHT} - ${HEADER_HEIGHT})`}
      sx={{ backgroundColor: 'grey.00' }}
    >
      <TopContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2" lineHeight="145%">
            {t('pages.midtermEndorsements.currentPolicy', 'Current Policy')}
          </Typography>
          <Typography mr={2} lineHeight="145%" fontWeight={FontWeight.regular}>
            ${currentPremium.toLocaleString()}
          </Typography>
        </Stack>
        {currentCoverages && (
          <Stack
            sx={{
              padding: (theme) => theme.spacing(1, 0, 0.5, 1),
            }}
          >
            {currentCoverages?.map((option, index) => (
              <Stack key={index} padding={1}>
                <Typography
                  lineHeight="145%"
                  fontSize="0.9375rem"
                  sx={{ color: 'grey.60' }}
                >
                  {
                    MidTermEndorsements[
                      option.coverage as MidTermEndorsementKeys
                    ]
                  }
                </Typography>
                <Typography fontWeight={FontWeight.semibold} lineHeight="145%">
                  {option.description}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </TopContainer>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        sx={{
          padding: (theme) => theme.spacing(2.5, 2, 1, 3),
        }}
      >
        <Typography variant="h2" lineHeight="145%">
          {t('pages.midtermEndorsements.policyPlusChanges', 'Policy + changes')}
        </Typography>
        <Typography
          fontWeight={FontWeight.regular}
          lineHeight="145%"
          sx={{
            color: additionalCosts ? 'secondary.main' : 'grey.60',
          }}
        >
          {newPremium}
        </Typography>
      </Stack>
      <Stack alignItems="flex-end">
        {hasSelectedEndorsements &&
          selectedEndorsements?.map((endorsement, index) => (
            <EndorsementCartItem
              key={index}
              coverage={endorsement.coverage}
              description={endorsement.description}
              cost={endorsement.cost}
              onDelete={() =>
                handleRemoveEndorsement(
                  endorsement.coverage as MidTermEndorsementKeys,
                )
              }
            />
          ))}
        {!hasSelectedEndorsements && (
          <Stack alignItems="center" py={6} px={3}>
            <SVG
              name="LightBulb"
              width="37"
              sx={{ color: 'primary.main', paddingBottom: 3 }}
            />
            <Typography
              fontWeight={FontWeight.regular}
              textAlign="center"
              lineHeight="145%"
              fontSize="1.0625rem"
              sx={{ color: 'grey.60' }}
            >
              {t(
                'pages.midtermEndorsements.emptyCartMessage',
                'Your coverage option changes will appear here as you make them',
              )}
            </Typography>
          </Stack>
        )}
      </Stack>
      <BottomContainer>
        <Typography variant="h2" lineHeight="145%">
          {t('pages.midtermEndorsements.premiumDue', 'Premium to be due')}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography
            fontWeight={FontWeight.semibold}
            fontSize="1.75rem"
            lineHeight="145%"
          >
            ${additionalCosts?.toFixed(2)?.toLocaleString() || '0.00'}
          </Typography>
          {additionalCosts && (
            <Typography
              lineHeight="145%"
              fontSize="0.9375rem"
              sx={{ color: 'grey.60' }}
            >
              {t('pages.midtermEndorsements.proratedRate', 'prorated rate')}
            </Typography>
          )}
        </Stack>
        {additionalCosts && (
          <Typography lineHeight="145%" sx={{ color: 'grey.80' }}>
            {t(
              'pages.midtermEndorsements.monthlyPayments',
              'or pay {{monthlyPayment}}/month for {{remainingMonths}} months',
              {
                monthlyPayment: `$${(additionalCosts / remainingMonths).toFixed(
                  2,
                )}`,
                remainingMonths,
              },
            )}
          </Typography>
        )}
        <Stack gap={2} paddingTop={3}>
          <Button
            disabled={!additionalCosts}
            variant="contained"
            onClick={onContinue}
            sx={{ fontSize: '1.3125rem', fontWeight: FontWeight.medium }}
          >
            {t('common.actions.continue', 'Continue')}
          </Button>
          <Button sx={{ color: 'grey.70' }} onClick={onCancel}>
            {t('common.actions.cancel', 'Cancel')}
          </Button>
        </Stack>
      </BottomContainer>
    </Stack>
  );
}
