import { Box, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme } from '@/config';
import { FontWeight } from '@/config/theme/typography';

import { QuoteStepperIconItem } from './QuoteStepperIconItem';

export const QUOTE_STEPPER_HEIGHT = '48px';

const Wrapper = styled(Box)`
  background: ${theme.palette.grey[10]};

  height: ${QUOTE_STEPPER_HEIGHT};
`;

const Container = styled(Stack)`
  height: 100%;
  padding: 0 ${theme.spacing(2)};
`;

interface IProps {
  address?: string;
  appId?: string;
  name?: string;
  startDate?: string;
  title?: string;
}

export function QuoteStepper({
  name,
  address,
  appId,
  startDate,
  title,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Wrapper height={QUOTE_STEPPER_HEIGHT}>
      <Container direction="row" spacing={6}>
        <Stack height="100%" alignItems="center" direction="row" spacing={2}>
          <Typography
            fontWeight={FontWeight.semibold}
            textTransform="uppercase"
            sx={{
              color: 'grey.60',
            }}
          >
            {title || t('components.quoteStepper.appId', 'APP ID')}
          </Typography>
          <Typography
            fontWeight={FontWeight.semibold}
            sx={{ color: 'grey.80' }}
          >
            {appId}
          </Typography>
        </Stack>
        <QuoteStepperIconItem color="grey.50" icon="person">
          {name || '--'}
        </QuoteStepperIconItem>
        <QuoteStepperIconItem color="grey.50" icon="home">
          {address || '--'}
        </QuoteStepperIconItem>
        <QuoteStepperIconItem color="grey.50" icon="calendar">
          {startDate || '--'}
        </QuoteStepperIconItem>
      </Container>
    </Wrapper>
  );
}
