import { Check } from '@mui/icons-material';
import { StepIconProps } from '@mui/material';

import { SVG } from '../SVGs/SVG';

import { StepIconRoot } from './PolicyStyledStepperComps';

export interface IProps {
  iconName?: any;
}

export function PolicyCustomStepIcon({ ...props }: IProps & StepIconProps) {
  const { active, completed, className, icon } = props;
  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </StepIconRoot>
  );
}

export function LastIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot
      ownerState={{ completed, active, last: true }}
      className={className}
    >
      <Check width={15} />
    </StepIconRoot>
  );
}

export function FirstIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot
      ownerState={{ completed, active, first: true }}
      className={className}
    >
      <SVG name="PowerOff" width={15} />
    </StepIconRoot>
  );
}
