import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { HomesBackground, StandardBackground } from '../Backgrounds';

interface IProps {
  type: 'homes' | 'standard';
}

export function Layout({ type = 'standard' }: IProps) {
  const Background = type === 'homes' ? HomesBackground : StandardBackground;

  return (
    <Fragment>
      <Background />
      <Outlet />
    </Fragment>
  );
}
