import { IconButton, Stack, Typography } from '@mui/material';

import { FontWeight } from '../../config/theme/typography';
import {
  MidTermEndorsements,
  MidTermEndorsementKeys,
} from '../../store/midtermEndorsements';
import { SVG } from '../SVGs/SVG';

export interface IEndorsementOption {
  coverage: string;
  description: string;
  cost: number;
  onDelete?: () => void;
}

export function EndorsementCartItem({
  coverage,
  description,
  cost,
  onDelete,
}: IEndorsementOption) {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      width="295px"
      padding={1}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'grey.30',
        '&:hover > .delete-button': {
          visibility: 'visible',
        },
      }}
    >
      <Stack>
        <Typography
          fontSize="0.9375rem"
          lineHeight="145%"
          sx={{ color: 'grey.60' }}
        >
          {MidTermEndorsements[coverage as MidTermEndorsementKeys]}
        </Typography>
        <Typography fontWeight={FontWeight.semibold} lineHeight="145%">
          {description}
        </Typography>
        <Typography lineHeight="145%" sx={{ color: 'grey.60' }}>
          + ${cost.toFixed(2).toLocaleString()}
        </Typography>
      </Stack>
      <IconButton
        onClick={onDelete}
        className="delete-button"
        sx={{
          backgroundColor: 'error.main',
          visibility: 'hidden',
          color: 'grey.00',
          borderRadius: 2,
          '&:hover': {
            backgroundColor: 'error.dark',
          },
        }}
      >
        <SVG name="TrashCan" width="14" height="15px" />
      </IconButton>
    </Stack>
  );
}
