import { StyledSVG, StyledSVGProps } from './StyledSVG';

export function LightBulb(props: StyledSVGProps) {
  return (
    <StyledSVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 38"
      {...props}
    >
      <path
        d="M18.5 9a10 10 0 0 1 10 10c0 3.7-2.016 6.934-5 8.667v3a1.667 1.667 0 0 1-1.666 1.667h-6.667a1.666 1.666 0 0 1-1.667-1.667v-3c-2.983-1.733-5-4.967-5-8.667a10 10 0 0 1 10-10Zm3.334 25v1.667a1.667 1.667 0 0 1-1.667 1.667h-3.333a1.667 1.667 0 0 1-1.667-1.667V34h6.667Zm10-16.666h5v3.333h-5v-3.333Zm-31.667 0h5v3.333h-5v-3.333Zm20-16.667v5h-3.333v-5h3.333ZM6.7 4.834 10.25 8.4l-2.366 2.35-3.55-3.533L6.7 4.834Zm20.05 3.55 3.534-3.55 2.383 2.383-3.55 3.533-2.367-2.366Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
}
