const PUBLIC_URL = process.env.PUBLIC_URL;

// documents
export const diligentSearchPDF =
  PUBLIC_URL + '/documents/statement-of-diligent-effort-form.pdf';

// images

export const HouseBodySelectedPNG =
  PUBLIC_URL + '/images/house-body-selected.png';
export const HouseWindForcePNG = PUBLIC_URL + '/images/house-wind-force.png';
export const HouseSelectedPNG = PUBLIC_URL + '/images/house-selected.png';
export const ThisPageIsEmptyPNG = PUBLIC_URL + '/images/this_page_is_empty.png';
