import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HorizontalRuler } from '../../components/HorizontalRuler';
import { IQuoteOption } from '../../store/quoter';

import { SuccessSummaryCard } from './SuccessSummaryCard';

const MAX_WIDTH = '782px';

interface IProps {
  quotes: IQuoteOption[];
}

export function QuoterSuccessPage({ quotes }: IProps) {
  const { t } = useTranslation();

  return (
    <Box maxWidth={MAX_WIDTH} mx="auto" mt={11}>
      <Typography
        sx={{
          fontSize: '2.5rem',
          fontWeight: 600,
          color: 'secondary.main',
          mb: 2,
        }}
      >
        {t('pages.quoterSuccessPage.title', 'Success!')}
      </Typography>
      <Typography
        sx={{
          fontSize: '1.0625rem',
          color: 'grey.90',
          lineHeight: '24.65px',
          mb: 5,
        }}
      >
        {t(
          'pages.quoterSuccessPage.description',
          `Congratulations for purchasing your homeowners coverage! You now have
        the option to purchase on an annual or monthly basis.`,
        )}
      </Typography>
      <Stack direction="row" gap={4} mb={9} flexWrap="wrap">
        {quotes.map((quote, index) => (
          <SuccessSummaryCard
            key={quote.id}
            monthly={quote.prices?.monthlyPremium}
            premium={quote.prices?.fullPremium}
            quoteId={quote.id}
          />
        ))}
      </Stack>
      <HorizontalRuler />
      <Typography sx={{ fontSize: '1.75rem', fontWeight: 600, mt: 4, mb: 2 }}>
        {t('pages.quoterSuccessPage.nextSteps', 'Next Steps')}
      </Typography>
      <Typography
        sx={{
          lineHeight: '24.65px',
          fontSize: '1.0625rem',
          color: 'grey.90',
        }}
      >
        {t(
          'pages.quoterSuccessPage.nextStepsDescription',
          `After selecting an option above, you will be redirected to our payments
        portal. Once Payment is received we will follow up shortly with your
        home inspection details.`,
        )}
      </Typography>
    </Box>
  );
}
