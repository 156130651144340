import { IQuoterUnderwritingChecksSchema } from './QuoterUnderwritingChecksPage.schema';

export function generateEmptyUnderwritingChecks(): IQuoterUnderwritingChecksSchema {
  return {
    condemnedProperty: '',
    farmOrRanch: '',
    commercialProperty: '',
    homemadeOrRebuilt: '',
    nonHabitational: '',
    fraternityOrSorority: '',
    fourOrMoreMortgages: '',
    underConstruction: '',
    mobileOrPrefabricatedHome: '',
    divingBoard: '',
    businessProperty: '',
    misrepresentationArsonOrFraud: '',
  };
}

/**
 * Converts the object into an array of [name, value] pairs of radio's checked yes
 *
 * @export
 * @param {IQuoterUnderwritingChecksSchema} underwritingChecks
 * @return {*} {Array<[string, string]>}
 */
export function filterIneligibleUnderwritingChecks(
  underwritingChecks: IQuoterUnderwritingChecksSchema,
) {
  return Object.entries(underwritingChecks).filter(
    ([key, value]) => value === 'true',
  );
}

/**
 * Takes an array of ineligible underwriting checks and returns them mapped
 * to the correct label
 *
 * @export
 * @param {[string, string][]} ineligibleUnderwritingChecks
 * @param {Array<{ label?: string; name: string }>} options
 * @return {*}
 */
export function remapIneligibleUnderwritingChecks(
  ineligibleUnderwritingChecks: [string, string][],
  options: Array<{ label?: string; name: string }>,
) {
  return ineligibleUnderwritingChecks.map(([name, value]) => ({
    name: name,
    label: options.find((option) => option.name === name)?.label || '',
    value: value === 'true',
  }));
}
