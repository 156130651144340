import InfoIcon from '@mui/icons-material/Info';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps extends IconButtonProps {
  tooltipText?: string;
}

export const InfoIconButton = React.forwardRef<HTMLButtonElement, IProps>(
  (props, ref) => {
    return (
      <IconButton
        disableTouchRipple
        disableFocusRipple
        disableRipple
        ref={ref}
        {...props}
      >
        <InfoIcon sx={{ paddingLeft: 0.5, color: 'primary.dark' }} />
      </IconButton>
    );
  },
);
InfoIconButton.displayName = 'InfoIconButton';

export function InfoIconButtonToolTip({ tooltipText, ...buttonProps }: IProps) {
  const { t } = useTranslation();

  const defaultTooltipText = t(
    'common.labels.clickLearnMore',
    'Click for info',
  );

  return (
    <Tooltip title={tooltipText || defaultTooltipText}>
      <InfoIconButton {...buttonProps} />
    </Tooltip>
  );
}
