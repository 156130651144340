import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SVG } from '../../components/SVGs/SVG';

interface IProps {
  quoteId: string;
  premium?: number;
  monthly?: number;
}

export function SuccessSummaryCard({ quoteId, premium, monthly }: IProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        px: 3,
        pt: 2,
        borderBottom: 'solid 1px',
        borderColor: 'grey.30',
        borderRadius: 2,
        backgroundColor: 'grey.00',
        width: '375px',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={0.5}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: '1.0625rem',
            color: 'grey.80',
          }}
        >
          {t('common.labels.quote', 'Quote')}: {quoteId}
        </Typography>
        <IconButton
          sx={{
            marginTop: -1,
            marginRight: -1,
          }}
          onClick={() => alert('downloading quote PDF...')}
        >
          <SVG
            name="Download"
            sx={{
              color: 'grey.60',
              width: '1.5rem',
            }}
          />
        </IconButton>
      </Stack>
      <Typography
        variant="h2"
        sx={{
          marginBottom: 0.5,
          color: 'grey.90',
          fontSize: '1.3125rem',
        }}
      >
        {t('common.currency.USD', {
          value: premium,
        })}
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          mb: 2.5,
          color: 'grey.80',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {t(
          'pages.customizeCoveragePage.monthlyTitle',
          'or pay {{monthly}}/month for 12 months',
          {
            monthly: t('common.currency.USD', {
              value: monthly,
            }),
          },
        )}
      </Typography>
    </Box>
  );
}
