import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MUIAccodion, AccordionProps } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

interface IProps extends AccordionProps {
  title?: string;
}

export function Accordion({ title, children, ...rest }: IProps) {
  return (
    <MUIAccodion {...rest}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h2"
          sx={{
            color: 'grey.100',
            '&:hover': {
              color: 'success.main',
            },
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccodion>
  );
}
