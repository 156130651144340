import { IconButton, Typography, Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFuseResults } from '@/helpers/fuse';

import { SearchBar } from '../Inputs';
import { SVG } from '../SVGs/SVG';

import { IStackedListOption, StackedList } from './StackedList';

const fuseOptions = {
  keys: ['label', 'subLabel'],
};

interface IProps<T> extends BoxProps {
  options: IStackedListOption<T>[];
  loading?: boolean;
  onSelected?: (value: T) => void;
  searchPlaceholder?: string;
}

export function SearchableStackedList<T>({
  options,
  loading,
  onSelected,
  ...boxProps
}: IProps<T>) {
  const { t } = useTranslation();
  const [filteredOptions, setFilteredOptions] =
    useState<IStackedListOption<T>[]>(options);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [hideResults, setHideResults] = useState(true);

  const reset = useCallback(() => {
    setFilteredOptions(options);
    setSearchValue('');
  }, [options]);

  useEffect(() => {
    // reset filtered options if options change
    reset();
  }, [options, reset]);

  const searchFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const keyword = event.target.value;

      if (keyword !== '') {
        const newFilteresOptionsgetFuseResults = getFuseResults<
          IStackedListOption<T>
        >(options, keyword, fuseOptions);
        setFilteredOptions(newFilteresOptionsgetFuseResults);
        setSearchValue(keyword);
        setHideResults(false);
      } else {
        reset();
      }
    },
    [options, reset],
  );

  return (
    <Box {...boxProps}>
      {showSearchBar ? (
        <SearchBar
          searchFilter={searchFilter}
          searchValue={searchValue}
          startAdornment={
            <SVG name="Search" sx={{ color: 'grey.50' }} width="15px" />
          }
          placeholder={t(
            'pages.quoterSelectClientPage.searchClients',
            'Client name or email',
          )}
          onClear={() => {
            setShowSearchBar(false);
            setHideResults(true);
            reset();
          }}
        />
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: 32 }}
        >
          <Typography variant="h2">
            {t(
              'pages.quoterSelectClientPage.rightPanelTitle',
              'Current Clients',
            )}
          </Typography>
          {!loading && (
            <IconButton
              disableRipple
              size="small"
              onClick={() => setShowSearchBar(true)}
            >
              <SVG name="Search" color="#688A3F" width="16px" />
            </IconButton>
          )}
        </Stack>
      )}
      <StackedList
        onSelected={onSelected}
        options={hideResults && showSearchBar ? [] : filteredOptions}
        loading={loading}
        sx={{ mt: 2 }}
      />
    </Box>
  );
}
