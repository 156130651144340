import { Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Fragment } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { EditButton } from '../../components/EditButton';
import { FullscreenLoader } from '../../components/Loaders';
import { EditUserInformationModal, IUser } from '../../components/Modals';
import { formatPhoneNumber } from '../../helpers/formatters';

import { IUserInfoSchema } from './MyInformationpage.schema';

const DetailText = styled(Typography)(({ theme }) => ({
  fontSize: '1.0625rem',
  lineHeight: '145%',
  color: theme.palette.grey[90],
}));

interface IProps {
  initialValues: IUser;
  loading: boolean;
  onClose: () => void;
  onSave: SubmitHandler<IUserInfoSchema>;
  open: boolean;
  openDialog: () => void;
}

export function UserInfo({
  initialValues,
  loading,
  onClose,
  onSave,
  open,
  openDialog,
}: IProps) {
  return (
    <Fragment>
      <Paper sx={{ padding: 5 }} elevation={0}>
        <Stack justifyContent="space-between" direction="row" pb={1}>
          <Typography variant="h1" lineHeight="145%">
            {initialValues.firstName + ' ' + initialValues.lastName}
          </Typography>
          <EditButton onClick={openDialog} />
        </Stack>
        <DetailText>{formatPhoneNumber(initialValues.phone)}</DetailText>
        <DetailText>{initialValues.email}</DetailText>
      </Paper>
      {loading ? (
        <FullscreenLoader> Updating your information</FullscreenLoader>
      ) : (
        <EditUserInformationModal
          initialValues={initialValues}
          onClose={onClose}
          onSave={onSave}
          open={open}
        />
      )}
    </Fragment>
  );
}
