import SendIcon from '@mui/icons-material/Send';

import { Button } from '@/components/Button';

import { BaseModal } from '../BaseModal';

interface IProps {
  children?: React.ReactNode;
  hideActionBar?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  open: boolean;
  title: string;
}

export function EmailSentModal({
  children,
  hideActionBar,
  hideCloseButton = true,
  onClose,
  open,
  title,
}: IProps) {
  const showActionBar = !hideActionBar && onClose;

  return (
    <BaseModal
      hideCloseButton={hideCloseButton}
      onClose={onClose}
      open={open}
      startIcon={<SendIcon />}
      title={title}
      actionBar={
        showActionBar && (
          <Button onClick={onClose} variant="text">
            Close
          </Button>
        )
      }
    >
      {children}
    </BaseModal>
  );
}
