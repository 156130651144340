import { useNavigate } from 'react-router-dom';

export function PoliciesPageContainer() {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate('/policies/12345')}>
      <p>Policies</p>
      <p>Click for policy detail page</p>
    </div>
  );
}
