import { Box, Grid, styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  background: #fff;

  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

interface IProps {
  children?: React.ReactNode;
  leftSlot?: React.ReactNode;
  middleSlot?: React.ReactNode;
}

export function CardActionBar({
  children,
  leftSlot,
  middleSlot,
  ...props
}: IProps) {
  return (
    <Container
      sx={{
        borderRadius: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: 'solid 1px',
        borderColor: 'grey.40',
      }}
      {...props}
    >
      <Grid container>
        <Grid item xs={4} display="flex" alignItems="flex-start">
          {leftSlot}
        </Grid>
        <Grid item xs={4} display="flex" alignItems="center">
          <Box sx={{ margin: 'auto' }}>{middleSlot}</Box>
        </Grid>
        <Grid item xs={4} display="flex" alignItems="center">
          <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
        </Grid>
      </Grid>
    </Container>
  );
}
