import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  quoteId: string;
  premium?: number;
  monthly?: number;
}

export function SummaryCardHeader({ quoteId, premium, monthly }: IProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ px: 2, pt: 2, borderBottom: 'solid 1px', borderColor: 'grey.30' }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: '1.0625rem',
          marginBottom: 0.5,
          color: 'grey.80',
        }}
      >
        {t('common.labels.quote', 'Quote')}: {quoteId}
      </Typography>
      <Typography
        variant="h2"
        sx={{
          marginBottom: 0.5,
          color: 'grey.90',
        }}
      >
        {t('common.currency.USD', {
          value: premium,
        })}
      </Typography>
      <Typography
        sx={{
          fontSize: '1.0625rem',
          fontWeight: 500,
          mb: 2.5,
          color: 'grey.80',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {t(
          'pages.customizeCoveragePage.monthlyTitle',
          'or pay {{monthly}}/month for 12 months',
          {
            monthly: t('common.currency.USD', {
              value: monthly,
            }),
          },
        )}
        <InfoIcon sx={{ paddingLeft: 0.5, color: '#688A3F' }} />
      </Typography>
    </Box>
  );
}
