import { useMemo } from 'react';

import { useAuth } from '../../hooks';
import { useGetOrganizationQuery } from '../../store/api';

import { MyTeamPage } from './MyTeamPage';

export function MyTeamPageContainer() {
  const { user: { organization } = {}, userIsAdmin } = useAuth();

  const orgId = organization?._id || '';

  const { data: { getOrganization: company } = { data: {} }, isLoading } =
    useGetOrganizationQuery({
      id: orgId,
    });

  const team = useMemo(() => {
    return company?.team || [];
  }, [company]);

  return (
    <MyTeamPage
      team={team}
      loading={isLoading}
      userIsAdmin={userIsAdmin}
      orgId={orgId}
    />
  );
}
