import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';

import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { SVG } from '../../components/SVGs/SVG';
import { shadowBoxes } from '../../config/theme/base';
import { FontWeight } from '../../config/theme/typography';

const PAGE_PADDING = 9;
const MAX_WIDTH = '1440px';

const backgrounds = {
  green:
    'linear-gradient(0deg, rgba(0, 160, 80, 0.62), rgba(0, 160, 80, 0.82))',
  blue: 'linear-gradient(0deg, rgba(0, 140, 180, 0.62), rgba(0, 140, 180, 0.82))',
  steel:
    'linear-gradient(0deg, rgba(130, 170, 170, 0.62), rgba(130, 170, 170, 0.82))',
};

function Card({ basis = '50%', background = 'green', children }: any) {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      borderRadius={1.5}
      flexBasis={basis}
      height="300px"
      sx={{
        background: backgrounds[background as keyof typeof backgrounds],
        boxShadow: shadowBoxes.type1,
        padding: 4,
      }}
    >
      <Box>{children}</Box>
    </Box>
  );
}
export function DashboardPage() {
  return (
    <Box sx={{ bgcolor: 'grey.10' }}>
      <Box padding={PAGE_PADDING} maxWidth={MAX_WIDTH} margin="0 auto">
        <Typography
          fontSize="1.3125rem"
          fontWeight={FontWeight.bold}
          lineHeight="30.45px"
          variant="h2"
        >
          Hey Dwight,
        </Typography>
        <Typography
          fontSize="1.3125rem"
          fontWeight={FontWeight.bold}
          lineHeight="30.45px"
          variant="h2"
        >
          Welcome to Homeowners!
        </Typography>
        <Typography fontSize="1.0625rem" sx={{ color: 'grey.80', mt: 2 }}>
          Let’s create your first quote
        </Typography>
        <ButtonLink
          to="/quoter/select-client"
          color="secondary"
          variant="contained"
          sx={{ fontWeight: FontWeight.semibold, mt: 5 }}
        >
          Create a quote
        </ButtonLink>
      </Box>
      <Box
        sx={{
          position: 'relative',
          height: '40px',
        }}
      >
        <Box
          sx={{
            height: '1px',
            backgroundColor: 'grey.30',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '15%',
            bottom: '15px',
            zIndex: 1,
          }}
        >
          <SVG name="HouseOutline" height="212px" />
        </Box>
      </Box>
      <Box padding={PAGE_PADDING} maxWidth={MAX_WIDTH} margin="0 auto">
        <Typography
          fontSize="1.75rem"
          fontWeight={FontWeight.bold}
          sx={{ mb: 4 }}
        >
          Resources
        </Typography>
        <Box
          sx={{
            bgcolor: 'transparent',
            height: '300px',
            position: 'relative',
            width: '100%',
          }}
        >
          <Stack direction="row" gap={4}>
            <Card basis="33.33%">
              <Stack
                direction="row"
                spacing={1}
                alignItems="flex-end"
                flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
              >
                <Typography
                  fontSize={{
                    xs: '1.15rem',
                    sm: '1.25rem',
                    md: '1.5rem',
                    lg: '1.75rem',
                  }}
                  fontWeight={FontWeight.bold}
                >
                  Learn the basics on how everything works
                </Typography>
                <IconButton>
                  <SVG name="OutlinedRightArrow" height={48} width={48} />
                </IconButton>
              </Stack>
            </Card>
            <Card basis="40%" background="blue">
              <Stack
                direction="row"
                spacing={1}
                alignItems="flex-end"
                flexWrap={{ xs: 'wrap', xl: 'nowrap' }}
              >
                <Box maxWidth="260px">
                  <Typography
                    color="#fff"
                    fontSize={{
                      xs: '1.15rem',
                      sm: '1.25rem',
                      md: '1.5rem',
                      lg: '1.75rem',
                    }}
                    fontWeight={FontWeight.bold}
                  >
                    Marketing Material
                  </Typography>
                  <Typography
                    color="#fff"
                    fontSize="1rem"
                    fontWeight={FontWeight.medium}
                  >
                    Download our presentation that covers everything you need to
                    sell this product.
                  </Typography>
                </Box>
                <Button
                  endIcon={<CloudDownloadOutlinedIcon />}
                  size="medium"
                  sx={{
                    borderColor: 'white',
                    borderRadius: '40px',
                    color: 'white',
                    fontSize: '1.3125rem',
                    fontWeight: FontWeight.medium,
                    px: 4,
                  }}
                  variant="outlined"
                >
                  Download
                </Button>
              </Stack>
            </Card>
            <Card basis="20%" background="steel">
              <Stack
                direction="row"
                spacing={4}
                alignItems="flex-end"
                flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
              >
                <Typography
                  fontSize={{
                    xs: '1.15rem',
                    sm: '1.25rem',
                    md: '1.5rem',
                    lg: '1.75rem',
                  }}
                  fontWeight={FontWeight.bold}
                >
                  Frequently Asked Questions
                </Typography>
                <IconButton>
                  <SVG name="OutlinedRightArrow" height={48} width={48} />
                </IconButton>
              </Stack>
            </Card>
          </Stack>
          <Box
            sx={{
              alignItems: 'center',
              bgcolor: 'transparent',
              display: 'flex',
              height: '100%',
              left: 0,
              pointerEvents: 'none',
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <SVG
              name="BackgroundSwoop"
              preserveAspectRatio="xMinYMid meet"
              sx={{
                bgcolor: 'transparent',
                height: '225px',
                width: '100%',
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box bgcolor="secondary.main" minHeight="400px" padding={11}>
        <Box maxWidth={MAX_WIDTH} margin="0 auto">
          <Stack direction="row" spacing={4} justifyContent="center">
            <Box maxWidth="400px">
              <Typography
                color="#5DFEA8"
                fontSize="1.75rem"
                fontWeight={FontWeight.bold}
              >
                Still have questions?
              </Typography>
              <Typography color="#fff">
                You can use this form to ask us anything and we’ll be standing
                by to answer.
              </Typography>
            </Box>
            <Box maxWidth="650px" width="100%" component="form">
              <Box height="2rem" />
              <TextField
                fullWidth
                placeholder="What’s your question?"
                multiline
                rows={4}
                variant="outlined"
                sx={{
                  '& fieldset': {
                    border: 'none',
                  },
                  '& textarea': {
                    padding: 2,
                  },
                  '& textarea::placeholder': {
                    opacity: 0.95,
                  },
                }}
                InputProps={{
                  sx: {
                    background: 'rgba(255, 255, 255, 0.09)',
                    border: '1px solid rgba(255, 255, 255, 0.22)',
                    color: 'white',
                    padding: 0,
                  },
                }}
              />
              <Box textAlign="right" sx={{ mt: 4 }}>
                <Button disabled size="small" variant="contained">
                  Submit
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
