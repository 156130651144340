import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FullscreenLoader } from '../../components/Loaders';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { useUnderwriteCheckMutation } from '../../store/api';

import { QuoterUnderwritingChecksPage } from './QuoterUnderwritingChecksPage';
import { IQuoterUnderwritingChecksSchema } from './QuoterUnderwritingChecksPage.schema';

const FIRST_PAGE_ROUTE = '/quoter/select-client';
export const PREV_PAGE_ROUTE = '/quoter/diligent-search';
const NEXT_PAGE_ROUTE = '/quoter/property-details';

export function QuoterUnderwritingChecksPageContainer() {
  const navigate = useNavigate();
  const { input, setInput, reset } = useQuoterStore();

  const initialValues: IQuoterUnderwritingChecksSchema | undefined =
    input?.underwritingChecks;

  // hooks
  const [underwriteCheck, { isLoading: underwriteCheckLoading }] =
    useUnderwriteCheckMutation();

  // handlers
  const onSubmitHandler = useCallback(
    async (value: IQuoterUnderwritingChecksSchema) => {
      const args = {
        clientId: input?.selectClient?._id || '',
        propertyId: input?.selectProperty?._id || '',

        condemnedProperty: value?.condemnedProperty === 'true',
        farmOrRanch: value?.farmOrRanch === 'true',
        commercialProperty: value?.commercialProperty === 'true',
        homemadeOrRebuilt: value?.homemadeOrRebuilt === 'true',
        nonHabitational: value?.nonHabitational === 'true',
        fraternityOrSorority: value?.fraternityOrSorority === 'true',
        fourOrMoreMortgages: value?.fourOrMoreMortgages === 'true',
        underConstruction: value?.underConstruction === 'true',
        mobileOrPrefabricatedHome: value?.mobileOrPrefabricatedHome === 'true',
        divingBoard: value?.divingBoard === 'true',
        businessProperty: value?.businessProperty === 'true',
        misrepresentationArsonOrFraud:
          value?.misrepresentationArsonOrFraud === 'true',
      };

      const results = await underwriteCheck(args);

      if ('data' in results) {
        if (results?.data?.underwritingCheckHH?.success) {
          setInput({
            underwritingChecks: {
              ...value,
            },
          });

          navigate(NEXT_PAGE_ROUTE);
        } else {
          alert(
            results.data.underwritingCheckHH?.message ||
              'Underwrite check failed',
          );
          reset();

          navigate(FIRST_PAGE_ROUTE);
        }
      }
    },
    [input, navigate, reset, setInput, underwriteCheck],
  );

  return (
    <Fragment>
      {underwriteCheckLoading && (
        <FullscreenLoader>
          Processing underwriting checks data ...
        </FullscreenLoader>
      )}
      <QuoterUnderwritingChecksPage
        onSubmit={onSubmitHandler}
        initialValues={initialValues}
      />
    </Fragment>
  );
}
