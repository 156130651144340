import {
  Box,
  Container,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableActionRow } from '../../components/TableActionRow';

interface IDocument {
  title: string;
  dateSigned?: Date;
  url: string;
}
interface IProps {
  agreements?: IDocument[];
  loading?: boolean;
}

export function AgreementsNoticesPage({ agreements, loading }: IProps) {
  const { t } = useTranslation();
  return (
    <Container sx={{ paddingY: 5 }}>
      <Paper sx={{ padding: 5 }} elevation={0}>
        <Typography variant="h2" lineHeight="1.45rem">
          {t('pages.agreementsNoticesPage.agreements', 'Agreements')}
        </Typography>
        <Box padding={0.5} />
        {loading ? (
          <>
            <Skeleton width="100%" height={55} />
            <Skeleton width="100%" height={55} />
          </>
        ) : (
          <Stack>
            {agreements?.map((doc, index) => (
              <TableActionRow
                key={index}
                left={doc.title}
                middle={
                  doc.dateSigned
                    ? `Signed ${doc.dateSigned.toDateString()}`
                    : 'Needs Signature'
                }
                middleColor={doc.dateSigned ? 'inherit' : 'error.dark'}
                right={doc.dateSigned ? 'View' : 'Sign'}
                href={doc.url}
              />
            ))}
          </Stack>
        )}
        <Box padding={1.5} />
        <Typography variant="h2" lineHeight="1.45rem">
          {t('pages.agreementsNoticesPage.notices', 'Notices')}
        </Typography>
        <Box padding={0.5} />
        <Stack>
          <TableActionRow
            left="Terms of Use"
            right="View"
            onClick={() => alert('TODO: show Terms of Use')}
          />
          <TableActionRow
            left="Privacy Policy"
            right="View"
            onClick={() => alert('TODO: show Privacy Policy')}
          />
          <TableActionRow
            left="GDPR Privacy Policy"
            right="View"
            onClick={() => alert('TODO: show GDPR Privacy Policy')}
          />
        </Stack>
      </Paper>
    </Container>
  );
}
