import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/configureStore';
import {
  IEndorsementsInput,
  reset,
  setInput,
} from '../store/midtermEndorsements';

export function useEndorsementsStore() {
  const dispatch = useDispatch();

  const setInputEventHandler = useCallback(
    (payload: IEndorsementsInput[]) => {
      dispatch(setInput(payload));
    },
    [dispatch],
  );

  const resetEventHandler = useCallback(() => {
    reset();
    dispatch(reset());
  }, [dispatch]);

  return {
    input: useSelector((state: RootState) => state.endorsements.input),
    setInput: setInputEventHandler,
    reset: resetEventHandler,
  };
}
