import { Box, BoxProps, Stack, Typography } from '@mui/material';

import { shadowBoxes } from '@/config/theme/base';
import { FontWeight } from '@/config/theme/typography';

interface IProps extends BoxProps {
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  title?: string;
}

export function BasicInfoCard({
  children,
  startIcon,
  title,
  ...props
}: IProps) {
  return (
    <Box
      {...props}
      sx={{
        backgroundColor: 'grey.00',
        borderRadius: 2,
        boxShadow: shadowBoxes.type1,
        px: 2,
        py: 1.5,
        width: '100%',
        ...props.sx,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {startIcon}
        <Box>
          <Typography
            sx={{
              color: 'secondary.main',
              fontSize: '1.0625rem',
              fontWeight: FontWeight.semibold,
              lineHeight: 1.4,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{ color: 'grey.80', fontSize: '1rem', lineHeight: 1.4 }}
          >
            {children}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
