import { Box, Typography } from '@mui/material';

import { EmailSentModal } from './EmailSentModal';

interface IProps {
  email?: string;
  onClose: () => void;
  open: boolean;
}

export function ForgotPasswordSuccessModal({ email, onClose, open }: IProps) {
  return (
    <EmailSentModal onClose={onClose} open={open} title="Reset email sent">
      <Typography maxWidth={400}>
        Please check{' '}
        <Box component="span" color="grey.80">
          {email}
        </Box>{' '}
        for a password reset link.
      </Typography>
    </EmailSentModal>
  );
}
